import React, { useState } from "react";
import { Tabs } from "antd";
import { CareServiceBooked } from "../../components/ServiceBooked/CareServicebooked";
import LayoutMom from "../../components/LayoutCommon/LayoutMom";
import { MedicalServiceBooked } from "../../components/ServiceBooked/MedicalServiceBooked";

const itemTabs = ["Dịch vụ", "Lịch khám"];
export const ServiceBooked = () => {
    const [activeKey, setActiveKey] = useState("0");
    return (<>
        <LayoutMom>
            <div className="container ml-auto mr-auto">
                <Tabs
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    className="m-5"
                    type="card"
                    items={itemTabs.map((item, i) => ({
                        label: (
                            <span
                                style={{
                                    fontWeight: activeKey === `${i}` ? "bold" : "normal",
                                    color: "black",
                                }}
                            >
                                {item}
                            </span>
                        ),
                        key: `${i}`,
                        children:
                            i === 0 ? (
                                <CareServiceBooked />
                            ) : (
                                <MedicalServiceBooked />
                            ),
                    }))} />
            </div >
        </LayoutMom>
    </>)
}