import React, { useEffect, useRef, useState } from "react";
import Header from '../../../components/Header/Header';
import { useLocation } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import './style.css'
import { toast } from "react-toastify";
import axios from "axios";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import dayjs from "dayjs";
import CustomModal from "../../../components/Common/CustomModalConfirm";
import { KYC_KEY, KYC_URL_PORT } from "../../../api/axiosInstance";

const ConfirmNursing = () => {
  const information = useLocation();
  const { state } = information
  const [account, setAccount] = useState({ ...state })
  const navigate = useNavigate();
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const frontInputRef = useRef(null);
  const backInputRef = useRef(null);
  const [frontBlob, setFrontBlob] = useState('');
  const [backBlob, setBackBlob] = useState('');
  const [isDragEnter, setIsDragEnter] = useState(false);

  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const [dataFront, setdataFront] = useState()
  const [dataBack, setdataBack] = useState()
  const [canSubmit, setCanSubmit] = useState({
    front: false,
    back: false
  })
  const [isLoading, setIsLoading] = useState(false);
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (frontFile) {
      setFrontBlob(URL.createObjectURL(frontFile));
    }

    if (backFile) {
      setBackBlob(URL.createObjectURL(backFile));
    }

    return () => {
      URL.revokeObjectURL(frontBlob);
      URL.revokeObjectURL(backBlob);
    };
  }, [frontFile, backFile]);

  const uploadToKYC = async (file, type) => {
    const formData = new FormData();
    formData.append('file', file);
    const respUploadImage = await axios.post(`${KYC_URL_PORT}/${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${KYC_KEY}`
      }
    });

    return respUploadImage;
  }

  useEffect(() => {
    if (dataFront) {
      const acc = {
        ...account,
        FullName: dataFront.name,
        NumberCard: dataFront.number,
        Sex: dataFront.sex === "Nam",
        DateOfBirth: dayjs(dataFront.birth, "DD/MM/YYYY").toISOString(),
        HomeAddress: dataFront.home
      }
      setAccount(acc)
    }

    if (dataBack) {
      const acc = {
        ...account,
        DateOfIssue: dayjs(Date(dataFront.issueDate)).toISOString()
      }
      setAccount(acc)
    }
  }, [dataBack, dataFront])

  useEffect(() => {
    console.log(account)
  }, [account])

  const onFileChange = async (e, type, setFileFunc, setDataFunc) => {
    try {
      setIsLoading(true)
      const newFile = e.target.files?.[0];
      if (newFile && newFile.type.match("image.*")) {
        frontInputRef.current && (frontInputRef.current.value = null);
        setFileFunc(newFile);
        const respUploadImage = await uploadToKYC(newFile, type)
        if (respUploadImage.data.success == true) {
          toast.success("Thêm thông tin thành công")
          setDataFunc(respUploadImage.data.data)
        } else {
          toast.error(respUploadImage.data.message);
        }
        setIsLoading(false)
        setCanSubmit({ ...canSubmit, [type]: respUploadImage.data.success })
      } else {
        toast.error("Lỗi đăng tải ảnh.");
      }
    } catch (error) {
      toast.error("Lỗi hệ thống: " + error.message);
    } finally {
      setIsLoading(false)
    }
  };

  const onDragLeave = () => {
    setIsDragEnter(false);
  };

  const onDragEnter = () => {
    setIsDragEnter(true);
  };

  const handleConfirm = () => {
    setIsOpenModalConfirm(false)
    navigate('/confirm-otp-code', { state: { account: account } })
  }

  function maskLastFourDigits(numberString) {
    if (numberString?.length <= 4) {
      return '*'.repeat(numberString.length); // Nếu chuỗi có ít hơn 4 ký tự
    }
    return numberString?.slice(0, -4) + '****';
  }

  return (
    <>
      <Header />
      <CustomModal isOpenModal={isOpenModalConfirm} handleConfirm={handleConfirm} handleCancel={() => setIsOpenModalConfirm(false)}
        textConfirm="Chấp nhận"
        header="Xác nhận thông tin"
        content={(<><h3 className="text-[16px] text-black pb-3">Bạn có chắc chắn đồng ý đăng ký tài khoản dành cho <strong><i>người chăm sóc</i></strong> không?</h3>
          <h3 className="text-[16px] text-black pb-3">Một khi chấp nhận thông tin cá nhân của bạn sẽ được lưu vào trong hệ thống của chúng tôi</h3>
        </>)}
      />
      <LoadingPage isLoading={isLoading} />
      <div className="cursor-pointer w-[100px] btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Quay lại
      </div>
      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-2">
            <h4 className="text-4xl font-semibold mb-4">Xác nhận thông tin cá nhân cho người chăm sóc</h4>
            <p className="text">Đăng tải căng cước công dân của bạn</p>

            <div className="flex space-x-3">
              <div
                onDrop={(e) => onFileChange(e, "front", setFrontFile, setdataFront)}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                style={{ "--bg": `url(${frontBlob})` }}
                onClick={() => frontInputRef.current && frontInputRef.current.click()}
                className={` ${frontBlob ? "before-bg-file" : ""} relative p-6 cursor-pointer h-[200px] w-[570px] mx-auto mt-10 border-2 border-dashed  ${canSubmit.front ? "border-blueColor" : "border-red-500"} flex flex-col items-center text-base leading-[1.6] select-none`}
              >
                <input ref={frontInputRef} onChange={(e) => onFileChange(e, "front", setFrontFile, setdataFront)} type="file" accept="image/*" hidden />
                <p className="text-center my-3 pointer-events-none">Mặt trước của thẻ:</p>
                <p className="text-center text-pinkColor pointer-events-none">
                  {isDragEnter ? "Drop photos here" : "Drag and drop photos here, or click to select photos"}
                </p>
              </div>

              <div
                onDrop={(e) => onFileChange(e, "back", setBackFile, setdataBack)}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                style={{ "--bg": `url(${backBlob})` }}
                onClick={() => backInputRef.current && backInputRef.current.click()}
                className={`${frontBlob ? "before-bg-file" : ""} relative p-6 cursor-pointer h-[200px] w-[570px] mx-auto mt-10 border-2 border-dashed ${canSubmit.back ? "border-blueColor" : "border-red-500"}  flex flex-col items-center text-base leading-[1.6] select-none`}
              >
                <input ref={backInputRef} onChange={(e) => onFileChange(e, "back", setBackFile, setdataBack)} type="file" accept="image/*" hidden />
                <p className="text-center my-3 pointer-events-none">Mặt sau của thẻ:</p>
                <p className="text-center text-pinkColor pointer-events-none">
                  {isDragEnter ? "Drop photos here" : "Drag and drop photos here, or click to select photos"}
                </p>
              </div>
            </div>
            <div class="flex border border-gray-300 p-4 bg-gray-50">
              <div class="flex-1 text-left pr-4 border-r border-gray-200">
                {dataFront && <>
                  <p className="text-center">Mặt trước</p>
                  <p className="m-0 p-0 text-[15px]">Họ và tên: <strong>{dataFront.name}</strong></p>
                  <p className="m-0 p-0 text-[15px]">Số thẻ: <strong>{maskLastFourDigits(dataFront.number)}</strong></p>
                  <p className="m-0 p-0 text-[15px]">Ngày sinh: <strong>{dataFront.birth}</strong></p>
                  <p className="m-0 p-0 text-[15px]">Giới tính: <strong>{dataFront.sex}</strong></p>
                  <p className="m-0 p-0 text-[15px]">Quốc tịch: <strong>{dataFront.nation}</strong></p>
                  <p className="m-0 p-0 text-[15px]">Quê quán: <strong>{dataFront.home}</strong></p>
                </>}
              </div>
              <div class="flex-1 pl-4">
                {dataBack && <>
                  <p className="text-center">Mặt Sau</p>
                  <p className="m-0 p-0 text-[15px]">Ngày cấp: <strong>{dataBack.issueDate}</strong></p>
                  <p className="m-0 p-0 text-[15px]">Nới cấp: <strong>{dataBack.issuePlace}</strong></p>
                </>}
              </div>
            </div>
            {!(canSubmit.back && canSubmit.front) && backBlob && frontBlob &&
              <label className="text-red-500"><strong><i>Thông tin chưa hợp lệ vui lòng thao tác lại</i></strong></label>
            }
            <button className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
              style={{ marginTop: '25px' }} disabled={!(canSubmit.back && canSubmit.front)} type="button"
              onClick={() => setIsOpenModalConfirm(true)}
            >
              Xong
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmNursing;
