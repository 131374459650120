import React, { useEffect, useState } from "react";
import { createEntity, fetchEntities } from '../../../api/odataClients/momClient';
import ChattingList from "../../../components/Chatting/ChattingList";
import ChatMessageList from "../../../components/Chatting/ChatMessageList";
import { Row } from "antd";
import { useLocation } from "react-router-dom";
import LayoutMom from "../../../components/LayoutCommon/LayoutMom";
import ChatBotMessage from "../../../components/Chatting/ChatBotMessage";
import { ACCOUNT_ID, TOKEN_API } from "../../../api/axiosInstance";
import dayjs from "dayjs";

export const ChatBoxMom = () => {
  const information = useLocation();
  const { state } = information;
  const [activeKey, setActiveKey] = useState("0");
  const [messagesData, setMessagesData] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState("");

  const onMessageReceived = (response) => {
    if (response.status === "success") {
      loadEntities();
    }
  };

  useEffect(() => {
    if (selectedConversation) {
      handleConversationClick(selectedConversation);
    }
  }, [messagesData, selectedConversation]);

  const loadEntities = async () => {
    let filter = searchText ? `and contains(CreatedBy/FullName,'${searchText}')` : "";
    try {
      const dataGroupMember = await fetchEntities('groupmembers', {
        filter: `AccountID eq ${ACCOUNT_ID()}`,
      });
      const userGroupIDs = dataGroupMember.value.map(member => member.GroupID);

      if (userGroupIDs.length > 0) {
        const data = await fetchEntities('messages', {
          filter: `GroupID in (${userGroupIDs.join(',')})` + filter,
          expand: ['CreatedBy($select=FullName,Id)', 'Group'],
          orderBy: ['Id desc', 'CreatedAt'],
        });

        const lastMessages = userGroupIDs.map(groupID => {
          return data.value.find(message => message.GroupID === groupID);
        });
        const sortedLastMessages = lastMessages
          .filter(Boolean)
          .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));

        setMessagesData(sortedLastMessages);
      }

      const rspAccount = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["FullName", "Id"] })
      setAccount(rspAccount)
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEntities();
  }, [searchText]);

  const handleConversationClick = (conversationId) => {
    setSelectedConversation(conversationId);
  };

  const onSubmit = async (value) => {
    if (selectedConversation && (value.mess || value.imageUrl)) {
      const newMessage = {
        GroupID: selectedConversation,
        Content: value.mess,
        CreatedByID: ACCOUNT_ID(),
        ImageUrl: value.imageUrl,
        IsMessageSys: false,
        CreatedAt: dayjs().toISOString()
      };

      try {
        await createEntity("messages", newMessage);
        await loadEntities();
      } catch (e) {
        console.error("Error saving message:", e);
      }
    }
  };

  useEffect(() => {
    if (state && state?.commonGroupID) {
      handleConversationClick(state?.commonGroupID)
    }
  }, [messagesData]);

  return (
    <LayoutMom isLoading={loading} onNotificateChat={onMessageReceived} hasFooter={false}>
      <div className="border rounded-lg w-[70%] ml-auto mr-auto mt-10 mb-10 h-auto shadow-lg">
        <Row>
          <ChattingList
            onReloadData={loadEntities}
            messagesData={messagesData}
            searchText={searchText}
            setSearchText={setSearchText}
            onConversationClick={handleConversationClick}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            account={account}
          />
          {
            selectedConversation == "chatbot" ?
              <ChatBotMessage
                token={TOKEN_API}
              />
              :
              <ChatMessageList
                setMessagesData={setMessagesData}
                selectedConversation={selectedConversation}
                account={account}
                onSubmit={onSubmit}
                token={TOKEN_API}
              />
          }
        </Row>
      </div>
    </LayoutMom>
  );
};
