import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Banner from "../../../components/Tool/ToolBanner/Banner";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPanel from "../../../components/Tool/CustomPanel/CustomPanel";
import CalculatorGrowth from "../../../components/Tool/ChildrenGrowth/CalculatorGrowth";
import dataPanel from "../../../assets/data/Panel/DataPanel";

export const ChildrenGrowthPage = () => {
  const [openPanelIndex, setOpenPanelIndex] = useState(null);

  return (
    <>
      <Header />
      <div className="container mx-auto pb-4">
        <Banner
          header="Biểu đồ tăng trưởng của bé: Chiều cao, cân nặng"
          content={
            <>
              Biểu đồ tăng trưởng này giúp đánh giá chỉ số khối cơ thể (BMI) của con bạn,
              biểu thị sự tăng trưởng của con bạn dựa trên các phần trăm
              được Tổ chức Y tế Thế giới (WHO) khuyến nghị.
            </>
          }
        />
        <div className="lg:px-[100px] md:px-[100px] grid grid-cols-1 lg:grid-cols-2 pt-6">
          {/* calculator */}
          <div className="flex justify-end">
            <CalculatorGrowth />
          </div>

          {/* info */}
          <div className="ml-4">
            <div className="">
              <div className="flex items-center">
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <p className="ml-2 text-gray-500">Tuyên bố miễn trừ trách nhiệm</p>
              </div>
              <p className="text-[15px] pt-4">
                Công cụ Biểu đồ tăng trưởng của bé đo chiều cao và cân nặng của trẻ
                dựa trên các phần trăm trung bình do Tổ chức Y tế Thế giới (WHO), Trung tâm Kiểm soát và Phòng ngừa Dịch bệnh (CDC) và các số liệu do Bộ Y tế Việt Nam khuyến nghị. Công cụ này chỉ nhằm mục đích cung cấp thông tin
                và không tính đến các yếu tố khác như di truyền hoặc
                tình trạng sức khỏe cụ thể của từng trẻ. Do đó,
                kết quả không thể thay thế cho việc đánh giá và chẩn đoán của chuyên gia y tế. Chúng tôi khuyên bạn nên tham khảo ý kiến
                bác sĩ nhi khoa hoặc nhà cung cấp dịch vụ chăm sóc sức khỏe tại địa phương của con bạn để có đánh giá chính xác hơn
              </p>
            </div>

            <div className="flex items-center mb-4 mt-4">
              <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                <AiOutlineExclamationCircle className="text-[35px]" />
              </div>
              <p className="ml-2 text-gray-500">Thông tin</p>
            </div>
            {dataPanel.childrenGrowth.toolPage.map((panel, index) => (
              <CustomPanel
                key={index}
                isOpen={openPanelIndex === index}
                onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                header={panel.header}
                content={panel.content}
              />
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ChildrenGrowthPage;
