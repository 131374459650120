import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { EstimateDate } from "./EstimateDate";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPanel from "../../../components/Tool/CustomPanel/CustomPanel";
import Banner from "../../../components/Tool/ToolBanner/Banner";
import dataPanel from "../../../assets/data/Panel/DataPanel";

export const EstimateDatePage = () => {

  const [openPanelIndex, setOpenPanelIndex] = useState(null);

  return (
    <>
      <Header />
      <div className="container mx-auto pb-4">
        {/* Banner */}
        <Banner header=" Công cụ tính ngày dự sinh (EDD)"
          content={<> Sử dụng công cụ này để tính ngày dự sinh của bạn. Xin lưu ý rằng đây chỉ là ước tính. Hầu hết phụ nữ sinh con trong vòng một tuần trước hoặc sau ngày này.</>} />
        {/* Body */}
        <div className="lg:px-[100px] md:px-[100px] grid grid-cols-1 lg:grid-cols-2 pt-6">
          <EstimateDate />
          {/* Info */}
          <div className="ml-4">
            <div className="mt-4">
              <div className="flex items-center mt-4">
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <p className="ml-2 text-gray-500">Tuyên bố miễn trừ trách nhiệm</p>
              </div>
              <p className="text-[15px] pt-4">
                Máy tính ngày dự sinh chỉ nhằm mục đích cung cấp thông tin và không thay thế cho lời khuyên y tế, chẩn đoán hoặc điều trị. Vui lòng tham khảo ý kiến ​​bác sĩ trước khi tìm kiếm phương pháp điều trị trên MomChildCare.
              </p>
            </div>
            <div className="flex items-center mb-4">
              <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                <AiOutlineExclamationCircle className="text-[35px]" />
              </div>
              <p className="ml-2 text-gray-500">Thông tin</p>
            </div>
            {dataPanel.estimateDate.map((panel, index) => (
              <CustomPanel
                key={index}
                isOpen={openPanelIndex === index}
                onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                header={panel.header}
                content={panel.content}
              />
            ))}

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EstimateDatePage;
