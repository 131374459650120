import buildQuery from 'odata-query';
import createAxiosInstance, { API_URL_PORT } from '../axiosInstance';

const rootService = `${API_URL_PORT}/odata`;
export const axiosAdminInstance = createAxiosInstance('authAdminToken', rootService);

/**
 * Hàm thực hiện các truy vấn GET với các tùy chọn OData.
 * @param {string} entitySet - Tên tập hợp thực thể (entity set) trong OData.
 * @param {object} queryOptions - Các tùy chọn truy vấn OData như filter, select, orderBy, v.v.
 * @returns {Promise<object>} - Dữ liệu trả về từ API.
 */
const fetchEntities = async (entitySet, queryOptions = {}) => {
  try {
    const query = buildQuery(queryOptions);
    const response = await axiosAdminInstance.get(`/${entitySet}${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching entities:', error);
    throw error;
  }
};

/**
 * Hàm tạo mới một thực thể.
 * @param {string} entitySet - Tên tập hợp thực thể trong OData.
 * @param {object} data - Dữ liệu của thực thể cần tạo.
 * @returns {Promise<object>} - Dữ liệu thực thể vừa tạo.
 */
const createEntity = async (entitySet, data) => {
  try {
    const response = await axiosAdminInstance.post(`/${entitySet}`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating entity:', error);
    throw error;
  }
};

/**
 * Hàm cập nhật một thực thể.
 * @param {string} entitySet - Tên tập hợp thực thể trong OData.
 * @param {number|string} key - Khóa chính của thực thể cần cập nhật.
 * @param {object} data - Dữ liệu mới của thực thể.
 * @returns {Promise<object>} - Dữ liệu thực thể sau khi cập nhật.
 */
const updateEntity = async (entitySet, key, data) => {
  try {
    const response = await axiosAdminInstance.put(`/${entitySet}(${key})`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating entity:', error);
    throw error;
  }
};

/**
 * Hàm xóa một thực thể.
 * @param {string} entitySet - Tên tập hợp thực thể trong OData.
 * @param {number|string} key - Khóa chính của thực thể cần xóa.
 * @returns {Promise<void>}
 */
const deleteEntity = async (entitySet, key) => {
  try {
    await axiosAdminInstance.delete(`/${entitySet}(${key})`);
  } catch (error) {
    console.error('Error deleting entity:', error);
    throw error;
  }
};


export { fetchEntities, createEntity, updateEntity, deleteEntity};
