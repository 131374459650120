import React from 'react';
import { Layout, theme } from 'antd';
import SidebarCommon from './SidebarCommon';
import HeaderCommon from './HeaderCommon';
import { getNameFromToken,  } from '../../utils/authenToken';

const { Content } = Layout;

const LayoutCommon = ({ children, sidebarItems, tokenKey}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  let token = localStorage.getItem(tokenKey);
  let name = getNameFromToken(token);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SidebarCommon 
        sidebarItems={sidebarItems}
        tokenKey={tokenKey}
      />
      <Layout>
        <HeaderCommon name={name} /> 
        <Content className='max-h-[90vh] overflow-y-auto whitespace-nowrap' style={{ margin: '24px 16px 0' }}>
          <div
            style={{
              padding: 24,
              minHeight: 500,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutCommon;

