import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { getDistricts, getProvinces, getWards } from '../../../api/addressClients';

const { Option } = Select;

const RegisterForm = () => {
  const information = useLocation();
  const { state } = information;
  const navigate = useNavigate();
  const [provinces, setProvinces] = useState()
  const [districts, setDistricts] = useState()
  const [wards, setWards] = useState()
  const [formData, setFormData] = useState({
    ...state
  });

  const [address, setAddress] = useState({
    province: "",
    district: "",
    ward: "",
  })

  const [form] = Form.useForm();

  console.log(state)
  const handleSelectAddress = (name, value) => {
    if (name === "province") {
      form.setFieldsValue({
        District: null,
        Ward: null
      });
      getDistricts(value).then((values) => setDistricts(values))
    } else if (name === "district") {
      form.setFieldsValue({
        Ward: null
      });
      getWards(address.province, value).then((values) => setWards(values))
    }
    setAddress((prev) => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    getProvinces().then((values) => setProvinces(values))
  }, [])

  const validateEmailOrPhone = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10,15}$/;

    if (!value) {
      return Promise.reject(new Error('Vui lòng nhập thông tin chính xác vào trường này.'));
    } else if (emailRegex.test(value)) {
      setFormData((prev) => ({ ...prev, Email: value.trim() }))
      return Promise.resolve();
    } else if (phoneRegex.test(value)) {
      setFormData((prev) => ({ ...prev, PhoneNumber: value.trim() }))
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('Vui lòng nhập thông tin chính xác vào trường này.'));
    }
  };

  const handleFinishSubmit = (values) => {
    const data = { ...formData, CurrentAddress: `${formData.CurrentAddress} - ${address.ward} - ${address.district} - ${address.province}` }
    navigate("/option-role", { state: data });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target
    if (name === "CurrentAddress") value = value.replace(/\s+/g, ' ').trim();
    setFormData((prev) => ({ ...prev, [name]: value.trim() }))
  }

  const handleDateChange = (value) => {
    let date = null;
    if (value !== null) date = value.format('YYYY-MM-DD')
    setFormData((prev) => ({ ...prev, ["DateOfBirth"]: date }))
  }

  const goBack = () => {
    navigate("/register", { state: formData });
  };

  return (
    <>
      <Header />
      <div className='btn w-[100px] btn-light my-3 flex font-bold m-3 cursor-pointer' onClick={goBack}>
        <AiOutlineArrowLeft className='mr-1 font-bold text-[22px] mt-[1px]' />
        Quay lại
      </div>

      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Đơn đăng ký</h4>
            <p className="text-sm">
              Enter your information
            </p>
            <Form form={form} onFinish={handleFinishSubmit} layout="vertical" initialValues={formData} className="space-y-3">
              <div className="flex space-x-3">
                <Form.Item
                  rules={[{ required: true, message: 'Nhập địa chỉ email của bạn' },
                  { validator: validateEmailOrPhone }]}
                  className='mb-0 w-full'
                  name="Email"
                  label="Email:"
                >
                  <Input
                    name="Email"
                    disabled={state.Email}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Email "
                  />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: 'Nhập số điện thoại của bạn' },
                  { validator: validateEmailOrPhone }
                  ]}
                  className='mb-0 w-full'
                  name="PhoneNumber"
                  label="Số điện thoại:"
                >
                  <Input
                    name="PhoneNumber"
                    disabled={state.PhoneNumber}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Số điện thoại*: "
                  />
                </Form.Item>
              </div>

              <div className="flex space-x-3">
                <Form.Item
                  rules={[
                    { required: true, message: 'Hãy nhập ngày sinh của bạn' }
                  ]}
                  className='mb-0 w-full'
                  name="DateOfBirth"
                  label="Ngày sinh:"
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    name="DateOfBirth"
                    maxDate={dayjs()}
                    className="w-full p-3 border border-gray-300 rounded-lg" placeholder="Date of birth*: "
                    onChange={handleDateChange} />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: 'Hãy nhập tên đầy đủ của bạn' }]}
                  name="FullName"
                  className='mb-0 w-full'
                  label="Họ và Tên:"
                >
                  <Input
                    name="FullName"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Họ và tên*: "
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="flex space-x-4">
                <Form.Item
                  rules={[{ required: true, message: 'Hãy chọn tỉnh thành' }]}
                  className='mb-0 w-full'
                  name="Province"
                  label="Tỉnh:"
                >
                  <Select
                    showSearch
                    className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                    placeholder="Chọn tỉnh thành"
                    onChange={(value) => handleSelectAddress("province", value)}
                  >
                    {provinces && provinces?.map((value, index) => (
                      <Option
                        key={index}
                        value={value.name}
                      >
                        {value.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: 'Hãy chọn quận/huyện' }]}
                  name="District"
                  className='mb-0 w-full'
                  label="Quận/Huyện:"
                >
                  <Select
                    showSearch
                    className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                    placeholder="Chọn Quận/Huyện"
                    onChange={(value) => handleSelectAddress("district", value)}
                  >
                    {districts && districts?.map((value, index) => (
                      <Option
                        key={index}
                        value={value.name}
                      >
                        {value.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: 'Hãy chọn phường/xã' }]}
                  name="Ward"
                  className='mb-0 w-full'
                  label="Phường/Xã:"
                >
                  <Select
                    showSearch
                    className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                    placeholder="Chọn Phường/Xã"
                    onChange={(value) => handleSelectAddress("ward", value)}
                  >
                    {wards && wards?.map((value, index) => (
                      <Option
                        key={index}
                        value={value.name}
                      >
                        {value.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                rules={[{ required: true, message: 'Hãy nhập địa chỉ của bạn' }]}
                name="CurrentAddress"
                label="Địa chỉ cụ thể:"
              >
                <Input
                  name="CurrentAddress"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  placeholder="Địa chỉ cụ thể"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item
                rules={[
                  { required: true, message: 'Hãy nhập mật khẩu' },
                  {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: 'Mật khẩu phải dài ít nhất 8 ký tự và bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt'
                  }
                ]}
                name="Password"
                label="Mật khẩu:"
              >
                <Input.Password
                  name="Password"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  placeholder="Mật khẩu của bạn*: "
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item
                label="Xác nhận mật khẩu:"
                name="ConfirmPassword"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Hãy nhập lại mật khẩu của bạn',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('Password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Mật khẩu không khớp!'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  name='ConfirmPassword'
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  placeholder="Xác nhập mật khẩu*: "
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item>
                <button
                  className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                  type="primary"
                  htmlType="submit"
                  block="true"
                >
                  Tiếp tục
                </button>
              </Form.Item>
            </Form>
          </div>
        </div >
      </div >
    </>
  )
}

export default RegisterForm
