import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { InputOTP } from 'antd-input-otp';
import { API_URL_PORT } from '../../../api/axiosInstance';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { BodyEmailOTPCode } from '../../../assets/data/Email/BodyEmailOTPCode';

const ConfirmOPTCode = () => {
  const information = useLocation();
  const { state } = information
  
  const navigate = useNavigate();
  const [OTPCode, setOTPCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")

  useEffect(() => {
    const generatedOTP = Math.floor(100000 + Math.random() * 900000);
    setOTPCode(generatedOTP);
  }, []);

  useEffect(() => {
    if (OTPCode > 0 && OTPCode) {
      sendEmmail()
    }
  }, [OTPCode]);

  const sendEmmail = async () => {
    try {
      const dataEmail = {
        to: state.account.Email,
        subject: "REGISTER OTP ACCOUNT - MOMCHILD CARE SYSTEM",
        body: BodyEmailOTPCode(OTPCode)
      }
      const url = process.env.NODE_ENV === 'development' ? "https://momchild.life/api/email/sendnoreplymail" : `${API_URL_PORT}/api/email/sendnoreplymail`
      await axios.post(url, dataEmail)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const goBack = () => {
    navigate(-1);
  };

  const handleFinishSubmit = async (values) => {
    if (Number(values.join('')) === OTPCode) {
      setError("")
      setLoading(true)
      try {
        const response = await axios.post(`${API_URL_PORT}/api/auth/register`, state.account)
        const accountId = response.data.data.accountId;
        if (state.momData) {
          await axios.post(`${API_URL_PORT}/api/auth/momhealth`, { ...state.momData, AccountID: accountId })
        }

        if (state.pregnantData) {
          await axios.post(`${API_URL_PORT}/api/auth/pregnancyhealth`, { ...state.pregnantData, AccountID: accountId })
        }

        if (state.childrenData && state.childrenData.length > 0) {
          for (let index = 0; index < state.childrenData.length; index++) {
            const element = state.childrenData[index];
            await axios.post(`${API_URL_PORT}/api/auth/childhealth`, { ...element, AccountID: accountId })
          }
        }
        toast.success("Tạo tài khoản thành công, quay lại đăng nhập sau 3 giây!")
        setTimeout(() => navigate("/login"), 3000)
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      setError("error")
    }
  };

  const handleResendOTP = () => {
    const generatedOTP = Math.floor(100000 + Math.random() * 900000);
    setOTPCode(generatedOTP);
    toast.success("Mã OTP đã được gửi lại")
  };

  return (
    <>
      <Header />
      <LoadingPage isLoading={loading} />
      <div className='btn w-[100px] btn-light my-3 flex font-bold m-3 cursor-pointer' onClick={goBack}>
        <AiOutlineArrowLeft className='mr-1 font-bold text-[22px] mt-[1px]' />
        Quay lại
      </div>

      <div className="flex items-center justify-center mt-32">
        <div className="container mx-auto px-7 w-full max-w-md">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Xác nhận mã OTP</h4>
            <p className="text-sm">
              Mã OTP đã được gửi vào trong email của bạn.
            </p>

            <div className="space-y-3">
              <div className='d-flex justify-content-center pb-4'>
                <InputOTP status={error} inputType="numeric" autoSubmit={handleFinishSubmit} />
                {(error.length > 0 &&
                  <p className='text-danger mt-3' style={{ color: "red" }}>mã OTP không đúng</p>
                )}
              </div>
              <button
                className="bg-gray-400 py-3 w-[60%] text-white font-bold rounded-lg"
                type="button"
                onClick={handleResendOTP}
              >
                Gửi lại
              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmOPTCode