import React from 'react'
import { AiOutlineClose } from "react-icons/ai";

const CustomModal = ({ isOpenModal, handleCancel, handleConfirm, header, content, textCancel = "Huỷ", textConfirm = "yes" }) => {
  if (!isOpenModal) return null; // Return null when modal is not open

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 popup-overlay">
      <div className="w-[500px] h-[auto] p-3 text-center rounded-lg relative bg-white">
        <AiOutlineClose onClick={() => handleCancel()} className='ml-auto text-[25px] mr-2 mt-2' style={{ cursor: 'pointer' }} />
        <h2 className="text-[25px] text-black font-bold pb-3">{header}</h2>

        {content}

        <div className='flex gap-4 items-center justify-center p-2 mx-8'>
          {handleCancel && <button onClick={() => handleCancel()} className='bg-pinkColor px-14 mt-4 py-4 rounded-lg text-[18px] text-white hover:opacity-70'>{textCancel}</button>}
          {handleConfirm && <button onClick={() => handleConfirm()} className='bg-white px-14 mt-4 py-4 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>{textConfirm}</button>}
        </div>
      </div>
    </div>
  );
}

export default CustomModal