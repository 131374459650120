import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { createEntity, fetchEntities } from '../../api/odataClients/caregiverClient';
import dayjs from 'dayjs';
import { CaregiverContractEmailTemplate } from '../../assets/data/Email/CaregiverContract';
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from '../../api/axiosInstance';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoadingPage from '../LoadingPage/LoadingPage';

export const ContractSign = ({ onSigned }) => {
    const [name, setName] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [error, setError] = useState('');
    const sigCanvas = useRef(null);
    const [account, setAccount] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sigCanvas.current.isEmpty()) {
            setError('Vui lòng ký tên.');
            return;
        }

        // Lưu chữ ký dưới dạng hình ảnh (base64)
        const signatureData = sigCanvas.current.toDataURL('image/png');

        // Chuyển đổi Base64 sang Blob
        const byteString = atob(signatureData.split(',')[1]);
        const mimeString = signatureData.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const file = new Blob([ab], { type: mimeString });

        // Tạo FormData và thêm tệp vào
        const formData = new FormData();
        formData.append('image', file, `${name}_signature.png`); // Tên tệp có thể thay đổi

        try {
            setIsLoading(true)
            const dataEmail = {
                to: "",
                subject: "SERVICE ACCEPTENCE CONTRACT - MOMCHILD CARE SYSTEM",
                body: CaregiverContractEmailTemplate(account, signatureData)
            }
            const urlSendEmail = process.env.NODE_ENV === 'development' ? "https://momchild.life/api/email/sendnoreplymail" : `${API_URL_PORT}/api/email/sendnoreplymail`
            await axios.post(urlSendEmail, dataEmail)

            const urlUploadImage = process.env.NODE_ENV === 'development' ? "https://momchild.life/api/s3/uploadimage" : `${API_URL_PORT}/api/s3/uploadimage`
            const respUploadImage = await axios.post(urlUploadImage, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${TOKEN_API}`
                }
            });
            const imageUrl = respUploadImage.data.data.imageUrl;

            const contract = {
                AccountID: ACCOUNT_ID(),
                FileUrl: imageUrl,
                IsSigned: true,
                CreatedAt: dayjs().toDate().toISOString()
            }
            await createEntity("contracts", contract)
        } catch (error) {
            toast.error(error.message)
        } finally {
            setIsLoading(false)
            setIsSigned(true);
            onSigned()
        }
    };

    const loadAccount = async () => {
        try {
            const resp = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["FullName", "Sex", "Email", "DateOfBirth", "PhoneNumber", "NumberCard"] });
            setAccount(resp)
        } catch (error) {

        }
    }

    useEffect(() => {
        loadAccount()
    }, [])

    const clearSignature = () => {
        sigCanvas.current.clear();
        setError('');
    };

    function maskLastFourDigits(numberString) {
        if (numberString?.length <= 4) {
            return '*'.repeat(numberString.length);
        }
        return numberString?.slice(0, -4) + '****';
    }

    return (
        <div style={{ padding: '20px', margin: 'auto', maxWidth: "1000px" }}>
            <LoadingPage isLoading={isLoading} />
            <h2 style={{ textAlign: "center" }}>Bạn cần đọc và chấp thuận điều khoản trước khi sửa dụng hệ thống</h2>
            {!isSigned ? (
                <div style={{
                    maxWidth: "100vw",
                    margin: "20px auto",
                    padding: "40px",
                    backgroundColor: "#fdfdf5",
                    border: "1px solid #ccc",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    lineHeight: "1.6",
                }}>
                    <h1 style={{ textAlign: "center", textDecoration: "underline" }}>HỢP ĐỒNG CHẤP THUẬN DỊCH VỤ</h1>

                    <div style={{ marginBottom: "20px" }}>
                        <p><strong>Tôi tên: </strong> {account?.FullName}</p>
                        <p><strong>Ngày sinh: </strong> {dayjs(account?.DateOfBirth).format("DD/MM/YYYY")} </p>
                        <p><strong>Giới tính: </strong> {account?.Sex ? "Nam" : "Nữ"} </p>
                        <p><strong>Số điện thoại: </strong> {account?.PhoneNumber} </p>
                        <p><strong>Số CCCD: </strong> {maskLastFourDigits(account?.NumberCard)} </p>
                    </div>

                    <p>Tôi đồng ý với các điều khoản sau để sử dụng hệ thống với vai trò là 1 người chăm sóc:</p>
                    <div className="contract-section">
                        <p><strong>1. Điều khoản Chất lượng Dịch vụ </strong></p>
                        <p>Caregiver cam kết cung cấp các dịch vụ đúng mô tả, đáp ứng đầy đủ tiêu chuẩn an toàn và vệ sinh. Đảm bảo kỹ năng chuyên môn của caregiver được duy trì và cập nhật thường xuyên.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>2. Trách nhiệm Đạo đức và An toàn </strong></p>
                        <p>Caregiver phải luôn giữ thái độ chuyên nghiệp, tôn trọng và hỗ trợ mẹ một cách tận tâm. Tất cả các hành vi không đạo đức hoặc gây nguy hại đều bị nghiêm cấm.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>3. Cam kết Bảo mật Thông tin </strong></p>
                        <p>Caregiver cam kết giữ bí mật mọi thông tin cá nhân và sức khoẻ của mẹ. Mọi thông tin chỉ được sử dụng với mục đích hỗ trợ và không chia sẻ cho bên thứ ba khi chưa được sự đồng ý.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>4. Điều khoản Bồi thường </strong></p>
                        <p>Trong trường hợp dịch vụ không đạt yêu cầu hoặc có sai sót, caregiver sẽ chịu trách nhiệm và bồi thường cho người sử dụng dịch vụ theo thỏa thuận.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>5. Điều khoản Dịch vụ </strong></p>
                        <p>Trong việc nhấp nhận các yêu cầu dịch vụ của các bà mẹ, phải bỏ ra phí với <strong>10%</strong> giá của dịch vụ để có thể xác nhận yêu cầu từ phía các mẹ</p>
                    </div>

                    <form onSubmit={handleSubmit} className='flex justify-end' style={{ marginTop: "30px" }}>
                        <div style={{ marginBottom: "15px" }}>
                            <i>{account?.FullName}</i>
                            <p style={{ fontWeight: "bold" }}>Ký vào ô bên dưới</p>
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{
                                    style: { border: "1px solid #aaa" },
                                    width: 350,
                                    height: 120,
                                    className: 'signatureCanvas'
                                }}
                            />
                            {error && <p style={{ color: "red" }}>{error}</p>}
                            <div className='flex justify-between'>
                                <button type="button" className='bg-white p-2 mt-4 py-3 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white' onClick={clearSignature}>
                                    Xóa chữ ký
                                </button>
                                <button type="submit" className='bg-pinkColor px-14 mt-4 py-3 rounded-lg text-[18px] text-white hover:opacity-70'>
                                    Ký hợp đồng
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <div>
                    <p>Cảm ơn {name}, hợp đồng của bạn đã được ký thành công!</p>
                </div>
            )}
        </div>
    );
};
