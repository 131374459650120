import { Form, Input, Rate, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { createEntity, fetchEntities } from '../../api/odataClients/momClient';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import { ACCOUNT_ID, TOKEN_API } from '../../api/axiosInstance';

export const Review = ({ idService, hasBooking, isActive }) => {
    const [rating, setRating] = useState(1);
    const [comment, setComment] = useState('');
    const [reviews, setReviews] = useState();
    const [hasComment, setHasComment] = useState(false);
    const { id } = useParams();
    const { form } = useForm();

    const handleRatingChange = (value) => {
        setRating(value);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const checkComment = async () => {
        try {
            const data = await fetchEntities(`comments`, { filter: `CreatedByID eq ${ACCOUNT_ID()} and ServiceID eq ${id}` });
            setHasComment(data.value.length > 0)
            console.log(hasComment);
        } catch (err) {
            toast.error("Lỗi tải các đánh giá")
        }
    }

    const handleSubmit = async (value) => {
        try {
            const comment = {
                ServiceID: idService,
                CreatedByID: ACCOUNT_ID(),
                Content: value.Content,
                Rating: rating,
                CreatedAt: dayjs().toDate()
            }
            await createEntity("comments", comment)
            fetchData()
            checkComment()
        } catch (err) {
            toast.error("Lỗi gửi đánh giá")
        }
    };

    const fetchData = async () => {
        try {
            const reviewReps = await fetchEntities("comments", {
                filter: `ServiceID eq ${idService}`,
                expand: ["CreatedBy($select=FullName)"],
                orderBy: ['Id desc']
            })
            setReviews(reviewReps.value)
        } catch (error) {
            toast.error("Lỗi tải nội dung review")
        }
    }

    useEffect(() => {
        fetchData()
        checkComment()
    }, [])

    return (
        <div>
            <div className='mt-20 px-16'>
                <h3 className='text-2xl font-semibold text-center'>Cảm nhận của khách hàng</h3>
                <div className='border-t border-gray-300 my-2 w-1/3 mx-auto'></div>
                <div className='space-y-4 mt-4 container'>
                    {/* Customer Feedback Item */}
                    {reviews && reviews.length > 0 ? <>
                        {reviews?.map((review, index) => (
                            <div key={index} className='flex items-start bg-slate-100 p-4 rounded-3xl'>
                                <div className='w-10 h-10 bg-black rounded-full mr-3'></div>
                                <div>
                                    <p className='font-semibold'>{review.CreatedBy.FullName}<span className='text-gray-500 text-sm font-normal mx-10'>{dayjs(review.CreatedAt).format("DD/MM/YYYY")}</span></p>
                                    <Rate disabled defaultValue={review.Rating} style={{ fontSize: '1rem', color: "#ff3259" }} />
                                    <p>{review.Content}</p>
                                </div>
                            </div>
                        ))}
                    </> : <><Tag className="container text-center mx-auto mb-36 text-4 p-3" color='blue'><strong>Chưa có bài đánh giá nào</strong></Tag></>}
                </div>
            </div>

            {/* Review Form */}
            {TOKEN_API &&
                <div className='px-16 py-10 bg-slate-50 mx-16'>
                    <h3 className='text-xl font-semibold'>Viết đánh giá</h3>

                    {isActive ? <>
                        {hasBooking && !hasComment ? <div className='mt-4'>
                            <Form form={form} onFinish={handleSubmit}>
                                <div className='flex items-center'>
                                    <Form.Item name="Rating" className='flex items-center'>
                                        <label className='text-gray-700 mr-4'>Chất lượng dịch vụ</label>
                                        <Rate onChange={handleRatingChange} style={{ color: "#ff3259" }} value={rating} />
                                    </Form.Item>
                                </div>
                                <Form.Item name="Content">
                                    <Input.TextArea
                                        className='w-full mt-4'
                                        placeholder='Hãy chia sẻ cảm nhận của bạn về dịch vụ này...'
                                        value={comment}
                                        onChange={handleCommentChange}
                                    /></Form.Item>
                                <button type='submit' className='bg-pinkColor text-white py-2 px-4 rounded-md mt-4'>
                                    Gửi đánh giá
                                </button>
                            </Form>
                        </div> :
                            <Tag className="container text-center mx-auto mb-36 text-4 p-3" color='red'>
                                <strong>Bạn chưa hoàn toàn sử dụng dịch vụ này không thể đánh giá</strong>
                            </Tag>}
                    </>
                        :
                        <>
                            <Tag className="container text-center mx-auto mb-36 text-4 p-3" color='red'>
                                <strong>Dịch vụ đã được khoá bởi người đăng không thể đăng đánh giá</strong>
                            </Tag>
                        </>}

                </div>
            }
        </div>
    )
}
