import './App.css';
import { Routers } from './routes/Routers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ToastContainer 
        autoClose={2000}
      />
      <Routers/>
    </GoogleOAuthProvider>
  );
}

export default App;
