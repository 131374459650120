import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getDistricts, getProvinces, getWards } from "../../../../api/addressClients";
import { toast } from "react-toastify";
import { fetchEntities } from "../../../../api/odataClients/momClient";
import { ACCOUNT_ID } from "../../../../api/axiosInstance";

const { Option } = Select;

export const AddressEdit = ({ editMode, onSave }) => {
    const [address, setAddress] = useState({
        Province: "",
        District: "",
        Ward: "",
        CurrentAddress: ""
    });
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [form] = Form.useForm();

    const handleSelectAddress = (name, value) => {
        if (name === "Province") {
            form.setFieldsValue({
                District: null,
                Ward: null
            });
            getDistricts(value).then((values) => setDistricts(values))
            setAddress((prev) => ({ ...prev, Province: value, District: null, Ward: null }));
        } else if (name === "District") {
            form.setFieldsValue({
                Ward: null
            });
            getWards(address.Province, value).then((values) => setWards(values))
            setAddress((prev) => ({ ...prev, District: value, Ward: null }));
        } else if (name === "Ward") {
            setAddress((prev) => ({ ...prev, Ward: value }));
        }
        setAddress((prev) => ({ ...prev, [name]: value }));
    };

    const getDataAddress = async () => {
        try {
            getProvinces().then((values) => setProvinces(values))
            const rspAccount = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["CurrentAddress"] })
            const splitAddress = rspAccount.CurrentAddress.split("-")
            const province = splitAddress[3];
            const district = splitAddress[2];
            const ward = splitAddress[1];
            const currentAddress = splitAddress[0];
            setAddress({
                Province: province,
                District: district,
                Ward: ward,
                CurrentAddress: currentAddress
            })

            if (province) {
                getDistricts(province).then((districts) => {
                    setDistricts(districts);
                    if (district) {
                        getWards(province, district).then((wards) => {
                            setWards(wards);
                        });
                    }
                });
            }
        } catch (error) {
            toast.error("Lỗi dữ liệu địa chỉ")
        }
    }

    useEffect(() => {
        getDataAddress()
    }, []);

    useEffect(() => {
        form.setFieldsValue(address);
    }, [address, form]);

    const handleChangeAddress = (_, value) => {
        const addressUpdate = `${value.CurrentAddress} - ${value.Ward} - ${value.District} - ${value.Province}`
        onSave(addressUpdate)
    }

    if (!editMode) return null;

    return (
        <>
            <Form form={form} layout='vertical' initialValues={address} onValuesChange={handleChangeAddress}>
                <div className="flex space-x-4">
                    <Form.Item
                        rules={[{ required: true, message: 'Hãy chọn tỉnh thành' }]}
                        className='mb-0 w-full'
                        name="Province"
                        label="Tỉnh:"
                    >
                        <Select
                            showSearch
                            value={address?.Province}
                            className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                            placeholder="Chọn tỉnh thành"
                            onChange={(value) => handleSelectAddress("Province", value)}
                        >
                            {provinces && provinces?.map((value, index) => (
                                <Option
                                    key={index}
                                    value={value.name}
                                >
                                    {value.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[{ required: true, message: 'Hãy chọn quận/huyện' }]}
                        name="District"
                        className='mb-0 w-full'
                        label="Quận/Huyện:"
                    >
                        <Select
                            showSearch
                            className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                            placeholder="Chọn Quận/Huyện"
                            value={address?.District}
                            onChange={(value) => handleSelectAddress("District", value)}
                        >
                            {districts && districts?.map((value, index) => (
                                <Option
                                    key={index}
                                    value={value.name}
                                >
                                    {value.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[{ required: true, message: 'Hãy chọn phường/xã' }]}
                        name="Ward"
                        className='mb-0 w-full'
                        label="Phường/Xã:"
                    >
                        <Select
                            showSearch
                            value={address?.Ward}
                            className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                            placeholder="Chọn Phường/Xã"
                            onChange={(value) => handleSelectAddress("Ward", value)}
                        >
                            {wards && wards?.map((value, index) => (
                                <Option
                                    key={index}
                                    value={value.name}
                                >
                                    {value.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item
                    rules={[{ required: true, message: 'Hãy nhập địa chỉ của bạn' }]}
                    name="CurrentAddress"
                    className='mb-0'
                    label="Địa chỉ cụ thể:"
                >
                    <Input
                        name="CurrentAddress"
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        placeholder="Địa chỉ cụ thể"
                    />
                </Form.Item>
            </Form>
        </>
    );
};