import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd';
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { createEntity } from '../../../api/odataClients/supporterClient';
import { getDistricts, getProvinces, getWards } from '../../../api/addressClients';
import axios from 'axios';
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from '../../../api/axiosInstance';
const { Option } = Select;


export const AddNewExaminationService = ({ show, onClose, onSuccess, onSave, hospitalAddress }) => {
    const [form] = Form.useForm();
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [imageUpload, setImageUpload] = useState();

    const handleSelectAddress = (name, value) => {
        if (name === "Province") {
            getDistricts(value).then((values) => setDistricts(values));
            form.setFieldsValue({
                District: null,
                Ward: null
            });
        } else if (name === "District") {
            getWards(form.getFieldValue("Province"), value).then((values) => setWards(values));
            form.setFieldsValue({
                Ward: null
            });
        }
    };

    useEffect(() => {
        getProvinces().then(
            (data) => {
                setProvinces(data);
            },
            (err) => {
                toast.error(err.message);
            }
        );
    }, []);


    const hanldeSubmit = async (value) => {
        let addr = [value.Address, value.Ward, value.District, value.Province];
        let fullAddress = addr.join(' - ');

        let examinationService = {
            Content: value.Content,
            HospitalName: value.HospitalName,
            CreatedByID: ACCOUNT_ID(),
            Address: fullAddress,
            IsActive: true,
        }

        try {
            let imageUrl = null;
            if (imageUpload) {
                const urlUploadImage = process.env.NODE_ENV === 'development' ? "https://momchild.life/api/s3/uploadimage" : `${API_URL_PORT}/api/s3/uploadimage`
                const respUploadImage = await axios.post(urlUploadImage, imageUpload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${TOKEN_API}`
                    }
                });
                imageUrl = respUploadImage.data.data.imageUrl;
            }
            examinationService = { ...examinationService, ImageUrl: imageUrl };
            await createEntity("schedules", examinationService);
            onSuccess()
        } catch (error) {
            toast.error(`Lỗi: ${error.message}`);
        }
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            setImageUpload(formData);
        }
    };

    if (!show) return <></>;

    return (
        <>
            <ToastContainer />
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white h-[auto] w-[700px] p-10 rounded-lg shadow-lg relative">
                    <h3 className='text-3xl text-center font-bold pb-4 text-pinkColor'>Thêm dịch vụ</h3>
                    <button className="absolute top-2 right-2" onClick={onClose}>
                        <FaRegTimesCircle className='w-6 h-6 m-2' />
                    </button>
                    <Form
                        form={form}
                        onFinish={hanldeSubmit}
                    >

                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập tên Dịch vụ khám' }]}
                            className='mb-0 mt-3 w-full'
                            name="Content"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Dịch vụ khám"
                            />
                        </Form.Item>

                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập tên bệnh viện' }]}
                            className='mb-0 mt-3 w-full'
                            name="HospitalName"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Tên bệnh viện"
                            />
                        </Form.Item>

                        <div className='flex'>
                            <Form.Item
                                className='mb-0 mt-3 mr-3 w-full'
                                name="Province"
                                rules={[{ required: true, message: 'Hãy nhập tên Tỉnh/TP' }]}
                            >
                                <Select
                                    showSearch
                                    className="w-full border border-gray-300 rounded-lg"
                                    placeholder="Tỉnh/TP"
                                    style={{ border: 'none !important', outline: 'none !important' }}
                                    onChange={(value) => handleSelectAddress("Province", value)}

                                >
                                    {
                                        provinces.map((value, _) => {
                                            return (
                                                <Option key={value.name} value={value.name} className="">
                                                    <p>{value.name}</p>
                                                </Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Form.Item>

                            <Form.Item
                                className='mb-0 mt-3 mr-3 w-full'
                                name="District"
                                rules={[{ required: true, message: 'Hãy nhập tên Quận/Huyện' }]}
                            >
                                <Select
                                    showSearch
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                    placeholder="Quận/Huyện"
                                    style={{ border: 'none !important', outline: 'none !important' }}
                                    onChange={(value) => handleSelectAddress("District", value)}

                                >
                                    {
                                        districts.map((value, _) => {
                                            return (
                                                <Option key={value.name} value={value.name} className="">
                                                    <p>{value.name}</p>
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                className='mb-0 mt-3 mr-3 w-full'
                                name="Ward"
                                rules={[{ required: true, message: 'Hãy nhập tên Phường/Xã' }]}
                            >
                                <Select
                                    showSearch
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                    placeholder="Phường/Xã"
                                    style={{ border: 'none !important', outline: 'none !important' }}
                                    onChange={(value) => handleSelectAddress("Ward", value)}
                                >
                                    {
                                        wards.map((value, _) => {
                                            return (
                                                <Option key={value.name} value={value.name} className="">
                                                    <p>{value.name}</p>
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập địa chỉ bệnh viện' }]}
                            className='mb-0 mt-3 w-full'
                            name="Address"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Địa chỉ cụ thể"
                            />
                        </Form.Item>

                        <Form.Item
                            className='mb-0 mt-3 w-full'
                            rules={[{ required: true, message: 'Hãy thêm ảnh' }]}
                        >
                            <Input
                                type="file"
                                accept="image/*"
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                onChange={handleImageChange}
                            />
                        </Form.Item>


                        <div className='flex gap-4 items-center justify-center p-2 mx-4'>
                            <button onClick={onClose} className='bg-pinkColor px-14 mt-4 py-3 rounded-lg text-[18px] text-white hover:opacity-70'>Huỷ</button>
                            <button type="submit" className='bg-white px-14 mt-4 py-3 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>Thêm dịch vụ</button>

                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};
