import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Home } from '../pages/Home/Home'
import { Contact } from '../pages/Contact'
import { Support } from '../pages/Support'
import { HealthInformation } from '../pages/HealthInformation'
import { Login } from '../pages/Login'
import HomeMom from '../pages/Home/HomeMom'
import Register from '../pages/Register'
import ConfirmNursing from '../pages/Authen/Register/ConfirmNursing'
import { Tool } from '../pages/Tool/Tool'
import EstimateDatePage from '../pages/Tool/EstimateDate/EstimateDatePage'
import { WeightOfPregnant } from '../pages/Tool/WeightOfPregnant/WeightOfPregnant'
import { WeightOfPregnantResult } from '../pages/Tool/WeightOfPregnant/WeightOfPregnantResult'
import ChildrenGrowthPage from '../pages/Tool/ChildrenGrowth/ChildrenGrowthPage'
import ChildrenGrowthPageResult from '../pages/Tool/ChildrenGrowth/ChildrenGrowthPageResult'
import ConfirmOPTCode from '../pages/Authen/Register/ConfirmOPTCode'
import RegisterForm from '../pages/Authen/Register/RegisterForm'
import OptionRole from '../pages/Authen/Register/OptionRole'
import AfterChildbirthRegister from '../pages/Authen/Register/AfterChildbirthRegister'
import NursingRegister from '../pages/Authen/Register/NursingRegister'
import PregnantMothersRegister from '../pages/Authen/Register/PregnantMothersRegister'
import { ProfileInformation } from '../pages/Profile/Mom/ProfileInformation'
import { ChangePassword } from '../pages/Profile/Mom/ChangePassword'
import { DisableAccount } from '../pages/Profile/Mom/DisableAccount'
import { Schedule } from '../components/Schedule/Schedule'
import NotFound from '../components/UI/NotFound'
import { ResetPassword } from '../pages/Authen/ForgotPassword/ResetPassword'
import { ConfirmOTP } from '../pages/Authen/ForgotPassword/ConfirmOTP'
import { ForgotPassword } from '../pages/Authen/ForgotPassword/ForgotPassword'
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute'
import Account from '../pages/Admin/Account/Account'
import Blog from '../pages/Admin/Blog/Blog'
import Contract from '../pages/Admin/Contract/Contract'
import Dashboard from '../pages/Admin/Dashboard'
import Feedback from '../pages/Admin/Feedback/Feedback'
import FeedbackDetail from '../pages/Admin/Feedback/FeedbackDetail'
import BlogDetail from '../pages/Admin/Blog/BlogDetail'
import ContractDetail from '../pages/Admin/Contract/ContractDetail'
import RequestWallet from '../pages/Admin/RequestWallet/RequestWallet'
import Wallet from '../pages/Profile/Mom/Wallet'
import { CaregiverDashboard } from '../pages/Caregiver/Dashboard/CaregiverDashboard'
import { CaregiverService } from '../pages/Caregiver/Service/CaregiverService'
import CaregiverServiceBill from '../pages/Caregiver/Service/CaregiverServiceBill'
import { CaregiverOrder } from '../pages/Caregiver/Booking/CaregiverOrder'
import { CaregiverWallet } from '../pages/Caregiver/Wallet/CaregiverWallet'
import { ManageHealth } from '../pages/Profile/Mom/ManageHealth'

import { CaregiverChatting } from '../pages/Caregiver/Chatting/CaregiverChatting'
import { ChatBoxMom } from '../pages/Profile/Mom/ChatBoxMom'

import { CaregiverSchedule } from '../pages/Caregiver/Schedule/CaregiverSchedule'
import { CaregiverContract } from '../pages/Caregiver/Contract/CaregiverContract'
import { Service } from '../pages/CareService/Service'
import { ServiceDetail } from '../pages/CareService/ServiceDetail'
import { ServiceBooked } from '../pages/ServiceBooked/ServiceBooked'
import { BookingManagement } from '../pages/Supporter/BookingManagement/BookingManagement'
import { ExaminationService } from '../pages/Supporter/ExaminationService/ExaminationService'
import { ListExamination } from '../pages/ExaminationSchedule/ListExamination'
import { SupporterChatting } from '../pages/Supporter/Chatting/SupporterChatting'


export const Routers = () => {
  const checkGuest = () => {

    const authSupporterToken = localStorage.getItem("authSupporterToken");
    const authCaregiverToken = localStorage.getItem("authCaregiverToken");
    const authMomToken = localStorage.getItem("authMomToken");

    if (authSupporterToken) {
      return <Navigate to="/supporter/booking-management" />;
    } else if (authCaregiverToken) {
      return <Navigate to="/caregiver/dashboard" />;
    } else if (authMomToken) {
      return <Navigate to="/mom/home" />;
    }

    return <Home />;
  };
  return (
    <>
      <Routes>
        {/* Guest */}
        <Route path="/" element={checkGuest()} />
        <Route path="/home" element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/support' element={<Support />} />
        <Route path='/health' element={<HealthInformation />} />
        <Route path='/login' element={<Login />} />
        <Route path='/mom/list-examination' element={<ListExamination />} />

        {/* Register */}
        <Route path='/register' element={<Register />} />
        <Route path='/confirm-nursing' element={<ConfirmNursing />} />
        <Route path='/confirm-otp-code' element={<ConfirmOPTCode />} />
        <Route path='/register-form' element={<RegisterForm />} />
        <Route path='/option-role' element={<OptionRole />} />
        <Route path='/after-childbirth' element={<AfterChildbirthRegister />} />
        <Route path='/nursing' element={<NursingRegister />} />
        <Route path='/pregnant-mothers' element={<PregnantMothersRegister />} />

        {/* Tools */}
        <Route path='/tool' element={<Tool />} />
        <Route path='/tool/estimated' element={<EstimateDatePage />} />
        <Route path='/tool/weight' element={<WeightOfPregnant />} />
        <Route path='/tool/weight/result' element={<WeightOfPregnantResult />} />
        <Route path='/tool/growth-child' element={<ChildrenGrowthPage />} />
        <Route path='/tool/growth-child/result' element={<ChildrenGrowthPageResult />} />

        {/** Mom */}
        <Route path="/mom/home" element={<ProtectedRoute children={<HomeMom />} authName={"authMomToken"} />} />
        <Route path='/mom/service' element={<Service />} />
        <Route path='/mom/service/:id' element={<ServiceDetail />} />
        <Route path='/mom/chat' element={<ProtectedRoute children={<ChatBoxMom />} authName={"authMomToken"} />} />
        <Route path='/mom/booked' element={<ProtectedRoute children={<ServiceBooked />} authName={"authMomToken"} />} />
        <Route path='/mom/profile' element={<ProtectedRoute children={<ProfileInformation />} authName={"authMomToken"} />} />
        <Route path='/mom/profile/change-pass' element={<ProtectedRoute children={<ChangePassword />} authName={"authMomToken"} />} />
        <Route path='/mom/profile/account' element={<ProtectedRoute children={<DisableAccount />} authName={"authMomToken"} />} />
        <Route path='/mom/profile/schedule' element={<ProtectedRoute children={<Schedule />} authName={"authMomToken"} />} />
        <Route path='/mom/profile/manage-health' element={<ProtectedRoute children={<ManageHealth />} authName={"authMomToken"} />} />
        <Route path='/mom/profile/wallet' element={<ProtectedRoute children={<Wallet />} authName={"authMomToken"} />} />

        {/* reset password */}
        <Route path='/reset-pass' element={<ResetPassword />} />
        <Route path='/reset-pass/confirm' element={<ConfirmOTP />} />
        <Route path='/forgot-pass' element={<ForgotPassword />} />

        {/* Admin */}
        <Route path="/admin/accounts" element={<ProtectedRoute children={<Account />} authName={"authAdminToken"} />} />
        <Route path='/admin/blogs' element={<ProtectedRoute children={<Blog />} authName={"authAdminToken"} />} />
        <Route path='/admin/blogs/:id' element={<ProtectedRoute children={<BlogDetail />} authName={"authAdminToken"} />} />
        <Route path='/admin/requestwallets' element={<ProtectedRoute children={<RequestWallet />} authName={"authAdminToken"} />} />
        <Route path='/admin/contract' element={<ProtectedRoute children={<Contract />} authName={"authAdminToken"} />} />
        <Route path='/admin/contract/:id' element={<ProtectedRoute children={<ContractDetail />} authName={"authAdminToken"} />} />
        <Route path='/admin/dashboard' element={<ProtectedRoute children={<Dashboard />} authName={"authAdminToken"} />} />
        <Route path='/admin/feedback' element={<ProtectedRoute children={<Feedback />} authName={"authAdminToken"} />} />
        <Route path='/admin/feedback/:id' element={<ProtectedRoute children={<FeedbackDetail />} authName={"authAdminToken"} />} />

        {/** Caregiver */}
        <Route path='/caregiver/dashboard' element={<ProtectedRoute children={<CaregiverDashboard />} authName={"authCaregiverToken"} />} />
        <Route path='/caregiver/contract' element={<ProtectedRoute children={<CaregiverContract />} authName={"authCaregiverToken"} />} />
        <Route path='/caregiver/service' element={<ProtectedRoute children={<CaregiverService />} authName={"authCaregiverToken"} />} />
        <Route path='/caregiver/service/:id' element={<ProtectedRoute children={<CaregiverServiceBill />} authName={"authCaregiverToken"} />} />
        <Route path='/caregiver/order' element={<ProtectedRoute children={<CaregiverOrder />} authName={"authCaregiverToken"} />} />
        <Route path='/caregiver/my-wallet' element={<ProtectedRoute children={<CaregiverWallet />} authName={"authCaregiverToken"} />} />
        <Route path='/caregiver/chatting' element={<ProtectedRoute children={<CaregiverChatting />} authName={"authCaregiverToken"} />} />
        <Route path='/caregiver/schedule' element={<ProtectedRoute children={<CaregiverSchedule />} authName={"authCaregiverToken"} />} />

        {/** Supporter */}
        <Route path='/supporter/booking-management' element={<ProtectedRoute children={<BookingManagement />} authName={"authSupporterToken"} />} />
        <Route path='/supporter/examination-service' element={<ProtectedRoute children={<ExaminationService />} authName={"authSupporterToken"} />} />
        <Route path='/supporter/chatting' element={<ProtectedRoute children={<SupporterChatting />} authName={"authSupporterToken"} />} />

        {/* 404 page */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  )
}
