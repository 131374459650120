import React, { useEffect, useState } from "react";
import { API_URL_PORT } from "../../api/axiosInstance";
import axios from "axios";
import LoadingPage from "../LoadingPage/LoadingPage";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker, Tag, Tooltip } from "antd";
import { FaCheck } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import CustomModal from "../Common/CustomModalConfirm";
import { createEntity, updateEntity } from "../../api/odataClients/supporterClient";
import { toast } from "react-toastify";

export const FormChatExamination = ({ examinationbookingId, token, role }) => {
    const [loading, setLoading] = useState(true)
    const [serviceBooking, setServiceBooking] = useState()
    const [serviceImage, setServiceImage] = useState();
    const [confirmModal, setConfirmModel] = useState({
        Status: "",
        Header: "",
        Title: "",
    })
    const [selectedBooking, setSelectedBooking] = useState()
    const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
    const [showPopupEditSchedule, setShowPopupEditSchedule] = useState(false)
    const [timeBooking, setTimeBooking] = useState(dayjs())

    const fetchData = async () => {
        setLoading(true)
        const rspBooking = await axios.get(`${API_URL_PORT}/odata/schedulebookings(${examinationbookingId})?$expand=Schedule,Account`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        })

        const imagePath = rspBooking.data.Schedule.ImageUrl
        const url = process.env.NODE_ENV === 'development' ?
            `https://momchild.life/api/s3/getimage?url=${imagePath}` :
            `${API_URL_PORT}/api/s3/getimage?url=${imagePath}`
        const respGetImage = await axios.get(url);
        setServiceImage(respGetImage.data.data.imageUrl)
        setServiceBooking(rspBooking.data)
        setLoading(false)
    }

    const handleChangeStatusBooking = (value, recordOrder) => {
        setSelectedBooking(recordOrder);
        setConfirmModel({
            Status: value,
            Header: value === "Đã xác nhận" ? "Xác nhận lịch hẹn" : "Hủy lịch hẹn",
            Title: value === "Đã xác nhận" ? "Bạn có chắc chắn xác nhận lịch hẹn này?" : "Bạn có chắc chắn hủy lịch hẹn này?"
        });
        setOpenPopupConfirm(true);
    };

    const handleConfirmModal = async (status) => {
        const scd = {
            Id: selectedBooking.Id,
            Content: selectedBooking.Content,
            Status: selectedBooking.Status,
            BookingAt: selectedBooking.BookingAt
        }
        try {
            await updateEntity('scheduleBookings', selectedBooking.Id, { Id: selectedBooking.Id, Status: status });
            setOpenPopupConfirm(false)
            if (scd.Status === "Đã xác nhận") {
                await createEntity("myschedules", {
                    Title: selectedBooking.Schedule.Content,
                    AccountID: selectedBooking.AccountID,
                    DoAt: dayjs(selectedBooking.BookingAt).toISOString(),
                    Status: false,
                    InHour: 2,
                })
            }
            toast.success('Cập nhập trạng thái thành công')
            fetchData()
        } catch (error) {
            toast.error("Lỗi xác nhận dịch vụ")
        }
    }

    const toggleEdit = (serviceBooked) => {
        if (serviceBooked.Status === "Đang chờ") {
            setShowPopupEditSchedule(true)
            setTimeBooking(serviceBooked.BookingAt)
        }
    }

    const handleConfirmUpdate = async () => {
        if (!timeBooking) {
            toast.success("Hãy chọn thời gian để thay đổi")
            return;
        }

        setLoading(true)
        try {
            await updateEntity('scheduleBookings', serviceBooking.Id, {
                Id: serviceBooking.Id,
                BookingAt: timeBooking, Status:
                serviceBooking.Status
            });
            toast.success("cập nhập thông tin thành công")
            setShowPopupEditSchedule(false)
            setTimeBooking(null)
            fetchData()
        } catch (err) {
            toast.error("Lỗi khi huỷ dịch vụ")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <LoadingPage isLoading={loading} />
            <CustomModal isOpenModal={openPopupConfirm} handleConfirm={() => handleConfirmModal(confirmModal.Status)} handleCancel={() => { setOpenPopupConfirm(false) }}
                header={confirmModal.Header}
                textConfirm="Xác nhận"
                textCancel="Hủy"
                content={(<><h2 className="text-[14px] text-black pb-3">{confirmModal.Title}</h2>
                    <h2 className="text-[14px] text-black pb-3">Tên đầy đủ: {selectedBooking?.Account.FullName}</h2>
                    <h2 className="text-[14px] text-black pb-3">Dịch vụ khám: {selectedBooking?.Schedule.Content}</h2>
                    <h2 className="text-[14px] text-black pb-3">Ngày khám: {dayjs(selectedBooking?.BookingAt).format('DD/MM/YYYY HH:mm')}</h2>
                </>)}
            />
            <CustomModal isOpenModal={showPopupEditSchedule} handleConfirm={handleConfirmUpdate}
                handleCancel={() => {
                    setTimeBooking(null)
                    setShowPopupEditSchedule(false)
                }}
                textConfirm="Chấp nhận"
                header="Chỉnh sửa thông tin"
                textCancel='Huỷ'
                content={(<>
                    <h2 className="text-[16px] text-black pb-1">{serviceBooking?.Schedule.Content}</h2>
                    <h2 className="text-[14px] text-black pb-3">{serviceBooking?.Schedule.HospitalName}</h2>
                    <h2 className="text-[14px] text-black pb-3">
                        Lịch hẹn:
                        <DatePicker
                            value={dayjs(timeBooking)}
                            onChange={(value) => {
                                if (value) {
                                    setTimeBooking(value.toISOString())
                                }
                            }
                            }
                            disabledDate={(current) => {
                                const today = dayjs().startOf('day');
                                const twoWeeksLater = dayjs().add(2, 'week').endOf('day');
                                return current && (current < today || current > twoWeeksLater);
                            }}
                            disabledTime={(date) => {
                                const hours = Array.from({ length: 24 }, (_, i) => i);
                                const disabledHours = hours.filter((hour) => hour < 8 || hour > 17);
                                return {
                                    disabledHours: () => disabledHours,
                                };
                            }}
                            format="DD/MM/YYYY HH:mm:ss"
                            showTime
                        />
                    </h2>
                </>)}
            />
            <div className="">
                <div key={serviceBooking?.Id} className="flex flex-row w-full border border-slate-300 rounded-lg overflow-hidden">
                    {/* Image Section */}
                    <div className="p-4">
                        <img
                            src={serviceImage}
                            alt="Ảnh dịch vụ"
                            className="w-full h-[130px] rounded-xl"
                        />
                    </div>

                    {/* Content Section */}
                    <div className="flex justify-between items-center px-4 space-x-2 w-[80%]">
                        <div className="w-[60%]">
                            <h3 className="text-lg font-semibold">
                                <Link className={`hover:text-slate-700 ${role === "1" ? 'text-black' : "text-pinkColor"}`}>{serviceBooking?.Schedule.Content}</Link>
                            </h3>
                            <p className="text-blue-600 font-semibold">Tại {serviceBooking?.Schedule.HospitalName}</p>
                            <p className="text-black-600 font-semibold">Ngày hẹn: {dayjs(serviceBooking?.BookingAt).format("DD/MM/YYYY HH:mm")}</p>
                            <Tag className={`flex items-center px-2 h-[30%] font-medium justify-center
                                            ${serviceBooking?.Status === 'Đã hủy' ? "bg-red-500 border border-red-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Đã xác nhận' ? "bg-green-500 border border-green-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Đang chờ' ? "bg-slate-500 border border-slate-500 text-white" : ''}
                                        `}>
                                {serviceBooking?.Status}
                            </Tag>
                        </div>

                        {/* Buttons */}
                        <div className="flex items-center space-x-2 mt-2">
                            {serviceBooking?.Status !== "Đã hủy" && role === "1" &&
                                <div className="flex justify-center items-end h-full">
                                    <Tooltip title="Lịch hẹn chỉ được thay đổi khi chưa xác nhận" >
                                        <button onClick={() => toggleEdit(serviceBooking)} className="p-2 border text-white rounded-lg mt-4">Thay đổi lịch hẹn</button>
                                    </Tooltip>
                                </div>
                            }

                            {serviceBooking?.Status !== "Đã hủy" && role === "3" &&
                                <>
                                    {serviceBooking?.Status === "Đang chờ" &&
                                        <button>
                                            <FaCheck onClick={() => handleChangeStatusBooking('Đã xác nhận', serviceBooking)} style={{ color: '#3CD856' }} className=' w-6 h-6 m-3 text-white' />
                                        </button>
                                    }

                                    {(serviceBooking?.Status === "Đang chờ" || serviceBooking?.Status === "Đã xác nhận") &&
                                        <button>
                                            <ImCancelCircle onClick={() => handleChangeStatusBooking('Đã hủy', serviceBooking)} style={{ color: '#FF0000' }} className=' w-6 h-6 m-3 text-white' />
                                        </button>
                                    }
                                </>
                            }
                        </div>

                        {/* <div className="w-[20%] flex flex-col items-end space-y-2 p-4 ">
                            {serviceBooking?.Status !== "Đã hủy" &&
                                <Tooltip title="Trước 24h so với giờ đặt lịch sẽ không được huỷ dịch vụ">
                                    {checkDateOver24h(serviceBooking?.BookingAt)
                                        ? <button
                                            type="button"
                                            className="bg-pinkColor p-2 w-[150px] px-5 border-none text-white rounded-lg"
                                            onClick={() => handleCancelService(serviceBooking)}
                                        >
                                            Huỷ Dịch vụ
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            className="bg-pinkColor p-2 opacity-50 pointer-events-none w-[150px] px-5 border-none text-white rounded-lg"
                                        >
                                            Huỷ Dịch vụ
                                        </button>}

                                </Tooltip>
                            }
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )

}