import { Form, Input } from 'antd';
import React from 'react';
import { toast } from 'react-toastify';
import { createEntity } from '../../../api/odataClients/caregiverClient';
import { ACCOUNT_ID } from '../../../api/axiosInstance';

export const FormAddNewWallet = ({onSuccess}) => {
    const hanldeSubmit = async (value) => {
        try {
            const wallet = {
                AccountID: ACCOUNT_ID(),
                Bank: value.Bank,
                NumberCard: value.NumberCard,
                Money: 0,
                CardholderName: value.CardholderName
            }
            await createEntity('wallets', wallet);
            onSuccess()
        } catch (error) {
            console.error('Error:', error);
            toast.error('Lỗi tạo yêu cầu');
        }
    }

    return (
        <div className="flex justify-center items-center mt-3">
            <div className="justify-center w-[50%] bg-white h-[auto] p-10 rounded-lg shadow-lg relative">
                <h3 className='text-3xl text-center font-bold pb-4 text-pinkColor'>Thêm ví vào hệ thống</h3>
                <Form onFinish={hanldeSubmit} >
                    <div className='flex'>
                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập tên ngân hàng' }]}
                            className='mb-0 mt-3 mr-3 w-full'
                            name="Bank"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Tên ngân hàng"
                            />
                        </Form.Item>

                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập số tài khoản' }]}
                            className='mb-0 mt-3 w-full'
                            name="NumberCard"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Số tài khoản"
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        className='mb-0 mt-3 w-full'
                        rules={[{ required: true, message: 'Hãy nhập tên chủ thẻ' }]}
                        name="CardholderName"
                    >
                        <Input
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder='Tên chủ thẻ'

                        />
                    </Form.Item>

                    <div className='flex gap-4 items-center justify-center p-2 mx-4'>
                        <button type="submit" className='bg-white px-14 mt-4 py-3 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>Tạo</button>
                    </div>
                </Form>
            </div>
        </div>
    );
};
