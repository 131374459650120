import { toast } from "react-toastify"
import { ProfileLayout } from "../../../components/Profile/Mom/ProfileLayout"
import { Tabs } from "antd"
import { useEffect, useState } from "react";
import { fetchEntities } from "../../../api/odataClients/momClient";
import dayjs from "dayjs";
import MomHealthModal from "../../../components/Profile/Mom/HealthManage/MomModalEdit";
import FetalHealthModal from "../../../components/Profile/Mom/HealthManage/FetalModalEdit";
import ManageVaccine from "../../../components/Profile/Mom/HealthManage/ManageVaccine";
import girlImg from '../../../assets/image/gender/girl.png'
import boyImg from '../../../assets/image/gender/boy.png'
import genderImg from '../../../assets/image/gender/gender_boy_girl.png'
import { ACCOUNT_ID } from "../../../api/axiosInstance";


const itemTabs = ["Thông tin sức khoẻ", "Sổ tiêm chủng"];

export const ManageHealth = () => {
    const [activeKey, setActiveKey] = useState("0");
    const [momHealth, setMomHealth] = useState();
    const [fetalHealth, setFetalHealth] = useState();
    const [account, setAccount] = useState()
    const [statusMom, setStatusMom] = useState(true)

    const [isMomModalVisible, setIsMomModalVisible] = useState(false);
    const [isFetalModalVisible, setIsFetalModalVisible] = useState(false);

    const loadMomHealth = async () => {
        try {
            const response = await fetchEntities("momhealths", {
                filter: `AccountID eq ${ACCOUNT_ID()}`
            })
            setMomHealth(response.value[0])
        } catch (err) {
            toast.error("Tải dữ liệu của mẹ không thành công")
        }
    }

    const loadFetalHealth = async () => {
        try {
            const response = await fetchEntities("pregnancyhealths", {
                filter: `AccountID eq ${ACCOUNT_ID()}`
            })
            const data = { ...response.value[0], ExpectedDateOfBirth: dayjs(response.value[0].ExpectedDateOfBirth) }
            setFetalHealth(data)
        } catch (err) {
            toast.error("Tải dữ liệu của thai không thành công")
        }
    }
    const loadAccount = async () => {
        try {
            const response = await fetchEntities(`accounts(${ACCOUNT_ID()})`, {})
            setAccount(response)
        } catch (err) {
            toast.error("Tải dữ liệu của tài khoản không thành công")
        }
    }

    const onSuccess = (message, type) => {
        toast.success(message);
        if (type === "Mom") {
            loadMomHealth();
        } else if (type === "Fetal") {
            loadFetalHealth()
        }
    }

    useEffect(() => {
        loadMomHealth()
        loadAccount()
    }, [])

    useEffect(() => {
        setStatusMom(account?.MomStatus)
        if (account && account.MomStatus) {
            loadFetalHealth()
        }

    }, [account])

    return (
        <ProfileLayout>
            <div className=" mb-4 p-4">
                <Tabs
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    type="card"
                    items={itemTabs.map((item, i) => ({
                        label: (
                            <span
                                style={{
                                    fontWeight: activeKey === `${i}` ? "bold" : "normal",
                                    color: "black",
                                }}
                            >
                                {item}
                            </span>
                        ),
                        key: `${i}`,
                        children:
                            i === 0 ? (
                                <div className="m-4">
                                    <div className="p-4">
                                        <div className="mb-6">
                                            <div className="flex justify-between items-center mb-4">
                                                <h2 className="text-lg font-bold">Thông số của mẹ</h2>
                                                <button onClick={() => setIsMomModalVisible(true)} className="h-[40px] px-2 bg-pinkColor text-white text-bold rounded-lg text-[16px]">Chỉnh sửa</button>
                                            </div>
                                            <div className="grid grid-cols-2 gap-3 md:grid-cols-4">

                                                <div className="text-center">
                                                    <div className="text-2xl">⚖️</div>
                                                    <p className="text-lg font-bold">{momHealth?.Weight ? momHealth.Weight : "No data"} KG</p>
                                                    <p>Cân nặng</p>
                                                </div>

                                                <div className="text-center">
                                                    <div className="text-2xl">📏</div>
                                                    <p className="text-lg font-bold">{momHealth?.Height ? momHealth.Height : "No data"} cm</p>
                                                    <p>Chiều cao</p>
                                                </div>

                                                <div className="text-center">
                                                    <div className="text-2xl">💧</div>
                                                    <p className="text-lg font-bold">{momHealth?.BloodPressure ? momHealth.BloodPressure : "No data"} mmHg</p>
                                                    <p>Huyết áp</p>
                                                </div>

                                                <div className="text-center">
                                                    <div className="text-2xl">🤰🏻</div>
                                                    <p className="text-lg font-bold">{momHealth?.BMIIndex ? momHealth.BMIIndex : "No data"}</p>
                                                    <p>Chỉ số cơ thể (BMI)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {statusMom ? <>
                                                <div className="flex justify-between items-center mb-4">
                                                    <h2 className="text-lg font-bold">Thông số của thai</h2>
                                                    <button onClick={() => setIsFetalModalVisible(true)} className="h-[40px] px-2 bg-pinkColor text-white text-bold rounded-lg text-[16px]">Chỉnh sửa</button>
                                                </div>
                                                <div className="grid grid-cols-2 gap-3 md:grid-cols-4">
                                                    <div className="text-center">
                                                        <div className="text-2xl">👶</div>
                                                        <p className="text-lg font-bold">{fetalHealth?.GestationalAge || "No data"} tuần</p>
                                                        <p>Tuổi thai</p>
                                                    </div>

                                                    <div className="text-center">
                                                        <div className="text-2xl">❤️</div>
                                                        <p className="text-lg font-bold">{fetalHealth?.FetalHeartRate || "No data"} nhịp/phút</p>
                                                        <p>Nhịp tim thai nhi</p>
                                                    </div>

                                                    <div className="text-center">
                                                        <div className="text-2xl">🌊</div>
                                                        <p className="text-lg font-bold">{fetalHealth?.AmnioticFluid || "No data"} mm3</p>
                                                        <p>Nước ối</p>
                                                    </div>

                                                    <div className="text-center">
                                                        <div className="text-2xl">📏</div>
                                                        <p className="text-lg font-bold">{fetalHealth?.GestationalSac || "No data"} cm</p>
                                                        <p>Đường kính túi thai</p>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-2 gap-3 md:grid-cols-3 flex justify-center mt-5">
                                                    <div className="text-center flex flex-col">
                                                        {fetalHealth?.ExpectedGender === "Boy" ? (
                                                            <img src={boyImg} alt="Boy" className="mt-2 w-10 h-8 mx-auto"/>
                                                        ) : fetalHealth?.ExpectedGender === "Girl" ? (
                                                            <img src={girlImg} alt="Girl" className="mt-2 w-10 h-8 mx-auto"/>
                                                        ) : (
                                                            <img src={genderImg} alt="gender" className="mt-2 w-10 h-8 mx-auto"/>
                                                        )}
                                                        <p className="text-lg font-bold">{fetalHealth?.ExpectedGender || "No data"}</p>
                                                        <p>Giới tính</p>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="text-2xl">📅</div>
                                                        <p className="text-lg font-bold">{fetalHealth?.ExpectedDateOfBirth ? dayjs(fetalHealth.ExpectedDateOfBirth).format("YYYY-MM-DD") : "No data"}</p>
                                                        <p>Ngày dự kiến sinh</p>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="text-2xl">⚖️</div>
                                                        <p className="text-lg font-bold">{fetalHealth?.EstimateFetalWeight || "No data"} gram</p>
                                                        <p>Khối lượng ước tính</p>
                                                    </div>
                                                </div>
                                            </> : <>
                                            </>}
                                        </div>
                                    </div>

                                    <MomHealthModal
                                        visible={isMomModalVisible}
                                        onClose={() => setIsMomModalVisible(false)}
                                        onSuccess={onSuccess}
                                        initialValues={momHealth}
                                    />

                                    <FetalHealthModal
                                        visible={isFetalModalVisible}
                                        onClose={() => setIsFetalModalVisible(false)}
                                        onSuccess={onSuccess}
                                        initialValues={fetalHealth}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="m-4 shadow bg-round">
                                        <div className="p-4">
                                            <ManageVaccine />
                                        </div>
                                    </div>
                                </>
                            ),
                    }))}
                />
            </div>
        </ProfileLayout>
    )
}