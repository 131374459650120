import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import "react-quill/dist/quill.snow.css";
import AdminLayout from "../../../components/Admin/AdminLayout";
import { fetchEntities } from "../../../api/odataClients/adminClient";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { ContractView } from "../../../components/LayoutCommon/ContractView";
import { ArrowLeftOutlined } from "@ant-design/icons";


export const ContractDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);

  const loadEntities = async () => {
    const data = await fetchEntities(`contracts(${id})`, { expand: ['Account'] });
    setContract(data)
    setLoading(false);
  }

  const handleDownloadPDF = async () => {
    // const element = document.getElementById("pdf-contract"); // Lấy element bạn muốn chuyển thành PDF
    // const canvas = await html2canvas(element);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "mm", "a4");
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    // pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    // pdf.save("download.pdf");
  };

  useEffect(() => {
    loadEntities();
  }, [])

  return (
    <AdminLayout>
      <LoadingPage isLoading={loading} />
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <Button type="link" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
            Quay lại
          </Button>
        </div>
        <div className="mb-10 text-center bg-gray-50 shadow-md py-8 rounded-3xl mx-[20%] space-y-5 text-xl">
          <p>
            Người ký: <strong>{contract?.Account?.FullName}</strong>
          </p>
          <p>
            Ngày ký:{" "}
            <strong>
              {new Date(contract?.CreatedAt).toLocaleDateString()}
            </strong>
          </p>
        </div>

        <ContractView account={contract?.Account} contract={contract} />
      </div>
    </AdminLayout>
  );
};

export default ContractDetail;
