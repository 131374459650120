import { InputOTP } from 'antd-input-otp';
import React, { useState } from 'react';
import Header from '../../../components/Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

export const ConfirmOTP = () => {
    const information = useLocation();
    const { state } = information;
    const navigate = useNavigate();
    const [otpCode, setOtpCode] = useState('');

    const goBack = () => {
        navigate("/reset-pass", { state: state });
    };

    const handleFinishSubmit = () => {
        navigate("/forgot-pass", { state: state });
    };

    const handleResendOTP = () => {
        // Logic gửi lại OTP ở đây
    };

    return (
        <>
            <Header />

            <div className='btn btn-light my-3 flex font-bold m-3 cursor-pointer' onClick={goBack}>
                <AiOutlineArrowLeft className='mr-1 font-bold text-[22px] mt-[1px]' />
                Quay lại
            </div>

            <div className="flex items-center justify-center mt-32">
                <div className="container mx-auto px-7 w-full max-w-md">
                    <div className="text-center space-y-4">
                        <h4 className="text-4xl font-semibold mb-4">Xác nhận mã OTP</h4>
                        <p className="text-sm">
                            Mã đã được gửi email của bạn.
                        </p>

                        <div className="space-y-3">
                            <div className='d-flex justify-content-center pb-4'>
                                <InputOTP inputType="numeric" autoSubmit={handleFinishSubmit}
                                />
                            </div>

                            <button
                                className="bg-gray-400 py-3 w-[30%] ml-3 text-white font-bold rounded-lg"
                                type="button"
                                onClick={handleResendOTP}
                            >
                                Gửi lại
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};