import React, { useEffect, useState } from "react";
import { FaRuler, FaWeight } from "react-icons/fa";
import { Switch } from "antd";
import { PregnancyWeek } from "../../components/Pregnancy/PregnancyWeek/PregnancyWeek";
import CalculateMomBMI from "../../components/CalculatorBMI/CalculateMomBMI";
import "../../components/CalculatorBMI/style.css";
import { fetchEntities, updateEntity } from "../../api/odataClients/momClient";
import dayjs from "dayjs";
import LayoutMom from "../../components/LayoutCommon/LayoutMom";
import { PostpartumUI } from "../../components/Postpartum/PostpartumUI";
import { toast } from "react-toastify";
import { ACCOUNT_ID } from "../../api/axiosInstance";

const HomeMom = () => {
  const [momData, setMomData] = useState({})
  const [momHealth, setMomHealth] = useState({})
  const [momPregnancy, setMomPregnancy] = useState({})
  const [calculateDate, setCalulateDate] = useState({})
  const [isLoading, SetIsLoading] = useState(true)
  const fecthData = async () => {
    const respMom = await fetchEntities(`accounts(${ACCOUNT_ID()})`)
    const respMomHealth = await fetchEntities(`momhealths`, {
      filter: `AccountID eq ${ACCOUNT_ID()}`
    })
    const respMomPregnancy = await fetchEntities(`pregnancyhealths`, {
      filter: `AccountID eq ${ACCOUNT_ID()}`
    })
    setMomData(respMom)
    setMomHealth(respMomHealth.value[0])
    setMomPregnancy(respMomPregnancy.value[0])
    SetIsLoading(false)
  }

  const hanldeSetMomHealth = (value) => {
    setMomHealth(value)
  }

  const calculateDaysUntilAndSinceDueDate = (dueDateStr) => {
    if (!dueDateStr) return;

    const dueDate = dayjs(dueDateStr);
    const today = dayjs();
    const totalPregnancyDays = 280;

    const pregnancyStartDate = dueDate.subtract(totalPregnancyDays, 'day');
    const totalDaysSincePregnancyStart = today.diff(pregnancyStartDate, 'day');
    const weeksSincePregnancyStart = Math.ceil(totalDaysSincePregnancyStart / 7);
    const daysSincePregnancyStart = totalDaysSincePregnancyStart % 7;

    const daysUntilDueDate = dueDate.diff(today, 'day');
    const percentageCompleted = ((totalDaysSincePregnancyStart / totalPregnancyDays) * 100).toFixed(2);
    return {
      weeksSincePregnancyStart: weeksSincePregnancyStart > 0 ? weeksSincePregnancyStart : 0,
      daysSincePregnancyStart: daysSincePregnancyStart > 0 ? daysSincePregnancyStart : 0,
      daysUntilDueDate: daysUntilDueDate > 0 ? daysUntilDueDate : 0,
      percentageCompleted: totalDaysSincePregnancyStart > 0 ? percentageCompleted : 0
    };
  }

  const handleChangeMomStatus = async (value) => {
    try {
      SetIsLoading(true)
      await updateEntity("accounts", ACCOUNT_ID(), { Id: ACCOUNT_ID(), MomStatus: !value })
      fecthData()
    } catch (error) {
      toast.error("Lỗi thay đổi trạng thái của Mẹ")
    } finally {
      SetIsLoading(false)
    }
  }

  useEffect(() => {
    fecthData()
  }, [])

  useEffect(() => {
    setCalulateDate(calculateDaysUntilAndSinceDueDate(momPregnancy?.ExpectedDateOfBirth))
  }, [momData, momHealth, momPregnancy])

  return (
    <LayoutMom isLoading={isLoading}>
      <div className="container mx-auto px-4 mb-8">
        <div className="h-[250px] bg-gradient-to-r from-[#8BDCF9] to-[#FCC4E7] rounded-[30px] lg:rounded-[50px] my-2 shadow-lg">
          <div className="text-center pt-6 lg:pt-9">
            {/* code nút chuyển trạng thái */}
            <div className="relative flex justify-center items-center text-center w-full">
              <p className="text-pinkColor font-bold text-lg lg:text-xl">
                Xin chào, {momData.FullName}
              </p>

              <div className="absolute right-4 flex flex-col items-start gap-2">
                <div className="flex gap-5">
                  <p className="font-bold">Mang thai</p>
                  <p className="font-bold">Sau sinh</p>
                </div>
                <div className="custom-switch ">
                  <Switch className="ml-[50px]" value={!momData?.MomStatus} onChange={handleChangeMomStatus} />
                </div>
              </div>
            </div>
            {momData.MomStatus &&
              <p className="font-bold text-lg lg:text-xl">
                Ngày dự kiến ​​sinh: {dayjs(momPregnancy?.ExpectedDateOfBirth).format('YYYY-MM-DD')}
              </p>
            }

            <div className="flex justify-center mt-3 text-center">
              <div className="w-[80%] lg:w-[60%] h-4 mb-4 mt-1 bg-gray-200 rounded-full dark:bg-gray-700">
                <div
                  className="h-4 bg-[#E55C8A] rounded-full dark:bg-[#E55C8A]"
                  style={{ width: `${calculateDate?.percentageCompleted}%` }}
                ></div>
              </div>
            </div>

            <div className="flex justify-center mt-3 text-center bg-white h-[98px] mx-[50px] sm:mx-[100px] lg:mx-[200px] rounded-tr-[20px] rounded-tl-[20px] lg:rounded-tr-[30px] lg:rounded-tl-[30px]">
              <div className="flex flex-col sm:flex-row justify-between items-center w-full sm:w-[80%] space-y-2 sm:space-y-0">
                {momData.MomStatus &&
                  <p className="text-blueColor text-[16px] sm:text-[18px] lg:text-[20px]">
                    {calculateDate?.weeksSincePregnancyStart} Tuần {calculateDate?.daysSincePregnancyStart} ngày
                  </p>
                }

                <div className="text-center px-2 sm:px-4">
                  <FaRuler className="ml-2 sm:ml-3 text-[24px] sm:text-[30px]" />
                  {momPregnancy?.GestationalSac} cm
                </div>

                <div className="text-center px-2 sm:px-4">
                  <FaWeight className="ml-2 sm:ml-4 text-[24px] sm:text-[30px]" />
                  {momPregnancy?.EstimateFetalWeight} gram
                </div>

                <div className="text-center px-2 sm:px-4">
                  <span className="font-bold text-[16px] sm:text-[18px] lg:text-[20px]">
                    BMI
                  </span>
                  <br />
                  {momHealth?.BMIIndex}
                </div>
                {momData.MomStatus &&
                  <p className="text-pinkColor text-[16px] sm:text-[18px] lg:text-[20px]">
                    {calculateDate?.daysUntilDueDate} ngày còn lại
                  </p>
                }
              </div>
            </div>
          </div>
        </div>


        {momData.MomStatus ?
          <>
            {/* Phần 40 weeks pregnant */}
            {momPregnancy?.GestationalAge && <PregnancyWeek weekTarget={momPregnancy.GestationalAge - 1} />}
            {/* Calculate mom BMI */}
            <CalculateMomBMI momHealth={momHealth} week={momPregnancy.GestationalAge - 1} onSubmitForm={hanldeSetMomHealth} />
          </> :
          <PostpartumUI />
        }
      </div>
    </LayoutMom>
  );
};

export default HomeMom;
