import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Rate, Select, Tag, Upload } from "antd";
import "react-quill/dist/quill.snow.css";
import { ArrowLeftOutlined, EditOutlined, LockOutlined, PlusOutlined, UnlockOutlined } from "@ant-design/icons";
import { fetchEntities, updateEntity } from "../../../api/odataClients/caregiverClient";
import { toast } from "react-toastify";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import axios from "axios";
import { API_URL_PORT, TOKEN_API } from "../../../api/axiosInstance";
import CaregiverLayout from "../../../components/Caregiver/CaregiverLayout";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";

const { Option } = Select;

export const CaregiverServiceBill = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState();
  const [error, setError] = useState();
  const [reviews, setReviews] = useState([]);
  const [careServiceType, setCareServiceType] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [fileList, setFileList] = useState([]);
  const [arraySRCImage, setArraySRCImage] = useState([]);
  const [form] = Form.useForm();

  const loadEntities = async () => {
    try {
      const data = await fetchEntities(`careservices(${id})`, { expand: ["CareServiceType"] });
      const listImage = data.ImageUrl.split(" - ");
      const imageUrlList = await Promise.all(
        listImage.map(async (element) => {
          const url =
            process.env.NODE_ENV === 'development'
              ? `https://momchild.life/api/s3/getimage?url=${element}`
              : `${API_URL_PORT}/api/s3/getimage?url=${element}`;
          const respGetImage = await axios.get(url);
          const imageUrl = respGetImage.data.data.imageUrl;
          return imageUrl;
        })
      );
      setArraySRCImage(imageUrlList);
      setService(data);

      const reviewReps = await fetchEntities("comments", { filter: `ServiceID eq ${id}` });
      setReviews(reviewReps.value);

      const typeRsp = await fetchEntities("careservicetypes", {});
      setCareServiceType(typeRsp.value);
    } catch (error) {
      console.error("Error fetching blog:", error);
      setError(error);
      toast.error("Error fetching blog");
    } finally {
      setLoading(false);
    }
  };

  const saveImageToEdit = (defaultImages) => {
    if (defaultImages?.length) {
      const initialFileList = defaultImages.map((image, index) => ({
        uid: `-${index}`,
        name: `image-${index + 1}.jpg`,
        status: 'done',
        url: image,
      }));
      setFileList(initialFileList);
    }
  };

  useEffect(() => {
    saveImageToEdit(arraySRCImage);
  }, [arraySRCImage]);

  const handleToggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleSubmitForm = async (value) => {
    setLoading(true);
    try {
      const listImage = service.ImageUrl.split(" - ");
      let imageUrl = null;
      const uploadedImageUrls = [];

      // Delete old images from the server
      await Promise.all(
        listImage.map(async (element) => {
          const url = process.env.NODE_ENV === 'development'
            ? `https://momchild.life/api/s3/deleteimage?url=${element}`
            : `${API_URL_PORT}/api/s3/deleteimage?url=${element}`;
          await axios.delete(url, {
            headers: {
              'Authorization': `Bearer ${TOKEN_API}`,
            },
          });
        })
      );

      const urlUploadImage = process.env.NODE_ENV === 'development'
        ? "https://momchild.life/api/s3/uploadimage"
        : `${API_URL_PORT}/api/s3/uploadimage`;

      const validFileList = fileList.filter(file => file && file.originFileObj);

      for (let i = 0; i < validFileList.length; i++) {
        const singleUpload = new FormData();
        singleUpload.append('image', validFileList[i].originFileObj);

        const respUploadImage = await axios.post(urlUploadImage, singleUpload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${TOKEN_API}`
          }
        });

        const newImageUrl = respUploadImage.data.data.imageUrl;
        uploadedImageUrls.push(newImageUrl);
      }

      imageUrl = uploadedImageUrls.join(" - ");

      const careService = {
        Id: service.Id,
        Title: value.Title,
        Description: value.Description,
        Price: Number(value.Price),
        CareServiceTypeID: value.CareServiceTypeID,
        ImageUrl: imageUrl,
      };

      // Update care service entity with new data
      await updateEntity("careservices", service.Id, careService);
      toast.success("Sửa thông tin dịch vụ thành công");
      loadEntities();
      setIsEdit(false);
    } catch (error) {
      toast.error("Hệ thông lỗi, vui lòng thử lại sau.");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
    form.validateFields(['images']);
  };

  const handleBeforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    const maxSize = 5;
    const isWithinSizeLimit = file.size / 1024 / 1024 <= maxSize;

    if (!isImage) {
      toast.error('Chỉ được phép tải lên ảnh.');
      return Upload.LIST_IGNORE;
    }

    if (!isWithinSizeLimit) {
      toast.error(`ảnh phải nhỏ hơn ${maxSize}MB.`);
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleClickLockService = async (isActive) => {
    setLoading(true)
    try {
      await updateEntity("careservices", service.Id, { ...service, IsActive: isActive })
      toast.success("Cập nhập thành công")
      loadEntities();
    } catch (error) {
      toast.error("Lỗi cập nhập dịch vụ")
    }
  }

  useEffect(() => {
    loadEntities();
  }, []);

  return (
    <CaregiverLayout>
      <LoadingPage isLoading={loading} />
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <Button type="link" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
            Back
          </Button>
        </div>
        <div className="container mx-auto mb-36">
          {!error ? (
            <div className="flex flex-col md:flex-row mt-10 px-16">
              <div className="w-full md:w-1/2 px-4">
                <img
                  src={currentImage || arraySRCImage[0]}
                  alt="Service"
                  className="w-full h-[400px] rounded-lg mb-4"
                />
                <div className="flex justify-center space-x-5">
                  {arraySRCImage.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Thumbnail ${index + 1}`}
                      className={`w-[15%] rounded-lg cursor-pointer ${currentImage === img ? 'border-2 border-pinkColor' : ''}`}
                      onClick={() => setCurrentImage(img)}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-around w-full items-start">
                {isEdit ? (
                  <Form
                    form={form}
                    onFinish={handleSubmitForm}
                    className="w-full px-4 space-y-5"
                    initialValues={service}
                    layout="vertical"
                  >
                    <Form.Item label="Tên dịch vụ" name="Title" rules={[{ required: true, message: "Hãy nhập tên dịch vụ" }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Loại dịch vụ" name="CareServiceTypeID">
                      <Select placeholder="Loại dịch vụ" showSearch className="w-[30%]">
                        {careServiceType.map((value) => (
                          <Option key={value.Id} value={value.Id}>
                            {value.Name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Giá dịch vụ theo giờ" name="Price" rules={[{ required: true, message: "Hãy nhập giá dịch vụ" }]}>
                      <Input placeholder="Giá dịch vụ" addonAfter="VND" />
                    </Form.Item>
                    <Form.Item name="images" rules={[{ required: true, message: 'Hãy thêm ảnh' }]}>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        beforeUpload={handleBeforeUpload}
                        onChange={handleUploadChange}
                        multiple
                        accept="image/*"
                      >
                        {fileList.length < 5 && (
                          <div>
                            <PlusOutlined />
                            <div>Upload</div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item label="Chi tiết" name="Description" rules={[{ required: true, message: "Hãy nhập mô tả dịch vụ" }]}>
                      <ReactQuill theme="snow" placeholder="Write your response here..." />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" className="bg-pinkColor border-pinkColor hover:bg-white hover:text-pinkColor">
                      Lưu thay đổi
                    </Button>
                  </Form>
                ) : (
                  <div className="w-full px-4 space-y-5">
                    <h2 className="text-pinkColor text-xl font-semibold">{service?.Title}</h2>
                    <p className="text-gray-700 mt-4">{service?.CareServiceType?.Name}</p>
                    <p className="text-gray-800 text-2xl font-bold">{service?.Price} VND / Giờ</p>
                  </div>
                )}
                <Button onClick={handleToggleEdit} icon={<EditOutlined />} className="ml-4 mr-3" />
                {service?.IsActive ?
                  <UnlockOutlined onClick={() => handleClickLockService(false)} style={{ fontSize: '24px', color: '#52c41a' }} /> :
                  <LockOutlined onClick={() => handleClickLockService(true)} style={{ fontSize: '24px', color: '#ff4d4f' }} />}
              </div>
            </div>
          ) : (
            <Tag color="red" className="container text-center mx-auto mb-36 text-4">
              Dịch vụ không tồn tại.
            </Tag>
          )}

          <div className="mt-5 overflow-y-auto w-[100vw]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service?.Description) }} />
          <div className="mt-20 px-16">
            <h3 className="text-2xl font-semibold text-center">Cảm nhận của khách hàng</h3>
            <div className="border-t border-gray-300 my-2 w-1/3 mx-auto"></div>
            <div className="space-y-4 mt-4">
              {reviews.length > 0 ? (
                reviews.map((review, index) => (
                  <div key={index} className="flex items-start bg-slate-100 p-6 rounded-3xl">
                    <div className="w-10 h-10 bg-black rounded-full mr-3"></div>
                    <div>
                      <p className="font-semibold">
                        {review?.CreatedBy?.FullName}
                        <span className="text-gray-500 text-sm font-normal mx-10">
                          {dayjs(review?.CreatedAt).format("DD/MM/YYYY")}
                        </span>
                      </p>
                      <Rate disabled defaultValue={review?.Rating} style={{ fontSize: '1rem', color: "#ff3259" }} />
                      <p>{review?.Content}</p>
                    </div>
                  </div>
                ))
              ) : (
                <Tag color="blue" className="container text-center mx-auto mb-36 text-4 p-3">
                  <strong>Chưa có bài đánh giá nào</strong>
                </Tag>
              )}
            </div>
          </div>
        </div>
      </div>
    </CaregiverLayout>
  );
};

export default CaregiverServiceBill;