import React from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenExpired } from '../../utils/authenToken';

const ProtectedRoute = ({ children, authName }) => {
  const hasValidToken = localStorage.getItem(authName);

  if (!hasValidToken || isTokenExpired(hasValidToken)) {
    localStorage.clear()
    return <Navigate to="/login" replace />
  }

  return children;
};

export default ProtectedRoute;
