import React, { useState } from 'react';
import { Modal, Form, Input, Button, DatePicker, Select } from 'antd';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { updateEntity } from '../../../../api/odataClients/momClient';

const { Option } = Select;
const FetalHealthModal = ({ visible, onClose, onSuccess, initialValues }) => {
    const [form] = Form.useForm();
    const [ExpectedDateOfBirth, setExpectedDateOfBirth] = useState()

    if (!visible) return <></>

    const handleUpdate = async (value) => {
        try {
            const pregnancyHealth = {
                Id: initialValues.Id,
                GestationalAge: Number(value.GestationalAge),
                FetalHeartRate: Number(value.FetalHeartRate),
                AmnioticFluid: Number(value.AmnioticFluid),
                GestationalSac: Number(value.GestationalSac),
                ExpectedGender: Number(value.ExpectedGender),
                ExpectedDateOfBirth: ExpectedDateOfBirth,
                EstimateFetalWeight: Number(value.EstimateFetalWeight),
            }
            await updateEntity("pregnancyhealths", pregnancyHealth.Id, pregnancyHealth)
            onSuccess("Cập nhập thông thi thai nhi thành công", "Fetal");
            onClose();
        } catch (error) {
            toast.error(error.messsage)
        }
    }


    const handleChangeDate = (date) => {
        setExpectedDateOfBirth(dayjs(date.format('YYYY-MM-DD')).toISOString())
    }

    const handleSubmit = () => {
        form.validateFields().then(handleUpdate);
    };

    return (
        <Modal
            title="Chỉnh sửa thông số của thai nhi"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Tuổi thai (tuần)"
                    name="GestationalAge"
                    rules={[
                        { required: true, message: 'Vui lòng nhập tuổi thai!' },
                    ]}
                >
                    <Input placeholder="Nhập tuổi thai" type="number" />
                </Form.Item>

                <Form.Item
                    label="Nhịp tim thai nhi (nhịp/phút)"
                    name="FetalHeartRate"
                    rules={[
                        { required: true, message: 'Vui lòng nhập nhịp tim thai nhi!' },
                    ]}
                >
                    <Input placeholder="Nhập nhịp tim" type="number" />
                </Form.Item>

                <Form.Item
                    label="Nước ối (mm3)"
                    name="AmnioticFluid"
                    rules={[
                        { required: true, message: 'Vui lòng nhập nước ối!' },
                    ]}
                >
                    <Input placeholder="Nhập thông số nước ối" type="number" />
                </Form.Item>

                <Form.Item
                    label="Đường kính túi thai (cm)"
                    name="GestationalSac"
                    rules={[
                        { required: true, message: 'Vui lòng nhập đường kính túi thai!' },
                    ]}
                >
                    <Input placeholder="Nhập đường kính túi thai" type="number" />
                </Form.Item>

                <Form.Item
                    label="Giới tính dự đoán"
                    name="ExpectedGender"
                    rules={[{ required: true, message: 'Vui lòng nhập giới tính dự đoán!' }]}
                >
                    <Select
                        className="w-full text-black text-large text-center flex items-center justify-between rounded-lg border"
                        placeholder="Chọn vai trò"
                    >
                        <Option key="1" value="Boy" >
                            Boy
                        </Option>
                        <Option key="2" value="Girl" >
                            Girl
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Ngày dự kiến sinh"
                    name="ExpectedDateOfBirth"
                    rules={[{ required: true, message: 'Vui lòng chọn ngày dự kiến sinh!' }]}
                >
                    <DatePicker
                        className='w-full'
                        format="DD/MM/YYYY"
                        onChange={handleChangeDate} />
                </Form.Item>

                <Form.Item
                    label="Khối lượng ước tính (kg)"
                    name="EstimateFetalWeight"
                    rules={[
                        { required: true, message: 'Vui lòng nhập khối lượng ước tính!' },
                    ]}
                >
                    <Input placeholder="Nhập khối lượng ước tính" type="number" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Lưu
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default FetalHealthModal;
