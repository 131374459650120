import React, { useEffect, useState } from 'react';
import { AcceptFormBookingService } from './AcceptFormBookingService';
import { getRoleFromToken } from '../../utils/authenToken';
import { FormChatExamination } from './FormChatExamination';
import { TOKEN_API } from '../../api/axiosInstance';

export const SafeHtml = ({ content, newHref, isListChat = false, isListMessage = false }) => {
    const [renderedContent, setRenderedContent] = useState(null);

    const handleLinkHref = (id) => {
        const momToken = localStorage.getItem("authMomToken");
        const caregiverToken = localStorage.getItem("authCaregiverToken");
        return momToken ? `/mom/service/${id}` : caregiverToken ? `/caregiver/service/${id}` : "#";
    };

    const sanitizeContent = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const role = getRoleFromToken(TOKEN_API)
        if (isListChat) {
            doc.querySelectorAll('a').forEach(link => {
                const parent = link.parentNode;
                while (link.firstChild) {
                    parent.insertBefore(link.firstChild, link);
                }
                parent.removeChild(link);
            });
        }

        if (isListMessage) {
            const link = doc.querySelector('a'); // Chỉ lấy thẻ <a> đầu tiên
            if (link) {
                if (link.className) {
                    const className = link.className.split("-")
                    const Id = Number(className[0]);
                    if (!isNaN(Id)) {
                        if (className[1] === "careservice") {
                            // Set AcceptFormBookingService vào state
                            setRenderedContent(
                                <>
                                    <AcceptFormBookingService token={TOKEN_API} careservicebookingId={Id} role={role} />
                                </>
                            );
                            return null;
                        } else if (className[1] === "examination") {
                            setRenderedContent(
                                <>
                                    <FormChatExamination token={TOKEN_API} examinationbookingId={Id} role={role} />
                                </>
                            );
                            return null;
                        }
                    }
                } else {
                    link.href = link.id ? handleLinkHref(link.id) : "";
                }
            }
        }

        setRenderedContent(doc.body.innerHTML); // Set sanitized HTML vào state
    };

    useEffect(() => {
        sanitizeContent(content);
    }, [content, isListChat, isListMessage]);

    if (renderedContent && typeof renderedContent !== "string") {
        return renderedContent; // Nếu là component React, render trực tiếp
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: renderedContent,
            }}
        />
    );
};