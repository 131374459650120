import { Dropdown, Menu } from "antd";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import eddIcon from "../../assets/image/tool/Due-Date.png";
import growthicon from "../../assets/image/tool/Baby-Growth-Chart.png";
import weightGain from "../../assets/image/tool/Pregnancy-Weight-Gain.png";
import { TOKEN_API } from "../../api/axiosInstance";

const DropDownTools = () => {
    const itemsDropDownTools = [
        {
            label: (
                <Link to="/tool/estimated" className="flex items-center">
                    <img
                        src={eddIcon}
                        className="rounded-full"
                        alt="EDD icon"
                        style={{ width: "3rem" }}
                    />
                    <span className="ml-2">Công cụ tính ngày dự sinh ​​(EDD)</span>
                </Link>
            ),
            key: "1",
        },
        {
            label: (
                <Link to="/tool/growth-child" className="flex items-center">
                    <img
                        src={growthicon}
                        className="rounded-full"
                        alt="Growth Chart icon"
                        style={{ width: "3rem" }}
                    />
                    <span className="ml-2">Biểu đồ tăng trưởng của bé: Chiều cao, cân nặng</span>
                </Link>
            ),
            key: "2",
        },
        {
            label: (
                <Link to="/tool/weight" className="flex items-center">
                    <img
                        src={weightGain}
                        className="rounded-full"
                        alt="Weight Gain icon"
                        style={{ width: "3rem" }}
                    />
                    <span className="ml-2">Công cụ tính cân nặng khi mang thai</span>
                </Link>
            ),
            key: "3",
        },
        {
            label: (
                <Link to="/tool" className='flex items-center bg-white border border-pinkColor text-pinkColor hover:text-white hover:bg-pinkColor border-solid rounded-lg py-2 px-4'>
                    Xem tất cả các công cụ
                    <FaAngleRight className='ml-3' />
                </Link>
            ),
            key: "4",
        },
    ];

    const menuTools = (
        <Menu
            items={itemsDropDownTools}
            className="w-[400px] h-[230px] bg-white shadow-lg border rounded-b-lg border-gray-200"
        />
    );

    return (<>
        <div className="group relative">
            <Dropdown
                overlay={menuTools}
                trigger={["hover"]}
                arrow={{ pointAtCenter: true }}
                className="cursor-pointer"
            >
                <Link
                    onClick={(e) => e.preventDefault()}
                    className="flex items-center"
                >
                    Công cụ <FaAngleDown className="w-4 h-4 ml-1 inline" />
                </Link>
            </Dropdown>

        </div>
    </>);
}

export default DropDownTools;