import React, { useEffect, useState } from 'react';
import { Input, Form, Timeline, DatePicker } from 'antd';
import { toast } from 'react-toastify';
import { createEntity, fetchEntities } from '../../../../api/odataClients/momClient';
import dayjs from 'dayjs';
import { ACCOUNT_ID } from '../../../../api/axiosInstance';

const VaccineForm = () => {
    const [dataTimeLine, setDataTimeLine] = useState([])
    const [vaccines, setVaccines] = useState()
    const [form] = Form.useForm()

    const handleSubmit = async (value) => {
        const vaccine = {
            ...value,
            Shot: Number(value.Shot),
            AccountId: ACCOUNT_ID(),
            Date: dayjs(value.Date).toISOString()
        }
        try {
            await createEntity("vacinationhistorys", vaccine)
            toast.success("Thêm thông tin vào hệ thống thành công")
            form.resetFields()
            loadDataVaccine();
        } catch (error) {
            toast.error(error.message)
        }
    };

    const loadDataVaccine = async () => {
        try {
            const response = await fetchEntities("vacinationhistorys", {
                filter: "AccountId eq " + ACCOUNT_ID(),
                orderBy: ['Date asc']
            })
            setVaccines(response.value)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        loadDataVaccine()
    }, [])

    useEffect(() => {
        if (vaccines && vaccines.length > 0) {
            setDataTimeLine([])
            for (let index = 0; index < vaccines.length; index++) {
                const element = vaccines[index];
                setDataTimeLine((prev) => ([...prev, {
                    color: 'green',
                    children: (<>
                        <p>{element.VaccineName} - mũi thứ {element.Shot}</p>
                        <p>Đơn vị cung cấp: {element.Organization}</p>
                        <p>ngày tiêm: {dayjs(element.Date).format("YYYY-MM-DD")}</p>
                    </>)
                }]))
            }
        }
    }, [vaccines])

    return (
        <div className="flex justify-center items-start p-8 space-x-8 w-[100%]">
            {/* Left Side: Vaccine List */}
            <div >
                <Timeline
                    items={dataTimeLine}
                />
            </div>

            {/* Right Side: Form and History */}
            <div className="flex flex-col items-center w-[70%]">
                {/* Vaccine Input Form */}
                <div className="bg-pink-100 rounded-lg shadow-md p-8 w-full max-w-lg">
                    <h2 className="text-pink-600 font-semibold text-xl mb-4">Thêm Vắc-xin đã tiêm</h2>
                    <Form form={form} onFinish={handleSubmit} layout="vertical" className="space-y-4">
                        <Form.Item
                            name="VaccineName"
                            label="Tên thuốc:"
                            rules={[
                                { required: true, message: 'Vui lòng nhập tên thuốc!' },
                            ]}>
                            <Input placeholder='Tên thuốc' />
                        </Form.Item>
                        <Form.Item name="Shot"
                            label="Tiêm lần thứ:"
                            rules={[
                                { required: true, message: 'Vui lòng nhập lần tiêm!' },
                            ]}>
                            <Input placeholder='Tiêm lần thứ' />
                        </Form.Item>
                        <Form.Item
                            name="Date"
                            label="Ngày tiêm thuốc:"
                            rules={[
                                { required: true, message: 'Vui lòng nhập Ngày tiêm thuốc!' },
                            ]}>
                            <DatePicker
                                disabledDate={(current) => current > dayjs()}
                                className='w-full' 
                                format="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item
                            name="Organization"
                            label="Đơn vị cung cấp:"
                            rules={[
                                { required: true, message: 'Vui lòng nhập Đơn vị cung cấp!' },
                            ]}>
                            <Input placeholder='Đơn vị cung cấp' />
                        </Form.Item>
                        <button className="w-full h-[40px] px-2 bg-pinkColor text-white text-bold rounded-lg text-[16px]"
                            type="submit">
                            Thêm vào hệ thống
                        </button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default VaccineForm;
