import React, { useState } from "react";
import {
  FaCalendarAlt,
  FaClipboard,
  FaLock,
  FaSignOutAlt,
  FaUserEdit,
  FaUserSlash,
} from "react-icons/fa";
import { Link,useNavigate } from "react-router-dom";
import CustomModal from "../../Common/CustomModalConfirm";

export const DashboardSidebar = () => {
  const navigate = useNavigate(); 
  const [isOpenModalLogout, setOpenModalLogout] = useState(false);
  const handleCancelLogout = () => {
    setOpenModalLogout(false);
  };
  const handleConfirmLogout = () => {
    setOpenModalLogout(false);
    localStorage.clear();
    navigate("/login");
  };
  
  const handleOpenLogout = () =>{
    setOpenModalLogout(true)
  }
  return (
    <>
      <CustomModal
        isOpenModal={isOpenModalLogout}
        handleConfirm={handleConfirmLogout}
        handleCancel={handleCancelLogout}
        textConfirm="Đăng xuất"
        header="Đăng xuất"
        content={
          <>
            <h2 className="text-[20px] text-black pb-3">
              Bạn chắc chắn muốn đăng xuất?
            </h2>
          </>
        }
      />
      <div className="p-5">
        <div className="bg-white rounded-xl shadow-lg p-6 w-full lg:w-[250px] space-y-5">
          <div className="flex items-center space-x-2">
            <FaUserEdit className="w-6 h-6" />
            <Link to="/mom/profile" className="text-lg">
              Thông tin
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <FaClipboard className="w-6 h-6" />
            <Link to="/mom/profile/manage-health" className="text-lg">Quản lý sức khỏe</Link>
          </div>
          <div className="flex items-center space-x-2">
            <FaLock className="w-6 h-6" />
            <Link to="/mom/profile/change-pass" className="text-lg">
              Đổi mật khẩu
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <FaCalendarAlt className="w-6 h-6" />
            <Link to="/mom/profile/schedule" className="text-lg">
              Lịch của tôi
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <FaSignOutAlt className="w-6 h-6" />
            <Link  onClick={handleOpenLogout } className="text-lg">Đăng xuất</Link>
          </div>
        </div>
      </div>
    </>
  );
};
