import React, { useEffect, useState } from 'react'
import CaregiverLayout from '../../../components/Caregiver/CaregiverLayout'
import { Pagination, Table, Tag, Tooltip } from 'antd';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { toast } from 'react-toastify';
import { fetchEntities } from '../../../api/odataClients/caregiverClient';
import { FormRequestWallet } from '../../../components/Caregiver/FormRequestWallet/FormRequestWallet';
import dayjs from 'dayjs';
import { FormAddNewWallet } from '../../../components/Caregiver/FormRequestWallet/FormAddNewWallet';
import { FaCrown, FaHourglassHalf, FaSyncAlt, FaWallet } from 'react-icons/fa';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormDepositPoint } from '../../../components/Caregiver/FormRequestWallet/FormDepositPoint';
import { useLocation } from 'react-router-dom';
import { ACCOUNT_ID } from '../../../api/axiosInstance';

export const CaregiverWallet = () => {
  const information = useLocation();
  const { state } = information
  const [requestWallet, setRequestWallet] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [myWallet, setMyWallet] = useState()
  const [statistical, setStatistical] = useState({
    walletId: 0,
    totalOrder: 0,
    totalPoint: 0,
    pending: 0,
  });
  const [loading, setLoading] = useState(true);
  const [showModalAddNew, setShowModalAddNew] = useState(false)
  const [showModalDeposit, setShowModalDeposit] = useState(false)

  const loadEntities = async () => {
    try {
      const dataWallet = await fetchEntities("wallets", { filter: `AccountID eq ${ACCOUNT_ID()}` })
      if (dataWallet.value.length > 0) {
        setMyWallet(dataWallet.value[0])

        const data = await fetchEntities('requestwallets', {
          filter: `WalletID eq ${dataWallet.value[0].Id} `,
          expand: ['ToAccount', 'Wallet'],
          count: true,
          skip: (currentPage - 1) * pageSize,
          top: pageSize,
          orderBy: ['Id desc']
        });
        setRequestWallet(data.value);

        setDataLength(data['@odata.count']);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false)
    }
  };

  const loadStatic = async (walletId) => {
    const dataStatistical = await fetchEntities('requestwallets',
      {
        filter: `WalletID eq ${walletId} `,
        select: ['money', 'status', 'walletID', 'toAccountID'],
        count: true
      });

    const totalPoint = myWallet.Money;
    const pending = dataStatistical.value.filter((item) => item.Status === false).length;

    setStatistical({
      walletId: myWallet.Id,
      totalPoint: totalPoint,
      pending: pending,
    });
  }

  useEffect(() => {
    if (state && state.RequestDeposit && myWallet) {
      setShowModalDeposit(true)
    }
  }, [])

  useEffect(() => {
    if (myWallet?.Id) {
      loadStatic(myWallet.Id);
    }
  }, [myWallet])

  useEffect(() => {
    loadEntities();
  }, [currentPage, pageSize]);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleActionSuccess = async () => {
    setLoading(true)
    await loadStatic(myWallet.Id);
    await loadEntities();
  }

  const handleAddNewWallet = () => {
    setLoading(true)
    loadEntities();
  }

  const columns = [
    {
      title: "Người nhận",
      dataIndex: "ToAccount",
      key: "ToAccount",
      render: (toAccount) => {
        return (toAccount?.FullName ? toAccount?.FullName : "Tôi");
      },
    },
    { title: "Số MC Point", dataIndex: "Money", key: "Money" },
    { title: "Nội dung", dataIndex: "Action", key: "Action" },
    { title: "Đặt vào ngày", dataIndex: "Date", key: "Date", render: (_, { date }) => { return dayjs(date).format("YYYY-MM-DD") } },
    {
      title: "Trạng thái",
      dataIndex: "Status",
      key: "Status",
      render: (status) => {
        return (
          <div>
            <Tag style={{ width: "60%" }} color={status ? "green" : "yellow"}>
              {status ? "Thành công" : "Đang chờ"}
            </Tag>
          </div>
        );
      },
    },
  ];

  return (
    <CaregiverLayout>
      <LoadingPage isLoading={loading} />
      <FormRequestWallet show={showModalAddNew} onClose={() => setShowModalAddNew(false)} dataWallet={myWallet} onSuccess={handleActionSuccess} />
      <FormDepositPoint show={showModalDeposit} onClose={() => setShowModalDeposit(false)} onSuccess={handleActionSuccess} myWallet={myWallet}/>
      <div className="grid grid-cols-3 gap-4 mb-10">
        <div className='p-4 bg-blue-200 rounded-lg h-32'>
          <span className="bg-blue-500 p-2 rounded-full flex items-center justify-center w-10 h-10">
            <FaSyncAlt className='text-white text-[25px]' />
          </span>
          <p className='text-[18px]'>Số giao dịch</p>
          <h2 className='text-[18px] font-medium'>{myWallet ? dataLength : "Not yet"}</h2>
        </div>
        <div className='relative p-4 bg-pink-200 rounded-lg h-32'>
          <span className="absolute top-2 right-2">
            <Tooltip placement="rightBottom" title={<span>1 điểm = 1.000 VND</span>}>
              <span className='bg-pink-500 p-2 rounded-full flex items-center justify-center w-8 h-8'>
                <QuestionCircleOutlined className='text-white text-[20px]' />
              </span>
            </Tooltip>
          </span>

          <div className='flex'>
            <span className="bg-pink-500 p-2 rounded-full flex items-center justify-center w-10 h-10">
              <FaCrown className='text-white text-[25px]' />
            </span>
          </div>

          <p className='text-[18px]'>Điểm MC của tôi</p>
          <h2 className='text-[18px] font-medium'>{myWallet ? statistical.totalPoint : "Not yet"}</h2>
        </div>
        <div className='p-4 bg-yellow-200 rounded-lg h-32'>
          <span className="bg-yellow-500 p-2 rounded-full flex items-center justify-center w-10 h-10">
            <FaHourglassHalf className='text-white text-[25px]' />
          </span>
          <p className='text-[18px]'>Đang xử lý</p>
          <h2 className='text-[18px] font-medium'>{myWallet ? statistical.pending : "Not yet"}</h2>
        </div>
      </div>

      {myWallet ? <>
        <div className="relative flex justify-between mb-4">
          <h1 className='text-2xl font-semibold'>Giao dịch của bạn</h1>
          <div className='flex'>
            <button
              className='bg-pinkColor px-14 py-2 rounded-lg text-[18px] text-white border border-pinkColor hover:bg-pinkColor hover:text-white'
              onClick={() => setShowModalAddNew(true)}
            >
              Rút điểm
            </button>
            <button
              className='ml-3 bg-pinkColor px-14 py-2 rounded-lg text-[18px] text-white border border-pinkColor hover:bg-pinkColor hover:text-white'
              onClick={() => setShowModalDeposit(true)}
            >
              Nạp thêm điểm
            </button>
          </div>
        </div>

        <Table
          dataSource={requestWallet}
          columns={columns}
          pagination={false}
        />

        {/* Pagination */}
        <div className="mt-4 flex justify-end">
          <Pagination
            total={dataLength}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePaginationChange}
          />
        </div></> : <>
        <Tag color="red" className="text-center p-3 w-full">
          <strong> Hãy thêm ngân hàng</strong>
        </Tag>
        <FormAddNewWallet onSuccess={handleAddNewWallet} />
      </>}
    </CaregiverLayout>
  )
}
