import React from "react";
import bannerImg from "../../assets/image/baby/image 2.png";
import blogImg from "../../assets/image/fruid/grapes.png";
import content1 from "../../assets/image/baby/image10.png";
import content2 from "../../assets/image/baby/image11.png";
import content3 from "../../assets/image/baby/image12.png";
import { PregnancyWeek } from "../../components/Pregnancy/PregnancyWeek/PregnancyWeek";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { EstimateDate } from "../Tool/EstimateDate/EstimateDate";

export const Home = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 mt-10">
        {/* Phần Banner */}
        <div className="flex flex-col lg:flex-row items-center justify-center  py-10">
          <div className="lg:w-[30%]">
            <img
              src={bannerImg}
              alt="Mother and Baby"
              className="w-full rounded-lg"
            />
          </div>
          <div className="lg:w-[60%] lg:h-[400px] bg-[#D8F4FF] mt-6 lg:mt-44 lg:text-left rounded-tr-[230px] px-2">
            <div className="mt-20 space-y-8">
              <h2 className="text-pinkColor text-[40px] text-center font-bold ">
                MCCare
              </h2>
              <p className="text-lg  text-center">
              Cung cấp thông tin về các dịch vụ chăm sóc sức khỏe và hỗ trợ cho
                bà mẹ và trẻ sơ sinh sau khi sinh, bà mẹ trong thời kỳ mang thai và
                trước khi mang thai, mang lại sự hài lòng đáng tin cậy về thông tin
                và tin tức về sức khỏe bà mẹ và trẻ em.
              </p>
            </div>
          </div>
        </div>

        {/* Phần Blog và Estimated Delivery Date */}
        <div className="flex justify-center">
          {/* Estimated Delivery Date */}
          <div>
            <h3 className="text-3xl text-center font-bold mb-4">
            Ngày sinh dự kiến (EDD)
            </h3>
            <EstimateDate />
          </div>
        </div>

        {/* Phần 40 weeks pregnant */}
        <PregnancyWeek />

        {/* Phần Services */}
        <div className="my-8">
          <div className="text-center px-4">
            <h3 className="text-xl sm:text-2xl font-semibold">
            MCCare cung cấp cho bạn thông tin về mẹ, bé và gia đình
            sức khỏe.
            </h3>
            <p className="text-base sm:text-lg mt-4">
            MCCare cam kết cung cấp các tài liệu hữu ích, dễ đọc và
            bài viết đáng tin cậy<br className="hidden sm:block" />{" "}
            chăm sóc sức khỏe bà mẹ và trẻ em.
            </p>
          </div>

          <div className="mt-10 flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-10 px-6 lg:px-40">
            <div className="text-center h-[450px] w-full sm:w-[80%] lg:w-[70%] bg-[#F2FCF7] p-6 rounded-3xl shadow-lg">
              <div>
                <img src={content1} alt="" />
              </div>
              <h4 className="text-xl font-bold mb-2">Nguồn xác thực</h4>
              <p className="text-sm sm:text-base">
              Tất cả các bài viết của MCCare đều được viết dựa trên nghiên cứu – khoa học
                báo cáo, tin tức từ các tổ chức giáo dục và y tế hàng đầu
                cơ quan.
              </p>
            </div>

            <div className="text-center h-[450px] w-full sm:w-[80%] lg:w-[70%] bg-[#D8F4FF] p-6 rounded-3xl shadow-lg">
              <div>
                <img src={content2} alt="" />
              </div>
              <h4 className="text-xl font-bold mb-2">Cập nhật thường xuyên</h4>
              <p className="text-sm sm:text-base">
              MCCare hợp tác với các bác sĩ và chuyên gia y tế để liên tục
              cập nhật bài viết để đảm bảo độ chính xác cao nhất.
              </p>
            </div>

            <div className="text-center h-[450px] w-full sm:w-[80%] lg:w-[70%] bg-[#FFEAF1] p-6 rounded-3xl shadow-lg">
              <div>
                <img src={content3} alt="" />
              </div>
              <h4 className="text-xl font-bold mb-2">Đáng tin cậy</h4>
              <p className="text-sm sm:text-base">
              MCCare - trang thông tin sức khỏe mẹ và bé hàng đầu trên thế giới
                thị trường, cam kết cung cấp thông tin chính xác, dễ đọc,
                và các bài viết được cập nhật liên tục, giúp bạn thực hiện tốt nhất
                quyết định cho sức khỏe của bạn và gia đình bạn.
              </p>
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </>
  );
};
