import React, { useEffect, useState } from "react";
import fruitImg from "../../assets/image/fruid/grapes.png";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { FaBell } from "react-icons/fa";
import { fetchEntities } from "../../api/odataClients/momClient";
import dayjs from "dayjs";
import { ACCOUNT_ID } from "../../api/axiosInstance";

const Notifications = () => {
  const [data, setData] = useState();
  const [itemsNotification, setItemsNotification] = useState([])
  const loadNoti = async () => {
    try {
      const response = await fetchEntities(`notifications`, {
        filter: `AccountId eq ${ACCOUNT_ID()}`,
        orderBy: ['CreatedAt desc']
      })
      setData(response.value)
    } catch (err) {
      console.error("Tải dữ liệu của tài khoản không thành công")
    }
  }
  useEffect(() => {
    loadNoti();
  }, [])

  useEffect(() => {
    if (data && data.length > 0) {
      setItemsNotification([]);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        setItemsNotification((prev) => [
          ...prev,
          {
            label: (
              <div className="flex items-start gap-3 bg-primaryColor p-3 rounded-lg border border-gray-200 shadow-md">
                <img
                  src={fruitImg}
                  alt="NoticeImg"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className="flex-1 flex flex-col space-y-1">
                <h4 className="text-sm font-semibold text-gray-800 max-w-full whitespace-normal break-words">
                  {element.Content}
                </h4>
                  <p className="text-xs text-gray-500">
                    Remind! - {dayjs(element.CreatedAt).format("YYYY-MM-DD")}
                  </p>
                </div>
              </div>
            ),
            key: index,
          },
        ]);
      }
    }
  }, [data]);

  const menuDrop = (
    <Menu
      items={itemsNotification}
      className="overflow-y-auto max-h-[250px] w-[28rem] p-2 rounded-lg border border-gray-300 shadow-lg scroll-smooth hide-scrollbar"
    />
  );

  return (
    <div>
      <Dropdown
        overlay={menuDrop}
        trigger={["click"]}
        arrow={{ pointAtLeft: true }}
        className="cursor-pointer "
      >
        <Link
          onClick={(e) => e.preventDefault()}
          className="flex items-center"
        >
          <button className="bg-pinkColor text-[18px] text-white p-3 rounded-full cursor-pointer shadow-lg transition-transform duration-200 transform hover:scale-110 hover:bg-pink-500">
            {itemsNotification.length > 0 && <span className="absolute top-0 right-0 h-3 w-3 bg-red-600 rounded-full border border-white"></span>}
            <FaBell className="w-4 h-4" />
          </button>
        </Link>
      </Dropdown>
    </div>
  );
};

export default Notifications;
