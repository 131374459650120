import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Col, Row } from "antd";
import { Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import banner from "../assets/image/baby/image 2.png";
import { ACCOUNT_ID, TOKEN_API } from "../api/axiosInstance";
import { toast } from "react-toastify";
import { fetchEntities } from "../api/odataClients/momClient";

export const Contact = () => {
  const [form] = Form.useForm();
  const [account, setAccount] = useState();
  const getAccount = async () => {
    try {
      if (ACCOUNT_ID() && TOKEN_API) {
        const rsp = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["FullName", "PhoneNumber", "Email", "Id"] })
        setAccount(rsp)
      }
    } catch (error) {
      toast.error("Lỗi thấy thông tin người dùng")
    }
  }
  useEffect(() => {
    getAccount()
  }, [])

  useEffect(() => {
    form.setFieldValue(account)
  }, [account])

  const onFinish = async (values) => {
    const createdAt = new Date().toISOString();
    let formData = { ...values, CreatedByID: ACCOUNT_ID(), CreatedAt: createdAt };
    console.log(formData)
    try {
      // await axios.post(`${API_URL_PORT}/odata/feedbacks`, formData);
      toast.success("Tin nhắn của bạn đã được gửi thành công!");
      form.resetFields("Content");
    } catch (e) {
      console.error(e);
      toast.error("Tin nhắn của bạn đã được gửi thất bại!");
    }
  }
  return (
    <>
      <Header />
      <div className="container mx-auto mt-10 px-4 lg:px-[200px] py-4">
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <h1 className="text-[40px] font-bold">Liên hệ với chúng tôi</h1>
            <p className="text-[18px] text-gray-500 py-4">
            </p>
            <Form layout="vertical" initialValues={account} onFinish={onFinish} form={form}>
              <FormItem
                name="FullName"
                label="Tên của bạn"
                style={{ marginRight: "20px", width: "100%" }}
                labelCol={{ style: { fontWeight: "bold" } }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên của bạn!",
                  },
                ]}
              >

                <Input placeholder="Tên của bạn" type="text" />
              </FormItem>
              <div className="flex">
                <FormItem
                  name="Email"
                  label="Địa chỉ Email"
                  style={{ marginRight: "20px", width: "100%" }}
                  labelCol={{ style: { fontWeight: "bold" } }}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập email của bạn!",
                    },
                  ]}
                >

                  <Input placeholder="Địa chỉ email" type="email" />
                </FormItem>

                <FormItem
                  name="PhoneNumber"
                  label="Số điện thoại"
                  style={{ marginRight: "20px", width: "100%" }}
                  labelCol={{ style: { fontWeight: "bold" } }}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại của bạn!",
                    },
                  ]}
                >
                  <Input placeholder="Số điện thoại" type="text" />
                </FormItem>
              </div>

              <FormItem
                name="Content"
                label="Nội dung của bạn"
                style={{ width: "100%" }}
                labelCol={{ style: { fontWeight: "bold" } }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập câu hỏi hoặc tin nhắn của bạn!",
                  },
                ]}
              >
                <TextArea
                  placeholder="Nhập câu hỏi hoặc tin nhắn của bạn"
                  type="text"
                  rows={6}
                />
              </FormItem>

              <button className="custom-button mt-4 px-[45%] bg-pinkColor text-white py-2 px-4 rounded-lg text-[18px]">
                Gửi
              </button>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="py-4 px-4 flex justify-end lg:justify-end sm:justify-center">
              <img src={banner} alt="Mother and Baby" className="w-full lg:w-[400px] lg:h-[500px] sm:w-[300px] sm:h-[400px] rounded-lg" />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};
