export const bodyEmailReplyFeedback = (name, nameTo, content)=>{
    return `<!DOCTYPE html>
<html lang="vi">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333333;
      margin: 0;
      padding: 0;
      background-color: #f4f4f4;
    }
    .email-container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .header {
      text-align: center;
      padding: 10px 0;
      background-color: #4CAF50;
      color: #ffffff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .header h1 {
      margin: 0;
      font-size: 24px;
    }
    .content {
      padding: 20px;
    }
    .content p {
      margin: 10px 0;
    }
    .footer {
      text-align: center;
      padding: 10px;
      color: #777777;
      font-size: 12px;
    }
    .button {
      display: inline-block;
      padding: 10px 20px;
      margin: 20px 0;
      color: #ffffff;
      background-color: #4CAF50;
      border-radius: 5px;
      text-decoration: none;
      font-weight: bold;
    }
  </style>
</head>
<body>
  <div class="email-container">
    <div class="header">
      <h1>Phản hồi của chúng tôi</h1>
    </div>
    
    <div class="content">
      <p>Xin chào ${nameTo},</p>

        ${content}
      <p>Trân trọng,</p>
      <p>${name}<br>
         Mom Child Care</p>

      <a href="https://momchild.life/" class="button">Xem thêm</a>
    </div>
    
    <!-- Email Footer -->
    <div class="footer">
      <p>Cảm ơn bạn đã liên hệ với chúng tôi. Chúng tôi đã nhận được yêu cầu của bạn và sẽ phản hồi sớm nhất có thể.</p>
      
      <p>Nếu bạn có thêm bất kỳ câu hỏi nào, đừng ngần ngại phản hồi lại email này hoặc liên hệ với chúng tôi qua số điện thoại 0359791037 hoặc email support@momchild.life</p>
    </div>
  </div>
</body>
</html>
`
}