import React, { useState } from 'react';
import avatarImg from '../../assets/image/other/avatar.png';
import { Button, Dropdown, Layout, Menu, theme } from 'antd';
import { ChangePasswordCommon } from './ChangePasswordCommoon';
import { AiOutlineLogout, AiOutlineSetting } from 'react-icons/ai';
import CustomModal from '../Common/CustomModalConfirm';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

const HeaderCommon = ({ name = 'Admin', tokenKey }) => {
  const [showPopupChangePass, setShowPopupChangePass] = useState(false);
  const [isOpenModalLogout, setOpenModalLogout] = useState(false);
  const navigate = useNavigate(); 
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleCancelLogout = () => {
    setOpenModalLogout(false)
  }

  const handleConfirmLogout = () => {
    localStorage.removeItem(tokenKey);
    navigate('/login');
    setOpenModalLogout(false)
  }

  const menuItems = [
    {
      label: (
        <div onClick={() => setShowPopupChangePass(true)} className="flex items-center cursor-pointer">
          <AiOutlineSetting className="mr-2" />
          Đổi mật khẩu
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div onClick={() => setOpenModalLogout(true)} className="flex items-center cursor-pointer">
          <AiOutlineLogout className="mr-2" />
          Đăng xuất
        </div>
      ),
      key: "2",
    },
  ];


  return (
    <>
      <CustomModal isOpenModal={isOpenModalLogout} handleConfirm={handleConfirmLogout} handleCancel={handleCancelLogout}
        textConfirm="Đăng xuất"
        header="Đăng xuất"
        textCancel='Huỷ'
        content={(<><h2 className="text-[20px] text-black pb-3">Bạn có muốn đăng xuất không?</h2></>)}
      />
      <ChangePasswordCommon show={showPopupChangePass} onClose={() => setShowPopupChangePass(false)} />
      <Header className='flex justify-between items-center px-[20px]' style={{ background: colorBgContainer }} >
        <h1 className='text-xl font-semibold'>Xin chào, <span className='text-pinkColor text-2xl'>{name}</span> </h1>
        <Dropdown
          overlay={<Menu items={menuItems} />}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className='flex items-center justify-end mr-5 w-[20%] cursor-pointer'>
            <img className='w-12 h-12 rounded-full' src={avatarImg} alt="avatar admin" />
          </div>
        </Dropdown>
      </Header>
    </>
  )
}

export default HeaderCommon;

