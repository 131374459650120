import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Review } from './Review';
import axios from 'axios';
import { createEntity, fetchEntities } from '../../api/odataClients/momClient';
import { toast } from 'react-toastify';
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from '../../api/axiosInstance';
import { DatePicker, Form, InputNumber, Rate, Tag } from 'antd';
import { AiOutlineWechat } from 'react-icons/ai';
import dayjs from 'dayjs';
import './style.css';
import LayoutMom from '../../components/LayoutCommon/LayoutMom';
import PopupConfirmBookingService from './PopupConfirmBookingService';
import DOMPurify from 'dompurify';
import { FaRegUser } from 'react-icons/fa';

export const ServiceDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [service, setService] = useState()
    const [hasBooking, setHasBooking] = useState(false);
    const [account, setAccount] = useState()
    const [dateBooking, setDateBooking] = useState(dayjs())
    const [isShowChatbox, setIsShowChatBox] = useState(false)
    const [message, setMessage] = useState()
    const [inforBooking, setInforBooking] = useState()
    const [showConfirm, setShowConfirm] = useState(false)
    const [serviceRating, setServiceRating] = useState();
    const [addressBooking, setAddressBooking] = useState()
    const [isExpanded, setIsExpanded] = useState(false);
    const [arraySRCImage, setArraySRCImage] = useState([]);
    const [currentImage, setCurrentImage] = useState();
    const maxLength = 2000

    const handleLoadingData = async () => {
        try {
            const data = await fetchEntities(`careservices(${id})`, {
                expand: ["CreatedBy($select=FullName)"]
            });

            const dataRating = await fetchEntities(`comments?$filter=ServiceID eq ${id} &$apply=groupby((ServiceID), aggregate(Rating with average as AverageRating))`, {})
            setServiceRating(dataRating[0])
            console.log("test: ", serviceRating)

            setService(data);
            const listImage = data.ImageUrl.split(" - ");
            const imageUrlList = await Promise.all(
                listImage.map(async (element) => {
                    const url =
                        process.env.NODE_ENV === 'development'
                            ? `https://momchild.life/api/s3/getimage?url=${element}`
                            : `${API_URL_PORT}/api/s3/getimage?url=${element}`;
                    const respGetImage = await axios.get(url);
                    const imageUrl = respGetImage.data.data.imageUrl;
                    return imageUrl;
                })
            );
            setArraySRCImage(imageUrlList);

            const dataAccount = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["FullName"] });
            setAccount(dataAccount)
        } catch (err) {
            if (err.status === 404) {
                navigate("/*")
            }
            console.log()
        }
    }

    const handleConfirmBooking = async () => {
        try {
            let careServiceBooking = { ...inforBooking, Address: addressBooking, RemainAmount: inforBooking.Price }
            const booking = await createEntity("careservicebookings", careServiceBooking)
            toast.success("Tạo đơn cho dịch vụ thành công")

            const checkGroup = await checkGroupChat()
            if (checkGroup) {
                const messSystem = `<p><a id='${service.Id}' class='${booking.Id}-careservice' href=""><strong>${service.Title}</strong></a></p>`
                await createNewGroupChat(checkGroup, messSystem)
            } else {
                toast.error("Hệ thống đang bận, không thể tạo đoạn trò chuyện")
            }
            setShowConfirm(false)
        } catch (error) {
            toast.error("Lỗi đặt dịch vụ")
        }
    }

    const toggleExpand = () => {
        setIsExpanded((prev) => {
            const descriptionElement = document.getElementById('description-section');
            if (descriptionElement) {
                if (prev) {
                    descriptionElement.classList.add("max-h-[100px]", "overflow-y-auto", "overflow-none");
                    const offsetTop = descriptionElement.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({
                        top: offsetTop - 150,
                        behavior: 'smooth',
                    });
                } else {
                    descriptionElement.classList.remove("max-h-[100px]", "overflow-y-auto", "overflow-none");
                }
            }
            return !prev;
        });
    };


    const handleSubmitForm = async (value) => {
        setInforBooking({
            CareServiceID: service.Id,
            AccountID: ACCOUNT_ID(),
            BookingAt: value.BookingAt,
            Status: "Đang chờ",
            InHour: Number(value.InHour),
            Price: Number(value.InHour) * service.Price
        })
        setShowConfirm(true)
    }

    const checkBooking = async () => {
        try {
            const data = await fetchEntities(`careservicebookings`, { filter: `AccountID eq ${ACCOUNT_ID()} and CareServiceID eq ${id}` });
            setHasBooking(data.value[data.value.length - 1].Status === 'Hoàn thành')
        } catch (err) {

        }
    }

    const SendMesssageFromCareService = async () => {
        const checkGroup = await checkGroupChat()
        if (checkGroup) {
            const messSystem = `<p><a id='${service.Id}' href=""><strong>${service.Title}</strong></a></p>${message}`
            await createNewGroupChat(checkGroup, messSystem)
        } else {
            toast.error("Hệ thống đang bận, vui lòng thử lại sau")
        }
    }

    const checkGroupChat = async () => {
        try {
            const caregiverId = service.CreatedByID;
            const dataAccount = await fetchEntities('groupmembers', {
                filter: `AccountId eq ${ACCOUNT_ID()}`,
                select: ["GroupID"]
            });
            const accountGroupIDs = dataAccount.value.map(message => message.GroupID);

            const dataCaregiver = await fetchEntities('groupmembers', {
                filter: `AccountId eq ${caregiverId}`,
                select: ["GroupID"]
            });
            const caregiverGroupIDs = dataCaregiver.value.map(message => message.GroupID);

            const commonGroupID = accountGroupIDs.find(groupID => caregiverGroupIDs.includes(groupID));
            const chatBoxExists = Boolean(commonGroupID);

            const formData = {
                chatBoxExists: chatBoxExists,
                momId: ACCOUNT_ID(),
                caregiverId: id,
                service: service,
                commonGroupID: commonGroupID,
                groupName: `${account.FullName}, ${service.CreatedBy.FullName}`
            }

            return formData
        } catch (err) {
            toast.error("Kiểm ta hộp thoại");
        }
        return null
    }

    const createNewGroupChat = async (dataSend, mess) => {
        try {
            let newMessage = null
            if (dataSend.chatBoxExists === true) {
                newMessage = {
                    GroupID: dataSend.commonGroupID,
                    Content: mess,
                    CreatedByID: dataSend.momId,
                    ImageUrl: null,
                    IsMessageSys: true,
                    CreatedAt: dayjs().toISOString()
                };
            } else {
                const rspGroupChat = await createEntity("groupchats", { Name: dataSend.groupName });
                await createEntity("groupmembers", { GroupID: rspGroupChat.Id, AccountID: dataSend.momId });
                await createEntity("groupmembers", { GroupID: rspGroupChat.Id, AccountID: dataSend.caregiverId });

                newMessage = {
                    GroupID: rspGroupChat.Id,
                    Content: mess,
                    CreatedByID: dataSend.momId,
                    ImageUrl: null,
                    IsMessageSys: true,
                    CreatedAt: dayjs().toISOString()
                };
            }

            if (newMessage) {
                await createEntity("messages", newMessage);
                navigate('/mom/chat', { state: { commonGroupID: newMessage.GroupID } })
            } else {
                toast.error('Lỗi tạo tin nhắn')
            }
        } catch (e) {
            console.error("Error saving message:", e);
        }
    }

    useEffect(() => {
        handleLoadingData()
        checkBooking()
    }, [])

    const toggleChatbox = () => {
        setIsShowChatBox((prev) => !prev);
    };

    return (
        <LayoutMom>
            {TOKEN_API &&
                <PopupConfirmBookingService show={showConfirm} setAddressBooking={setAddressBooking} onClose={() => setShowConfirm(false)} title={service?.Title} inforBooking={inforBooking} handleConfirm={handleConfirmBooking} />
            }
            <div className='container mx-auto mb-36'>
                <div className='bg-[#EEEEEE] text-center px-16 py-10 h-[150px]'>
                    <h1 className='text-[30px] text-start font-bold'>{service?.Title}</h1>
                    <div className="flex">
                        <Link to="/" className="hover:text-pinkColor">
                            Home
                        </Link>
                        <span className="mx-1">/</span>
                        <Link to="/mom/service" className="hover:text-pinkColor">
                            Service
                        </Link>
                        <span className="mx-1">/</span>
                        <Link to={`/mom/service/${service?.Id}`} className="hover:text-pinkColor">
                            {service?.Title}
                        </Link>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row mt-10 px-16'>
                    <div className='w-full md:w-1/2 px-4'>
                        <img
                            src={currentImage || arraySRCImage[0]}
                            alt="Service"
                            className="w-full h-[400px] rounded-lg mb-4"
                        />
                        <div className="flex justify-center space-x-5">
                            {arraySRCImage.map((img, index) => (
                                <img
                                    key={index}
                                    src={img}
                                    alt={`Thumbnail ${index + 1}`}
                                    className={`w-[15%] rounded-lg cursor-pointer ${currentImage === img ? 'border-2 border-pinkColor' : ''}`}
                                    onClick={() => setCurrentImage(img)}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='w-full md:w-1/2 px-4 space-y-5'>
                        <h2 className='text-pinkColor text-2xl font-semibold'>{service?.Title}</h2>
                        <p className='text-gray-800 text-2xl font-bold'>{service?.Price} VND / Giờ</p>
                        <p color='blue text-sml'>Người chăm sóc: <i>{service?.CreatedBy.FullName}</i></p>
                        {/* Star rating */}
                        <div className="flex items-center my-2">
                            {serviceRating && <Rate disabled defaultValue={serviceRating?.AverageRating || 0} style={{ fontSize: '1rem' }} />}
                            <span className="ml-2 text-yellow-500 text-sm">{serviceRating?.AverageRating ? serviceRating?.AverageRating : "0"}/5.0</span>
                        </div>

                        <div className="flex flex-col items-start mt-10 space-y-4">
                            {service?.IsActive ? <Form className="space-y-3" onFinish={handleSubmitForm} initialValues={{ BookingAt: dayjs(), InHour: 1 }} layout='vertical'>
                                <div className='flex space-x-4'>
                                    <Form.Item
                                        label="Thời gian bắt đầu"
                                        rules={[{ required: true, message: "Hãy chọn thời gian thực hiện" }]}
                                        className='m-0' name="BookingAt">
                                        <DatePicker
                                            defaultValue={dayjs()}
                                            format="DD/MM/YYYY HH:mm:ss"
                                            disabledDate={(current) => current && current < dayjs().startOf('day')}
                                            showTime
                                            value={dateBooking}
                                            onChange={(_, dateStr) => {
                                                if (dateStr) {
                                                    setDateBooking(dayjs(dateStr))
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{ required: true, message: "Hãy chọn số giờ thực hiện" }]}
                                        label="Số giờ làm việc"
                                        className='m-0' name="InHour">
                                        <InputNumber min={1} />
                                    </Form.Item>
                                </div>
                                <Tag className="container text-center mx-auto mb-36" color="red">
                                    Lưu ý: chọn chính xác ngày mà người chăm sóc tới để thực hiện dịch vụ
                                </Tag>
                                {TOKEN_API &&
                                    <div className=''>
                                        <button type='submit' className="bg-pinkColor text-white py-3 px-10 rounded-full">
                                            Đặt dịch vụ
                                        </button>
                                    </div>
                                }
                            </Form> :
                                <Tag className='p-3 text-[16px]' color='red'>Dịch vụ đã được người đăng khoá lại</Tag>}
                            <div className="relative">
                                {TOKEN_API ?
                                    <button
                                        className="bg-pinkColor flex items-center text-white py-3 px-10 rounded-full"
                                        onClick={toggleChatbox}
                                    >
                                        <AiOutlineWechat className="w-6 h-6 mr-2" />
                                        Chat với người đăng
                                    </button> :
                                    <div className="flex items-center justifyContent-center bg-pinkColor text-white p-3 rounded-full mt-4">
                                        <Link to='/login' className='pr-2'>Đăng nhập</Link>
                                        <FaRegUser className='w-4 h-4' />
                                    </div>
                                }

                                {isShowChatbox && (
                                    <div className="chatbox-slide-down absolute top-14 right-0 w-80 bg-white p-4 rounded-lg shadow-lg border border-gray-200 z-10">
                                        <h3 className="text-lg font-semibold mb-2">Chat với người đăng</h3>
                                        <textarea
                                            className="w-full h-24 p-2 border border-gray-300 rounded-lg"
                                            placeholder="Nhập tin nhắn..."
                                            onChange={({ target }) => setMessage(target.value)}
                                        ></textarea>
                                        <button onClick={SendMesssageFromCareService} className="bg-pinkColor text-white py-2 px-4 rounded-full mt-3 w-full">
                                            Gửi
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
                {service?.Description
                    && (
                        <>
                            <h2 className='text-[21px] text-center underline'>Thông tin chi tiết</h2>
                            <div id="description-section" style={{ overflow: 'hidden' }} className='mt-5 max-h-[100px] overflow-y-auto overflow-none px-16' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service?.Description) }} />
                            {service?.Description.length > maxLength && (
                                <div className='flex justify-center mt-3'>
                                    <button
                                        onClick={toggleExpand}
                                        className="text-blue-500 hover:underline"
                                    >
                                        {isExpanded ? 'Ẩn bớt' : 'Xem thêm'}
                                    </button>
                                </div>
                            )}
                        </>
                    )
                }
                <Review hasBooking={hasBooking} idService={id} isActive={service?.IsActive} />
            </div>
        </LayoutMom>
    )
}
