import axios from "axios";
import React, { useEffect, useState } from "react"
import { API_URL_PORT } from "../../api/axiosInstance";
import dayjs from "dayjs";

export const ContractView = ({ account, contract }) => {
    const [imageSigning, setImageSigning] = useState();
    const [isImageError, setIsImageError] = useState(false);

    function maskLastFourDigits(numberString) {
        if (numberString?.length <= 4) {
            return '*'.repeat(numberString.length); // Nếu chuỗi có ít hơn 4 ký tự
        }
        return numberString?.slice(0, -4) + '****';
    }

    const loadingImageSigning = async () => {
        const urlGetImage = process.env.NODE_ENV === 'development' ?
            `https://momchild.life/api/s3/getimage?url=${contract?.FileUrl}` :
            `${API_URL_PORT}/api/s3/getimage?url=${contract?.FileUrl}`
        const respGetImage = await axios.get(urlGetImage);
        setImageSigning(respGetImage.data.data.imageUrl)
    }

    useEffect(() => {
        loadingImageSigning()
    }, [contract])

    return (
        <div className="mt-4 p-6 border min-h-max shadow-md py-8 rounded-3xl flex justify-center items-center flex-col">
            <div id="pdf-contract" style={{ padding: "20px", margin: 'auto', maxWidth: "1000px" }}>
                <div style={{
                    width: "100%",
                    margin: "20px auto",
                    padding: "40px",
                    backgroundColor: "#fdfdf5",
                    border: "1px solid #ccc",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    lineHeight: "1.6",
                    whiteSpace: "normal",
                }}>
                    <h1 style={{ textAlign: "center", textDecoration: "underline" }}>HỢP ĐỒNG CHẤP THUẬN DỊCH VỤ</h1>

                    <div style={{ marginBottom: "20px" }}>
                        <p><strong>Tôi tên: </strong> {account?.FullName}</p>
                        <p><strong>Ngày sinh: </strong> {dayjs(account?.DateOfBirth).format("DD/MM/YYYY")} </p>
                        <p><strong>Giới tính: </strong> {account?.Sex ? "Nam" : "Nữ"} </p>
                        <p><strong>Số điện thoại: </strong> {account?.PhoneNumber} </p>
                        <p><strong>Số CCCD: </strong> {maskLastFourDigits(account?.NumberCard)} </p>
                    </div>

                    <p>Tôi đồng ý với các điều khoản sau để sử dụng hệ thống với vai trò là 1 người chăm sóc:</p>
                    <div className="contract-section">
                        <p><strong>1. Điều khoản Chất lượng Dịch vụ </strong></p>
                        <p>Caregiver cam kết cung cấp các dịch vụ đúng mô tả, đáp ứng đầy đủ tiêu chuẩn an toàn và vệ sinh. Đảm bảo kỹ năng chuyên môn của caregiver được duy trì và cập nhật thường xuyên.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>2. Trách nhiệm Đạo đức và An toàn </strong></p>
                        <p>Caregiver phải luôn giữ thái độ chuyên nghiệp, tôn trọng và hỗ trợ mẹ một cách tận tâm. Tất cả các hành vi không đạo đức hoặc gây nguy hại đều bị nghiêm cấm.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>3. Cam kết Bảo mật Thông tin </strong></p>
                        <p>Caregiver cam kết giữ bí mật mọi thông tin cá nhân và sức khoẻ của mẹ. Mọi thông tin chỉ được sử dụng với mục đích hỗ trợ và không chia sẻ cho bên thứ ba khi chưa được sự đồng ý.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>4. Điều khoản Bồi thường </strong></p>
                        <p>Trong trường hợp dịch vụ không đạt yêu cầu hoặc có sai sót, caregiver sẽ chịu trách nhiệm và bồi thường cho người sử dụng dịch vụ theo thỏa thuận.</p>
                    </div>

                    <div className="contract-section">
                        <p><strong>5. Điều khoản Dịch vụ </strong></p>
                        <p>Trong việc nhấp nhận các yêu cầu dịch vụ của các bà mẹ, phải bỏ ra phí với <strong>10%</strong> giá của dịch vụ để có thể xác nhận yêu cầu từ phía các mẹ</p>
                    </div>

                    <div className='flex justify-end text-center' style={{ marginBottom: "15px" }}>
                        <div className='mt-5 text-center'>
                            <p className='mr-6 pl-5'><strong>Chữ ký:</strong></p>
                            <p className='text-center'><i>{account?.FullName}</i></p>
                            {/* <img src={imageSigning} className="pl8" alt="Signing" /> */}
                            {!isImageError ? (
                                <img
                                    src={imageSigning}
                                    className="pl-8"
                                    alt="Signing"
                                    onError={() => setIsImageError(true)}
                                />
                            ) : (
                                <p className="text-pinkColor">Khách hàng chưa ký</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}