import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Select, Tag } from "antd";
import "react-quill/dist/quill.snow.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AdminLayout from "../../../components/Admin/AdminLayout";
import { fetchEntities, updateEntity } from "../../../api/odataClients/adminClient";
import { toast } from "react-toastify";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
const { Option } = Select;

export const BlogDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(true);

  const loadEntities = async () => {
    try {
      const data = await fetchEntities(`blogs(${id})`, {
        expand: ['BlogType', 'CreatedBy']
      });
      setBlog(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blog:', error);
      toast.error('Error fetching blog');
      setLoading(false);
    }
  }

  useEffect(() => {
    loadEntities();
  }, [])

  const handleChangeStatusBlog = async (value) => {
    const blogUpdate = { ...blog, Status: value }
    try {
      await updateEntity('blogs', blog.Id, blogUpdate);
      toast.success('User updated success')
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user')
    }finally{
      setBlog(blogUpdate)
    }
  }

  return (
    <AdminLayout>
      <LoadingPage isLoading={loading} />
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <Button type="link" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
            Back
          </Button>
        </div>
        <div className="mb-10 text-center bg-gray-50 shadow-md py-8 rounded-3xl mx-[20%] space-y-5 text-xl">
          <p>
            Title: <strong>{blog?.Title}</strong>
          </p>
          <p>
            Author: <strong>{blog?.CreatedBy?.FullName}</strong> | Type: <strong>{blog?.BlogType?.Name}</strong>
          </p>
          <p>
            Status:
            <Select
              className={`border-none p-0`}
              size="large"
              value={blog.Status}
              style={{ border: 'none !important', outline: 'none !important' }}
              onChange={(value) => handleChangeStatusBlog(value)}
            >
              <Option value="Pending Review" className="text-green-600">
                <Tag className='w-[100%]' color={'blue'}>
                  <strong>Pending Review</strong>
                </Tag>
              </Option>
              <Option value="Published" className="text-green-600">
                <Tag className='w-[100%]' color={'green'}>
                  <strong>Published</strong>
                </Tag>
              </Option>
              <Option value="Cancelled" className="text-red-600">
                <Tag className='w-[100%]' color={'red'}>
                  <strong>Cancelled</strong>
                </Tag>
              </Option>
            </Select>
          </p>
        </div>

        <div className="mt-4 p-6 border min-h-max shadow-md py-8 rounded-3xl">
          <div dangerouslySetInnerHTML={{ __html: blog?.Content }} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default BlogDetail;
