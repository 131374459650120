import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createEntity, fetchEntities, updateEntity } from "../../api/odataClients/momClient";
import { ACCOUNT_ID, API_URL_PORT } from "../../api/axiosInstance";
import dayjs from "dayjs";
import axios from "axios";
import { Link } from "react-router-dom";
import { DatePicker, Tag, Tooltip } from "antd";
import LoadingPage from "../LoadingPage/LoadingPage";
import CustomModal from "../Common/CustomModalConfirm";

export const MedicalServiceBooked = () => {
    const [services, setServices] = useState();
    const [serviceBooked, setServiceBooked] = useState();
    const [serviceImages, setServiceImages] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
    const [serviceBookingSelected, setServiceBookingSelected] = useState()
    const [showPopupEditSchedule, setShowPopupEditSchedule] = useState(false)
    const [timeBooking, setTimeBooking] = useState(dayjs())

    const fetchdata = async () => {
        try {
            const rspServiceBooked = await fetchEntities('scheduleBookings', {
                filter: "AccountID eq " + ACCOUNT_ID(),
                expand: ['Schedule'],
                orderBy: ['Id desc']
            });

            setServiceBooked(rspServiceBooked.value);
            let servicesArray = {}
            if (rspServiceBooked && rspServiceBooked.value.length > 0) {
                for (let element of rspServiceBooked.value) {
                    servicesArray = { ...servicesArray, [element.ScheduleID]: element.Schedule }
                }
            }
            setServices(servicesArray);
        } catch (err) {
            console.log(err)
            toast.error("Lỗi hệ thống")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        hanldeLoadingImages()
    }, [services])

    const hanldeLoadingImages = async () => {
        if (serviceBooked && serviceBooked.length > 0) {
            for (let v of serviceBooked) {
                const element = v.Schedule.ImageUrl.split(" - ")[0].trim()
                const url = process.env.NODE_ENV === 'development' ? `https://momchild.life/api/s3/getimage?url=${element}` : `${API_URL_PORT}/api/s3/getimage?url=${element.split(" - ")[0].trim()}`
                const respGetImage = await axios.get(url);
                setServiceImages((prev) => ({ ...prev, [element]: respGetImage.data.data.imageUrl }))
            }
        }
    }

    const handleConfirmUpdate = async () => {
        if (!timeBooking) {
            toast.success("Hãy chọn thời gian để thay đổi")
            return;
        }

        setIsLoading(true)
        try {
            await updateEntity('scheduleBookings', serviceBookingSelected.Id, {
                Id: serviceBookingSelected.Id,
                BookingAt: timeBooking, Status:
                    serviceBookingSelected.Status
            });
            toast.success("cập nhập thông tin thành công")
            setShowPopupEditSchedule(false)
            setTimeBooking(null)
            fetchdata()
        } catch (err) {
            toast.error("Lỗi khi huỷ dịch vụ")
        } finally {
            setIsLoading(false)
        }
    }

    const handleConfirmModal = async () => {
        setIsLoading(true)
        try {
            await updateEntity('scheduleBookings', serviceBookingSelected.Id, { Id: serviceBookingSelected.Id, Status: "Đã hủy" });
            await createEntity('notifications', {
                AccountID: serviceBookingSelected.Schedule.CreatedByID,
                Content: `Dịch vụ ${serviceBookingSelected.Schedule.Content} đã được hủy`,
                CreatedAt: dayjs().toISOString(),
                IsRead: false
            });
            toast.success("Huỷ dịch vụ thành công")
            setOpenPopupConfirm(false)
            fetchdata()
        } catch (err) {
            toast.error("Lỗi khi huỷ dịch vụ")
        } finally {
            setIsLoading(false)
        }
    }

    const toggleEdit = (serviceBooked) => {
        if (serviceBooked.Status === "Đang chờ") {
            setShowPopupEditSchedule(true)
            setServiceBookingSelected(serviceBooked)
            setTimeBooking(serviceBooked.BookingAt)
        }
    }

    const handleCancelService = (serviceBooked) => {
        setOpenPopupConfirm(true)
        setServiceBookingSelected(serviceBooked)
    }

    const checkDateOver24h = (date) => {
        const now = dayjs();
        const givenDate = dayjs(date);

        return givenDate.diff(now, 'hour') > 24;
    };

    useEffect(() => {
        setIsLoading(true)
        fetchdata()
    }, [])

    return (<>
        <div className="space-y-4 pl-10 pr-10 mr-10 ml-10 min-h-[90vh]">
            <LoadingPage isLoading={isLoading} />
            <CustomModal isOpenModal={openPopupConfirm} handleConfirm={handleConfirmModal} handleCancel={() => { setOpenPopupConfirm(false) }}
                textConfirm="Chấp nhận"
                header="Xác nhận dịch vụ"
                textCancel='Huỷ'
                content={(<><h2 className="text-[14px] text-black pb-3">Bạn có chắc chắn muốn huỷ dịch vụ đã đăng ký không?</h2></>)}
            />
            <CustomModal isOpenModal={showPopupEditSchedule} handleConfirm={handleConfirmUpdate}
                handleCancel={() => {
                    setTimeBooking(null)
                    setShowPopupEditSchedule(false)
                }}
                textConfirm="Chấp nhận"
                header="Chỉnh sửa thông tin"
                textCancel='Huỷ'
                content={(<>
                    <h2 className="text-[16px] text-black pb-1">{serviceBookingSelected?.Schedule.Content}</h2>
                    <h2 className="text-[14px] text-black pb-3">{serviceBookingSelected?.Schedule.HospitalName}</h2>
                    <h2 className="text-[14px] text-black pb-3">
                        Lịch hẹn:
                        <DatePicker
                            value={dayjs(timeBooking)}
                            onChange={(value) => {
                                if (value) {
                                    setTimeBooking(value.toISOString())
                                }
                            }
                            }
                            disabledDate={(current) => {
                                const today = dayjs().startOf('day');
                                const twoWeeksLater = dayjs().add(2, 'week').endOf('day');
                                return current && (current < today || current > twoWeeksLater);
                            }}
                            disabledTime={(date) => {
                                const hours = Array.from({ length: 24 }, (_, i) => i);
                                const disabledHours = hours.filter((hour) => hour < 8 || hour > 17);
                                return {
                                    disabledHours: () => disabledHours,
                                };
                            }}
                            format="DD/MM/YYYY HH:mm"
                            showTime
                        />
                    </h2>
                </>)}
            />
            {serviceBooked && serviceBooked.length > 0 ? <>
                {serviceBooked?.map(serviceBooking => (
                    <>
                        <div className="flex justify-center mx-auto">
                            <div key={serviceBooking?.Id} className="flex flex-row w-full max-w-[80%] border border-slate-300 rounded-lg overflow-hidden">
                                {/* Image Section */}
                                <div className="w-[20%] p-4">
                                    <img
                                        src={serviceImages && serviceImages[serviceBooking?.Schedule.ImageUrl.split(" - ")[0].trim()]}
                                        alt="Ảnh dịch vụ"
                                        className="w-full h-[130px] rounded-xl"
                                    />
                                </div>

                                {/* Content Section */}
                                <div className="flex justify-between items-center px-4 w-[80%]">
                                    <div className="w-[60%]">
                                        <h3 className="text-lg font-semibold">
                                            <Link className="hover:text-slate-700 text-pinkColor " to={`/mom/service/${serviceBooking?.ScheduleID}`}>{serviceBooking?.Schedule.Content}</Link>
                                        </h3>
                                        <p className="text-blue-600 font-semibold">Tại {serviceBooking?.Schedule.HospitalName}</p>
                                        <p className="text-black-600 font-semibold">Ngày hẹn:  {dayjs(serviceBooking?.BookingAt).format("DD/MM/YYYY HH:mm")}</p>
                                        {serviceBooking.Status !== "Đã hủy" &&
                                            <div className="flex justify-center w-full">
                                                <Tooltip title="Lịch hẹn chỉ được thay đổi khi chưa xác nhận" >
                                                    <button onClick={() => toggleEdit(serviceBooking)} className="bg-pinkColor px-10 border-none text-white rounded-lg mt-4">Thay đổi lịch hẹn</button>
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>

                                    {/* Buttons */}
                                    <div className="flex items-center space-x-2 mt-2 w-[20%]">
                                        <Tag className={`flex items-center px-2 h-[30%] font-medium 
                                            ${serviceBooking?.Status === 'Đã hủy' ? "bg-red-500 border border-red-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Đã xác nhận' ? "bg-green-500 border border-green-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Đang chờ' ? "bg-slate-500 border border-slate-500 text-white" : ''}
                                        `}>
                                            {serviceBooking?.Status}
                                        </Tag>
                                    </div>

                                    <div className="w-[20%] flex flex-col items-end space-y-2 p-4 ">
                                        {serviceBooking?.Status !== "Đã hủy" &&
                                            <Tooltip title="Trước 24h so với giờ đặt lịch sẽ không được huỷ dịch vụ">
                                                {checkDateOver24h(serviceBooking?.BookingAt)
                                                    ? <button
                                                        type="button"
                                                        className="bg-pinkColor p-2 w-[150px] px-5 border-none text-white rounded-lg"
                                                        onClick={() => handleCancelService(serviceBooking)}
                                                    >
                                                        Huỷ Dịch vụ
                                                    </button>
                                                    :
                                                    <button
                                                        type="button"
                                                        className="bg-pinkColor p-2 opacity-50 pointer-events-none w-[150px] px-5 border-none text-white rounded-lg"
                                                    >
                                                        Huỷ Dịch vụ
                                                    </button>}

                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </> : <>
                <h3 className='text-center'> no Data</h3>
            </>
            }
        </div>
    </>)
}