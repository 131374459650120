import { jwtDecode } from 'jwt-decode';

/**
 * Giải mã JWT token.
 * @param {string} token - JWT token.
 * @returns {object|null} - Thông tin giải mã hoặc null nếu token không hợp lệ.
 */
export const decodeToken = (token) => {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error("Không thể giải mã token:", error);
        return null;
    }
};

/**
 * Lấy Id từ JWT token.
 * @param {string} token - JWT token.
 * @returns {string|null} - Id hoặc null nếu không tìm thấy.
 */
export const getIdFromToken = (token) => {
    const decoded = decodeToken(token);
    if (decoded) {
        return decoded.Id || null;
    }
    return null;
};

/**
 * Lấy role từ JWT token.
 * @param {string} token - JWT token.
 * @returns {string|null} - Vai trò hoặc null nếu không tìm thấy.
 */
export const getRoleFromToken = (token) => {
    const decoded = decodeToken(token);
    if (decoded) {
        return decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || null;
    }
    return null;
};

/**
 * Lấy name từ JWT token.
 * @param {string} token - JWT token.
 * @returns {string|null} - Vai trò hoặc null nếu không tìm thấy.
 */
export const getNameFromToken = (token) => {
    const decoded = decodeToken(token);
    if (decoded) {
        return decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] || null;
    }
    return null;
};

/**
 * Lấy Issuer từ JWT token.
 * @param {string} token - JWT token.
 * @returns {string|null} - Issuer hoặc null nếu không tìm thấy.
 */
export const getIssuerFromToken = (token) => {
    const decoded = decodeToken(token);
    if (decoded) {
        return decoded.iss || null;
    }
    return null;
};

/**
 * Kiểm tra Issuer của token có khớp với giá trị mong đợi hay không.
 * @param {string} token - JWT token.
 * @param {string} expectedIssuer - Giá trị Issuer mong đợi.
 * @returns {boolean} - True nếu khớp, ngược lại false.
 */
export const isIssuerValid = (token, expectedIssuer) => {
    const issuer = getIssuerFromToken(token);
    return issuer === expectedIssuer;
};

/**
 * Kiểm tra xem token có hết hạn hay không.
 * @param {string} token - JWT token.
 * @returns {boolean} - True nếu đã hết hạn, ngược lại false.
 */
export const isTokenExpired = (token) => {
    const decoded = decodeToken(token);
    if (decoded && decoded.exp) {
        const currentTime = Date.now() / 1000; // Chuyển đổi sang giây
        return decoded.exp < currentTime;
    }
    return true; // Nếu không có exp, coi như hết hạn
};
