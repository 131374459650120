import React, { useEffect, useRef, useState } from 'react';
import { Table, Input, Select, Pagination, Tag, Button, Radio } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, WechatOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '../../../components/Admin/AdminLayout';
import { fetchEntities } from '../../../api/odataClients/adminClient';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import debounce from 'lodash.debounce';

const { Option } = Select;

export const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dataLength, setDataLength] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSordOrder] = useState('newest')
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [valueFilter, setValueFilter] = useState("")

  const [statistical, setStatistical] = useState({
    feedback: 0,
    answered: 0,
    notYet: 0,
  });

  const loadStatic = async () => {
    const dataStatistical = await fetchEntities('feedbacks', { count: true });
    const answered = dataStatistical.value.filter((feedback) => feedback?.Reply !== null & feedback.Reply?.length > 0).length;
    let notYet = dataStatistical['@odata.count'] - answered;
    notYet = notYet > 0 ? notYet : 0

    setStatistical({
      feedback: dataStatistical['@odata.count'],
      answered: answered,
      notYet: notYet,
    });
  }

  const loadEntities = async (searchValue = null) => {
    let filter = searchValue ? `contains(Name,'${searchValue}')` : ""

    if (valueFilter && valueFilter.length > 0)
      filter += searchValue ? ` and ${valueFilter}` : valueFilter

    try {
      const data = await fetchEntities('feedbacks', {
        filter: filter,
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: [`${sortOrder === 'newest' ? 'CreatedAt desc' : 'CreatedAt asc'}`]
      });
      setFeedbacks(data.value);
      setDataLength(data['@odata.count']);
    } catch (err) {
      toast(err);
      console.error(err);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadStatic();
  }, []);

  useEffect(() => {
    loadEntities();
  }, [currentPage, pageSize, sortOrder, valueFilter]);

  const handleReplyClick = (feedbackId) => {
    navigate(`/admin/feedback/${feedbackId}`, { state: { defaultSelectedValue: 5 } });
  };

  const handleSortChange = (value) => {
    setSordOrder(value);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.slice(0, maxLength) + "...";
    }
    return text;
  };

  const handleFilterSelection = (e) => {
    const { value } = e.target;
    setValueFilter(value)
  }

  const debounceDropDown = useRef(
    debounce((searchValue) => {
      loadEntities(searchValue)
    }, 500)
  ).current;

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue)
    debounceDropDown(searchValue);
  };

  React.useEffect(() => {
    return () => {
      debounceDropDown.cancel();
    };
  }, [debounceDropDown]);

  const columns = [
    { title: 'Tên đầy đủ', dataIndex: 'Name', key: 'Name' },
    { title: 'Ngày', dataIndex: 'CreatedAt', key: 'CreatedAt', render: (date) => dayjs(date).format("YYYY-MM-DD") },
    { title: 'Nội dung', dataIndex: 'Content', key: 'Content' },
    {
      title: 'Trả lời', dataIndex: 'Reply', key: 'Reply',
      render: (reply) => (
        <Tag color={reply ? 'green' : 'red'}>{reply ? "Đã trả lờI" : "Chưa trả lời"}</Tag>
      )
    },
    {
      title: 'Hành động', dataIndex: 'action', id: 'action',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleReplyClick(record.Id)}>
          Trả lời
        </Button>
      ),
    },
  ];

  return (
    <AdminLayout>
      <LoadingPage isLoading={loading} />
      <Radio.Group onChange={handleFilterSelection} className="grid grid-cols-3 gap-3 mb-10 justify-center" defaultValue={valueFilter}>
        <Radio.Button className="shadow rounded p-0" value="" style={{ height: "auto" }}>
          <div className="bg-green-200 rounded-lg p-4">
            <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-12 h-12 mb-2">
              <WechatOutlined className="text-white text-3xl" />
            </span>
            <div className="text-sm">Tổng Phản hồi</div>
            <div className="text-3xl font-bold">{statistical.feedback}</div>
          </div>
        </Radio.Button>

        <Radio.Button className="shadow rounded p-0" value="Reply ne null and Reply ne ''" style={{ height: "auto" }}>
          <div className="bg-green-200 rounded-lg p-4">
            <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-12 h-12 mb-2">
              <CheckCircleOutlined className="text-white text-3xl" />
            </span>
            <div className="text-sm">Đã trả lời</div>
            <div className="text-3xl font-bold">{statistical.answered}</div>
          </div>
        </Radio.Button>

        <Radio.Button className="shadow rounded p-0" value="Reply eq null or Reply eq ''" style={{ height: "auto" }}>
          <div className="bg-red-200 rounded-lg p-4">
            <span className="bg-red-500 p-2 rounded-full flex items-center justify-center w-12 h-12 mb-2">
              <CloseCircleOutlined className="text-white text-3xl" />
            </span>
            <div className="text-sm">Chưa trả lời</div>
            <div className="text-3xl font-bold">{statistical.notYet}</div>
          </div>
        </Radio.Button>
      </Radio.Group>

      <div className="flex justify-between mb-4">
        <h1 className='text-2xl font-semibold'>Phản hồi</h1>
        <div className="flex gap-2">
          <Input
            placeholder="Tìm theo tên"
            onChange={handleInputChange}
            allowClear
            prefix={<SearchOutlined />}
          />
          <Select defaultValue="newest" onChange={handleSortChange}>
            <Option value="newest">Mới nhất</Option>
            <Option value="oldest">Cũ nhât</Option>
          </Select>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={feedbacks}
        pagination={false}
        rowKey="Id"
      />

      <div className="mt-4 flex justify-end">
        <Pagination
          total={dataLength}
          showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} phản hồi`}
          pageSize={pageSize}
          current={currentPage}
          onChange={handlePaginationChange}
        />
      </div>
    </AdminLayout>
  );
};

export default Feedback;