import React, { useEffect, useState } from "react";
import { Table, Input, Pagination, Tag } from "antd";
import {
  EyeOutlined,
  LaptopOutlined,
  ProfileOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AdminLayout from "../../../components/Admin/AdminLayout";
import { useNavigate } from "react-router-dom";
import { fetchEntities } from "../../../api/odataClients/adminClient";
import { toast } from "react-toastify";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";

export const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dataLength, setDataLength] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [statistical, setStatistical] = useState({
    totalBlogs: 0,
    totalViews: 0,
    active: 0,
  });

  const loadStatic = async () => {
    const dataStatistical = await fetchEntities("blogs", {count: true, });
    const totalVies = dataStatistical.value.reduce(
      (sum, blog) => sum + blog.NumberOfView,
      0
    );
    const totalblogs = dataStatistical["@odata.count"];
    const active = dataStatistical.value.filter(
      (blog) => blog.Status === "Published"
    ).length;

    setStatistical({
      totalBlogs: totalblogs,
      totalViews: totalVies,
      active: active,
    });
  }

  const loadEntities = async () => {
    try {
      const data = await fetchEntities("blogs", {
        filter: searchText ? `contains(Title,'${searchText}')` : undefined,
        expand: ["BlogType", "CreatedBy"],
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ['Id desc']
      });
      setBlogs(data.value);

      setDataLength(data["@odata.count"]);
    } catch (err) {
      toast(err);
      console.error(err);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    loadStatic();
  }, []);

  useEffect(() => {
    loadEntities();
  }, [searchText, currentPage, pageSize]);

  const handleSearch = (value) => {
    setLoading(true)
    setSearchText(value);
  };

  const handlePaginationChange = (page, pageSize) => {
    setLoading(true)
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    { 
      title: "Author", dataIndex: "CreatedBy", key: "CreatedBy", width: "20%", 
      render: (createdBy) => (createdBy ? createdBy.FullName : "No Data"),
    },
    { title: "Title", dataIndex: "Title", key: "Title" },
    { 
      title: "Type", dataIndex: "BlogType", key: "BlogType", width: "15%",
      render: (blogtype) => (blogtype ? blogtype.Name : "No Data"),
    },
    { title: "Views", dataIndex: "NumberOfView", key: "NumberOfView", width: "15%", },
    { 
      title: "Status", dataIndex: "Status", key: "Status", width: "15%",
      render: (status, record) => (
        <div>
          <Tag style={{ width: "70%" }} color={status === "Pending Review" ? "blue" : status === "Published" ? "green" : "red"}>
            {status}
          </Tag>
          <EyeOutlined
            className="text-[20px] cursor-pointer"
            onClick={() => handleViewDetail(record.Id)}
          />
        </div>
      ),
    },
  ];

  const handleViewDetail = (blogId) => {
    navigate(`/admin/blogs/${blogId}`, { state: { defaultSelectedValue: 3 } });
  };
  
  return (
    <AdminLayout>
      <LoadingPage isLoading={loading} />
      <div className="grid grid-cols-3 gap-4 mb-10">
        <div className="bg-white shadow rounded-lg p-4 flex flex-col items-center">
          <ProfileOutlined className="text-3xl mb-2 text-green-500" />
          <div className="text-sm text-slate-400">Total Blogs</div>
          <div className="text-3xl font-bold">
            {statistical.totalBlogs}
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-4 flex flex-col items-center">
          <EyeOutlined className="text-3xl mb-2 text-blue-500" />
          <div className="text-sm text-slate-400">Total Views</div>
          <div className="text-3xl font-bold">
            {statistical.totalViews}
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-4 flex flex-col items-center">
          <LaptopOutlined className="text-3xl mb-2 text-green-500" />
          <div className="text-sm text-slate-400">Active Now</div>
          <div className="text-3xl font-bold">{statistical.active}</div>
        </div>
      </div>

      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-semibold">All Blogs</h1>

        <Input
          className="w-1/4 ml-96"
          placeholder="Search"
          prefix={<SearchOutlined />}
          size="large"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {/* <Select
          className="w-1/4"
          size="large"
          defaultValue="highest"
          onChange={handleSortChange}
        >
          <Option value="highest">Sort by: Highest</Option>
          <Option value="lowest">Sort by: Lowest</Option>
        </Select> */}
      </div>

      <Table dataSource={blogs} columns={columns} pagination={false} />

      {/* Pagination */}
      <div className="mt-4 flex justify-end">
        <Pagination
          total={dataLength}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePaginationChange}
        />
      </div>
    </AdminLayout>
  );
};

export default Blog;
