import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd';
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { getDistricts, getProvinces, getWards } from '../../../api/addressClients';
import {
    fetchEntities,
    updateEntity,
} from "../../../api/odataClients/supporterClient";

const { Option } = Select;


export const EditExaminationService = ({ id, show, onClose, onSuccess }) => {
    const [form] = Form.useForm();
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [isServiceInfo, setIsServiceInfo] = useState("");
    const [serviceInfo, setServiceInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [imageUpload, setImageUpload] = useState();

    const onFinish = async (values) => {
        let addr = [values.Address, values.Ward, values.District, values.Province];
        let fullAddress = addr.join(' - ');

        let formData = {
            ID: id,
            Content: values.Content,
            Address: fullAddress,
            ImageUrl: values.ImageUrl
        };
        console.log("Form Values:", formData);
        try {
            await updateEntity("schedules", id, formData);
            toast.success("Chỉnh sửa thông tin thành công!");
        } catch (error) {
            console.error("Error updating profile:", error);
            toast.error("Chỉnh sửa thông tin thất bại!");
        }
        onSuccess()
        loadEntities()
        setIsServiceInfo(false);
    };

    const loadEntities = async () => {
        try {
            const data = await fetchEntities(`schedules(${id})`);
            let address = data.Address.split(" - ");
            form.setFieldsValue({
                Content: data.Content,
                HospitalName: data.HospitalName,
                Address: address[0],
                Ward: address[1],
                District: address[2],
                Province: address[3],
                // ImageUrl: data.ImageUrl
            });
            setServiceInfo(data);

            if (address[3]) {
                getDistricts(address[3]).then((districts) => {
                    setDistricts(districts);
                    if (address[2]) {
                        getWards(address[3], address[2]).then((wards) => {
                            setWards(wards);
                        });
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching profile:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadEntities();
    }, [id]);

    const handleChangeInfoService = () => {
        if (isServiceInfo) {
            form.submit();
        } else {
            setIsServiceInfo(true);
        }
    };
    const handleSelectAddress = (name, value) => {
        if (name === "Province") {
            getDistricts(value).then((values) => setDistricts(values));
            form.setFieldsValue({
                District: null,
                Ward: null
            });
        } else if (name === "District") {
            getWards(form.getFieldValue("Province"), value).then((values) => setWards(values));
            form.setFieldsValue({
                Ward: null
            });
        }
    };

    useEffect(() => {
        getProvinces().then(
            (data) => {
                setProvinces(data);
            },
            (err) => {
                console.log(err.message);
            }
        );
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            setImageUpload(formData);
        }
    };

    if (!show) return <></>;

    return (
        <>
            <ToastContainer />
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white h-[auto] w-[700px] p-10 rounded-lg shadow-lg relative">
                    <h3 className='text-3xl text-center font-bold pb-4 text-pinkColor'>Cập nhật dịch vụ</h3>
                    <button className="absolute top-2 right-2" onClick={onClose}>
                        <FaRegTimesCircle className='w-6 h-6 m-2' />
                    </button>
                    <Form
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập tên Dịch vụ khám' }]}
                            className='mb-0 mt-3 w-full'
                            name="Content"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Dịch vụ khám"

                            />
                        </Form.Item>

                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập tên bệnh viện' }]}
                            className='mb-0 mt-3 w-full'
                            name="HospitalName"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Tên bệnh viện"
                            />
                        </Form.Item>

                        <div className='flex'>
                            <Form.Item
                                className='mb-0 mt-3 mr-3 w-full'
                                name="Province"
                                rules={[{ required: true, message: 'Hãy nhập tên Tỉnh/TP' }]}
                            >
                                <Select
                                    showSearch
                                    className="w-full border border-gray-300 rounded-lg"
                                    placeholder="Tỉnh/TP"
                                    style={{ border: 'none !important', outline: 'none !important' }}
                                    onChange={(value) => handleSelectAddress("Province", value)}

                                >
                                    {
                                        provinces.map((value, _) => {
                                            return (
                                                <Option key={value.name} value={value.name} className="">
                                                    <p>{value.name}</p>
                                                </Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Form.Item>

                            <Form.Item
                                className='mb-0 mt-3 mr-3 w-full'
                                name="District"
                                rules={[{ required: true, message: 'Hãy nhập tên Quận/Huyện' }]}
                            >
                                <Select
                                    showSearch
                                    className="w-full border border-gray-300 rounded-lg"
                                    placeholder="Quận/Huyện"
                                    style={{ border: 'none !important', outline: 'none !important' }}
                                    onChange={(value) => handleSelectAddress("District", value)}

                                >
                                    {
                                        districts.map((value, _) => {
                                            return (
                                                <Option key={value.name} value={value.name} className="">
                                                    <p>{value.name}</p>
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                className='mb-0 mt-3 mr-3 w-full'
                                name="Ward"
                                rules={[{ required: true, message: 'Hãy nhập tên Phường/Xã' }]}
                            >
                                <Select
                                    showSearch
                                    className="w-full border border-gray-300 rounded-lg"
                                    placeholder="Phường/Xã"
                                    style={{ border: 'none !important', outline: 'none !important' }}
                                    onChange={(value) => handleSelectAddress("Ward", value)}
                                >
                                    {
                                        wards.map((value, _) => {
                                            return (
                                                <Option key={value.name} value={value.name} className="">
                                                    <p>{value.name}</p>
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập địa chỉ bệnh viện' }]}
                            className='mb-0 mt-3 w-full'
                            name="Address"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Địa chỉ cụ thể: số nhà, tên đường"
                            />
                        </Form.Item>

                        <Form.Item
                            className='mb-0 mt-3 w-full'
                            rules={[{ required: true, message: 'Hãy thêm ảnh' }]}
                            name='ImageUrl'
                        >
                            <Input
                                type="file"
                                accept="image/*"
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                onChange={handleImageChange}
                            />
                        </Form.Item>


                        <div className='flex gap-4 items-center justify-center p-2 mx-4'>
                            <button onClick={onClose} className='bg-pinkColor px-14 mt-4 py-3 rounded-lg text-[18px] text-white hover:opacity-70'>Huỷ</button>
                            <button onclick={handleChangeInfoService} type="submit" className='bg-white px-14 mt-4 py-3 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>
                                Cập nhật</button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};
