import React, { useEffect, useRef, useState } from 'react'
import SupporterLayout from '../../../components/Supporter/SupporterLayout';
import { Input, Pagination, Radio, Table } from 'antd';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { createEntity, fetchEntities, updateEntity } from '../../../api/odataClients/supporterClient';
import { FaCheck, FaFileAlt, FaHourglassHalf } from 'react-icons/fa';
import { MdCancel } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import dayjs from "dayjs";
import CustomModal from '../../../components/Common/CustomModalConfirm';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_ID } from '../../../api/axiosInstance';

export const BookingManagement = () => {
  const [services, setServices] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statistical, setStatistical] = useState({
    totalBookingRequest: 0,
    waiting: 0,
    completed: 0,
    cancel: 0,
  });
  const [loading, setLoading] = useState(true);
  const [selectedBooking, setSelectedBooking] = useState()
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
  const [confirmModal, setConfirmModel] = useState({
    Status: "",
    Header: "",
    Title: "",
  })
  const [valueFilter, setValueFilter] = useState("")
  const navigate = useNavigate();

  const loadEntities = async (searchText) => {
    setLoading(true)
    let filter = searchText ? `contains(schedule/Content,'${searchText}') and ` : ""

    if (valueFilter && valueFilter.length > 0) {
      filter += `${valueFilter} and`
    }

    try {
      const data = await fetchEntities('scheduleBookings', {
        filter: filter + ` schedule/CreatedByID eq ${ACCOUNT_ID()} `,
        expand: ['schedule($select=Content)', 'Account($select=FullName,PhoneNumber,Email,DateOfBirth)'],
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ['Id desc']
      });
      setServices(data.value);
    } catch (err) {
      toast(err);
    } finally {
      setLoading(false)
    }
  };

  const loadStatic = async () => {
    let filter = searchText ? `contains(schedule/Content,'${searchText}')` : ""
    const dataStatistical = await fetchEntities('scheduleBookings',
      {
        filter: `schedule/CreatedByID eq ${ACCOUNT_ID()}` + filter,
        expand: ['schedule($select=Content)', 'Account($select=FullName,PhoneNumber,Email,DateOfBirth)'],
        count: true,
        orderBy: ['Id desc']
      },
      { select: ['Status'] });
    const waiting = dataStatistical.value.filter((item) => item.Status === 'Đang chờ').length;
    const completed = dataStatistical.value.filter((item) => item.Status === 'Đã xác nhận').length;
    const cancel = dataStatistical.value.filter((item) => item.Status === 'Đã hủy').length;
    setDataLength(dataStatistical['@odata.count'])
    setStatistical({
      waiting: waiting,
      completed: completed,
      cancel: cancel,
    });
  }

  useEffect(() => {
    loadStatic();
  }, [])

  useEffect(() => {
    setLoading(true)
    loadEntities();
  }, [searchText, currentPage, pageSize, valueFilter]);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleChangeStatusBooking = (value, recordOrder) => {
    setSelectedBooking(recordOrder);
    setConfirmModel({
      Status: value,
      Header: value === "Đã xác nhận" ? "Xác nhận lịch hẹn" : "Hủy lịch hẹn",
      Title: value === "Đã xác nhận" ? "Bạn có chắc chắn xác nhận lịch hẹn này?" : "Bạn có chắc chắn hủy lịch hẹn này?"
    });
    setOpenPopupConfirm(true);
  };

  const changeStatusBooking = async (value, recordOrder) => {
    const bookingId = recordOrder.Id
    try {
      await updateEntity('scheduleBookings', bookingId, { Id: bookingId, Status: value });
      toast.success('Cập nhập trạng thái thành công')
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Cập nhập thất bại')
    }
    loadEntities()
  }

  const handleConfirmModal = async (status) => {
    const scd = {
      Id: selectedBooking.Id,
      Content: selectedBooking.Content,
      Status: selectedBooking.Status,
      BookingAt: selectedBooking.BookingAt
    }
    try {
      changeStatusBooking(status, selectedBooking)
      await updateEntity("scheduleBookings", selectedBooking.Id, scd)
      setOpenPopupConfirm(false)
      if (scd.Status === "Đã xác nhận") {
        await createEntity("myschedules", {
          AccountID: selectedBooking.AccountID,
          Title: selectedBooking.Schedule.Content,
          DoAt: dayjs(selectedBooking.BookingAt).toISOString(),
          Status: false,
          InHour: 2,
        })
      }
      loadStatic()
      loadEntities()
    } catch (error) {
      toast.error("Lỗi xác nhận dịch vụ")
    }
  }

  const handleFilterSelection = (e) => {
    const { value } = e.target;
    setValueFilter(value)
  }

  const debounceDropDown = useRef(
    debounce((searchValue) => {
      loadEntities(searchValue)
    }, 500)
  ).current;

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    debounceDropDown(searchValue);
  };

  React.useEffect(() => {
    return () => {
      debounceDropDown.cancel();
    };
  }, [debounceDropDown]);

  const handleChangeChat = async (record) => {
    try {
      const dataAccount = await fetchEntities('groupmembers', {
        filter: `AccountId eq ${record.AccountID}`,
        select: ["GroupID"]
      });
      const accountGroupIDs = dataAccount.value.map(message => message.GroupID);

      const dataCaregiver = await fetchEntities('groupmembers', {
        filter: `AccountId eq ${ACCOUNT_ID()}`,
        select: ["GroupID"]
      });
      const caregiverGroupIDs = dataCaregiver.value.map(message => message.GroupID);

      const commonGroupID = accountGroupIDs.find(groupID => caregiverGroupIDs.includes(groupID));
      const chatBoxExists = Boolean(commonGroupID);
      navigate('/supporter/chatting', { state: { commonGroupID: commonGroupID } })
    } catch (err) {
      toast.error("Kiểm ta hộp thoại");
    }
    return null
  }

  const columns = [
    {
      title: 'Ngày hẹn', dataIndex: 'BookingAt', key: 'BookingAt',
      render: (date) => {
        const bookingAt = new Date(date);
        return dayjs(bookingAt).format("DD/MM/YYYY HH:mm")
      }
    },
    {
      title: 'Dịch vụ khám', dataIndex: 'Schedule', key: 'Schedule',
      render: (Schedule) => (Schedule.Content ? Schedule.Content : 'No Data')
    },
    {
      title: 'Người đặt', dataIndex: 'Account', key: 'Account',
      render: (Account) => (Account.FullName ? Account.FullName : 'No Data')
    },
    {
      title: 'Trạng thái', dataIndex: 'Status', key: 'Status',
      render: (status, record) => (
        status === 'Đã xác nhận' ? <p className='bg-[#DCFCE7] p-2 rounded-lg inline-block px-3 rounded-lg text-[15px] text-[#3CD856] border'>Đã xác nhận</p>
          : status === 'Đã hủy' ? <p className='w-170px h-70px bg-[#FFE2E5] p-2 rounded-lg inline-block px-3 rounded-lg text-[15px] text-[#FF0000] border'>Đã hủy</p>
            : <p className='bg-[#FFF4DE] p-2 rounded-lg inline-block px-3 rounded-lg text-[15px] text-[#FF947A] border'>Đang chờ</p>

      ),
    },
    {
      title: '',
      dataIndex: 'Status', key: 'Status',
      render: (status, record) => (
        <>
          {status === "Đang chờ" &&
            <button>
              <FaCheck onClick={() => handleChangeStatusBooking('Đã xác nhận', record)} style={{ color: '#3CD856' }} className=' w-6 h-6 m-3 text-white' />
            </button>
          }

          {(status === "Đang chờ" || status === "Đã xác nhận") &&
            <button>
              <ImCancelCircle onClick={() => handleChangeStatusBooking('Đã hủy', record)} style={{ color: '#FF0000' }} className=' w-6 h-6 m-3 text-white' />
            </button>
          }

          <button>
            <BsFillChatDotsFill onClick={() => handleChangeChat(record)} style={{ color: '#f1c40f' }} className=' w-6 h-6 m-3' />
          </button>
        </>
      )
    }
  ];
  return (
    <div>
      <SupporterLayout>
        <CustomModal isOpenModal={openPopupConfirm} handleConfirm={() => handleConfirmModal(confirmModal.Status)} handleCancel={() => { setOpenPopupConfirm(false) }}
          header={confirmModal.Header}
          textConfirm="Xác nhận"
          textCancel="Hủy"
          content={(<><h2 className="text-[14px] text-black pb-3">{confirmModal.Title}</h2>
            <h2 className="text-[14px] text-black pb-3">Tên đầy đủ: <strong>{selectedBooking?.Account.FullName}</strong></h2>
            <h2 className="text-[14px] text-black pb-3">Giới tính: <strong></strong>{selectedBooking?.Account.Sex}</h2>
            <h2 className="text-[14px] text-black pb-3">Năm sinh: <strong>{dayjs(selectedBooking?.Account.DateOfBirth).format('YYYY')}</strong></h2>
            <h2 className="text-[14px] text-black pb-3">Dịch vụ khám: <strong>{selectedBooking?.Schedule.Content}</strong></h2>
            <h2 className="text-[14px] text-black pb-3">Ngày khám: <strong>{dayjs(selectedBooking?.BookingAt).format('DD/MM/YYYY HH:mm')}</strong></h2>
          </>)}
        />
        <LoadingPage isLoading={loading} />
        <Radio.Group onChange={handleFilterSelection} className="grid grid-cols-4 gap-5 mb-10" defaultValue=''>
          <Radio.Button className='shadow rounded p-0' value="" style={{ height: "auto" }}>
            <div className='p-4 bg-[#E6F4FF] rounded-lg h-32'>
              <span className="bg-[#1677FF] p-2 rounded-full inline-block">
                <FaFileAlt className=' text-white ' />
              </span>
              <h2 className='text-xl font-medium'>{dataLength}</h2>
              <p>Tổng yêu cầu đặt lịch</p>
            </div>
          </Radio.Button>
          <Radio.Button className='shadow rounded p-0' value="contains(Status, 'Đang chờ')" style={{ height: "auto" }}>
            <div className='p-4 bg-[#FFF4DE] rounded-lg h-32'>
              <span className="bg-[#FF947A] p-2 rounded-full inline-block">
                <FaHourglassHalf className=' text-white ' />
              </span>
              <h2 className='text-xl font-medium'>{statistical.waiting}</h2>
              <p> Đang chờ </p>
            </div>
          </Radio.Button>
          <Radio.Button className='shadow rounded p-0' value="contains(Status, 'Đã xác nhận')" style={{ height: "auto" }}>
            <div className='p-4 bg-[#DCFCE7] rounded-lg h-32'>
              <span className="bg-[#3CD856] p-2 rounded-full inline-block">
                <FaCheck className=' text-white ' />
              </span>
              <h2 className='text-xl font-medium'>{statistical.completed}</h2>
              <p>Đã xác nhận</p>
            </div>
          </Radio.Button>
          <Radio.Button className='shadow rounded p-0' value="contains(Status, 'Đã hủy')" style={{ height: "auto" }}>
            <div className='p-4 bg-[#FFE2E5] rounded-lg h-32'>
              <span className="bg-red-500 p-2 rounded-full inline-block">
                <MdCancel className=' text-white ' />
              </span>
              <h2 className='text-xl font-medium'>{statistical.cancel}</h2>
              <p>Đã hủy</p>
            </div>
          </Radio.Button>
        </Radio.Group>

        <div className="flex justify-between mb-4">
          <h1 className='text-2xl font-semibold'>Quản lý đặt lịch khám</h1>
          <Input
            className="w-1/4"
            placeholder="Tìm kiếm..."
            prefix={<SearchOutlined />}
            size="large"
            onChange={handleInputChange}
          />
        </div>

        <Table
          dataSource={services}
          columns={columns}
          pagination={false}
        />

        {/* Pagination */}
        <div className="mt-4 flex justify-end">
          <Pagination
            total={dataLength}
            showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} lịch khám`}
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePaginationChange}
          />
        </div>
      </SupporterLayout>
    </div>
  )
}
