import React, { useCallback } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NotificateSignalR from '../NotificateSignalR/NotificateSignalR';
import LoadingPage from '../LoadingPage/LoadingPage';
import { toast } from 'react-toastify';

const LayoutMom = ({ children, hasHeader = true, hasFooter = true, isLoading = false, onNotificateChat, onNotificate }) => {
    const handleNotificate = useCallback(onNotificate || ((response) => {
        if (response.status === "success") {
            toast("Bạn có 1 dịch vụ đã được xác nhận");
        }
    }), []);

    const handleNotificateChat = useCallback(onNotificateChat || ((response) => {
        if (response.status === "success") {
            toast(<a href='/mom/chat'>Bạn có 1 tin nhắn mới</a>);
        }
    }), []);

    return (
        <>
            <NotificateSignalR onNotificate={handleNotificate} onNotificateChat={handleNotificateChat}>
                <LoadingPage isLoading={isLoading} />
                {hasHeader && <Header />}
                {children}
                {hasFooter && <Footer />}
            </NotificateSignalR>
        </>
    );
};

export default LayoutMom;

