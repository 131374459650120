import React, { useState, useEffect } from 'react';
import { fetchEntities } from '../../../api/odataClients/caregiverClient';
import CaregiverLayout from '../../../components/Caregiver/CaregiverLayout';
import { ContractView } from '../../../components/LayoutCommon/ContractView';
import { ACCOUNT_ID } from '../../../api/axiosInstance';
export const CaregiverContract = () => {
    const [account, setAccount] = useState()
    const [contract, setContract] = useState()
    const loadData = async () => {
        try {
            const respAccount = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["FullName", "Sex", "Email", "DateOfBirth", "PhoneNumber", "NumberCard"] });
            setAccount(respAccount)
            const respContract = await fetchEntities(`contracts`, { filter: `AccountId eq ${ACCOUNT_ID()}` });
            setContract(respContract.value[0])
        } catch (error) {

        }
    }

    useEffect(() => {
        loadData()
    }, [])

    return (
        <CaregiverLayout>
            <ContractView account={account} contract={contract}/>
        </CaregiverLayout>
    );
}