const weeksData = [
  {
    week: 1,
    image:  require("../image/40weekspregnant/1-3-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week1-2.png"),
    information: "Bạn chưa thực sự có thai.",
    description: {
     "height": "Chưa rõ ràng",
      "weight": "Chưa rõ ràng",
    },
    describe: null
  },
  {
    week: 2,
    image: require("../image/40weekspregnant/1-3-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week1-2.png"),
    information: "Sự rụng trứng sắp xảy ra.",
    description: {
      "height": "Chưa rõ ràng",
      "weight": "Chưa rõ ràng",
    },
    describe: null
  },
  {
    week: 3,
    image: require("../image/40weekspregnant/1-3-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week3.png"),
    information: "Bón phân.",
    description: {
      "height": "Chưa rõ ràng",
      "weight": "Chưa rõ ràng",
    },
    describe: null
  },
  {
    week: 4,
    image: require("../image/40weekspregnant/4-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week4.png"),
    information: "Cấy phôi.",
    description: {
      "height": "Chưa rõ ràng",
      "weight": "Chưa rõ ràng",
    },
    describe: null
  },
  {
    week: 5,
    image: require("../image/40weekspregnant/5-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week5.png"),
    information: "Những phát triển chính trong tuần này bao gồm mũi, miệng và tai. Đầu quá khổ của em bé và những đốm đen nơi mắt và lỗ mũi đang bắt đầu hình thành.",
    description: {
      "height": "0.1 cm",
      "weight": "Chưa rõ ràng",
    },
      describe: "Hạt mè"
  },
  {
    week: 6,
    image: require("../image/40weekspregnant/6-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week6.png"),
    information: "Vào tuần thứ 6 của thai kỳ, mắt, mũi, miệng và tai của bé bắt đầu hình thành cùng với đó là trái tim nhỏ bé đập nhanh gần gấp đôi tim mẹ.",
    description: {
      "height": "0.2 cm",
      "weight": "Chưa rõ ràng",
    },
      describe: "Hạt táo"
  },
  {
    week: 7,
    image: require("../image/40weekspregnant/7-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week7.png"),
    information: "Phôi đang thích nghi với tử cung.",
    description: {
      "height": "1.3 cm",
      "weight": "Chưa rõ ràng",
    },
      describe: "Hạt đậu"
  },
  {
    week: 8,
    image: require("../image/40weekspregnant/8-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week8.png"),
    information: "Khám thai lần thứ hai.",
    description: {
      "height": "1.6 cm",
      "weight": "Chưa rõ ràng",
    },
      describe: "Việt quất"
  },
  {
    week: 9,
    image: require("../image/40weekspregnant/9-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week9.png"),
    information: "Bé bắt đầu cơ quan sinh sản.",
    description: {
      "height": "2.3 cm",
      "weight": "Chưa rõ ràng",
    },
      describe: "Mâm xôi"
  },
  {
    week: 10,
    image: require("../image/40weekspregnant/10-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week10.png"),
    information: "Lần khám thứ ba.",
    description: {
      "height": "3.1 cm",
      "weight": "Chưa rõ ràng",
    },
      describe: "Anh đào"
  },
  {
    week: 11,
    image: require("../image/40weekspregnant/11-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week11.png"),
    information: "Bé vẫn còn rất nhỏ.",
    description: {
     "height": "4.1 cm",
      "weight": "0.007 kg",
    },
      describe: "Quả dâu"
  },
  {
    week: 12,
    image: require("../image/40weekspregnant/12-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week12.png"),
    information: "Kết thúc tam cá nguyệt đầu tiên.",
    description: {
      "height": "5.4 cm",
      "weight": "0.014 kg",
    },
      describe: "Chanh xanh"
  },
  {
    week: 13,
    image: require("../image/40weekspregnant/13-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week13.png"),
    information: "Bước vào tam nguyệt thứ hai.",
    description: {
      "height": "7.4 cm",
      "weight": "0.028 kg",
    },
      describe: "Mận"
  },
  {
    week: 14,
    image: require("../image/40weekspregnant/14-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week14.png"),
    information: "Em bé đang phát triển hệ thống sinh sản.",
    description: {
      "height": "8.7 cm",
      "weight": "0.057 kg",
    },
      describe: "Chanh vàng"
  },
  {
    week: 15,
    image: require("../image/40weekspregnant/15-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week15.png"),
    information: "Em bé đang lớn nhanh.",
    description: {
      "height": "10.1 cm",
      "weight": "0.071 kg",
    },
      describe: "Quả đào"
  },
  {
    week: 16,
    image: require("../image/40weekspregnant/16-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week16.png"),
    information: "Cú đá đầu tiên của em bé.",
    description: {
      "height": "11.6 cm",
      "weight": "0.1 kg",
    },
      describe: "Quả cam"
  },
  {
    week: 17,
    image: require("../image/40weekspregnant/17-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week17.png"),
    information: "Nhau thai đang phát triển mạnh mẽ.",
    description: {
      "height": "13 cm",
      "weight": "0.14 kg",
    },
      describe: "Quả bơ"
  },
  {
    week: 18,
    image: require("../image/40weekspregnant/18-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week18.png"),
    information: "Đôi tai đang bắt đầu hình thành.",
    description: {
      "height": "14.2 cm",
      "weight": "0.14-0.18 kg",
    },
      describe: "Quả lựu"
  },
  {
    week: 19,
    image: require("../image/40weekspregnant/19-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week19.png"),
    information: "Thận bắt đầu sản xuất nước tiểu.",
    description: {
     "height": "15.3 cm",
      "weight": "0.23 kg",
    },
      describe: "Atiso"
  },
  {
    week: 20,
    image: require("../image/40weekspregnant/20-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week20.png"),
    information: "Bé tống phân ra ngoài.",
    description: {
     "height": "25.6 cm",
      "weight": "0.28 kg",
    },
      describe: "Quả Xoài"
  },
  {
    week: 21,
    image: require("../image/40weekspregnant/21-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week21.png"),
    information: "Em bé di chuyển đáng kể.",
    description: {
     "height": "26.7 cm",
      "weight": "0.31-0.35 kg",
    },
      describe: "Chuối"
  },
  {
    week: 22,
    image: require("../image/40weekspregnant/22-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week22.png"),
    information: "Bé phản ứng với ánh sáng.",
    description: {
      "height": "27.8 cm",
      "weight": "0.45 kg",
    },
      describe: "Rau diếp xoăn"
  },
  {
    week: 23,
    image: require("../image/40weekspregnant/23-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week23.png"),
    information: "Bé có thể nghe được âm thanh từ bên ngoài.",
    description: {
     "height": "28.9 cm",
      "weight": "0.54 kg",  
    },
      describe: "Trái dừa"
  },
  {
    week: 24,
    image: require("../image/40weekspregnant/24-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week24.png"),
    information: "Xét nghiệm tiểu đường thai kỳ.",
    description: {
      "height": "30 cm",
      "weight": "0.59 kg",
    },
      describe: "Quả bưởi"
  },
  {
    week: 25,
    image: require("../image/40weekspregnant/25-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week25.png"),
    information: "Em bé đang lớn nhanh.",
    description: {
     "height": "34.6 cm",
      "weight": "0.68 kg",
    },
      describe: "Dưa nhỏ"
  },
  {
    week: 26,
    image: require("../image/40weekspregnant/26-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week26.png"),
    information: "Bé tích mỡ và xây dựng cơ bắp.",
    description: {
      "height": "35.6 cm",
      "weight": "0.77 kg",
    },
      describe: "Súp lơ"
  },
  {
    week: 27,
    image: require("../image/40weekspregnant/27-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week27.png"),
    information: "Bước vào tam cá nguyệt thứ ba.",
    description: {
      "height": "36.6 cm",
      "weight": "0.86 kg",
    },
      describe: "Cải xoăn"
  },
  {
    week: 28,
    image: require("../image/40weekspregnant/28-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week28.png"),
    information: "Xác định vị trí của bé.",
    description: {
      "height": "37.6 cm",
      "weight": "1 kg",
    },
      describe: "Bắp cải Napa"
  },
  {
    week: 29,
    image: require("../image/40weekspregnant/29-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week29.png"),
    information: "Trí não của bé đang phát triển nhanh chóng.",
    description: {
     "height": "38.6 cm",
      "weight": "1.13 kg",
    },
      describe: "Cà tím"
  },
  {
    week: 30,
    image: require("../image/40weekspregnant/30-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week30.png"),
    information: "Em bé to như quả dưa hấu nhỏ.",
    description: {
     "height": "39.9 cm",
      "weight": "1.3 kg",
    },
      describe: "Bí xanh"
  },
  {
    week: 31,
    image: require("../image/40weekspregnant/31-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week31.png"),
    information: "Em bé đang đi tiểu.",
    description: {
      "height": "41.1 cm",
      "weight": "1.5 kg",
    },
      describe: "Bí đỏ"
  },
  {
    week: 32,
    image: require("../image/40weekspregnant/32-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week32.png"),
    information: "Em bé gần như đã phát triển hoàn thiện.",
    description: {
      "height": "42.4 cm",
      "weight": "1.72 kg",
    },
      describe: "Măng tây"
  },
  {
    week: 33,
    image: require("../image/40weekspregnant/33-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week33.png"),
    information: "Bé ngủ rất nhiều.",
    description: {
     "height": "43.7 cm",
      "weight": "1.9 kg",
    },
      describe: "Dưa mùa đông"
  },
  {
    week: 34,
    image: require("../image/40weekspregnant/34-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week34.png"),
    information: "Em bé đã sẵn sàng cho tư thế chào đời.",
    description: {
      "height": "45 cm",
      "weight": "2.13 kg",
    },
      describe: "Cần tây"
  },
  {
    week: 35,
    image: require("../image/40weekspregnant/35-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week35.png"),
    information: "Bé ngày càng mập hơn.",
    description: {
      "height": "46.2 cm",
      "weight": "2.4 kg",
    },
      describe: "Bí Kabocha"
  },
  {
    week: 36,
    image: require("../image/40weekspregnant/36-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week36.png"),
    information: "Em bé đang tiến dần về phía tử cung.",
    description: {
      "height": "47.4 cm",
      "weight": "2.63 kg",
    },
      describe: "Dứa lớn"
  },
  {
    week: 37,
    image: require("../image/40weekspregnant/37-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week37.png"),
    information: "Bé vẫn tăng cân.",
    description: {
      "height": "48.6 cm",
      "weight": "2.85 kg",
    },
      describe: "Đu đủ lớn"
  },
  {
    week: 38, 
    image: require("../image/40weekspregnant/38-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week38.png"),
    information: "Sự tăng trưởng của em bé chậm lại.",
    description: {
      "height": "49.8 cm",
      "weight": "3.08 kg",
    },
      describe: "Xà lách Romaine"
  }, 
  {
      week: 39, 
      image: require("../image/40weekspregnant/39-weeks.png"),
      imageSlide:  require("../image/weightofpregnant/Week39.png"),
      information: "Không có thay đổi đáng kể.",
      description: {
        "height": "50.7 cm",
      "weight": "3.3 kg",
      },
      describe: "Dưa mùa đông trưởng thành"
  }, 
  {
      week: 40, 
      image: require("../image/40weekspregnant/40-weeks.png"),
      imageSlide:  require("../image/weightofpregnant/Week40.png"),
      information: "Em bé đã sẵn sàng chào đời.",
      description: {
       "height": "51.2 cm",
      "weight": "3.44 kg",
      },
      describe: "Bí ngô lớn"
  }, 
  {
      week: 41, 
      image: require("../image/40weekspregnant/41-weeks.png"),
      imageSlide:  require("../image/weightofpregnant/Week41.png"),
      information: "Em bé đã sẵn sàng chào đời",
      description: {
        "height": "52.4 cm",
      "weight": "3.64 kg",
      },
      describe: "Bí ngô lớn"
    }

]
  
export default weeksData;
