import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createEntity, fetchEntities, updateEntity } from "../../api/odataClients/momClient";
import { ACCOUNT_ID, API_URL_PORT } from "../../api/axiosInstance";
import dayjs from "dayjs";
import axios from "axios";
import { Link } from "react-router-dom";
import { Tag, Tooltip } from "antd";
import LoadingPage from "../LoadingPage/LoadingPage";
import CustomModal from "../Common/CustomModalConfirm";
import PaymentService from "./PaymentService";
import { FaClock } from "react-icons/fa";

export const CareServiceBooked = () => {
    const [services, setServices] = useState();
    const [serviceBooked, setServiceBooked] = useState();
    const [serviceImages, setServiceImages] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
    const [serviceBookingSelected, setServiceBookingSelected] = useState()
    const [showPopupPayment, setShowPopupPayment] = useState(false)

    const fetchdata = async () => {
        try {
            const rspServiceBooked = await fetchEntities('careservicebookings', {
                filter: "AccountID eq " + ACCOUNT_ID(),
                expand: ['CareService'],
                orderBy: ['Id desc']
            });

            setServiceBooked(rspServiceBooked.value);
            let servicesArray = {}
            if (rspServiceBooked && rspServiceBooked.value.length > 0) {
                for (let element of rspServiceBooked.value) {
                    servicesArray = { ...servicesArray, [element.CareServiceID]: element.CareService }
                }
            }
            setServices(servicesArray);
        } catch (err) {
            console.log(err)
            toast.error("Lỗi hệ thống")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        hanldeLoadingImages()
    }, [services])

    const checkDateOver24h = (date) => {
        const now = dayjs();
        const givenDate = dayjs(date);

        return givenDate.diff(now, 'hour') > 24;
    };

    const hanldeLoadingImages = async () => {
        if (serviceBooked && serviceBooked.length > 0) {
            for (let v of serviceBooked) {
                const element = v.CareService.ImageUrl.split(" - ")[0].trim()
                const url = process.env.NODE_ENV === 'development' ?
                    `https://momchild.life/api/s3/getimage?url=${element}`
                    : `${API_URL_PORT}/api/s3/getimage?url=${element}`
                const respGetImage = await axios.get(url);
                setServiceImages((prev) => ({ ...prev, [element]: respGetImage.data.data.imageUrl }))
            }
        }
    }

    const handleCancelService = (service) => {
        setServiceBookingSelected(service)
        setOpenPopupConfirm(true)
    }

    const handlePaymentService = (service) => {
        setServiceBookingSelected(service)
        setShowPopupPayment(true)
    }

    const handleConfirmModal = async () => {
        setIsLoading(true)
        const careserviceTemp = serviceBookingSelected.CareService
        try {
            if (serviceBookingSelected.Status === "Xác nhận") {
                const rspWallet = await fetchEntities("wallets", { filter: `AccountID eq ${careserviceTemp.CreatedByID}`, select: ["Id", "Money"] })
                const walletCaregiver = rspWallet.value[0]
                const price = Math.ceil((careserviceTemp.Price / 1000) * 0.1)
                await updateEntity("wallets", walletCaregiver.Id, { Id: walletCaregiver.Id, Money: walletCaregiver.Money + price })
            }
            await updateEntity('careservicebookings', serviceBookingSelected.Id, { Id: serviceBookingSelected.Id, Status: "Đã huỷ" });
            await createEntity('notifications', {
                AccountID: serviceBookingSelected.CareService.CreatedByID,
                Content: `Dịch vụ ${serviceBookingSelected.CareService.Title} đã được huỷ`,
                CreatedAt: dayjs().toISOString(),
                IsRead: false
            });
            toast.success("Huỷ dịch vụ thành công")
            setOpenPopupConfirm(false)
            fetchdata()
        } catch (err) {
            toast.error("Lỗi khi huỷ dịch vụ")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        fetchdata()
    }, [])

    return (<>
        <div className="space-y-4 pl-10 pr-10 mr-10 ml-10 min-h-[90vh]">
            <LoadingPage isLoading={isLoading} />
            <CustomModal isOpenModal={openPopupConfirm} handleConfirm={handleConfirmModal} handleCancel={() => { setOpenPopupConfirm(false) }}
                textConfirm="Chấp nhận"
                header="Xác nhận dịch vụ"
                textCancel='Huỷ'
                content={(<><h2 className="text-[14px] text-black pb-3">Bạn có chắc chắn muốn huỷ dịch vụ đã đăng ký không?</h2></>)}
            />
            <PaymentService show={showPopupPayment} onClose={() => setShowPopupPayment(false)} careServiceBooking={serviceBookingSelected} onSuccess={fetchdata} />
            {serviceBooked && serviceBooked.length > 0 ? <>
                {serviceBooked?.map(serviceBooking => (
                    <>
                        <div className="flex justify-center mx-auto">
                            <div key={serviceBooking?.Id} className="flex flex-row w-full max-w-[80%] border border-slate-300 rounded-lg overflow-hidden">
                                {/* Image Section */}
                                <div className="w-[20%] p-4">
                                    <img
                                        src={serviceImages && serviceImages[serviceBooking?.CareService.ImageUrl.split(" - ")[0].trim()]}
                                        alt="Ảnh dịch vụ"
                                        className="w-full h-[130px] rounded-xl"
                                    />
                                </div>

                                {/* Content Section */}
                                <div className="flex justify-between items-center px-4 w-[80%]">
                                    <div className="w-[60%]">
                                        <h3 className="text-lg font-semibold">
                                            <Link className="hover:text-slate-700 " to={`/mom/service/${serviceBooking?.CareServiceID}`}>{serviceBooking?.CareService.Title}</Link>
                                        </h3>
                                        <p className="text-blue-600 font-semibold">Giá tổng dịch vụ: {serviceBooking?.Price} VND</p>
                                        <div className="flex items-center space-x-1">
                                            <FaClock />
                                            <span>{dayjs(serviceBooking?.BookingAt).format("DD/MM/YYYY HH:mm")}</span>
                                            <Tag color="red" className="text-gray-500 text-sm">
                                                {serviceBooking?.InHour} tiếng
                                            </Tag>
                                        </div>
                                        <p>Địa chỉ hẹn: {serviceBooking?.Address}</p>
                                    </div>

                                    {/* Buttons */}
                                    <div className="flex items-center space-x-2 mt-2 w-[20%]">
                                        <Tag className={`flex items-center px-2 h-[30%] font-medium 
                                            ${serviceBooking?.Status === 'Đã huỷ' ? "bg-red-500 border border-red-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Hoàn thành' ? "bg-green-500 border border-green-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Đang chờ' ? "bg-slate-500 border border-slate-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Xác nhận' ? "bg-yellow-500 border border-yellow-500 text-white" : ''}
                                        `}>
                                            {serviceBooking?.Status}
                                        </Tag>
                                    </div>
                                </div>

                                <div className="w-[30%] flex flex-col items-center space-y-2 p-4 ">
                                    {serviceBooking?.Status === "Đã huỷ" &&
                                        <div className="mt-1 text-center">
                                            <p className="w-full text-center"><strong>Lý do từ chối</strong></p>
                                            {serviceBooking?.ReasonCancel}
                                        </div>
                                    }
                                    {(serviceBooking?.Status === "Đang chờ" ||
                                        serviceBooking?.Status === "Xác nhận") &&
                                        (checkDateOver24h(serviceBooking?.BookingAt) ?
                                            <button
                                                type="button"
                                                className="bg-pinkColor p-2  w-[150px] px-5 border-none text-white rounded-lg"
                                                onClick={() => handleCancelService(serviceBooking)}
                                            >
                                                Huỷ Dịch vụ
                                            </button>
                                            :
                                            <Tooltip title="Trước 24h so với giờ đặt lịch sẽ không được huỷ dịch vụ">
                                                <button
                                                    type="button"
                                                    className="bg-pinkColor p-2 opacity-50 pointer-events-none w-[150px] px-5 border-none text-white rounded-lg"
                                                >
                                                    Huỷ Dịch vụ
                                                </button>
                                            </Tooltip>
                                        )}
                                    {serviceBooking?.Status === "Xác nhận" && (
                                        <button
                                            type="button"
                                            className="bg-blueColor p-2  w-[150px] px-5 border-none text-white rounded-lg"
                                            onClick={() => { handlePaymentService(serviceBooking) }}
                                        >
                                            Thanh toán qua hệ thống
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </> : <>
                <h3 className='text-center'> no Data</h3>
            </>
            }
        </div>
    </>)
}