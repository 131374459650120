import React, { useEffect, useRef, useState } from 'react'
import { Pagination, Input, Button, Form, Radio } from 'antd'
import { toast } from 'react-toastify'
import { fetchEntities } from '../../api/odataClients/momClient'
import axios from 'axios'
import { API_URL_PORT, TOKEN_API } from '../../api/axiosInstance'
import LayoutMom from '../../components/LayoutCommon/LayoutMom'
import { SearchOutlined } from '@ant-design/icons';
import { AddNewBookingSchedule } from '../../components/BookingSchedule/AddNewBookingSchedule';
import debounce from 'lodash.debounce';

export const ListExamination = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [dataLength, setDataLength] = useState(0);
    const [schedule, setSchedule] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [valueFilter, setValueFilter] = useState("");
    const [serviceImages, setServiceImages] = useState();
    const [showModalAddNew, setShowModalAddNew] = useState(false);
    const [examination, setExamination] = useState();

    const loadEntities = async (searchText) => {
        let filter = searchText ? `contains(Content,'${searchText}')` : ""
        if (valueFilter && valueFilter.length > 0)
            filter += searchText ? ` and ${valueFilter}` : valueFilter
        try {
            const data = await fetchEntities('schedules', {
                filter: filter.length > 0 ? filter + "and IsActive eq true" : " IsActive eq true",
                expand: ["CreatedBy($select=FullName)"],
                skip: (currentPage - 1) * pageSize,
                top: pageSize,
                orderBy: ['Id desc']
            });
            setSchedule(data.value);
        } catch (err) {
            toast(err);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        setIsLoading(true)
        loadEntities()
    }, [])

    useEffect(() => {
        hanldeLoadingImages()
    }, [schedule])

    const hanldeLoadingImages = async () => {
        if (schedule && schedule.length > 0) {
            for (let i = 0; i < schedule.length; i++) {
                const element = schedule[i];
                const url = process.env.NODE_ENV === 'development' ? `https://momchild.life/api/s3/getimage?url=${element.ImageUrl}` : `${API_URL_PORT}/api/s3/getimage?url=${element.ImageUrl}`
                const respGetImage = await axios.get(url);
                setServiceImages((prev) => ({ ...prev, [element.ImageUrl]: respGetImage.data.data.imageUrl }))
            }
        }
    }

    const handleCreateNewBookingSchedule = () => {
        setShowModalAddNew(false);
        setIsLoading(true)
        loadEntities();
    }

    const searchDebouce = useRef(
        debounce((searchValue) => {
            loadEntities(searchValue);
        }, 500)
    ).current;

    const handleSearch = (value) => {
        searchDebouce(value);
    };

    useEffect(() => {
        return () => {
            searchDebouce.cancel();
        };
    }, [searchDebouce]);

    const handleChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };


    return (
        <LayoutMom
            isLoading={isLoading}
        >
            {TOKEN_API &&
                <AddNewBookingSchedule examination={examination} show={showModalAddNew} onClose={() => setShowModalAddNew(false)} onSuccess={handleCreateNewBookingSchedule} />
            }
            <div className='container mx-auto mb-36'>
                <div className='bg-primaryColor text-center px-20 py-5 h-[230px] space-y-3'>
                    <h1 className='text-[30px] sm:text-[40px] font-bold text-pinkColor'>Đặt lịch khám</h1>
                    <p className='text-base text-[#454545] sm:text-lg hidden sm:block'>MCCare cho phép người dùng có thể đặt lịch khám tại các bệnh viện trên thành phố Đà Nẵng.</p>
                    <div>

                        <Input
                            className="text-center"
                            style={{ width: "70%" }}
                            placeholder="Tìm theo tên dịch vụ khám"
                            prefix={<SearchOutlined />}
                            size="large"
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </div>

                <div className='p-6 grid md:grid-cols-2 grid-cols-1 gap-6' style={{ textAlign: "center" }}>
                    {schedule && schedule.length > 0 ? <>
                        {schedule?.map(service => (
                            <div key={service.Id} className="p-6 flex flexborder border-gray-300 rounded-lg shadow-2xl">
                                {/* Image Section */}
                                <div className="w-1/3">
                                    <img
                                        src={serviceImages && serviceImages[service.ImageUrl]}
                                        alt="Ảnh dịch vụ"
                                        className="h-[200px] w-[200px] object-cover rounded-full"
                                    />
                                </div>
                                {/* Content Section */}
                                <div className="p-2 w-2/3">
                                    <h1 style={{ textAlign: "center" }} className="text-lg font-semibold text-pinkColor">{service.Content}</h1>
                                    <p className="p-2 text-blue-600 font-semibold">{service.HospitalName}</p>
                                    <p className="p-2 text-pinkColor-600 font-semibold">{service.Address}</p>

                                    {/* Buttons */}
                                    {TOKEN_API &&
                                        <div className="text-center">
                                            <button
                                                type="button"
                                                className="bg-pinkColor px-10 border-none text-white rounded-lg py-2"
                                                onClick={() => {
                                                    setExamination(service);
                                                    setShowModalAddNew(true);
                                                }}
                                            >
                                                Đặt ngay
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </> : <>
                        <h3 className='text-center'> no Data</h3>
                    </>}
                </div>


            </div>
            <Pagination
                total={dataLength}
                pageSize={pageSize}
                current={currentPage}
                onChange={handleChangePage}
                className="flex justify-center mt-6"
            />
        </LayoutMom>
    )
}
