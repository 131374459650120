import React, { useState } from "react";
import { Input, Row, Col, Tabs, Dropdown } from "antd";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import { SafeHtml } from "./SafeHTMLText";
import ChatBot from "../../assets/image/logo/chatbot.svg";
import CustomModal from "../Common/CustomModalConfirm";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL_PORT, TOKEN_API } from "../../api/axiosInstance";

const ChattingList = ({ onReloadData, messagesData, searchText, setSearchText, onConversationClick, activeKey, setActiveKey, account }) => {
  const [groupDelete, setGroupDelete] = useState();
  const [groupName, setGroupname] = useState();
  const [confirm, setConfirm] = useState(false);
  const items = ["Tất cả", "Chưa trả lời"];

  const handleReplaceName = (nameGroup) => {
    let filnalName = nameGroup?.replaceAll(", ", "")
    filnalName = filnalName?.replaceAll(account.FullName, "")
    return filnalName
  }

  const toggleDelete = (groupId, Name) => {
    setGroupname(Name)
    setGroupDelete(groupId)
    setConfirm(true)
  }

  const handleConfirmModal = async () => {
    try {
      // const urlSendDelete = process.env.NODE_ENV === 'development' ?
      //   `https://momchild.life/api/messages/deletemessagechat?groupId=${groupDelete}` :
      //   `${API_URL_PORT}/api/messages/deletemessagechat?groupId=${groupDelete}`
      // const rspDeleteGroupChat = await axios.delete(urlSendDelete, {
      //   headers: {
      //     Authorization: `Bearer ${TOKEN_API}`,
      //   }
      // })

      // if (rspDeleteGroupChat.data.status === "error") {
      //   toast.error(rspDeleteGroupChat.data.message)
      // }
    } catch (err) {
      toast.error("Lỗi hệ thống, vui lòng thử lại sau")
    } finally {
      onReloadData()
      setConfirm(false)
    }
  }

  const IsMom = () => {
    return !!localStorage.getItem("authMomToken");
  }

  return (
    <Col flex={1} className="border shadow-md rounded-tl-lg rounded-bl-lg lg:w-[350px]">
      <CustomModal isOpenModal={confirm} handleConfirm={() => handleConfirmModal()} handleCancel={() => { setConfirm(false) }}
        header="Xoá tin nhắn"
        textConfirm="Xác nhận"
        textCancel="Hủy"
        content={(<>
          <h2 className="text-[14px] text-black pb-3">Bạn muốn xoá tin nhắn với <strong>{groupName}</strong> chứ?</h2>
        </>)}
      />
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="font-bold text-lg">Tin nhắn của bạn</h2>
          {
            IsMom()
            &&
            <button
              className="text-[#7E7E7E] hover:text-black"
              onClick={() => onConversationClick("chatbot")}
            >
              <img src={ChatBot} alt="ChatBot" className="w-10 h-10" />
            </button>
          }
        </div>
        <div className="flex">
          <Input
            className="rounded-3xl bg-[#EAEAEA] relative pl-8 py-2 hover:bg-[#EAEAEA] border-none"
            placeholder="Tìm kiếm tin nhắn"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <SearchOutlined className="absolute mt-3 ml-3 text-[16px] text-[#7E7E7E]" />
        </div>
      </div>
      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        items={items.map((item, i) => ({
          label: <span style={{
            fontWeight: activeKey === `${i}` ? "bold" : "normal", color: "black",
            paddingLeft: i === 0 ? "18px" : "0",
          }}>{item}</span>,
          key: `${i}`,
          children: (
            i === 0 ? (
              <div className="overflow-y-auto overflow-x-hidden h-[400px]">
                {messagesData.map(message => {
                  const notOwnMessage = message.CreatedBy?.Id !== account.Id
                  return <>
                    <div
                      key={message.GroupID}
                      className="flex border-tb border p-2 cursor-pointer hover:bg-[#EAEAEA]"
                    >
                      <Row
                        className="ml-4 mr-4 w-full flex"
                        onClick={() => onConversationClick(message.GroupID)}>
                        <Col flex={4}>
                          <h2 className="font-bold pb-2">{handleReplaceName(message?.Group?.Name)}</h2>
                          <p className={`${notOwnMessage && !message.IsRead ? "font-medium" : "text-[#7E7E7E]"} overflow-hidden text-ellipsis whitespace-nowrap w-[100%] max-w-[300px]`}>
                            {notOwnMessage ?
                              <>{message.IsMessageSys ? <SafeHtml content={`${message?.ImageUrl ? 'Bạn nhận được 1 ảnh' : `${message?.Content}`}`} isListChat={true} /> : `${message?.ImageUrl ? 'Bạn nhận được 1 ảnh' : `${message?.Content}`}`}</>
                              :
                              <>{message.IsMessageSys ? <SafeHtml content={`${message?.ImageUrl ? 'Bạn đã gửi 1 ảnh' : `Bạn: ${message?.Content}`}`} isListChat={true} /> : `${message?.ImageUrl ? 'Bạn đã gửi 1 ảnh' : `Bạn: ${message?.Content}`}`}</>}
                          </p>
                        </Col>
                        {notOwnMessage && !message.IsRead && <Col className="w-3 h-3 bg-pinkColor rounded-3xl mt-6 mr-6"></Col>}
                      </Row>
                      <DeleteFilled className="text-[red]" onClick={() => toggleDelete(message.GroupID, handleReplaceName(message?.Group?.Name))} />
                    </div>
                  </>
                }
                )}
              </div>
            )
              : (
                <div className="overflow-y-auto overflow-x-hidden h-[400px]">
                  {messagesData.map(message => {
                    message.CreatedBy?.Id !== account.Id && !message.IsRead &&
                    (
                      <div key={message.GroupID} className="border-tb border p-2 cursor-pointer hover:bg-[#EAEAEA]"
                        onClick={() => onConversationClick(message.GroupID)}
                      >
                        <Row className="ml-4 mr-4 w-full flex">
                          <Col flex={4} className="">
                            <h2 className="font-bold pb-2">{handleReplaceName(message.Group?.Name)}</h2>
                            <p className="font-medium overflow-hidden text-ellipsis whitespace-nowrap w-[100%] max-w-[300px]">
                              {message.IsMessageSys ?
                                <SafeHtml content={`${message?.ImageUrl ? 'Bạn đã nhận được 1 ảnh' : `${message?.Content}`}`} isListChat={true} /> :
                                `${message?.ImageUrl ? 'Bạn đã gửi 1 ảnh' : `${message?.Content}`}`}
                            </p>
                          </Col>
                          <Col className="w-3 h-3 bg-pinkColor rounded-3xl mt-6 mr-6"></Col>
                        </Row>
                      </div>
                    )
                  }
                  )}
                </div>
              )
          ),
        }))}
      />
    </Col>
  );
};

export default ChattingList;
