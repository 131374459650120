import React, { useEffect, useState } from 'react'
import CaregiverLayout from '../../../components/Caregiver/CaregiverLayout'
import { FaCheck, FaEye, FaFileAlt, FaHourglassHalf, FaPoll, FaRegListAlt } from 'react-icons/fa'
import { Tag } from 'antd';
import { BarChart } from '../../../components/Chart/BarChart';
import { fetchEntities } from '../../../api/odataClients/caregiverClient';
import { ContractSign } from '../../../components/Caregiver/SigningContract';
import { ACCOUNT_ID } from '../../../api/axiosInstance';

export const CaregiverDashboard = () => {
  const [careServices, setCareServices] = useState([])
  const [careServiceBookings, setCareServicebookings] = useState([])
  const [wallet, setWallet] = useState({})
  const [walletRequest, setWalletRequest] = useState([])
  const [topServiceBooking, setTopServiceBooking] = useState([])

  const [bookCompleted, setBookCompleted] = useState(0)
  const [bookWaiting, setBookWaiting] = useState(0)
  const [requestWithdraw, setRequestWithdraw] = useState(0)
  const [requestWaiting, setRequestWaiting] = useState(0)
  const [hasWallet, setHasWallet] = useState(false)
  const [isSignContract, setIsSignContract] = useState(true)
  const loadEntities = async () => {
    const respCareService = await fetchEntities("careservices", {
      filter: `CreatedByID eq ${ACCOUNT_ID()}`
    });
    setCareServices(respCareService.value);

    if (respCareService.value.length > 0) {
      let filterSearchBooking = respCareService.value.map((careservice) => careservice.Id);
      
      const respCareServiceBooking = await fetchEntities("careservicebookings", {
        filter: `CareServiceID in (${filterSearchBooking})`
      });
      setCareServicebookings(respCareServiceBooking.value)
    }

    const respWallet = await fetchEntities("wallets", {
      filter: `AccountId eq ${ACCOUNT_ID()}`
    });
    if (respWallet.value.length > 0) {
      setWallet(respWallet.value[0])
      setHasWallet(true)

      const respWalletRequest = await fetchEntities("requestwallets", {
        filter: `WalletID eq ${respWallet.value[0].Id}`
      });

      setWalletRequest(respWalletRequest.value)
    }

  }

  const getNumberStatus = (status) => {
    const length = careServiceBookings.filter((value) => value.Status.trim() === status).length
    return length
  }

  const top5Service = () => {
    const serviceBookingsCount = [];

    for (let i = 0; i < careServiceBookings.length; i++) {
      const booking = careServiceBookings[i];
      const careService = careServices.find((value) => value.Id === booking.CareServiceID);

      if (careService) {
        serviceBookingsCount[careService.Id] = (serviceBookingsCount[careService.Id] || 0) + 1;
      }
    }

    const servicesDataArray = Object.keys(serviceBookingsCount).map(serviceId => {
      const careService = careServices.find((service) => service.Id === Number(serviceId));
      return {
        id: careService.Id,
        name: careService.Title,
        orders: serviceBookingsCount[serviceId],
      };
    });

    servicesDataArray.sort((a, b) => b.orders - a.orders);

    const top5Services = servicesDataArray?.slice(0, 5);

    const colorPalette = ['#0095FF', '#00E58F', '#884DFF', '#FF6363', '#FFB900'];
    top5Services.forEach((service, index) => {
      service.color = colorPalette[index % colorPalette.length];
    });

    const servicesData = top5Services;
    return servicesData;
  }

  const checkSignedContrac = async () => {
    const respContract = await fetchEntities("contracts", { filter: `AccountId eq ${ACCOUNT_ID()}` })
    setIsSignContract(respContract.value.length > 0)
  }

  useEffect(() => {
    if (isSignContract == true) {
      loadEntities()
    }
  }, [isSignContract])

  useEffect(() => {
    checkSignedContrac()
  }, [])

  useEffect(() => {
    setBookCompleted(getNumberStatus("Hoàn thành"))
    setBookWaiting(getNumberStatus("Đang chờ"))
    setRequestWithdraw(walletRequest.filter((value) => value.ToAccountId === null).length)
    setRequestWaiting(walletRequest.filter((value) => !value.Status).length)
    setTopServiceBooking(top5Service())
  }, [careServices, careServiceBookings, walletRequest])

  return (
    <div>
      {isSignContract ?
        <CaregiverLayout>
          <div className='space-y-10'>
            <div className='flex space-x-10'>
              <div className='bg-white border border-slate-300 rounded-3xl p-4 w-[90%] '>
                <h3 className='text-lg font-medium'>Tổng quan</h3>
                <div className='grid grid-cols-4 gap-5 mt-4'>
                  <div className='p-4 bg-[#FFE2E5] rounded-lg h-32'>
                    <span className="bg-red-500 p-2 rounded-full inline-block">
                      <FaPoll className=' text-white ' />
                    </span>
                    <h2 className='text-xl font-medium'>{careServices?.length}</h2>
                    <p>DV Chăm Sóc</p>
                  </div>
                  <div className='p-4 bg-[#FFF4DE] rounded-lg h-32'>
                    <span className="bg-[#FF947A] p-2 rounded-full inline-block">
                      <FaFileAlt className=' text-white ' />
                    </span>
                    <h2 className='text-xl font-medium'>{careServiceBookings?.length}</h2>
                    <p> Số Đơn Đặt DV </p>
                  </div>
                  <div className='p-4 bg-[#DCFCE7] rounded-lg h-32'>
                    <span className="bg-[#3CD856] p-2 rounded-full inline-block">
                      <FaCheck className=' text-white ' />
                    </span>
                    <h2 className='text-xl font-medium'>{bookCompleted}</h2>
                    <p>Hoàn Thành</p>
                  </div>
                  <div className='p-4 bg-[#F3E8FF] rounded-lg h-32'>
                    <span className="bg-[#BF83FF] p-2 rounded-full inline-block">
                      <FaHourglassHalf className=' text-white ' />
                    </span>
                    <h2 className='text-xl font-medium'>{bookWaiting}</h2>
                    <p className=''>Đang chờ</p>
                  </div>
                </div>
              </div>

              <div className='bg-white border border-slate-300 rounded-3xl w-[45%] p-5 space-y-8'>
                <div className='grid grid-cols-2 space-x-3'>
                  <div className='flex '>
                    <div className='p-4 bg-[#D3FFE7] rounded-full'  >
                      <FaRegListAlt className='w-5 h-5' />
                    </div>
                    <div className='ml-2'>
                      <p className='text-slate-500 text-[12px]'>MC Point</p>
                      <h2 className='font-medium text-2xl'>{wallet.Money}</h2>
                    </div>
                  </div>
                  {/* <div className='flex '>
                  <div className='p-4 bg-[#D3FFE7] rounded-full'  >
                    <FaRegListAlt className='w-5 h-5' />
                  </div>
                  <div className='ml-2'>
                    <p className='text-slate-500 text-[12px]'>Remaining Point</p>
                    <h2 className='font-medium text-2xl'>235</h2>
                  </div>
                </div> */}
                </div>

                <div className='grid grid-cols-2 space-x-3'>
                  <div className='flex '>
                    <div className='p-4 bg-[#D3FFE7] rounded-full'  >
                      <FaEye className='w-5 h-5' />
                    </div>

                    <div className='ml-2'>
                      <p className='text-slate-500 text-[12px]'>Yêu cầu rút tiền</p>
                      <h2 className='font-medium text-2xl'>{requestWithdraw}</h2>
                    </div>
                  </div>
                  <div className='flex '>
                    <div className='p-4 bg-[#D3FFE7] rounded-full'  >
                      <FaEye className='w-5 h-5' />
                    </div>
                    <div className='ml-2'>
                      <p className='text-slate-500 text-[12px]'>Yêu cầu đang chờ</p>
                      <h2 className='font-medium text-2xl'>{requestWaiting}</h2>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* Top Dịch Vụ */}
              <div className="p-4 bg-white border border-slate-300 rounded-lg">
                <h2 className="text-xl font-bold mb-4">Top Dịch Vụ</h2>
                <table className="min-w-full bg-white">
                  <thead>
                    <tr className="border-b pb-2 text-slate-400">
                      <th className="text-left font-normal">#</th>
                      <th className="text-left font-normal">Tên</th>
                      <th className="text-center font-normal">Số lượt đặt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topServiceBooking.map((service) => (
                      <tr key={service.id} className="border-b">
                        <td className="py-4">{service.id}</td>
                        <td className="py-4">{service.name}</td>
                        <td className="py-4 text-center font-bold text-blue-600">
                          <Tag className='px-5' color={service.color}>{service.orders}</Tag>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Total Revenue */}
              <div className="p-4 bg-white border border-slate-300 rounded-lg ">
                <h2 className="text-xl font-bold mb-4">Doanh thu</h2>
                <BarChart hasWallet={hasWallet} dataRequest={walletRequest} />
              </div>
            </div>
          </div>
        </CaregiverLayout>
        :
        <ContractSign onSigned={() => { setIsSignContract(true) }} />}
    </div>
  )
}
