import React from "react";
import { FaRuler, FaWeight } from "react-icons/fa";
import weeksData from "../../../assets/data/weekData";

const PopupEstimate = ({ isOpen, onClose, resultCalcullator }) => {
  const weekFind = resultCalcullator.weeksPassed > 0 ? resultCalcullator.weeksPassed : 1;
  const weewPregnacy = weeksData.find(week => week.week === weekFind);

  if (!isOpen) return null;
  const handleClickOutside = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 popup-overlay" onClick={handleClickOutside}>
      <div className="w-[800px] h-[500px] text-center rounded-lg relative bg-white">
        <div className="h-[100px] bg-[#FECDDD] rounded-bl-[80px] rounded-br-[80px] rounded-tr-[10px] rounded-tl-[10px] border p-4">
          <div className="ml-[30px] space-y-6">
            <h2 className="text-[19px] text-black font-bold">
            Chúc mừng!
              <br /> Bạn đã có thai {" "}
              <span className="text-pinkColor font-bold">{resultCalcullator.weeksPassed} tuần</span>
            </h2>
          </div>
        </div>
        <div className="flex justify-center mt-3 text-center">
          <p className="text-pinkColor mr-2 font-bold">Đã qua {resultCalcullator.weeksPassed} tuần</p>
          <div class="w-[60%] h-4 mb-4 mt-1  bg-gray-200 rounded-full dark:bg-gray-700">
            <div
              class="h-4 bg-[#E55C8A] rounded-full dark:bg-[#E55C8A]"
              style={{ width: `${(resultCalcullator.weeksPassed / 40) * 100}%` }}
            ></div>
          </div>
          <p className=" ml-2 font-bold">còn lại {resultCalcullator.leftWeek} tuần </p>
        </div>
        <div className="h-[355px] bg-[#FECDDD] rounded-tl-[80px] rounded-tr-[80px] rounded-br-[10px] rounded-bl-[10px] border p-4">
          <div className=" space-y-6">
            <h2 className="flex justify-center font-bold text-[25px] ">
              Ngày dự sinh của bạn là
              <span className="text-pinkColor font-bold ml-1">{resultCalcullator.dueDateRoot?.format('D MMMM, YYYY')}</span>
            </h2>

            <div className="flex flex-col md:flex-row items-center justify-center mt-6">
              <img
                src={weewPregnacy.image}
                alt={"Weeks " + weewPregnacy.week}
                className="w-40 h-50 md:w-60 md:h-60 object-cover"
              />
              <div className="text-center">
                <h4 className="text-2xl font-bold">Tuần {resultCalcullator.weeksPassed}</h4>
                <p
                  className="text-md mb-2"
                  style={{ width: "350px", whiteSpace: "wrap" }}
                >
                  {weewPregnacy.information}
                </p>
                <div className="bg-[#06ADEF] py-8 px-6 w-72 text-start rounded-3xl shadow-lg ml-0 md:ml-6 mt-4 md:mt-0 space-y-2">
                  <div>
                    <div className="flex space-x-1">
                      <FaRuler />
                      <p className="text-sm font-medium">Height:</p>
                    </div>
                    <p className="text-sm font-semibold text-gray-200">12 cm</p>
                  </div>
                  <div>
                    <div className="flex space-x-1">
                      <FaWeight />
                      <p className="text-sm font-medium">
                        {weewPregnacy.description.height
                          ? `${weewPregnacy.description.height}`
                          : "_cm"}
                      </p>
                    </div>
                    <p className="text-sm font-semibold text-gray-200">
                      {weewPregnacy.description.weight
                        ? `${weewPregnacy.description.weight}`
                        : "_kg"}
                      {weewPregnacy.describe ? ` - ${weewPregnacy.describe}` : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PopupEstimate;
