import { Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { axiosMomInstance, createEntity, fetchEntities, updateEntity } from '../../api/odataClients/momClient';
import { API_URL_PORT, TOKEN_API } from '../../api/axiosInstance';
import { toast } from 'react-toastify';
import * as signalR from '@microsoft/signalr';
import LoadingPage from '../LoadingPage/LoadingPage';

const PaymentService = ({ show, onClose, careServiceBooking, onSuccess }) => {
    const [connection, setConnection] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let amount = careServiceBooking?.RemainAmount;
    
    const closePopup = () => {
        connection.stop();
        setImageUrl('');
        onClose();
    };

    const instencePayment = async () => {
        if (show) {
            setIsLoading(true);
            let urlPayment = process.env.NODE_ENV === 'development'
                ? `https://momchild.life/api/payment/getqrforcareservicebooking?id=${careServiceBooking?.Id}`
                :
                `${API_URL_PORT}/api/payment/getqrforcareservicebooking?id=${careServiceBooking?.Id}`
            axiosMomInstance.get(urlPayment)
                .then(
                    response => {
                        if (response.data.status === "success") {
                            setImageUrl(response.data.data.imageUrl);
                            amount = response.data.data.amount
                        } else {
                            toast.error("Hệ thống đang bận. Vui lòng thử lại sau!");
                            closePopup();
                        }
                        setIsLoading(false);

                        let connect = new signalR.HubConnectionBuilder()
                            .withUrl(`${API_URL_PORT}/hub/payment`, {
                                accessTokenFactory: () => TOKEN_API
                            })
                            .configureLogging(signalR.LogLevel.Error)
                            .withAutomaticReconnect()
                            .build();
                        setConnection(connect);
                    },
                    err => {
                        toast.error("Hệ thống đang bận. Vui lòng thử lại sau!");
                        closePopup();
                    }
                );
        }
    }

    useEffect(() => {
        instencePayment()
    }, [show]);

    useEffect(() => {
    }, [imageUrl]);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    connection.invoke("Connect");

                    connection.on("Connected", response => {
                        if (response.status === "success") {
                            toast.success(response.message);
                        } else {
                            toast.error(response.message);
                        }
                    });

                    connection.on('Paymented', async (response) => {
                        if (response.status === "success") {
                            let receiveAmount = response.data.amount;
                            if (receiveAmount >= amount) {
                                toast.success('Thanh toán thành công!');
                                hanldeTransitionWallet(receiveAmount)
                                handleUpdateStatusCareServiceBooking(0, true)
                                setImageUrl('');
                                closePopup();
                                onSuccess();
                                return;
                            }
                            else {
                                toast.error('Số tiền chuyển khoản không đúng. Vui lòng thanh toán số tiền còn thiếu!');
                                amount = amount - receiveAmount;
                                handleUpdateStatusCareServiceBooking(amount)
                                let response = await axiosMomInstance.get(`/api/payment/GetQR?amount=${amount}`);
                                setImageUrl(response.data.data.imageUrl);
                            }

                        } else {
                            toast.error(response.message);
                        }
                    });
                })
                .catch(e => toast.error("Kết nối tới server kiểm tra giao dịch thất bại!"));
        }
    }, [connection]);

    if (!show) return null;

    const handleUpdateStatusCareServiceBooking = async (remainAmount, status = false) => {
        try {
            const careServiceUpdate = status ?
                { ...careServiceBooking, Status: "Hoàn thành", RemainAmount: remainAmount } :
                { ...careServiceBooking, RemainAmount: remainAmount }
            await updateEntity("careservicebookings", careServiceUpdate.Id, careServiceUpdate)
            closePopup()
        } catch (error) {
            toast.error("Lỗi cập nhập dịch vụ")
        }
    }

    const hanldeTransitionWallet = async (amount) => {
        try {
            const rsp = await fetchEntities("wallets", {
                filter: `AccountId eq ${careServiceBooking.CareService.CreatedByID}`,
                select: ["Id", "AccountID", "Money"]
            })
            let wallet = rsp.value[0]
            wallet.Money = wallet.Money + amount / 1000
            await updateEntity("wallets", wallet.Id, wallet)

            const requestwallets = {
                WalletID: wallet.Id,
                ToAccountID: careServiceBooking.CareService.CreatedByID,
                Money: amount,
                Action: `Thanh toán tiền dịch vụ với số tiền ${amount}`,
                Date: dayjs(),
                Status: true,
            }
            await createEntity("requestwallets", requestwallets)
        } catch (error) {
            toast.error("Lỗi hệ thống chuyển tiền")
        }
    }

    return (
        <>
            <LoadingPage isLoading={isLoading} />
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 popup-overlay">
                <div className="w-[500px] h-[auto] p-3 px-5 text-center rounded-lg relative bg-white">
                    <AiOutlineClose onClick={closePopup} className='ml-auto text-[25px] mr-2 mt-2' style={{ cursor: 'pointer' }} />
                    <h2 className="text-[25px] text-black font-bold pb-3">Thanh toán dịch vụ</h2>
                    <img src={imageUrl} alt="QRPayment" />
                    <Tag color='red'>Nội dung chuyển khoản không được thay đổi</Tag>
                    <div className='flex gap-4 items-center justify-center p-2 mx-8'>
                        <button onClick={closePopup} className='bg-pinkColor mt-4 p-2 rounded-lg text-[18px] text-white hover:opacity-70'>Huỷ</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentService