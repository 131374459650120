import { DatePicker, Form, Input, Select, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = TimePicker;

export const EventForm = ({ onSubmit, selectedEvent }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    title: null,
    start: null,
    end: null,
    date: null,
    startTime: '',
    endTime: '',
    content: '',
    status: 'Pending',
  });

  useEffect(() => {
    if (selectedEvent) {
      setFormData(selectedEvent);
      form.setFieldsValue({
        ...selectedEvent,
        date: selectedEvent.date ? dayjs(selectedEvent.date) : null,
        // Time: [selectedEvent.startTime ? dayjs(selectedEvent.startTime, 'h:mm') : null, selectedEvent.endTime ? dayjs(selectedEvent.endTime, 'h:mm') : null],
      });
    }
  }, [selectedEvent]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData, [name]: value,
    });
  };

  const handleDateChange = (date, dateString) => {
    console.log(dateString)
    setFormData((prev) => {
      // const newStart = prev.startTime
      //   ? dayjs(`${dateString} ${prev.startTime}`, 'YYYY-MM-DD HH:mm').toDate()
      //   : prev.start;
      // const newEnd = prev.endTime
      //   ? dayjs(`${dateString} ${prev.endTime}`, 'YYYY-MM-DD HH:mm').toDate()
      //   : prev.end;

      return {
        ...prev,
        date: dayjs(`${dateString}`, 'YYYY-MM-DD HH:mm:ss').toDate(),
        // start: newStart,
        // end: newEnd,
      };
    });
  };

  const handleTimeChange = (time, timeString) => {
    setFormData((prev) => {
      const startTimeString = timeString[0];
      const endTimeString = timeString[1];
      const newStart = dayjs(`${prev.date} ${startTimeString}`, 'YYYY-MM-DD HH:mm').toDate()
      const newEnd = dayjs(`${prev.date} ${endTimeString}`, 'YYYY-MM-DD HH:mm').toDate()

      return {
        ...prev,
        startTime: startTimeString,
        endTime: endTimeString,
        start: newStart,
        end: newEnd,
      };
    });
  };

  const handleSubmit = (value) => {
    onSubmit({...selectedEvent, ...value});
  };

  return (
    <Form layout='vertical' form={form} onFinish={handleSubmit} initialValues={formData} className="space-y-4 p-5 w-[500px]" style={{ maxWidth: "100vh" }}>
      <h2 className='text-center text-[24px] font-bold'>{selectedEvent ? 'Cập nhập lịch trình' : 'Thêm lịch trình'} </h2>

      <Form.Item
        name="title"
        className='w-full '
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label='Tên lịch khám:'
        rules={[{ required: true, message: "Hãy nhập tiêu đề" }]}
      >
        <Input
          name="title"
          placeholder='Tên lịch khám'
          value={formData.title}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-lg p-2"
        />
      </Form.Item>

      <Form.Item
        label="Ngày: "
        name="date"
        rules={[{ required: true, message: "Hãy chọn ngày" }]}
      >
        <DatePicker
          name="date"
          showTime
          format="DD/MM/YYYY HH:mm:ss"
          defaultValue={formData.date ? dayjs(formData.date) : null}
          onChange={handleDateChange}
          className="w-full border border-gray-300 rounded-lg p-2"
        />
      </Form.Item>

      {/* <Form.Item
        label="Time: "
        name="Time"
        rules={[{ required: true, message: "hãy chọn thời gian" }]}
      >
        <RangePicker
          className="w-full border border-gray-300 rounded-lg p-2"
          use12Hours
          format="HH:mm"
          onChange={(time, timeString) => handleTimeChange(time, timeString)}
          placeholder={['Start Time', 'End Time']} />
      </Form.Item> */}

      <Form.Item
        name="content"
        label="Nội dung lịch khám"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: "Hãy nhập nội dung" }]}
      >
        <TextArea
          name="content"
          value={formData.content}
          style={{ maxHeight: '40vh', overflowY: 'auto' }}
          onChange={handleChange}
          rows={4}
        />
      </Form.Item>

      {selectedEvent && (
        <Form.Item label="Trạng thái" name='status'>
          <Select
            name='status'
            value={formData.status}
            onChange={(value) => setFormData({ ...formData, status: value })}
          >
            <Option value="Pending">Chưa thực hiện</Option>
            <Option value="Completed">Đã hoàn thành</Option>
          </Select>
        </Form.Item>
      )}

      <button
        type="submit"
        className="w-full bg-pinkColor text-white py-2 px-4 rounded-lg"
      >
        <strong>{selectedEvent ? 'Lưu thay đổi' : 'Tạo'}</strong>
      </button>
    </Form>
  );
};
