import React, { useEffect, useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { fetchEntities } from '../../api/odataClients/caregiverClient';
import { Tag } from 'antd';
import { ACCOUNT_ID, TOKEN_API } from '../../api/axiosInstance';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Revenue and Received Money',
    },
  },
};

export const BarChart = ({ hasWallet = false, dataRequest = [] }) => {
  const [receiveDatares, setReceiveData] = useState([])
  const accountId = useMemo(() => ACCOUNT_ID(), []);

  const chartData = useMemo(() => {
    if (dataRequest.length === 0) {
      return { labels: [], datasets: [] };
    }

    const labels = dataRequest.map(item => new Date(item.Date).toLocaleDateString());
    const totalData = dataRequest.map(item => item.Money);

    const receiveData = receiveDatares.map(item => item.Money);

    return {
      labels,
      datasets: [
        {
          label: 'Tổng doanh thu',
          data: totalData,
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Tiền nhận',
          data: receiveData.length ? receiveData : new Array(totalData.length).fill(0), // Ensure same length
          backgroundColor: 'rgba(225, 162, 235, 0.5)',
        },
      ],
    };
  }, [dataRequest, accountId]);

  const loadRequest = async () => {
    const respWalletRequest = await fetchEntities("requestwallets", {
      filter: `ToAccountID eq ${accountId} and Status eq true`
    });
    setReceiveData(respWalletRequest.value)
  }

  useEffect(() => {
    loadRequest()
  }, [])

  if (!hasWallet) {
    return <>
      <Tag color="red" className="text-center p-3 w-full">
        <strong> Hãy thêm ngân hàng</strong>
      </Tag>
    </>
  }

  return <Bar options={options} data={chartData} />;
};
