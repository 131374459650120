import { Form, Input, Select, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { getDistricts, getProvinces, getWards } from '../../api/addressClients';
import { fetchEntities } from '../../api/odataClients/momClient';
import { EditOutlined } from '@ant-design/icons';
import { ACCOUNT_ID } from '../../api/axiosInstance';
const { Option } = Select

const PopupConfirmBookingService = ({ show, setAddressBooking, onClose, handleConfirm, title, inforBooking }) => {
    const [form] = Form.useForm();
    const [provinces, setProvinces] = useState()
    const [districts, setDistricts] = useState()
    const [wards, setWards] = useState()
    const [address, setAddress] = useState({
        Province: "",
        District: "",
        Ward: "",
        CurrentAddress: "",
        AddressString: "",
    })
    const [bookingForHelp, setBookingForHelp] = useState(false)

    const getDataAddress = async () => {
        try {
            getProvinces().then((values) => setProvinces(values))
            const rspAccount = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["CurrentAddress"] })
            const splitAddress = rspAccount.CurrentAddress.split("-")
            const province = splitAddress[3];
            const district = splitAddress[2];
            const ward = splitAddress[1];
            const currentAddress = splitAddress[0];
            setAddress({
                Province: province,
                District: district,
                Ward: ward,
                CurrentAddress: currentAddress,
                AddressString: rspAccount.CurrentAddress
            })

            if (province) {
                getDistricts(province).then((districts) => {
                    setDistricts(districts);
                    if (district) {
                        getWards(province, district).then((wards) => {
                            setWards(wards);
                        });
                    }
                });
            }
            setAddressBooking(rspAccount.CurrentAddress)
            console.log(address)
        } catch (error) {
            toast.error("Lỗi dữ liệu địa chỉ")
        }
    }

    useEffect(() => {
        getDataAddress()
    }, [])

    if (!show) return null;

    const handleSelectAddress = (name, value) => {
        if (name === "Province") {
            form.setFieldsValue({
                District: null,
                Ward: null
            });
            getDistricts(value).then((values) => setDistricts(values))
            setAddress((prev) => ({ ...prev, Province: value, District: null, Ward: null }));
        } else if (name === "District") {
            form.setFieldsValue({
                Ward: null
            });
            getWards(address.province, value).then((values) => setWards(values))
            setAddress((prev) => ({ ...prev, District: value, Ward: null }));
        } else if (name === "Ward") {
            setAddress((prev) => ({ ...prev, Ward: value }));
        }
        setAddress((prev) => ({ ...prev, [name]: value }));
    }

    const handleChangeAddress = (_, value) => {
        const addressUpdate = `${value.CurrentAddress} - ${value.Ward} - ${value.District} - ${value.Province}`
        setAddressBooking(addressUpdate)
        setAddress({ ...address, AddressString: addressUpdate })
    }

    const date = dayjs(inforBooking.BookingAt)
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 popup-overlay">
            <div className="w-[auto] h-[auto] p-3 px-5 text-center rounded-lg relative bg-white">
                <AiOutlineClose onClick={onClose} className='ml-auto text-[25px] mr-2 mt-2' style={{ cursor: 'pointer' }} />
                <h2 className="text-[25px] text-black font-bold pb-3">Xác nhận đăng ký dịch vụ</h2>
                <div className='w-full flex justify-center'>
                    <span className="bg-green-500 w-[100px] h-[100px] p-2 rounded-full flex items-center justify-center w-10 h-10 mb-2">
                        <FaCheckCircle className="text-white text-[50px]" />
                    </span>
                </div>
                <p>Tên dịch vụ: <strong>{title}</strong></p>
                <p>Thời gian: ngày <strong>{date.format("DD/MM/YYYY")}</strong> từ <strong>{date.format("HH:mm")}</strong> đến <strong>{date.add(inforBooking.InHour, 'hours').format("HH:mm")}</strong></p>
                <p>Giá cho dịch vụ: <strong>{inforBooking.Price} VND</strong></p>
                {bookingForHelp ?
                    <Form form={form} onFinish={() => setBookingForHelp(false)} layout='vertical' initialValues={address} onValuesChange={handleChangeAddress}>
                        <div className="flex space-x-4">
                            <Form.Item
                                rules={[{ required: true, message: 'Hãy chọn tỉnh thành' }]}
                                className='mb-0 w-full'
                                name="Province"
                                label="Tỉnh:"
                            >
                                <Select
                                    showSearch
                                    value={address?.Province}
                                    className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                                    placeholder="Chọn tỉnh thành"
                                    onChange={(value) => handleSelectAddress("Province", value)}
                                >
                                    {provinces && provinces?.map((value, index) => (
                                        <Option
                                            key={index}
                                            value={value.name}
                                        >
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                rules={[{ required: true, message: 'Hãy chọn quận/huyện' }]}
                                name="District"
                                className='mb-0 w-full'
                                label="Quận/Huyện:"
                            >
                                <Select
                                    showSearch
                                    className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                                    placeholder="Chọn Quận/Huyện"
                                    value={address?.District}
                                    onChange={(value) => handleSelectAddress("District", value)}
                                >
                                    {districts && districts?.map((value, index) => (
                                        <Option
                                            key={index}
                                            value={value.name}
                                        >
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                rules={[{ required: true, message: 'Hãy chọn phường/xã' }]}
                                name="Ward"
                                className='mb-0 w-full'
                                label="Phường/Xã:"
                            >
                                <Select
                                    showSearch
                                    value={address?.Ward}
                                    className="w-full border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                                    placeholder="Chọn Phường/Xã"
                                    onChange={(value) => handleSelectAddress("Ward", value)}
                                >
                                    {wards && wards?.map((value, index) => (
                                        <Option
                                            key={index}
                                            value={value.name}
                                        >
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập địa chỉ của bạn' }]}
                            name="CurrentAddress"
                            className='mb-0'
                            label="Địa chỉ cụ thể:"
                        >
                            <Input
                                name="CurrentAddress"
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Địa chỉ cụ thể"
                            />
                        </Form.Item>
                        <button className='bg-pinkColor m-2 px-1 rounded-lg text-[18px] text-white hover:opacity-70'>Xác nhận</button>
                    </Form>
                    :
                    <>
                        <p>Địa chỉ:
                            <strong> {address.AddressString}</strong>
                            <EditOutlined onClick={() => setBookingForHelp(true)} className='ml-3' style={{ fontSize: '24px', color: '#1890ff' }} />
                        </p>
                    </>
                }

                {!bookingForHelp &&
                    <>
                        <div className='flex justify-center w-full mt-2'>
                            <Tag color='red'>Hãy kiểm tra lại thông tin trước khi xác nhận đăng ký dịch vụ</Tag>
                        </div>
                        <div className='flex gap-4 items-center justify-center p-2 mx-8'>
                            <button onClick={onClose} className='bg-pinkColor mt-4 p-2 rounded-lg text-[18px] text-white hover:opacity-70'>Huỷ</button>
                            <button onClick={handleConfirm} className='bg-white mt-4 p-2 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>Chấp nhận</button>
                        </div>
                    </>}
            </div>
        </div>
    );
}

export default PopupConfirmBookingService