import React, { useState } from 'react';
import Header from '../../../components/Header/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import { AiOutlineArrowLeft } from 'react-icons/ai';

export const ResetPassword = () => {
    const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: ""
  })

  const goBack = () => {
    navigate(-1);
  };
  const information = useLocation();
  const { state } = information
  const handleFinishSubmit = (value) => {
    navigate("/reset-pass/confirm", { state: { ...formData, fromResetPassword: true, fromLogin: true } });
  }

  const validateEmailOrPhone = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value) {
      return Promise.reject(new Error('Hãy nhập email của bạn'));
    } else if (emailRegex.test(value)) {
      setFormData({
        email: value.trim(),
        phoneNumber: ""
      })
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('Hãy nhập đúng email'));
    }
  };
  return (
    <>
      <Header />
      <Link className="btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Previous
      </Link>
      <div className="flex items-center justify-center mt-32">
        <div className="container mx-auto px-7 w-full max-w-md">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Cài lại mật khẩu</h4>

            <Form onFinish={handleFinishSubmit} layout="vertical" initialValues={state}>
              <div className="space-y-3">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      validator: validateEmailOrPhone
                    },
                  ]}
                >
                  <Input
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Email or Phone number"
                    value={state?.email}
                  />
                </Form.Item>

                <Form.Item>
                  <button
                    className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    Gửi mã OTP
                  </button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}
