import React, { useState } from 'react'
import Header from '../../../components/Header/Header'
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Form, Input } from 'antd';

const NursingRegister = () => {
  const information = useLocation();
  const { state } = information
  const [formData, setFormData] = useState({
    ...state,
    introduction: null
  })
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const navigate = useNavigate();

  const handleFinish = (values) => {
    navigate("/confirm-nursing", { state: formData })
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Header />
      <div className="cursor-pointer w-[5vw] btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Previous
      </div>


      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Detailed information for nursing</h4>
            <p className="text-sm">
              Enter your information
            </p>
            <Form layout="vertical" onFinish={handleFinish} initialValues={formData} className="space-y-3">
              <Form.Item
                className='m-0'
                name="FullName"
                label="Họ và tên:"
              >
                <Input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  disabled
                />
              </Form.Item>

              <div className="flex space-x-3">
                <Form.Item
                  name="Email"
                  label="Email:"
                  className="w-full m-0"
                >
                  <Input
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  name="PhoneNumber"
                  label="Số điện thoại:"
                  className="w-full m-0"
                >
                  <Input
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    disabled
                  />
                </Form.Item>
              </div>

              <Form.Item
                name="DateOfBirth"
                label="Ngày sinh:"
                className="w-ful m-0"
              >
                <Input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  disabled
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                name="CurrentAddress"
                label="Địa chỉ hiện tại:"
              >
                <Input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  disabled
                />
              </Form.Item>

              <Form.Item label="Introduce yourself:" name="introduction" rules={[{ required: true, message: 'Hãy nhập lời giới thiệu của bạn' }]}>
                <Input.TextArea
                  onChange={handleInputChange}
                  name="introduction"
                  className="w-full p-3 border border-gray-300 rounded-lg" placeholder="Introduce yourself*:" rows={5} />
              </Form.Item>

              <Form.Item>
                <button className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg" type="primary" htmlType="submit">
                  Next
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default NursingRegister
