import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";
import { FaFileAlt, FaHourglassHalf, FaPoll } from "react-icons/fa";
import { AiFillStar, AiOutlineEdit } from "react-icons/ai";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LineChart,
  Line,
} from "recharts";
import { fetchEntities } from "../../api/odataClients/adminClient"
import LoadingPage from "../../components/LoadingPage/LoadingPage";

export const Dashboard = () => {
  const [accountsLength, setAccountsLength] = useState(0)
  const [contractsLength, setContractsLength] = useState(0)
  const [feedbacksLength, setFeedbacksLength] = useState(0)
  const [requestLength, setRequestLength] = useState(0)

  const [accountStatus, setAccountStatus] = useState([])
  const [contractsPerMonth, setDataContracts] = useState([])
  const [feedbackCounts, setFeebackCounts] = useState([])
  const [accountCounts, setAccountCounts] = useState([])

  const [loading, setLoading] = useState(true)

  const loadEntities = async () => {
    try {
      const dataAccount = await fetchEntities('accounts', {
        select: ['RoleID', 'IsActive'],
        expand: ['Role'],
        count: true
      })
      const dataFeedbacks = await fetchEntities('feedbacks', { count: true })
      const dataContracts = await fetchEntities('contracts', {
        select: ['CreatedAt', 'IsSigned'],
        count: true
      })
      const dataRequest = await fetchEntities('requestwallets', {
        select: ['Status']
      })
      const dataComment = await fetchEntities('comments', {
        select: ['Rating'],
        count: true
      })

      const contractsPerMonth = Array(12).fill(0);
      dataContracts.value.forEach(contract => {
        if (contract.IsSigned) {
          const month = new Date(contract.CreatedAt).getMonth();
          contractsPerMonth[month]++;
        }
      });

      const feedbackCounts = [1, 2, 3, 4, 5].map((rating) => ({
        rating,
        feedbackCount: dataComment.value.filter((value) => value.Rating === rating).length
      }))


      const accountCounts = ['Mom', 'Caregiver', 'Supporter'].map((name) => ({
        name,
        value: dataAccount.value.filter((role => role.Role.Name === name)).length
      }))

      const accountStatuslength = await dataAccount.value.filter((active => active.IsActive)).length
      const dataRequestLength = dataRequest.value.filter(request => request.Status === false).length;

      setAccountsLength(dataAccount['@odata.count'])
      setFeedbacksLength(dataFeedbacks['@odata.count'])
      setContractsLength(dataContracts['@odata.count'])
      setRequestLength(dataRequestLength)
      setAccountStatus(accountStatuslength)

      setDataContracts([
        { month: "Tháng 1", count: contractsPerMonth[0] },
        { month: "Tháng 2", count: contractsPerMonth[1] },
        { month: "Tháng 3", count: contractsPerMonth[2] },
        { month: "Tháng 4", count: contractsPerMonth[3] },
        { month: "Tháng 5", count: contractsPerMonth[4] },
        { month: "Tháng 6", count: contractsPerMonth[5] },
        { month: "Tháng 7", count: contractsPerMonth[6] },
        { month: "Tháng 8", count: contractsPerMonth[7] },
        { month: "Tháng 9", count: contractsPerMonth[8] },
        { month: "Tháng 10", count: contractsPerMonth[9] },
        { month: "Tháng 11", count: contractsPerMonth[10] },
        { month: "Tháng 12", count: contractsPerMonth[11] },
      ]);

      setFeebackCounts(feedbackCounts)
      setAccountCounts(accountCounts)

    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false);
    }

  }
  useEffect(() => { loadEntities() }, [])


  const dataAccountStatus = [
    { name: "Đang hoạt động", value: accountStatus },
    { name: "Bị khoá", value: (accountsLength - accountStatus) }
  ];


  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];
  const COLORSTATUS = ["#FF4081", "#5E35B1"];
  const renderCustomLabel = ({ percent }) => `${(percent * 100).toFixed(0)}%`;

  const renderCustomXAxisTick = ({ x, y, payload }) => (
    <g>
      <text x={x} y={y + 10} textAnchor="middle" fill="#333">
        {payload.value}
      </text>
      <g transform={`translate(${x + 7}, ${y})`}>
        {Array.from({ length: payload.value }).map((_, index) => (
          <AiFillStar
            key={index}
            style={{ color: "#FFD700", fontSize: "12px", marginBottom: "12px" }}
          />
        ))}
      </g>
    </g>
  );

  return (
    <>
      <LoadingPage isLoading={loading} />
      <AdminLayout>
        <div className="space-y-10">
          <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-10">
            <div className="bg-white rounded-3xl p-4 w-full">
              <h3 className="text-lg font-medium">Phân tích</h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-5 mt-4">
                <div className="p-4 bg-[#FFE2E5] rounded-lg h-24 md:h-32">
                  <span className="bg-red-500 p-2 rounded-full inline-block">
                    <FaPoll className="text-white" />
                  </span>
                  <h2 className="text-lg md:text-xl font-medium">{accountsLength}</h2>
                  <p className="text-sm md:text-base">Tổng số tài khoản</p>
                </div>
                <div className="p-4 bg-[#FFF4DE] rounded-lg h-24 md:h-32">
                  <span className="bg-[#FF947A] p-2 rounded-full inline-block">
                    <FaFileAlt className="text-white" />
                  </span>
                  <h2 className="text-lg md:text-xl font-medium">{contractsLength}</h2>
                  <p className="text-sm md:text-base">Tổng số hợp đồng</p>
                </div>
                <div className="p-4 bg-[#DCFCE7] rounded-lg h-24 md:h-32">
                  <span className="bg-[#3CD856] p-2 rounded-full inline-block">
                    <FaHourglassHalf className="text-white" />
                  </span>
                  <h2 className="text-lg md:text-xl font-medium">{requestLength}</h2>
                  <p className="text-sm md:text-base">Các yêu cầu rút tiền</p>
                </div>
                <div className="p-4 bg-[#F3E8FF] rounded-lg h-24 md:h-32">
                  <span className="bg-[#BF83FF] p-2 rounded-full inline-block">
                    <AiOutlineEdit className="text-white" />
                  </span>
                  <h2 className="text-lg md:text-xl font-medium">{feedbacksLength}</h2>
                  <p className="text-sm md:text-base">Phản hồi</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-10">
            <div className="bg-white border border-slate-300 rounded-3xl p-4 w-full">
              <h3 className="text-lg font-medium">Phân bổ các quyền</h3>
              <div className="flex justify-center mt-4">
                <PieChart width={250} height={250}>
                  <Pie
                    data={accountCounts}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={renderCustomLabel}
                    labelLine={false}
                    labelPosition="inside"
                  >
                    {accountCounts.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </div>
            </div>

            <div className="bg-white border border-slate-300 rounded-3xl p-4 w-full">
              <h3 className="text-lg font-medium">Trạng thái tài khoản</h3>
              <div className="flex justify-center mt-4">
                <PieChart width={250} height={250}>
                  <Pie
                    data={dataAccountStatus}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={renderCustomLabel}
                    labelLine={false}
                    labelPosition="inside"
                  >
                    {dataAccountStatus.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORSTATUS[index % COLORSTATUS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-10 lg:w-[100%]">
            <div className="bg-white border border-slate-300 rounded-3xl p-4 w-full lg:w-[40%]">
              <h3 className="text-lg font-medium">Đánh giá dịch vụ</h3>
              <div className="flex justify-center mt-4">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={feedbackCounts}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="rating" tick={renderCustomXAxisTick} />
                    <YAxis
                      label={{
                        value: "Số lượng đánh giá",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Bar dataKey="feedbackCount" fill="#82ca9d" barSize={40} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="bg-white border border-slate-300 rounded-3xl p-4 w-full lg:w-[50%]">
              <h3 className="text-lg font-medium">
                Số hợp đồng ký theo tháng
              </h3>
              <div className="flex justify-center mt-4">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={contractsPerMonth}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis
                      label={{
                        value: "Số hợp đồng",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="count" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>


        </div>
      </AdminLayout>
    </>
  );
};

export default Dashboard;
