import React, { useEffect, useRef, useState } from 'react'
import CaregiverLayout from '../../../components/Caregiver/CaregiverLayout'
import { Button, Input, Pagination, Radio, Table, Tag } from 'antd';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { LockOutlined, SearchOutlined, UnlockOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { fetchEntities, updateEntity } from '../../../api/odataClients/caregiverClient';
import { AddNewService } from '../../../components/Caregiver/AddNewService/AddNewServive';
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle, FaUsers } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import { ACCOUNT_ID } from '../../../api/axiosInstance';

export const CaregiverService = () => {
  const [services, setServices] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [statistical, setStatistical] = useState({
    totalService: 0,
    active: 0,
    disable: 0,
  });
  const [loading, setLoading] = useState(true);
  const [showModalAddNew, setShowModalAddNew] = useState(false)
  const [valueFilter, setValueFilter] = useState("")


  const loadEntities = async (searchText) => {
    setLoading(true);
    let baseFilter = `CreatedByID eq ${ACCOUNT_ID()}`;
    let searchFilter = searchText ? `contains(Title,'${searchText}')` : "";
    let additionalFilter = valueFilter ? valueFilter : "";

    let combinedFilter = [baseFilter, searchFilter, additionalFilter]
        .filter(Boolean) 
        .join(' and '); 

    try {
        const data = await fetchEntities('careservices', {
            filter: combinedFilter,
            expand: ['CareServiceType'],
            count: true,
            skip: (currentPage - 1) * pageSize,
            top: pageSize,
            orderBy: ['Id desc']
        });
        console.log(data);
        setServices(data.value);
        setDataLength(data['@odata.count']);
    } catch (err) {
        toast(err);
    } finally {
        setLoading(false);
    }
};


  const loadStatic = async () => {
    const dataStatistical = await fetchEntities('careservices', { filter: `CreatedByID eq ${ACCOUNT_ID()}`, count: true });
    const active = dataStatistical.value.filter((x) => x.IsActive === true).length;
    const disable = dataStatistical.value.filter((x) => x.IsActive !== true).length;
    setStatistical({
      totalService: dataStatistical['@odata.count'],
      active: active,
      disable: disable,
    });
  }

  useEffect(() => {
    loadStatic();
  }, [])

  useEffect(() => {
    loadEntities();
  }, [currentPage, pageSize, valueFilter]);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleCreateNewService = () => {
    setShowModalAddNew(false);
    setLoading(true)
    loadEntities();
    loadStatic();
    toast.success('Tạo dịch vụ thành công');
  }

  const handleClickLockService = async (service, isActive) => {
    setLoading(true)
    try {
      await updateEntity("careservices", service.Id, {...service, IsActive: isActive})
      toast.success("Cập nhập thành công")
      loadEntities();
    } catch (error) {
      toast.error("Lỗi cập nhập dịch vụ")
    }
  }

  const handleFilterSelection = (e) => {
    const { value } = e.target;
    console.log("Selected Filter Value:", value);
    setValueFilter(value)
  }
  const handleViewDetail = (serviceId) => {
    navigate(`/caregiver/service/${serviceId}`, { state: { defaultSelectedValue: 3 } });
  };

  const debounceDropDown = useRef(
    debounce((searchValue) => {
      loadEntities(searchValue)
    }, 500)
  ).current;

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    debounceDropDown(searchValue);
  };

  React.useEffect(() => {
    return () => {
      debounceDropDown.cancel();
    };
  }, [debounceDropDown]);

  const columns = [
    { title: 'Tên', dataIndex: 'Title', key: 'Title' },
    {
      title: 'Loại dịch vụ', dataIndex: 'CareServiceType', key: 'CareServiceType',
      render: (CareServiceType) => (CareServiceType ? CareServiceType.Name : "No Data"),
    },
    {
      title: 'Giá', dataIndex: 'Price', key: 'Price',
      render: (_, record) => (
        <div>
          {record.Price
            ? `${record.Price} VND`
            : "No Price"}
        </div>
      ),
    },
    {
      title: 'Trạng thái', dataIndex: 'IsActive', key: 'IsActive', render: (isActive) => {
        if (isActive) {
          return <Tag color='green'>Đang hoạt động</Tag>
        }
        return <Tag color='red'>Đã khoá</Tag>
      }
    },
    {
      title: 'Chức năng', dataIndex: 'IsActive', key: 'IsActive',
      render: (_, record) => (
        <div className='w-full flex items-center'>
          <Button className='w-[30%] mr-3' style={{ background: 'rgba(22, 192, 152, 0.38)', color: '#008767', border: 'solid 2px #008767' }}
            onClick={() => handleViewDetail(record.Id)} >
            Xem chi tiết
          </Button>
          {record.IsActive ?
            <UnlockOutlined onClick={()=>handleClickLockService(record, false)} style={{ fontSize: '24px', color: '#52c41a' }} />:
            <LockOutlined onClick={()=>handleClickLockService(record, true)} style={{ fontSize: '24px', color: '#ff4d4f' }} />}
        </div>
      ),
    },
  ];
  return (
    <div>
      <CaregiverLayout>
        <LoadingPage isLoading={loading} />
        <AddNewService show={showModalAddNew} onClose={() => setShowModalAddNew(false)} onSuccess={handleCreateNewService} />
        <Radio.Group onChange={handleFilterSelection} className="grid grid-cols-3 gap-4 mb-10" defaultValue={valueFilter}>
          <Radio.Button className='shadow rounded p-0' value="" style={{ height: "auto" }}>
            <div className='p-4 bg-[#E6FFFA] rounded-lg h-32'>
              <span className="bg-[#38B2AC] p-2 rounded-full flex items-center justify-center w-10 h-10">
                <FaUsers className='text-white text-[20px]' />
              </span>
              <p className='text-[18px] mt-2'>Tổng số dịch vụ</p>
              <h2 className='text-[18px] font-medium'>{statistical.totalService}</h2>
            </div>
          </Radio.Button>

          <Radio.Button className='shadow rounded p-0' value="IsActive eq true" style={{ height: "auto" }}>
            <div className='p-4 bg-[#DCFCE7] rounded-lg h-32'>
              <span className="bg-[#3CD856] p-2 rounded-full flex items-center justify-center w-10 h-10">
                <FaCheckCircle className='text-white text-[20px]' />
              </span>
              <p className='text-[18px] mt-2'>Đang hoạt động</p>
              <h2 className='text-[18px] font-medium'>{statistical.active}</h2>
            </div>
          </Radio.Button>

          <Radio.Button className='shadow rounded p-0' value="IsActive eq false" style={{ height: "auto" }}>
            <div className='p-4 bg-[#FFE2E5] rounded-lg h-32'>
              <span className="bg-[#E53E3E] p-2 rounded-full flex items-center justify-center w-10 h-10">
                <FaTimesCircle className='text-white text-[20px]' />
              </span>
              <p className='text-[18px] mt-2'>Dịch vụ đã khoá</p>
              <h2 className='text-[18px] font-medium'>{statistical.disable}</h2>
            </div>
          </Radio.Button>
        </Radio.Group>

        <div className="flex justify-between mb-4">
          <h1 className='text-2xl font-semibold'>Danh sách dịch vụ chăm sóc</h1>

          <Input
            className="w-1/4"
            placeholder="Tìm kiếm..."
            prefix={<SearchOutlined />}
            size="large"
            onChange={handleInputChange}
          />

          <button
            className='bg-pinkColor px-14 py-2 rounded-lg text-[18px] text-white border border-pinkColor hover:bg-pinkColor hover:text-white'
            onClick={() => setShowModalAddNew(true)}
          >
            Tạo dịch vụ chăm sóc
          </button>
        </div>

        <Table
          dataSource={services}
          columns={columns}
          pagination={false}
        />

        {/* Pagination */}
        <div className="mt-4 flex justify-end">
          <Pagination
            total={dataLength}
            showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dịch vụ`}
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePaginationChange}
          />
        </div>
      </CaregiverLayout>
    </div>
  )
}
