import React from 'react';
import { FaFacebook, FaLinkedin, FaYoutube, FaInstagram, FaPhone, FaMailBulk, FaAddressCard } from "react-icons/fa";
import logo from "../../assets/image/logo/LOGO_MC_CARE__.png";

export default function Footer() {
    return (
        <footer className="bg-gray-50 py-8 md:px-10">
            <div className="container mx-auto px-4">
                <div className="flex space-x-4">

                    {/* Mom Child Care Section */}
                    <div className="md:col-span-2">
                        <h3 className="font-bold text-lg mb-4"><img src={logo} alt="" className="w-24 mt-4" /></h3>
                        <p className="text-sm mb-6">
                            Mom Child Care là trang thông tin dành cho Mẹ và Bé, chuyên cung cấp những kiến ​​thức, kinh nghiệm hữu ích về Thụ thai,
                            Mang thai và nuôi con. Để giúp người đọc có những quyết định đúng đắn trong việc chăm sóc sức khỏe, xây dựng cuộc sống gia đình và nuôi dạy con cái tốt hơn.
                        </p>
                    </div>

                    <div className="md:col-span-1 w-full space-y-10">
                        <h3 className="font-bold text-lg mb-4">Liên hệ</h3>
                        <div className='flex items-center space-x-4'>
                            <p className="text-sm flex space-x-3 items-center"><FaPhone className="w-6 h-6 text-gray-700 hover:text-gray-900" /><span>0123456789</span></p>
                            <p className="text-sm flex space-x-3 items-center"><FaMailBulk className="w-6 h-6 text-gray-700 hover:text-gray-900" /><span>Email@gmail.com</span></p>
                            <p className="text-sm flex space-x-3 items-center"><FaAddressCard className="w-6 h-6 text-gray-700 hover:text-gray-900" /><span>Hoà Hải, Ngũ Hành Sơn, Đà Nẵng</span></p>
                        </div>
                        <div className="flex space-x-3">
                            <a href="#"><FaFacebook className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                            <a href="#"><FaLinkedin className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                            <a href="#"><FaYoutube className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                            <a href="#"><FaInstagram className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    )
}
