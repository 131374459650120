import React, { useState } from "react";
import Header from '../../../components/Header/Header';
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
const { Option } = Select;

const AfterChildbirthRegister = () => {
  const navigate = useNavigate();
  const information = useLocation();
  const { state } = information

  const calculateAge = (dateOfBirth) => {
    const today = dayjs();
    const birthDate = dayjs(dateOfBirth);
    const age = today.diff(birthDate, 'year');
    return age;
  };

  let account = { ...state }
  const [momData, setMomData] = useState({
    Height: 0,
    Weight: 0,
    Age: Number(calculateAge(state.DateOfBirth)),
    NumberOfChild: 1
  })
  const [childrenData, setChildrenData] = useState([
    {}
  ])

  const handleFinish = (values) => {
    const heightMeter = account.Height / 100
    const BMIIndex = account.Weight / (heightMeter * heightMeter)
    account = { ...account, DateOfBirth: dayjs(account.DateOfBirth).toISOString(), BMIIndex: BMIIndex.toFixed(2) }
    navigate('/confirm-otp-code', { state: { account: account, momData, childrenData } })
  };

  const handleInputChangeMom = (e) => {
    let { name, value } = e.target
    setMomData((prev) => ({ ...prev, [name]: Number(value) }))
  }

  const handleInputDate = (value, index) => {
    handleInputChangeChild({ target: { name: "DateOfBirth", value: value.toISOString() } }, index)
  }

  const handleChangeSexChildren = (value, index) => {
    handleInputChangeChild({ target: { name: "Sex", value: value === "Boy" } }, index)
  }

  const handleInputChangeChild = (e, index) => {
    let { name, value } = e.target;
    name = name.replace(`_${index}`, "");
    const updatedChildren = [...childrenData];

    updatedChildren[index] = {
      ...updatedChildren[index],
      [name]: name === "HeadCircumference" || name === "Height" || name === "Weight" ? Number(value) : value,
    };

    setChildrenData(updatedChildren);
  }

  const goBack = () => {
    navigate(-1);
  };

  const validateNumber = (_, value) => {
    if (!value || (Number.isInteger(Number(value)) && value > 0)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Chỉ được nhập số dương.'));
  }

  return (
    <>
      <Header />
      <div className="cursor-pointer btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Quay lại
      </div>

      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Thông tin chi tiết cho mẹ sau khi sinh con</h4>
            <p className="text-sm">
              Nhập thông tin của bạn
            </p>
            <Form layout="vertical" onFinish={handleFinish} initialValues={account} className="space-y-3">
              <div className="flex space-x-3">
                <Form.Item className="w-full m-0" name="Email" label="Email:">
                  <Input disabled type="email" className="w-full p-3 border border-gray-300 rounded-lg" />
                </Form.Item>

                <Form.Item className="w-full m-0" name="PhoneNumber" label="Số điện thoại:" >
                  <Input disabled className="w-full p-3 border border-gray-300 rounded-lg" />
                </Form.Item>
              </div>

              <div className="flex space-x-3">
                <Form.Item className="w-full m-0" name="DateOfBirth" label="Ngày sinh:" >
                  <Input disabled className="w-full p-3 border border-gray-300 rounded-lg" />
                </Form.Item>

                <Form.Item className="w-full m-0" name="FullName" label="Họ và tên:" >
                  <Input disabled className="w-full p-3 border border-gray-300 rounded-lg" />
                </Form.Item>
              </div>

              <Form.Item name="CurrentAddress" label="Địa chỉ hiện tại:" >
                <Input disabled className="w-full p-3 border border-gray-300 rounded-lg" />
              </Form.Item>
            </Form>
            <Form layout="vertical" onFinish={handleFinish}>
              {/* Mom's Weight and Height */}
              <div className="flex space-x-3">
                <Form.Item name="Weight" className="w-full m-0" label="Cân nặng của mẹ (Kg):"
                  rules={[{ required: true, message: 'Nhập cân nặng của bạn' },
                  { validator: validateNumber }]}>
                  <Input
                    name="Weight"
                    placeholder="Cân nặng của bạn: "
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    onChange={handleInputChangeMom} />
                </Form.Item>

                <Form.Item name="Height" className="w-full m-0" label="Chiều bao của mẹ (Cm):"
                  rules={[{ required: true, message: 'Nhập chiều cao của bạn' },
                  { validator: validateNumber }]}>
                  <Input
                    name="Height"
                    placeholder="Chiều cao của bạn: "
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    onChange={handleInputChangeMom} />
                </Form.Item>
              </div>

              {/* Number of Children */}
              <Form.Item
                name="NumberOfChild"
                label="Số lượng bé:"
                rules={[
                  { required: true, message: 'Hãy nhập số lượng trẻ nhỏ' },
                  { validator: validateNumber },
                ]}
              >
                <Input
                  name="NumberOfChild"
                  placeholder="Number of children*: "
                  type="number"
                  value={momData.NumberOfChild}
                  min={1}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  onChange={handleInputChangeMom}
                />
              </Form.Item>
              {/* Child Number 1 Details */}
              <div className="border rounded-lg p-3 space-y-3">
                {Array.from({ length: momData.NumberOfChild }, (_, index) => (
                  <><p>Trẻ thứ {index + 1}:</p>
                    <div className="flex space-x-3">
                      <Form.Item className="w-full m-0" name={`Name_${index}`} label="Tên của bé:"
                        rules={[{ required: true, message: 'Hãy nhập tên của bé' }]}>
                        <Input
                          placeholder="Tên của bé: "
                          name={`Name_${index}`}
                          onChange={(e) => handleInputChangeChild(e, index)}
                          className="w-full p-3 border border-gray-300 rounded-lg"
                        />
                      </Form.Item>

                      <Form.Item name={`Sex_${index}`} className="h-full w-full m-0" label="Giới tính:" >
                        <Select
                          className="w-full p-3 border border-gray-300 rounded-lg"
                          size="large"
                          name={`Sex_${index}`}
                          defaultValue={'Chọn giới tính của bé'}
                          onChange={(value) => handleChangeSexChildren(value, index)}
                        >
                          <Option value="Boy" className="text-green-600">
                            Bé trai
                          </Option>
                          <Option value="Girl" className="text-red-600">
                            Bé gái
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <Form.Item name={`DateOfBirth_${index}`} label="Ngày sinh của bé:"
                      rules={[{ required: true, message: 'Hãy nhập ngày sinh của bé' }]}>
                      <DatePicker
                        name={`DateOfBirth_${index}`}
                        placeholder="Ngày sinh của bé: "
                        onChange={(value) => handleInputDate(value, index)}
                        className="w-full p-3 border border-gray-300 rounded-lg"
                      />
                    </Form.Item>

                    <div className="flex space-x-3">
                      <Form.Item name={`Weight_${index}`} className="w-full m-0" label="Cân nặng:"
                        rules={[{ required: true, message: 'Hãy nhập cân cặng của bé' }]}>
                        <Input
                          name={`Weight_${index}`}
                          placeholder="Cân nặng của bé: "
                          className="w-full p-3 border border-gray-300 rounded-lg"
                          onChange={(e) => handleInputChangeChild(e, index)}
                        />
                      </Form.Item>

                      <Form.Item name={`Height_${index}`} className="w-full m-0" label="Chiều cao:"
                        rules={[{ required: true, message: 'Hãy nhập chiều cao của bé' }]}>
                        <Input
                          name={`Height_${index}`}
                          placeholder="Chiều cao của bé: "
                          className="w-full p-3 border border-gray-300 rounded-lg"
                          onChange={(e) => handleInputChangeChild(e, index)}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item name={`HeadCircumference_${index}`} label="Head Circumference:"
                      rules={[{ required: true, message: 'hãy nhập chu vi vòng đầu của trẻ' }]}>
                      <Input
                        name={`HeadCircumference_${index}`}
                        placeholder="Child's head circumference*: "
                        className="w-full p-3 border border-gray-300 rounded-lg"
                        onChange={(e) => handleInputChangeChild(e, index)}
                      />
                    </Form.Item></>
                ))}
              </div>

              <Form.Item>
                <button type="primary" htmlType="submit" className="w-full bg-pinkColor py-3 text-white font-bold rounded-lg">
                  Sign up
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

    </>
  );
};

export default AfterChildbirthRegister;
