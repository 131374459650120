import React, { useEffect, useState } from "react";
import CaregiverLayout from "../../../components/Caregiver/CaregiverLayout";
import { Row } from "antd";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { createEntity, fetchEntities } from '../../../api/odataClients/caregiverClient';
import ChattingList from "../../../components/Chatting/ChattingList";
import ChatMessageList from "../../../components/Chatting/ChatMessageList";
import NotificateSignalR from "../../../components/NotificateSignalR/NotificateSignalR";
import { useLocation } from "react-router-dom";
import { ACCOUNT_ID, TOKEN_API } from "../../../api/axiosInstance";
import dayjs from "dayjs";

export const CaregiverChatting = () => {
  const [activeKey, setActiveKey] = useState("0");
  const information = useLocation();
  const { state } = information;
  const [messagesData, setMessagesData] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState("");

  const onMessageReceived = (response) => {
    if (response.status === "success") {
      loadEntities();
    }
  };

  useEffect(() => {
    if (selectedConversation) {
      handleConversationClick(selectedConversation);
    }
  }, [messagesData, selectedConversation]);

  const loadEntities = async () => {
    let filter = "";
    if (searchText) {
      const encodedSearchText = encodeURIComponent(searchText);
      filter = `and contains(CreatedBy/FullName,'${encodedSearchText}')`;
    }
    try {
      const [dataGroupMember, rspAccount] = await Promise.all([
        fetchEntities('groupmembers', { filter: `AccountID eq ${ACCOUNT_ID()}` }),
        fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["FullName", "Id"] }),
      ]);

      setAccount(rspAccount);

      const userGroupIDs = dataGroupMember.value.map(member => member.GroupID);
      if (userGroupIDs.length > 0) {
        const data = await fetchEntities('messages', {
          filter: `GroupID in (${userGroupIDs.join(',')})` + filter,
          expand: ['CreatedBy($select=FullName,Id)', 'Group'],
          orderBy: ['GroupID desc', 'CreatedAt desc'],
        });

        const lastMessages = userGroupIDs.map(groupID => {
          return data.value.find(message => message.GroupID === groupID);
        });
        const sortedLastMessages = lastMessages
          .filter(Boolean)
          .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));

        setMessagesData(sortedLastMessages);
      } else {
        setMessagesData([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEntities();
  }, []);

  const handleConversationClick = (conversationId) => {
    setSelectedConversation(conversationId);
  };
  
  const onSubmit = async (value) => {
    if (selectedConversation && (value.mess || value.imageUrl)) {
      const newMessage = {
        GroupID: selectedConversation,
        Content: value.mess,
        CreatedByID: ACCOUNT_ID(),
        ImageUrl: value.imageUrl,
        IsMessageSys: false,
        CreatedAt: dayjs().toISOString()
      };

      try {
        const data = await createEntity("messages", newMessage);
        if (data) {
          data.CreatedBy = { Id: ACCOUNT_ID() };
          setMessagesData((prev) => [data, ...prev]);
          await loadEntities();
        }
      } catch (e) {
        console.error("Error saving message:", e);
      }
    }
  };

  useEffect(() => {
    if (state && state?.commonGroupID) {
      handleConversationClick(state?.commonGroupID)
    }
  }, [messagesData]);

  return (
    <NotificateSignalR onNotificateChat={onMessageReceived}>
      <CaregiverLayout>
        <LoadingPage isLoading={loading} />
        <div className="border rounded-lg w-full shadow-lg">
          <Row>
            {/* list trò chuyện */}
            <ChattingList
              onReloadData={loadEntities}
              messagesData={messagesData}
              searchText={searchText}
              setSearchText={setSearchText}
              onConversationClick={handleConversationClick}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              account={account}
            />
            {/*  Soạn tin nhắn */}

            <ChatMessageList
              setMessagesData={setMessagesData}
              selectedConversation={selectedConversation}
              account={account}
              onSubmit={onSubmit}
              token={TOKEN_API}
            />
          </Row>
        </div>
      </CaregiverLayout>
    </NotificateSignalR>
  );
};
