import React, { useEffect, useState } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { API_URL_PORT, TOKEN_API } from '../../../api/axiosInstance';
import * as signalR from '@microsoft/signalr';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { axiosCaregiverInstance, createEntity, updateEntity } from '../../../api/odataClients/caregiverClient';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

export const FormDepositPoint = ({ show, onSuccess, onClose, myWallet }) => {
    const [connection, setConnection] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const closePopup = () => {
        connection.stop();
        setImageUrl('');
        onClose();
    };

    useEffect(() => {
        if (show) {
            setIsLoading(true);
            axiosCaregiverInstance.get(`${API_URL_PORT}/api/payment/getqr`)
                .then(
                    response => {
                        if (response.data.status === "success") {
                            setImageUrl(response.data.data.imageUrl);
                        } else {
                            toast.error("Hệ thống đang bận. Vui lòng thử lại sau!");
                            closePopup();
                        }
                        setIsLoading(false);

                        let connect = new signalR.HubConnectionBuilder()
                            .withUrl(`${API_URL_PORT}/hub/payment`, {
                                accessTokenFactory: () => TOKEN_API
                            })
                            .configureLogging(signalR.LogLevel.Error)
                            .withAutomaticReconnect()
                            .build();
                        setConnection(connect);
                    },
                    err => {
                        toast.error("Hệ thống đang bận. Vui lòng thử lại sau!");
                        closePopup();
                    }
                );
        }
    }, [show]);


    useEffect(() => {
        if (connection) {
            connection.start()
                .catch(e => {
                    toast.error("Hệ thống đang bận. Vui lòng thử lại sau!");
                    closePopup();
                });

            connection.on('Paymented', (response) => {
                if (response.status === "success") {
                    let receiveAmount = response.data.amount;
                    let mccPoint = receiveAmount / 1000;
                    myWallet.Money += mccPoint;
                    updateEntity("wallets", myWallet.Id, myWallet);
                    const walletRequest = {
                        WalletID: myWallet.Id,
                        Money: mccPoint,
                        Action: `Nạp điểm vào tài khoảng của account ${localStorage.setItem("Id")}`,
                        Date: dayjs().toISOString(), 
                        Status: false,
                      };
                      
                    createEntity('requestwallets', walletRequest);
                    toast.success(`Nạp ${mccPoint} MCC thành công!`);
                    onSuccess();
                } else {
                    toast.error(response.message);
                }
                closePopup();
            });
        }
    }, [connection]);

    if (!show) return <></>;

    return (
        <>
            <LoadingPage isLoading={isLoading} />
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white h-[auto] w-[600px] p-10 rounded-lg shadow-lg relative">
                    <h3 className='text-3xl text-center font-bold pb-4 text-pinkColor'>Quét QR để nạp tiền</h3>
                    <button className="absolute top-2 right-2" onClick={closePopup}>
                        <FaRegTimesCircle className='w-6 h-6 m-2' />
                    </button>
                    <div className='text-center space-y-4 flex justify-center'>
                        <img
                            className='h-[400px]'
                            src={imageUrl}
                            alt="QR"
                            hidden={!imageUrl}
                        />
                    </div>
                    <div className='text-center'>
                        <p className='text-[16px] font-semibold mt-4'>Vui lòng quét mã QR rồi nhập số tiền cần nạp. <br /><i>(1 point = 1000 VND)</i></p>
                        <p className='text-[16px] font-semibold text-red-600'><i>Lưu ý: Không sửa đổi nội dung chuyển khoản</i></p>
                    </div>
                </div>
            </div>
        </>
    );
}