import React from 'react';
import LayoutCommon from '../LayoutCommon/LayoutCommon';
import { AuditOutlined, CalendarOutlined, CommentOutlined, CreditCardOutlined, LogoutOutlined, MessageOutlined, PieChartOutlined } from '@ant-design/icons';

const CaregiverLayout = ({ children }) => {
  return (
    <LayoutCommon
      sidebarItems={[
        {
          label: 'Thống kê',
          icon: PieChartOutlined,
          route: '/caregiver/dashboard'
        },
        {
          label: 'Dịch vụ chăm sóc',
          icon: AuditOutlined,
          route: '/caregiver/service'
        },
        {
          label: 'Đơn đặt dịch vụ',
          icon: CommentOutlined,
          route: '/caregiver/order'
        },
        {
          label: 'Lịch trình',
          icon: CalendarOutlined,
          route: '/caregiver/schedule'
        },
        {
          label: 'Ví của tôi',
          icon: CreditCardOutlined,
          route: '/caregiver/my-wallet'
        },
        {
          label: 'Hợp đồng',
          icon: AuditOutlined,
          route: '/caregiver/contract'
        },
        {
          label: 'Tư vấn',
          icon: MessageOutlined,
          route: '/caregiver/chatting'
        },
      ]}
      tokenKey="authCaregiverToken"
    >
      {children}
    </LayoutCommon>
  );
};

export default CaregiverLayout;

