import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Form, Select } from 'antd';
import './style.css'

const OptionRole = () => {
  const information = useLocation();
  let { state } = information
  const navigate = useNavigate();
  const { Option } = Select;
  const role = [{ id: 1, name: "Mẹ đang mang thai" }, { id: 2, name: "Sau khi sinh con" }, { id: 3, name: "Người chăm sóc" },]
  const [roleSelected, setRoleSelected] = useState(0)

  const goBack = () => {
    navigate(-1);
  };

  const handleSelectRole = (role) => {
    setRoleSelected(role)
  }

  useEffect(()=>{
    if(roleSelected === 1 || roleSelected === 2){
      const MomStatus = roleSelected === 1
      state = {...state, RoleId: 1, MomStatus: MomStatus}
    }else if(roleSelected === 3){
      state = {...state, RoleId: 2}
    }
  },[roleSelected])

  const handleNextClick = () => {
    if (!roleSelected) return;
    switch (roleSelected) {
      case 1:
        navigate('/pregnant-mothers', {state: state})
        break;
      case 2:
        navigate('/after-childbirth', {state: state})
        break;
      case 3:
        navigate('/confirm-nursing', {state: state})
        break;
      default:
        return;
    }
  }
  return (
    <>
      <Header />
      <div className='cursor-pointer w-[100px] btn btn-light my-3 flex font-bold m-3' onClick={goBack}>
        <AiOutlineArrowLeft className='mr-1 font-bold text-[22px] mt-[1px]' />
        Quay lại
      </div>

      <div className="flex items-center justify-center mt-32">
        <div className="container mx-auto px-7 w-full max-w-md">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Bạn là:</h4>
            <p className="text-sm">
              Chọn vai trò của bạn
            </p>

            <div className="space-y-3">
              <Form layout="vertical">
                <Form.Item
                  name="role"
                  rules={[{ required: true, message: 'Hãy chọn vai trò của bạn' }]}
                >
                  <Select
                    className="w-full text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
                    style={{ borderColor: "#ff3259" }}
                    placeholder="Chọn vai trò"
                    onSelect={handleSelectRole}
                  >
                    {role.map((value) => (
                      <Option
                        key={value.id}
                        value={value.id}
                      >
                        {value.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <button
                    type="primary"
                    className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                    onClick={handleNextClick}
                  >
                    Tiếp tục
                  </button>
                </Form.Item>
              </Form>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default OptionRole
