import React, { useState } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form, Input, Slider } from 'antd';
import dataPanel from '../../../assets/data/Panel/DataPanel';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import CustomPanel from '../../../components/Tool/CustomPanel/CustomPanel';
import Banner from '../../../components/Tool/ToolBanner/Banner';

export const WeightOfPregnant = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [openPanelIndex, setOpenPanelIndex] = useState(null);
  const [formData, setFormData] = useState({
    beforeWeight: "",
    height: "",
    currentWeight: "",
    weeksPregnant: 16,
    isTwins: false,
  });

  const handleInputChange = (name, { target: { value } }) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    const numberValueSubmit = {
      ...formData,
      beforeWeight: Number(formData.beforeWeight),
      height: Number(formData.height),
      currentWeight: Number(formData.currentWeight)
    }
    navigate("/tool/weight/result", { state: numberValueSubmit });
  };

  return (
    <>
      <Header />
      <div className='container mx-auto'>
        <Banner
          header="Công cụ tính cân nặng khi mang thai"
          content={
            <>
              <div className='space-y-4'>
                <p className='text-lg hidden sm:block'>
                  Công cụ này dành riêng cho những phụ nữ muốn biết cân nặng khỏe mạnh của mình
                  phạm vi tăng cân trong thời kỳ mang thai dựa trên cân nặng trước khi mang thai.
                </p>
                <p className='text-base lg:text-xl hidden sm:block font-semibold text-blueColor'>
                  Tư vấn y khoa: Bác sĩ Tạ Trung Kiên 24/09/2021
                </p>
              </div>
            </>
          }
        />

        <div className='grid lg:grid-cols-2 ms:grid-cols-1 lg:flex-row my-10 px-8 sm:my-16 space-y-10 lg:space-y-0 lg:space-x-10'>
          {/** Input information */}
          <div className='bg-gray-100 h-[590px] lg:w-full p-6 rounded-[30px] sm:rounded-[50px]'>
            <Form form={form} className='space-y-5' onFinish={handleSubmit} initialValues={formData}>
              {/* Weeks Pregnant */}
              <div className='mt-2 h-20 py-2 px-6 bg-white rounded-2xl space-y-4'>
                <h5>Bạn đang mang thai ở tuần thứ bao nhiêu?</h5>
                <Form.Item name="beforeWeight" >
                  <div className='flex items-center'>
                    {formData.weeksPregnant}
                    <Slider
                      min={1}
                      max={40}
                      value={formData.weeksPregnant}
                      onChange={(value) => setFormData(prev => ({ ...prev, weeksPregnant: value }))}
                      className='w-full ml-2'
                    />
                  </div>
                </Form.Item>
              </div>

              {/* Weight Before Pregnancy */}
              <div className="relative">
                {formData.beforeWeight.length > 0 && (
                  <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 rounded px-1" style={{ zIndex: '100' }}>
                    Cân nặng của bạn trước khi mang thai là bao nhiêu? (kg)
                  </label>
                )}
                <Form.Item
                  rules={[{ required: true, message: 'Vui lòng nhập cân nặng của bạn trước khi mang thai!' }, { pattern: /^\d+$/, message: 'Chỉ được phép nhập số!' }]}
                  className='mb-8'
                  name="beforeWeight"
                >
                  <Input
                    autoFocus
                    className="w-full h-16 sm:h-20 rounded-2xl px-4 border"
                    onChange={(e) => handleInputChange("beforeWeight", e)}
                    placeholder='Cân nặng của bạn trước khi mang thai là bao nhiêu? (kg)'
                  />
                </Form.Item>
              </div>

              {/* Height */}
              <div className="relative">
                {formData.height.length > 0 && (
                  <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 rounded px-1" style={{ zIndex: '100' }}>
                    Bạn cao bao nhiêu? (cm)
                  </label>
                )}
                <Form.Item
                  rules={[{ required: true, message: 'Vui lòng nhập chiều cao của bạn!' }, { pattern: /^\d+$/, message: 'Chỉ được phép nhập số!' }]}
                  className='mb-8'
                  name="height"
                >
                  <Input
                    autoFocus
                    className="w-full h-16 sm:h-20 rounded-2xl px-4 border"
                    onChange={(e) => handleInputChange("height", e)}
                    placeholder='Bạn cao bao nhiêu? (cm)'
                  />
                </Form.Item>
              </div>

              {/* Current Weight */}
              <div className="relative">
                {formData.currentWeight.length > 0 && (
                  <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 rounded px-1" style={{ zIndex: '100' }}>
                    Cân nặng hiện tại của bạn là bao nhiêu? (kg)
                  </label>
                )}
                <Form.Item
                  rules={[{ required: true, message: 'Vui lòng nhập cân nặng hiện tại của bạn!' }, { pattern: /^\d+$/, message: 'Chỉ được phép nhập số!' }]}
                  className='mb-8'
                  name="currentWeight"
                >
                  <Input
                    autoFocus
                    className="w-full h-16 sm:h-20 rounded-2xl px-4 border"
                    onChange={(e) => handleInputChange("currentWeight", e)}
                    placeholder='Cân nặng hiện tại của bạn là bao nhiêu? (kg)'
                  />
                </Form.Item>
              </div>

              {/* Pregnancy with twins */}
              <div className='px-2'>
                <Form.Item
                  name="isTwins"
                  valuePropName="checked"
                >
                  <Checkbox name="isTwins" onChange={(e) => setFormData((prev) => ({ ...prev, isTwins: e.target.checked, }))} checked={formData.isTwins}>
                    Bạn có đang mang thai đôi không?
                  </Checkbox>
                </Form.Item>
              </div>

              {/* Calculate Button */}
              <Form.Item>
                <Button className='btn-hover-no-change bg-pinkColor text-white py-2 w-full rounded-full' htmlType='submit'>
                  Tính toán
                </Button>
              </Form.Item>
            </Form>
          </div>


          {/** Show Information */}
          <div className='space-y-8'>
            <div className='space-y-5'>
              <div className='flex items-center space-x-3'>
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <h5>Tuyên bố miễn trừ trách nhiệm</h5>
              </div>

              <div>
                <p className='font-bold'>
                  Công cụ này không cung cấp lời khuyên y khoa và chỉ nhằm mục đích cung cấp thông tin. Không bao giờ bỏ qua lời khuyên y khoa chuyên nghiệp khi tìm kiếm phương pháp điều trị vì thông tin bạn đã đọc trên trang web MarryBaby. Hãy nhớ rằng mỗi lần mang thai là khác nhau.
                </p>
              </div>
            </div>

            <div className='space-y-4'>
              <div className='flex space-x-3'>
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <h5>Thông tin</h5>
              </div>

              <div>
                {dataPanel.weightOfPregnant.toolPage.map((panel, index) => (
                  <CustomPanel
                    key={index}
                    isOpen={openPanelIndex === index}
                    onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                    header={panel.header}
                    content={panel.content}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
