import { Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { API_URL_PORT, TOKEN_API } from '../../../api/axiosInstance';
import axios from 'axios';

const { Option } = Select;

export const AddNewAccount = ({ show, onClose, onSuccess }) => {
    const [formData, setFormData] = useState({
        FullName: "",
        Password: "",
        Email: "",
        PhoneNumber: "",
        RoleID: 4,
        IsActive: true
    })

    if (!show) return null;

    const hanldeSubmit = async (value) => {
        try {
            const response = await axios.post(`${API_URL_PORT}/api/auth/createaccount`, { ...formData, ...value }, {
                headers: {
                    Authorization: `Bearer ${TOKEN_API}`,
                }
            });

            if (response.data.status === "error") {
                toast.error(response.data.message)
            }

            else {
                toast.success("Thêm tài khoản thành công")
                setTimeout(() => {
                    onSuccess();
                }, 200);
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    const validateEmail = async (_, value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!value) {
            return Promise.reject(new Error('Hãy nhập địa chỉ email của bạn'));
        } else if (emailRegex.test(value)) {
            const email = value.trim();
            const response = await axios.get(`${API_URL_PORT}/api/auth/checkemailregister?email=${email}`);
            if (response.data.status === "success") {
                return Promise.resolve();
            }
            return Promise.reject(new Error('Email đã được đăng ký'));
        } else {
            return Promise.reject(new Error('Hãy nhập Email hợp lệ'));
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white h-[auto] w-[600px] p-10 rounded-lg shadow-lg relative">
                <h3 className='mb-3 text-center text-3xl'>Tạo mới tài khoản</h3>
                <button className="absolute top-2 right-2" onClick={onClose}>
                    <FaRegTimesCircle className='w-6 h-6 m-2' />
                </button>
                <Form onFinish={hanldeSubmit} initialValues={formData} layout='vertical'>
                    <Form.Item
                        rules={[{ required: true, message: 'Hãy nhập tên đầy đủ' }]}
                        className='w-full'
                        name="FullName"
                        label="Tên đầy đủ:"
                    >
                        <Input
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Họ và tên "
                        />
                    </Form.Item>

                    <Form.Item
                        className='w-full'
                        name="Email"
                        label="Email:"
                        rules={[
                            {
                                validator: validateEmail
                            },
                        ]}
                        validateTrigger="onSubmit"
                    >
                        <Input
                            type='email'
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Email "
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[{ required: true, message: 'Hãy nhập số điện thoại' }]}
                        className='w-full'
                        name="PhoneNumber"
                        label="Số điện thoại:"
                    >
                        <Input
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Số điện thoại*: "
                        />
                    </Form.Item>

                    <Form.Item
                        label="Quyền"
                        className='w-full'>
                        <Select
                            className={`w-2/4 border-none p-0`}
                            size="large"
                            defaultValue={"4"}
                            onChange={(value) => setFormData((prev) => ({ ...prev, ["RoleID"]: Number(value) }))}
                            style={{ border: 'none !important', outline: 'none !important' }}>
                            <Option value="4" className="text-green-600">
                                <strong>Quản trị viên</strong>
                            </Option>
                            <Option value="3" className="text-red-600">
                                <strong>Hỗ trợ viên</strong>
                            </Option>
                        </Select>
                    </Form.Item>
                    <button
                        type="submit"
                        className="w-full bg-pinkColor text-white py-2 px-4 rounded-lg"
                    >
                        <strong>Tạo</strong>
                    </button>
                </Form>
            </div>
        </div>
    );
};
