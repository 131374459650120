import React, { useEffect, useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ChildForm } from './ChildForm';
import { toast } from 'react-toastify';
import { deleteEntity, fetchEntities } from '../../api/odataClients/momClient';
import dayjs from 'dayjs';
import CustomModal from '../Common/CustomModalConfirm';
import axios from 'axios';
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from '../../api/axiosInstance';

export const PostpartumUI = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);
  const [children, setChildren] = useState([]);
  const [isFirstChange, setIsFirstChange] = useState(false)
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [images, setImages] = useState()
  const [imageSelected, setImageSelected] = useState()

  const fecthAndCheckFirstChange = async () => {
    try {
      const rspChildHealths = await fetchEntities("childhealths", { filter: `AccountId eq ${ACCOUNT_ID()}` })
      const isFirst = !(rspChildHealths.value.length > 0)
      setChildren(rspChildHealths.value)
      setIsFirstChange(isFirst)
    } catch (err) {
      toast.error("Lỗi kiểm tra trạng thái của mẹ")
    }
  }

  const handleEdit = (child, imageURL) => {
    setImageSelected(imageURL)
    setSelectedChild(child);
    setShowPopup(true);
  };

  const handleDeleteChildren = (child) => {
    setSelectedChild(child)
    setShowModalConfirmDelete(true)
  }

  const ConfirmDeleteChildren = async () => {
    try {
      await deleteEntity("childhealths", selectedChild.Id)
      const url = process.env.NODE_ENV === 'development' ? `https://momchild.life/api/s3/deleteimage?url=${selectedChild.URLImage}` : `${API_URL_PORT}/api/s3/deleteimage?url=${selectedChild.URLImage}`
      await axios.delete(url, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${TOKEN_API}`
        }
      });
      toast.success("Xoá thông tin bé thành công")
      fecthAndCheckFirstChange()
      setShowModalConfirmDelete(false)
    } catch (error) {
      toast.error("Lỗi xoá thông tin em bé")
    }
  }

  const loadingImage = () => {
    try {
      children.forEach(async (child) => {
        const url = process.env.NODE_ENV === 'development' ?
          `https://momchild.life/api/s3/getimage?url=${child.URLImage}` :
          `${API_URL_PORT}/api/s3/getimage?url=${child.URLImage}`
        const respGetImage = await axios.get(url);
        setImages((prev) => ({ ...prev, [child.Id]: respGetImage.data.data.imageUrl }))
      });

    } catch (error) {
      toast.error("Lỗi tải hình ảnh")
    }
  }

  const calculateBMI = (weight, heightInCm) => {
    const heightInMeters = heightInCm / 100;
    return (weight / (heightInMeters * heightInMeters)).toFixed(2);
  };

  const handleAddBabyClick = () => {
    setSelectedChild(null);
    setShowPopup(true);
  };

  useEffect(() => {
    if (isFirstChange) {
      // alert("chúc mừng bạn đã sinh em bé")
    } else {

    }
  }, [isFirstChange])

  useEffect(() => {
    fecthAndCheckFirstChange()
  }, [])

  useEffect(() => {
    loadingImage()
  }, [children])

  return (
    <div className='container mx-auto px-4 mb-8'>
      <div className='bg-[#FFF1DD]  rounded-[50px] mt-8 py-10 px-48'>
        <div className='flex justify-between'>
          <p className='text-2xl'>Số lượng bé: {children.length}</p>
          <button
            className='bg-pinkColor py-2 px-14 rounded-xl text-white'
            onClick={handleAddBabyClick}
          >
            Thêm bé
          </button>
        </div>

        {/* Render danh sách bé */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
          {children.map((child, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-4 flex flex-col items-center">
              {/* Icons Edit và Delete */}
              <div className="flex justify-end w-full mb-4 space-x-2">
                <button className="text-blue-500 hover:text-blue-700">
                  <FaEdit onClick={() => handleEdit(child, images[child?.Id])} size={20} />
                </button>
                <button className="text-red-500 hover:text-red-700">
                  <FaTrash onClick={() => handleDeleteChildren(child)} size={20} />
                </button>
              </div>

              {/* Hình ảnh */}
              <div className="w-full flex justify-center">
                <div className="relative">
                  {child.URLImage ? (
                    <img
                      src={images && images[child?.Id]}
                      alt="Child"
                      className="w-[300px] h-[200px] object-cover rounded-lg shadow-md"
                    />
                  ) : (
                    <div className="w-[200px] h-[150px] bg-gray-200 rounded-lg flex items-center justify-center text-gray-500">
                      No Image
                    </div>
                  )}
                  <h2 className=" text-center absolute -top-4 left-1/2 transform -translate-x-1/2 bg-orange-500 text-white py-1 px-4 rounded-t-lg">
                    {child.Name}
                  </h2>
                </div>
              </div>

              {/* Thông tin */}
              <div className="mt-4">
                <div className="text-center">
                  <p className="text-sm">
                    Giới tính: {child.Sex ? "Bé trai" : "Bé gái"} <br /> Ngày sinh: {dayjs(child.DateOfBirth).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {/* Cân nặng */}
                  <div className="bg-pink-300 rounded-lg p-4 shadow-md text-center">
                    <p className="font-bold">Cân Nặng</p>
                    <p>{child.Weight} Kg</p>
                  </div>
                  {/* Chiều cao */}
                  <div className="bg-green-300 rounded-lg p-4 shadow-md text-center">
                    <p className="font-bold">Chiều cao</p>
                    <p>{child.Height} cm</p>
                  </div>
                  {/* Vòng đầu */}
                  <div className="bg-blue-300 rounded-lg p-4 shadow-md text-center">
                    <p className="font-bold">Vòng đầu</p>
                    <p>{child.HeadCircumference} cm</p>
                  </div>
                  {/* BMI */}
                  <div className="bg-purple-300 rounded-lg p-4 shadow-md text-center">
                    <p className="font-bold">BMI</p>
                    <p>{child.BMIIndex}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CustomModal isOpenModal={showModalConfirmDelete} handleCancel={() => setShowModalConfirmDelete(false)} handleConfirm={ConfirmDeleteChildren}
        header="Xác nhận xoá thông tin của bé"
        textCancel='Đóng'
        textConfirm='Xác nhận'
        content={(<><h2 className="text-[14px] text-black pb-3">Bạn có chắc muốn xoá thông tin của bé không?</h2></>)}
      />
      <ChildForm
        isOpen={showPopup}
        imageUpdate={imageSelected}
        onClose={() => setShowPopup(false)}
        onSuccess={fecthAndCheckFirstChange}
        initialData={selectedChild ? selectedChild : null}
      />
    </div>
  )
}
