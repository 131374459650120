const childrenGrowth = {
    toolPage: [
        {
            header: "Biểu đồ tăng trưởng của bé là gì?",
            content: (
                <>
                    Biểu đồ tăng trưởng giúp các bậc cha mẹ và bác sĩ nhi khoa theo dõi sự phát triển của trẻ qua thời gian. Chiều cao, cân nặng, vòng đầu và chỉ số BMI của trẻ có thể so sánh với những trẻ cùng tuổi và giới tính để xác định xem trẻ có phát triển bình thường hay không.
                </>
            ),
        },
        {
            header: "Cách đọc biểu đồ tăng trưởng của bé?",
            content: (
                <>
                    Một phần trăm là thước đo cho thấy vị trí của một đứa trẻ so với những đứa trẻ khác. Trên biểu đồ tăng trưởng, các phần trăm được hiển thị dưới dạng các đường biểu đồ. Khi bạn vẽ cân nặng và chiều cao của trẻ trên biểu đồ này, bạn sẽ thấy các số đo của trẻ ở nhóm phần trăm tương ứng. Một con số phần trăm cao hơn có thể hiểu là một đứa trẻ lớn hơn/cao hơn so với những đứa trẻ khác cùng tuổi và giới tính về chiều cao và cân nặng. Ngược lại, một con số phần trăm thấp hơn nghĩa là một đứa trẻ nhỏ hơn/ngắn hơn so với những đứa trẻ cùng tuổi và giới tính. Ví dụ, một đứa trẻ ở phân vị thứ 5 được coi là nhẹ hơn 95% những đứa trẻ khác cùng tuổi. Mặt khác, một đứa trẻ ở phân vị thứ 90 được coi là nặng hơn 90% những đứa trẻ cùng tuổi.
                </>
            ),
        },
        {
            header: "Tại sao theo dõi sự phát triển của trẻ lại quan trọng?",
            content: (
                <>
                    Theo dõi chiều cao, cân nặng và vòng đầu của trẻ trên biểu đồ tăng trưởng qua thời gian cho phép bác sĩ nhi khoa và các bậc cha mẹ nhận thấy nếu trẻ phát triển quá chậm hoặc quá nhanh — điều này có thể cho thấy những lo ngại về dinh dưỡng hoặc sức khỏe khác.
                    <br /> <br />
                    Tuy nhiên, đừng quá lo lắng về phần trăm này. Chúng chỉ cho thấy rằng bác sĩ nhi khoa của bạn đang theo dõi sự phát triển của trẻ qua thời gian. Con bạn có phát triển đúng theo phân vị của mình, nghĩa là trẻ đang phát triển tốt? Hay có bất kỳ sự thay đổi nào trong quá trình phát triển của trẻ, điều này có thể cho thấy vấn đề sức khỏe? Điều này rất quan trọng vì nó giúp phát hiện những thay đổi sớm trong cuộc sống của trẻ.
                </>
            ),
        },
        {
            header: "Những tiêu chuẩn này từ đâu mà có?",
            content: (
                <>
                    Những tiêu chuẩn này được phát triển bằng cách sử dụng dữ liệu thu thập từ Nghiên cứu Tham chiếu Tăng trưởng Đa Trung tâm của Tổ chức Y tế Thế giới (WHO).
                    <br />
                    Nghiên cứu Tham chiếu Tăng trưởng Đa Trung tâm của WHO (MGRS) được thực hiện từ năm 1997 đến năm 2003 để tạo ra các vùng tăng trưởng mẫu cho việc đánh giá sự phát triển của trẻ sơ sinh và trẻ nhỏ trên toàn thế giới. Nghiên cứu MGRS đã thu thập dữ liệu tăng trưởng chính và các thông tin liên quan từ khoảng 8.500 trẻ em từ các nền văn hóa và sắc tộc khác nhau (như Brazil, Ghana, Ấn Độ, Na Uy, Oman và Hoa Kỳ).
                    <br />
                    Nghiên cứu dự kiến sẽ cung cấp một tiêu chuẩn quốc tế thống nhất đại diện cho sự phát triển sinh lý tốt nhất cho tất cả trẻ em từ khi sinh đến 5 tuổi, và từ đó thiết lập mô hình nuôi con bằng sữa mẹ làm tiêu chuẩn cho sự phát triển và tăng trưởng của trẻ.
                </>
            ),
        },
        {
            header: "Tài liệu tham khảo",
            content: (
                <>
                    <a className="underline" href="https://www.cdc.gov/growthcharts/growthchart_faq.htm">
                        https://www.cdc.gov/growthcharts/growthchart_faq.htm
                    </a>
                    <br />
                    <a className="underline" href="https://www.researchgate.net/publication/324165249_A_Comparative_Study_on_Feeding_Practice_and_Nutrition_Status_among_6-23_Months_Age_Children">
                        https://www.researchgate.net/publication/324165249_A_Comparative_Study_on_Feeding_Practice_and_Nutrition_Status_among_6-23_Months_Age_Children
                    </a>
                    <br />
                    <a className="underline" href="https://kidshealth.org/en/parents/growth-charts.html">
                        https://kidshealth.org/en/parents/growth-charts.html
                    </a>
                    <br />
                    <a className="underline" href="https://www.who.int/tools/child-growth-standards/who-multicentre-growth-reference-study#:~:text=">
                        https://www.who.int/tools/child-growth-standards/who-multicentre-growth-reference-study#:~:text=
                    </a>
                    <br />
                </>
            ),
        },
    ],
    result: [
        {
            header: "Phần trăm của bé có thể giảm không?",
            content: (
                <>
                    Phần trăm của bé có thể giảm theo thời gian do một số yếu tố. Đôi khi, trẻ sẽ thay đổi phần trăm trong hai hoặc ba năm đầu tiên do di truyền của chúng. Mô hình phát triển cũng phụ thuộc vào dinh dưỡng của bé. Ví dụ, trẻ bú sữa mẹ tăng trưởng nhanh hơn trẻ bú sữa công thức trong 6 tháng đầu, trong khi trẻ bú sữa công thức phát triển nhanh hơn sau 6 tháng.
                    <br />
                    Một đứa trẻ chuyển từ biểu đồ tăng trưởng của WHO sang biểu đồ CDC sau 24 tháng cũng có thể được phân loại khác do sự khác biệt về định dạng và giá trị giữa hai biểu đồ này.
                    <br />
                    Nếu phần trăm của con bạn giảm, điều đó không nhất thiết có nghĩa là bạn nên lo lắng. Hãy tham khảo ý kiến bác sĩ nhi khoa hoặc nhà cung cấp dịch vụ chăm sóc sức khỏe để xác định nguyên nhân và nhận được lời khuyên phù hợp.
                </>
            ),
        },
        {
            header: "Chu vi đầu của bé trai có lớn hơn bé gái không?",
            content: (
                <>
                    Bé trai thường sinh ra với đầu và cơ thể lớn hơn bé gái. Dựa trên biểu đồ CDC và WHO, chu vi đầu của bé trai thường lớn hơn bé gái khoảng 1 cm.
                </>
            ),
        },
        {
            header: "Trẻ sinh non có phần trăm thấp hơn trẻ đủ tháng không?",
            content: (
                <>
                    Điều này sẽ phụ thuộc vào sức khỏe của trẻ khi sinh. Trẻ sinh non hoặc sinh trước ngày dự sinh nhưng khỏe mạnh thường có phần trăm thấp hơn trẻ đủ tháng từ lúc sinh cho đến 8 tuổi, nhưng chúng có thể bắt kịp trong những năm tiếp theo. Hơn nữa, trẻ sinh non được mong đợi sẽ đạt được các chỉ số cơ thể hợp lý khi chúng 4 tuổi.
                    <br />
                    Kết quả biểu đồ tăng trưởng cũng có thể bị ảnh hưởng nếu trẻ nhỏ hơn tuổi thai (khi còn trong bụng mẹ) hoặc gặp vấn đề sức khỏe khi sinh.
                </>
            ),
        },
        {
            header: "Con tôi dưới mức trung bình. Tôi có nên lo lắng không?",
            content: (
                <>
                    Không có phần trăm lý tưởng nào cho con bạn. Trẻ em khỏe mạnh có thể ở phân vị 95 hoặc thậm chí phân vị thứ 4. Các bác sĩ thường tập trung vào việc liệu dữ liệu của con bạn có phù hợp với các mẫu phát triển bình thường, bao gồm cả tốc độ tăng trưởng chiều cao và cân nặng có nhất quán hay không.
                    <br />
                    Nếu tốc độ tăng trưởng hoặc phần trăm thay đổi, đó là lúc các bác sĩ xem xét kỹ hơn — nhưng điều này không phải lúc nào cũng là nguyên nhân đáng lo ngại. Đôi khi, phần trăm của trẻ thay đổi trong các giai đoạn phát triển như thời kỳ sơ sinh hoặc dậy thì.
                </>
            ),
        },
        {
            header: "Tại sao việc đo chu vi đầu của trẻ lại quan trọng?",
            content: (
                <>
                    Đo chu vi đầu của trẻ giúp xác định xem não có phát triển bình thường hay không. Điều này cung cấp thông tin cho các bậc cha mẹ và bác sĩ về bất kỳ vấn đề phát triển hoặc tăng trưởng tiềm ẩn nào.
                    <br />
                    Ví dụ, nếu đầu của một đứa trẻ nhỏ hơn nhiều so với bình thường, điều đó có thể cho thấy rằng não không phát triển bình thường. Ngược lại, nếu đầu lớn hơn đáng kể, đó có thể là dấu hiệu của chứng não úng thủy (tích tụ dịch xung quanh não), cần can thiệp y tế.
                </>
            ),
        },
        {
            header: "Chỉ số BMI của trẻ em có được đánh giá khác với người lớn không?",
            content: (
                <>
                    Mặc dù công thức tính BMI giống nhau, nhưng BMI của trẻ em được hiểu theo cách khác so với người lớn. Ở trẻ em, BMI không đánh giá chính xác khối lượng mỡ cơ thể. Bác sĩ sẽ theo dõi chỉ số BMI của trẻ theo thời gian và sử dụng phần trăm BMI trên biểu đồ tăng trưởng để xác định sự phát triển lành mạnh.
                </>
            ),
        },
    ],
};

const estimateDate = [
    {
        header: "Kỳ kinh cuối cùng",
        content: (
            <>
                Hầu hết các thai kỳ kéo dài khoảng 40 tuần (hoặc 38 tuần kể từ khi thụ thai). Vì vậy, cách tốt nhất để ước tính ngày dự sinh của bạn là đếm 40 tuần, hoặc 280 ngày, từ ngày đầu tiên của kỳ kinh cuối cùng. Một cách khác là lấy ngày đầu tiên của kỳ kinh cuối cùng của bạn, trừ đi ba tháng và cộng thêm bảy ngày. Điều kiện duy nhất để tính ngày dự sinh dựa trên kỳ kinh cuối là chu kỳ kinh nguyệt của bạn phải đều, có chu kỳ 28 ngày và bạn biết được ngày của kỳ kinh cuối.
                <br /> <br />
                Nếu kỳ kinh cuối của bạn bắt đầu vào ngày 11 tháng 4 năm 2020, bạn sẽ trừ đi ba tháng, tức là ngày 11 tháng 1 năm 2020, sau đó cộng thêm bảy ngày, có nghĩa là ngày dự sinh của bạn sẽ là ngày 18 tháng 1 năm 2021. Đây là cách các bác sĩ ước tính ngày dự sinh của bạn. Nhưng hãy nhớ rằng, việc sinh sớm hoặc muộn một tuần là hoàn toàn bình thường.
            </>
        ),
    },
    {
        header: "Ngày thụ thai",
        content: (
            <>
                Phương pháp tính tuổi thai dựa trên ngày quan hệ áp dụng cho các cặp đôi nhớ chính xác ngày quan hệ, người phụ nữ có chu kỳ kinh nguyệt đều đặn và có thể xác định được ngày rụng trứng. Lý do là vì tinh trùng có thể sống trong cơ thể người phụ nữ trong 5 ngày, nhưng trứng chỉ sống được 1 ngày sau khi rụng. Tinh trùng chỉ có thể thụ tinh với trứng trong thời gian này.
                <br /> <br />
                Theo phương pháp này, ngày đầu tiên của tuổi thai sẽ được tính từ ngày quan hệ với ngày rụng trứng và sau đó cộng thêm 38 tuần (tức là 266 ngày).
            </>
        ),
    },
    {
        header: "Thụ tinh trong ống nghiệm - IVF",
        content: (
            <>
                Tính tuần thai và ngày dự sinh bằng phương pháp thụ tinh nhân tạo sẽ chính xác hơn so với thụ thai tự nhiên. Điều này là do ngày cấy phôi hoặc ngày rụng trứng đã được xác định chính xác.
                <br /> <br />
                Ngày dự sinh bằng thụ tinh trong ống nghiệm sẽ được tính bằng cách cộng thêm 38 tuần (tức là 266 ngày) kể từ thời điểm trứng được thụ tinh. Một cách tính khác là vẫn cộng thêm 38 tuần nhưng trừ đi số ngày mà phôi được cấy ghép (tức là khi phôi được đặt vào tử cung). Nếu phôi 3 ngày tuổi, trừ đi 3 ngày, và nếu phôi 5 ngày tuổi, trừ đi 5 ngày.
            </>
        ),
    }
];

const weightOfPregnant = {
    toolPage: [
        {
            header: "Tại sao bạn tăng cân khi mang thai?",
            content: (
                <>
                    Trong suốt quá trình mang thai, cơ thể người phụ nữ thay đổi và tăng cân để đảm bảo rằng thai nhi nhận đủ dinh dưỡng trong suốt thời kỳ mang thai. Lượng cân nặng tăng lên sẽ phụ thuộc vào cân nặng trước khi mang thai của người mẹ; những phụ nữ nhỏ bé và thiếu cân sẽ cần tăng nhiều cân hơn so với những người thừa cân trước khi mang thai.
                </>
            ),
        },
        {
            header: "Thay đổi trong cơ thể của bạn khi mang thai",
            content: (
                <>
                    Cùng với việc tăng cân, một số thay đổi cơ thể mà bạn sẽ trải qua khi mang thai bao gồm:
                    <br />
                    - Tăng tuần hoàn máu, điều này có thể làm da bạn trở nên hồng hào hơn và cảm thấy tươi tắn.
                    <br />
                    - Thay đổi hormone gây ra các vết sạm da, nám, và mụn trứng cá.
                    <br />
                    - Tử cung mở rộng dẫn đến bụng to ra.
                    <br />
                    - Sự thay đổi ở ngực để chuẩn bị cho quá trình sản xuất sữa.
                    <br />
                    - Táo bón và ợ nóng.
                    <br />
                    Cơ thể bạn đang làm việc chăm chỉ để đảm bảo sự phát triển an toàn và khỏe mạnh của em bé.
                </>
            ),
        },
        {
            header: "Bạn nên tăng bao nhiêu cân khi mang thai?",
            content: (
                <>
                    Để tính toán bạn nên tăng bao nhiêu cân khi mang thai, điều quan trọng là phải bắt đầu với chỉ số BMI (chỉ số khối cơ thể) của bạn.
                    <br />
                    Thiếu cân: tăng khoảng 12-18kg
                    <br />
                    Cân nặng bình thường: tăng khoảng 11-12kg
                    <br />
                    Thừa cân: tăng khoảng 7-12kg
                    <br />
                    Béo phì: tăng khoảng 6-10kg
                </>
            ),
        },
        {
            header: "Trong giai đoạn nào bạn sẽ tăng cân nhiều nhất?",
            content: (
                <>
                    Nhiều phụ nữ tăng cân nhiều nhất vào khoảng tam cá nguyệt thứ ba. Đây là lúc thai nhi thường tích lũy cân nặng nhiều nhất trong thời gian này.
                </>
            ),
        },
        {
            header: "Bạn nên tăng bao nhiêu cân mỗi tuần khi mang thai?",
            content: (
                <>
                    Thông thường, bạn nên tăng 0.9 kg - 1.3 kg trong tam cá nguyệt đầu tiên;
                    và 0.45 kg mỗi tuần trong hai tam cá nguyệt còn lại.
                </>
            ),
        },
        {
            header: "Tham khảo",
            content: (
                <>
                    Tăng cân khi mang thai <br />
                    <a className="underline" href="https://www.nhs.uk/pregnancy/related-conditions/common-symptoms/weight-gain/#:~:text=">https://www.nhs.uk/pregnancy/related-conditions/common-symptoms/weight-gain/#:~:text=</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Tăng cân khi mang thai <br />
                    <a className="underline" href="https://www.cdc.gov/reproductivehealth/maternalinfanthealth/pregnancy-weight-gain.htm">https://www.cdc.gov/reproductivehealth/maternalinfanthealth/pregnancy-weight-gain.htm</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Tăng cân khi mang thai <br />
                    <a className="underline" href="https://www.pregnancybirthbaby.org.au/weight-gain-in-pregnancy">https://www.pregnancybirthbaby.org.au/weight-gain-in-pregnancy</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Các giai đoạn mang thai <br />
                    <a className="underline" href="https://www.womenshealth.gov/pregnancy/youre-pregnant-now-what/stages-pregnancy">https://www.womenshealth.gov/pregnancy/youre-pregnant-now-what/stages-pregnancy</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Tăng cân khi mang thai <br />
                    <a className="underline" href="https://www.acog.org/clinical/clinical-guidance/committee-opinion/articles/2013/01/weight-gain-during-pregnancy#:-:text=">https://www.acog.org/clinical/clinical-guidance/committee-opinion/articles/2013/01/weight-gain-during-pregnancy#:-:text=</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                </>
            ),
        }
    ],
    result: [
        {
            header: "Bạn nên tăng bao nhiêu cân khi mang thai?",
            content: (
                <>
                    Viện Y học đã phát triển các hướng dẫn cho việc tăng cân lành mạnh trong thai kỳ được các chuyên gia y tế trên toàn thế giới tuân theo. Đối với phụ nữ thừa cân trước khi mang thai (BMI từ 25 đến 29.9), họ nên tăng từ 15 đến 25 pound (khoảng 6.8 đến 11.3 kg) trong suốt thai kỳ.
                </>
            ),
        },
        {
            header: "Thừa cân ảnh hưởng đến thai kỳ như thế nào?",
            content: (
                <>
                    Thừa cân trong thời gian mang thai có thể khiến bạn gặp rủi ro về các biến chứng sức khỏe như cao huyết áp, tiền sản giật và tiểu đường thai kỳ. Nó cũng có thể làm tăng nguy cơ phát triển bệnh tiểu đường loại 2 và cao huyết áp sau này trong cuộc sống.
                    <br />
                    Trẻ em sinh ra từ những phụ nữ có các vấn đề sức khỏe này cũng có nguy cơ cao mắc các dị tật bẩm sinh, sinh non hoặc sinh mổ.
                </>
            ),
        }
    ]
};

const dataPanel = {
    childrenGrowth,
    estimateDate,
    weightOfPregnant,
}

export default dataPanel