import React from 'react';
import { Modal, Form, Input } from 'antd';
import { toast } from 'react-toastify';
import { updateEntity } from '../../../../api/odataClients/momClient';

const MomHealthModal = ({ visible, onClose, onSuccess, initialValues }) => {
    const [form] = Form.useForm();
    if (!visible) return <></>

    const handleUpdate = async (value) => {
        try {
            const momHealth = {
                Id: initialValues.Id,
                Height: Number(value.Height),
                Weight: Number(value.Weight),
                BloodPressure: Number(value.BloodPressure),
                BMIIndex: Number(calculateBMIIndex(Number(value.Height), Number(value.Weight)))
            }

            await updateEntity("momhealths", momHealth.Id, momHealth)
            onSuccess("Cập nhập Thông tin thành công", "Mom");
            onClose();
        } catch (error) {
            toast.error(error.message)
        }
    }

    const calculateBMIIndex = (Height, Weight) => {
        const heightInMeters = Height / 100;
        return (Weight / (heightInMeters * heightInMeters)).toFixed(2);
    }

    const handleSubmit = () => {
        form.validateFields().then(handleUpdate);
    };

    return (
        <Modal
            title="Chỉnh sửa thông số của mẹ"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Cân nặng (KG)"
                    name="Weight"
                    rules={[
                        { required: true, message: 'Vui lòng nhập cân nặng!' },
                    ]}
                >
                    <Input placeholder="Nhập cân nặng" type="number" />
                </Form.Item>

                <Form.Item
                    label="Chiều cao (cm)"
                    name="Height"
                    rules={[
                        { required: true, message: 'Vui lòng nhập chiều cao!' },
                    ]}
                >
                    <Input placeholder="Nhập chiều cao" type="number" />
                </Form.Item>

                <Form.Item
                    label="Huyết áp (mmHg)"
                    name="BloodPressure"
                    rules={[
                        { required: true, message: 'Vui lòng nhập huyết áp!' },
                    ]}
                >
                    <Input placeholder="Nhập huyết áp" type="number" />
                </Form.Item>

                <Form.Item>
                    <button
                        className="h-[40px] px-2 bg-pinkColor text-white text-bold rounded-lg text-[16px]"
                        type="submit"
                    >
                        Lưu
                    </button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default MomHealthModal;
