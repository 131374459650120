import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tag } from 'antd';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AdminLayout from '../../../components/Admin/AdminLayout';
import { fetchEntities, updateEntity } from "../../../api/odataClients/adminClient";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { bodyEmailReplyFeedback } from '../../../assets/data/Email/BodyEmailReplyFeedback';
import axios from 'axios';
import { API_URL_PORT } from '../../../api/axiosInstance';

export const FeedbackDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFeedback = async () => {
      try {
        const response = await fetchEntities(`feedbacks(${id})`, { select: ['Id', 'Name', 'CreatedAt', 'Reply', 'Email'] });
        setFeedback(response);
        setValue(response.Reply || '');
        setLoading(false);
      } catch (error) {
        toast.error('Hệ thông đang bận, vui lòng thử lại sau.');
        setLoading(false);
      }
    }
    loadFeedback();
  }, [id]);

  const handleSend = async () => {
    try {
      const updateFeedback = { ...feedback, Reply: value };
      await updateEntity('feedbacks', feedback.Id, updateFeedback);
      const dataEmail = {
        to: feedback.Email,
        subject: "REPLY FEEDBACK - MOMCHILD CARE SYSTEM",
        body: bodyEmailReplyFeedback("Admin MCC", updateFeedback.Name, updateFeedback.Reply)
      }
      const urlSendEmail = process.env.NODE_ENV === 'development' ? "https://momchild.life/api/email/sendsupportemail" : `${API_URL_PORT}/api/email/sendsupportemail`
      await axios.post(urlSendEmail, dataEmail)

      toast.success('Tạo phản hồi thành công!');
      navigate('/admin/feedback');
    } catch (error) {
      toast.error('Lỗi hệ thông phản hồi');
    }
  };

  return (
    <AdminLayout>
      <LoadingPage isLoading={loading} />
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <Button type="link" onClick={() => navigate(-1)}><ArrowLeftOutlined />Quay lại</Button>
        </div>
        <div className="mb-10 text-center bg-gray-50 shadow-md py-8 rounded-3xl mx-[20%] space-y-5 text-xl">
          <p>Người gửi: <strong>{feedback?.Name}</strong></p>
          <p>Ngày: <strong>{dayjs(feedback?.CreatedAt).format("YYYY-MM-DD")}</strong> | Trạng thái: <Tag color={feedback?.Reply === null ? 'red' : 'green'}>{feedback?.Reply === null ? 'Chưa trả lời' : 'Đã trả lời'}</Tag></p>
        </div>

        <ReactQuill
          value={value}
          onChange={setValue}
          theme="snow"
          placeholder="Write your response here..."
        />
        <div className="mt-4 text-center">
          {feedback?.Reply !== 'Done' && (
            <Button
              type="primary"
              className='bg-pinkColor text-lg font-semibold py-5 px-[100px] hover:bg-pinkColor'
              onClick={handleSend}
            >
              Gửi
            </Button>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default FeedbackDetail;