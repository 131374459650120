import { Form, Input, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { createEntity, fetchEntities } from '../../../api/odataClients/caregiverClient';
import axios from 'axios';
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from '../../../api/axiosInstance';
import ReactQuill from 'react-quill';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

export const AddNewService = ({ show, onClose, onSuccess }) => {
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm()
    const [caregiverType, setCaregiverType] = useState()

    const laodCaregiverType = async () => {
        const response = await fetchEntities("careservicetypes", {})
        setCaregiverType(response.value)
    }

    useEffect(() => {
        laodCaregiverType()
    }, [])

    if (!show) return <></>;

    const hanldeSubmit = async (value) => {
        let careService = {
            ...value,
            Price: Number(value.Price) * 1000,
            CreatedByID: ACCOUNT_ID(),
            IsActive: true
        }
        delete careService.images

        const uploadedImageUrls = [];
        let imageUrl = null;
        const urlUploadImage = process.env.NODE_ENV === 'development'
            ? "https://momchild.life/api/s3/uploadimage"
            : `${API_URL_PORT}/api/s3/uploadimage`;

        try {

            for (let i = 0; i < fileList.length; i++) {
                const singleUpload = new FormData();
                singleUpload.append('image', fileList[i].originFileObj);

                const respUploadImage = await axios.post(urlUploadImage, singleUpload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${TOKEN_API}`
                    }
                });

                const imageUrl = respUploadImage.data.data.imageUrl;
                uploadedImageUrls.push(imageUrl);
            }
            imageUrl = uploadedImageUrls.join(" - ")
            careService = { ...careService, ImageUrl: imageUrl };

            await createEntity("careservices", careService);
            onSuccess()
        } catch (error) {
            toast.error(`Lỗi: ${error.message}`);
        }
    }

    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList);
        if (fileList.length > 0) {
            form.validateFields(['images']);
        }
    };

    const handleBeforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        const isUnder5MB = file.size / 1024 / 1024 < 5;
        if (!isImage) {
            toast.error('Chỉ được tải ảnh lên.');
        }

        if (!isUnder5MB) {
            toast.error('Kích thước ảnh phải nhỏ hơn 5MB.');
            return Upload.LIST_IGNORE;
        }

        return isImage || Upload.LIST_IGNORE;
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white h-[auto] w-[80vw] p-10 rounded-lg shadow-lg relative">
                <h3 className='text-3xl text-center font-bold pb-4 text-pinkColor'>Thêm dịch vụ</h3>
                <button className="absolute top-2 right-2" onClick={onClose}>
                    <FaRegTimesCircle className='w-6 h-6 m-2' />
                </button>
                <Form form={form} onFinish={hanldeSubmit} layout='vertical'>
                    <div className='flex justify-center'>
                        <Form.Item
                            label="Loại dịch vụ"
                            name="CareServiceTypeID"
                            className='w-[50%] mb-0'
                            rules={[{ required: true, message: 'Hãy chọn loại dịch vụ' }]}
                        >
                            <Select
                                className={`w-2/4 border rounded-lg`}
                                defaultValue="Loại dịch vụ"
                                style={{ border: 'none !important', outline: 'none !important' }}>
                                {caregiverType.map((value, _) => {
                                    return (
                                        <Option value={value.Id} className="">
                                            <strong>{value.Name}</strong>
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='flex justify-center'>
                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập tên dịch vụ' }]}
                            className='mb-1 w-[50%]'
                            name="Title"
                            label="Tên dịch vụ"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Tên dịch vụ "
                            />
                        </Form.Item>
                    </div>

                    <div className='flex justify-center'>
                        <Form.Item
                            label="Giá dịch vụ"
                            rules={[{ required: true, message: 'Hãy nhập giá dịch vụ' }]}
                            className='mb-0 w-[50%]'
                            name="Price"
                        >
                            <Input
                                className="w-full"
                                placeholder="Giá dịch vụ "
                                addonAfter=".000 VND / Giờ"
                            />
                        </Form.Item>
                    </div>
                    <div className='flex justify-center'>
                        <Form.Item
                            name="images"
                            className='mb-0 mt-3 w-[50%]'
                            rules={[{ required: true, message: 'Hãy thêm ảnh' }]}
                            validateTrigger="onChange"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={handleBeforeUpload}
                                onChange={handleUploadChange}
                                multiple
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                accept="image/*"
                            >
                                {fileList.length < 5 && <div><PlusOutlined /><div>Upload</div></div>}
                            </Upload>
                        </Form.Item>
                    </div>
                    <Form.Item
                        className='mb-0 mt-3 w-full'
                        name="Description"
                        rules={[{ required: true, message: 'Hãy thêm mô tả dịch vụ của bạn' }]}
                    >
                        <ReactQuill
                            className='h-[100px] mb-10'
                            placeholder="Write your response here..."
                        />
                    </Form.Item>

                    <div className='flex gap-4 items-center justify-center p-2 mx-4'>
                        <button onClick={onClose} className='bg-pinkColor px-14 mt-4 py-3 rounded-lg text-[18px] text-white hover:opacity-70'>Huỷ</button>
                        <button type="submit" className='bg-white px-14 mt-4 py-3 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>Thêm dịch vụ</button>

                    </div>
                </Form>
            </div>
        </div>
    );
};
