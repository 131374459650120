import React, { useEffect, useState } from 'react'
import CaregiverLayout from '../../../components/Caregiver/CaregiverLayout'
import { MyCalendar } from '../../../components/Schedule/Calender/MyCalender'
import { fetchEntities } from '../../../api/odataClients/caregiverClient';
import dayjs from 'dayjs';
import { ACCOUNT_ID } from '../../../api/axiosInstance';

export const CaregiverSchedule = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const checkHour = (date, inHour) => {
    if (!(date.hour() === 0 && date.minute() === 0)) {
      date = date.add(inHour, 'hour');
    }

    return date.toDate();
  }

  const loadingEvent = async () => {
    const response = await fetchEntities("myschedules", {
      filter: `AccountId eq ${ACCOUNT_ID()}`
    });
    const rspEvents = response.value
    if (rspEvents && rspEvents.length > 0) {
      let arrayEvents = []
      for (let index = 0; index < rspEvents.length; index++) {
        const rspEvent = {
          id: rspEvents[index].Id,
          date: dayjs(rspEvents[index].DoAt).format("YYYY-MM-DD"),
          start: dayjs(rspEvents[index].DoAt).toDate(),
          end: checkHour(dayjs(rspEvents[index].DoAt), rspEvents[index].InHour),
          title: rspEvents[index].Title,
          // startTime: dayjs(rspEvents[index].StartTime).format("HH:mm a"),
          // endTime: dayjs(rspEvents[index].EndTime).format("HH:mm a"),
          content: rspEvents[index].Content,
          status: rspEvents[index].Status ? "Completed" : "Pending",
          InHour: rspEvents[index].InHour
        };
        arrayEvents.push(rspEvent)
      }
      setEvents(arrayEvents)
    }
  }

  useEffect(() => {
    loadingEvent()
  }, [])

  const hanldleShowDateWorking = (value)=>{
    const date = value.format("DD/MM/YYYY")
    const time = `${value.format("HH:mm")} - ${value.add(selectedEvent.InHour, 'hours').format("HH:mm")}`
    return <>
      <p>{date}</p> {time}
    </>
  } 

  return (
    <div>
      <CaregiverLayout>
        <div className="flex">
          {/* Left Column: Calendar */}
          <div className="w-8/12">
            <h1 className="font-bold text-3xl mb-3 ml-10">Lịch trình</h1>
            <MyCalendar events={events} onSelectEvent={handleSelectEvent} />
          </div>

          {/* Right Column: Event Details */}
          <div className="w-4/12 border-l p-4">
            {selectedEvent ? (
              <div>
                <table className="min-w-full table-auto">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left">Thời gian</th>
                      <th className="px-4 py-2 text-left">Nội dung</th>
                      <th className="px-4 py-2 text-left">Trạng thái</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2 w-[34%]">
                        {hanldleShowDateWorking(dayjs(selectedEvent.start))}
                      </td>
                      <td className="border px-4 py-2" style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '180px'}}>{selectedEvent.title}</td>
                      <td className="border px-4 py-2" >{selectedEvent.status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <p>Không có sự kiện nào.</p>
            )}
          </div>
        </div>
      </CaregiverLayout>
    </div>
  )
}
