import React, { useState, useEffect } from "react";
import { Col, Form, Input } from "antd";
import { AiOutlinePicture } from "react-icons/ai";
import { FaRegTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { ACCOUNT_ID, API_URL_PORT } from "../../api/axiosInstance";
import LoadingPage from "../LoadingPage/LoadingPage";
import { SafeHtml } from "./SafeHTMLText";
import dayjs from "dayjs";

const ChatMessageList = ({
  selectedConversation,
  setMessagesData,
  account,
  token,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [conversationMessages, setConversationMessages] = useState()
  const [imageUpload, setImageUpload] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());
  const [loadedImages, setLoadedImages] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let messagesEndRef = React.createRef();

  const loadDataUpdateReaded = () => {
    setMessagesData((prevMessages) =>
      prevMessages.map((message) => 
        message.GroupID === selectedConversation 
          ? { ...message, IsRead: true }
          : message
      )
    );
  };

  const handleReplaceName = (nameGroup) => {
    let finalName = nameGroup?.replaceAll(", ", "");
    try {
      finalName = finalName.replaceAll(account.FullName, "");
    } catch (error) { }
    return finalName;
  };

  const loadConversation = async () => {
    const rspMessage = await axios.get(`${API_URL_PORT}/odata/messages?$filter=GroupID eq ${selectedConversation}&$expand=CreatedBy($select=FullName,Id),Group&$orderby=Id`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    setConversationMessages(rspMessage.data.value)
    if(rspMessage.data.value.length > 0 && rspMessage.data.value){
      for (let index = 0; index < rspMessage.data.value.length; index++) {
        let element = rspMessage.data.value[index];
        element.IsRead = true;
        await axios.put(`${API_URL_PORT}/odata/messages(${element.Id})`, {...element}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      }
      loadDataUpdateReaded()
    }
  };

  useEffect(() => {
    if (selectedConversation) {
      loadConversation();
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedConversation]);

  const loadImages = async () => {
    const newLoadedImages = {};
    if (conversationMessages) {
      for (let index = 0; index < conversationMessages?.length; index++) {
        const message = conversationMessages[index];
        if (message.ImageUrl && !loadedImages[message.ImageUrl]) {
          try {
            const urlAPI =
              process.env.NODE_ENV === "development"
                ? `https://momchild.life/api/s3/getimage?url=${message.ImageUrl}`
                : `${API_URL_PORT}/api/s3/getimage?url=${message.ImageUrl}`;
            const response = await axios.get(urlAPI);
            newLoadedImages[message.ImageUrl] = response.data.data.imageUrl;
          } catch (error) {
            toast.error("Failed to load image: " + error.message);
          }
        }
      }
    }
    setLoadedImages((prevImages) => ({ ...prevImages, ...newLoadedImages }));
  };

  useEffect(() => {
    loadImages();
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationMessages]);

  const handleSubmit = async (value) => {
    setIsLoading(true)
    let imageUrl = null;

    if (imageUpload) {
      const urlAPI =
        process.env.NODE_ENV === "development"
          ? "https://momchild.life/api/s3/uploadimage"
          : `${API_URL_PORT}/api/s3/uploadimage`;
      const respUploadImage = await axios.post(urlAPI, imageUpload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      imageUrl = respUploadImage.data.data.imageUrl;
    }

    value = { ...value, imageUrl: imageUrl };
    onSubmit(value);
    setIsLoading(false)
    setImageUpload(null);
    form.resetFields(["mess"]);
    const newMessage = {
      GroupID: selectedConversation,
      Content: value.mess,
      CreatedByID: ACCOUNT_ID(),
      ImageUrl: value.imageUrl,
      IsMessageSys: false,
      CreatedAt: dayjs().toISOString(),
      CreatedBy:{
        Id: ACCOUNT_ID()
      }
    };
    setConversationMessages((prev)=>([...prev, newMessage]))
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/") && file.size <= 5 * 1024 * 1024) {
      const formData = new FormData();
      formData.append("image", file);
      setImageUpload(formData);
    } else {
      toast.error("Hãy chọn file dưới 5MB.");
    }
  };

  const handleRemoveImage = () => {
    setImageUpload(null);
    setInputKey(Date.now());
  };

  return (
    <Col flex={4} className="flex flex-col">
      <LoadingPage isLoading={isLoading} />
      <div className="w-full h-[10%] border shadow-md rounded-tr-lg flex items-center justify-center">
        {conversationMessages && selectedConversation ? (
          <h2 className="text-center font-bold text-2xl">
            {handleReplaceName(conversationMessages[0]?.Group?.Name)}
          </h2>
        ) : (
          <h2 className="text-center font-bold text-2xl">
            Chọn một cuộc trò chuyện
          </h2>
        )}
      </div>
      <div className="flex-grow flex p-4 overflow-y-auto overflow-x-hidden h-[400px]">
        <div key={selectedConversation} className="flex-col w-full">
          {conversationMessages && conversationMessages?.map((message, index) => (
            <div
              key={index}
              className={`flex w-full ${message.CreatedBy?.Id === account.Id
                ? "justify-end"
                : "justify-start"
                }`}
            >
              <div
                className={`${message.CreatedBy?.Id === account.Id
                  ? "bg-pinkColor text-white"
                  : "bg-[#EAEAEA] text-black"
                  } max-w-lg p-3 rounded-xl mb-3 overflow-hidden whitespace-normal`}
              >
                {message.ImageUrl ? (
                  <>
                    {message?.Content && <p className="m-0 mb-2">{message?.Content}</p>}
                    <img
                      src={loadedImages[message.ImageUrl]}
                      alt="messageImage"
                      className="max-w-full h-auto rounded"
                    />
                  </>
                ) : <>
                  {message.IsMessageSys ? <SafeHtml content={message?.Content} isListMessage={true} /> : message?.Content}
                </>
                }
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div>
        {selectedConversation && (
          <Form form={form} onFinish={handleSubmit} className="border w-full pl-2 pr-2">
            {imageUpload !== null && (
              <label className="bg-red w-full top-10 flex items-center justify-end">
                1 image
                <button className="flex justify-end ml-5 mr-5" onClick={handleRemoveImage}>
                  <FaRegTimesCircle className="w-6 h-6 m-2" />
                </button>
              </label>
            )}
            <div className="flex w-full mt-2">
              <Form.Item name="img" style={{ margin: "3px 0 0 0", padding: "0 " }}>
                <label htmlFor="file-input">
                  <AiOutlinePicture className="w-8 h-8 cursor-pointer" />
                </label>
              </Form.Item>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
                key={inputKey}
              />
              <Form.Item
                name="mess"
                className="w-full mr-4 p-0"
                style={{ margin: 0, padding: "0 10px 10px 0" }}
              >
                <Input
                  className="rounded-3xl bg-[#EAEAEA] hover:bg-[#EAEAEA] ml-2 mr-2 text-[16px]"
                  placeholder="Nhập tin nhắn"
                />
              </Form.Item>
              <Form.Item className="p-0" style={{ margin: 0, padding: "0 10px" }}>
                <button
                  type="submit"
                  className="px-3 py-1 rounded-3xl bg-pinkColor text-white hover:bg-pinkColor hover:text-white hover:opacity-90 border-none text-[17px]"
                >
                  Gửi
                </button>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
    </Col>
  );
};

export default ChatMessageList;