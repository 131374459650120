import axios from "axios";
import { API_URL_PORT } from "./axiosInstance";

let addressUrl = `${API_URL_PORT}/api/address`

export const getProvinces = async () => {
    let response = await axios.get(`${addressUrl}/provinces`);
    return response.data;
}

export const getDistricts = async (province) => {
    let response = await axios.get(`${addressUrl}/districts`, {
        params: {
            province: String(province)
        }
    });
    return response.data;
}

export const getWards = async (province, district) => {
    let response = await axios.get(`${addressUrl}/wards`, {
        params: {
            province: String(province),
            district: String(district)
        }
    });
    return response.data;
}