import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { API_URL_PORT, TOKEN_API } from '../../api/axiosInstance';

const NotificateSignalR = ({onNotificate, onNotificateChat, children}) => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        let connect = null;
        if (!!TOKEN_API) {
            connect = new signalR.HubConnectionBuilder()
                .withUrl(`${API_URL_PORT}/hub/chat`, {
                    accessTokenFactory: () => TOKEN_API
                })
                .configureLogging(signalR.LogLevel.None)
                .withAutomaticReconnect()
                .build();
        }
        setConnection(connect);
    }, []);

    useEffect(() => {
        if(connection) {
            connection
                .start().catch((err) => console.error('Error connecting to SignalR hub:', err));
        }
    }, [connection]);

    useEffect(() => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            if (!!onNotificate) connection.on('Notificate', response => onNotificate(response));
            if (!!onNotificateChat) connection.on('NotificateChat', response => onNotificateChat(response));
        }
    }, [connection, onNotificate, onNotificateChat]);

    return (
        <>{children}</>
    );
};

export default NotificateSignalR;