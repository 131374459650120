import React, { useEffect, useRef, useState } from 'react'
import CaregiverLayout from '../../../components/Caregiver/CaregiverLayout'
import { Input, Pagination, Radio, Select, Table, Tag } from 'antd';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { createEntity, fetchEntities, updateEntity } from '../../../api/odataClients/caregiverClient';
import { FaCheckCircle, FaClipboardList, FaSyncAlt, FaTimesCircle } from 'react-icons/fa';
import CustomModal from '../../../components/Common/CustomModalConfirm';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { BsChatDots } from 'react-icons/bs';
import { ACCOUNT_ID } from '../../../api/axiosInstance';
const { Option } = Select;
export const CaregiverOrder = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statistical, setStatistical] = useState({
    waiting: 0,
    completed: 0,
    cancel: 0,
  });
  const [loading, setLoading] = useState(true);
  const [valueFilter, setValueFilter] = useState("")
  const [myWallet, setMyWallet] = useState()
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
  const [openPopupAccountInfor, setOpenPopupAccountInfor] = useState(false)
  const [accountInfor, setAccountInfor] = useState()
  const [price, setPrice] = useState()
  const [selectedBooking, setSelectedBooking] = useState()
  const [enoughtPoint, setEnoughtPoint] = useState(false)
  const [service, setService] = useState();
  const [selectedService, setSelectedService] = useState();
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [reasonCancel, setReasonCancel] = useState();

  const loadEntities = async (searchText) => {
    setLoading(true);

    try {
      const respCareServiceBooking = await fetchEntities("careservicebookings", {
        filter: `CareService/CreatedByID eq ${ACCOUNT_ID()} ${valueFilter ? ` and ${valueFilter}` : ""} ${selectedService ? ` and CareService/Id eq ${selectedService}` : ""}`,
        expand: [`CareService($select=Title,Price,CareServiceTypeID,CreatedByID,Id),Account($select=FullName,Id,CurrentAddress)`],
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ['Id desc']
      });
      setServices(respCareServiceBooking.value)
      setDataLength(respCareServiceBooking['@odata.count']);

      loadStatic()


    } catch (err) {
      toast(err);
    } finally {
      setLoading(false)
    }
  };

  const fecthWalletAndtype = async () => {
    const rpsWallet = await fetchEntities("wallets", { filter: `AccountId eq ${ACCOUNT_ID()}`, select: ["Money", "Id"] })
    setMyWallet(rpsWallet.value[0])

    const rspServiceType = await fetchEntities("careservices", { filter: `CreatedByID eq ${ACCOUNT_ID()}` })
    setService(rspServiceType.value)
  }

  const loadStatic = async () => {
    const respCareServiceBooking = await fetchEntities("careservicebookings", {
      expand: [`CareService($select=CreatedByID)`],
      filter: `CareService/CreatedByID eq ${ACCOUNT_ID()}`,
      count: true
    });
    console.log("test", respCareServiceBooking)

    const waiting = respCareServiceBooking.value.filter((item) => item.Status === 'Đang chờ').length;
    const completed = respCareServiceBooking.value.filter((item) => item.Status === 'Hoàn thành').length;
    const cancel = respCareServiceBooking.value.filter((item) => item.Status === 'Đã huỷ').length;

    setStatistical({
      waiting: waiting,
      completed: completed,
      cancel: cancel,
      total: respCareServiceBooking['@odata.count']
    });
  }

  useEffect(() => {
    setLoading(true)
    loadEntities();
  }, [currentPage, pageSize, selectedService, valueFilter]);

  useEffect(() => {
    fecthWalletAndtype()
  }, []);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleFilterSelection = (e) => {
    const { value } = e.target;
    setValueFilter(value)
    console.log("test: ", valueFilter)
  }

  const handleChangeStatusOrder = (value, recordOrder) => {
    setSelectedBooking(recordOrder)
    if (value === "Xác nhận") {
      setOpenPopupConfirm(true)
      setPrice(Math.ceil((recordOrder.CareService.Price / 1000) * 0.1))
      setEnoughtPoint(Math.ceil((recordOrder.CareService.Price / 1000) * 0.1) < myWallet?.Money)
      handleViewAccountInfor(recordOrder, false)
      return;
    }

    changeStatusBooking(value, recordOrder)
  };

  const selectBookingCancel = (booking) => {
    setSelectedBooking(booking)
    setConfirmCancel(true)
  }

  const changeStatusBooking = async (value, recordOrder) => {
    const OrderId = recordOrder.Id
    try {
      let careservicebooking = { Id: OrderId, Status: value, RemainAmount: recordOrder.RemainAmount }
      if (value === "Hoàn thành") {
        careservicebooking.RemainAmount = 0
      }
      await updateEntity('careservicebookings', careservicebooking.Id, careservicebooking);
      toast.success('Cập nhập trạng thái thành công')
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Cập nhập thất bại')
    }
    loadEntities()
  }

  const handleViewAccountInfor = async (record, viewPopup = true) => {
    try {
      const respAccount = await fetchEntities(`accounts(${record.Account.Id})`, {
        select: ['Id', 'FullName', 'PhoneNumber', 'Email', 'CurrentAddress'],
      });
      setAccountInfor({ ...respAccount, Address: record.Address })
      setOpenPopupAccountInfor(viewPopup)
    } catch (error) {
      toast.error("lỗi lấy thông tin người dùng")
    }
  }

  const handleConfirmModal = async () => {
    if (!enoughtPoint) {
      navigate("/caregiver/my-wallet", { state: { RequestDeposit: true } })
    } else {
      try {
        changeStatusBooking("Xác nhận", selectedBooking)
        await updateEntity("wallets", myWallet.Id, { Id: myWallet.Id, Money: myWallet.Money - price })
        await createEntity("notifications", {
          AccountID: accountInfor?.Id,
          Content: `Dịch vụ: ${selectedBooking.CareService.Title} của bạn đã được chấp thuận`,
          CreatedAt: dayjs(),
          IsRead: false
        })
        await createEntity("myschedules", {
          AccountID: ACCOUNT_ID(),
          Title: `${selectedBooking.CareService.Title}`,
          Content: `${selectedBooking.CareService.Title}`,
          DoAt: dayjs(selectedBooking.BookingAt).toISOString(),
          Status: false,
          InHour: selectedBooking.InHour
        })
        setOpenPopupConfirm(false)
        loadEntities()
      } catch (error) {
        toast.error("lỗi xác nhận dịch vụ")
      }
    }
  }

  const handleFilterService = async (value) => {
    const id = Number(value)
    setSelectedService(id);
    console.log(id)
  }
  const checkDateOver24h = (date) => {
    const now = dayjs();
    const givenDate = dayjs(date);

    return givenDate.diff(now, 'hour') > 24;
  };

  const debounceDropDown = useRef(
    debounce((searchValue) => {
      loadEntities(searchValue)
    }, 500)
  ).current;

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    debounceDropDown(searchValue);
  };

  React.useEffect(() => {
    return () => {
      debounceDropDown.cancel();
    };
  }, [debounceDropDown]);

  const checkGroupChat = async (momId) => {
    try {
      const dataAccount = await fetchEntities('groupmembers', {
        filter: `AccountId eq ${ACCOUNT_ID()}`,
        select: ["GroupID"]
      });
      const accountGroupIDs = dataAccount.value.map(message => message.GroupID);

      const dataCaregiver = await fetchEntities('groupmembers', {
        filter: `AccountId eq ${momId}`,
        select: ["GroupID"]
      });
      const caregiverGroupIDs = dataCaregiver.value.map(message => message.GroupID);

      const commonGroupID = accountGroupIDs.find(groupID => caregiverGroupIDs.includes(groupID));
      const chatBoxExists = Boolean(commonGroupID);
      navigate('/caregiver/chatting', { state: { commonGroupID: commonGroupID } })
    } catch (err) {
      toast.error("Kiểm ta hộp thoại");
    }
    return null
  }

  const handleConfirmCancel = async () => {
    try {
      const bookingUpdate = { ...selectedBooking, ReasonCancel: reasonCancel, Status: "Đã huỷ" }
      delete bookingUpdate.CareService
      await updateEntity("careservicebookings", bookingUpdate.Id, { ...bookingUpdate })
      loadEntities()
      setConfirmCancel(false)
    } catch (error) {
      toast.error("Lỗi hệ thống, vui lòng thử lại sau")
    }
  }

  const columns = [
    {
      title: 'Người đặt', dataIndex: 'Account', key: 'Account',
      render: (Account) => (Account.FullName ? Account.FullName : 'No Data')
    },
    {
      title: 'Tên dịch vụ', dataIndex: 'CareService', key: 'CareService',
      render: (CareService, record) => (CareService.Title ? <a style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '100px' }} href={`/caregiver/service/${record.Id}`}>{CareService.Title}</a> : 'No Data')
    },
    {
      title: 'Đặt vào ngày', dataIndex: 'BookingAt', key: 'BookingAt',
      render: (date) => {
        const bookingAt = dayjs(date).format("DD/MM/YYYY")
        return bookingAt
      }
    },
    {
      title: 'Từ', dataIndex: 'BookingAt', key: 'BookingAt',
      render: (date, record) => {
        const bookingAt = dayjs(record.BookingAt).format("HH:mm")
        return bookingAt
      }
    },
    {
      title: 'Đến', dataIndex: 'BookingAt', key: 'BookingAt',
      render: (date, record) => {
        const bookingAt = dayjs(record.BookingAt).add(record.InHour, 'hour').format("HH:mm")
        return bookingAt
      }
    },
    {
      title: 'Địa chỉ', dataIndex: 'Account', key: 'Account',
      render: (acc, record) => {
        const splitAddress = record.Address.split('-')
        const currentAddress = [splitAddress[1], splitAddress[2], splitAddress[3]].join(" - ")
        return (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '200px' }} >
            {currentAddress}
          </div>
        )
      }
    },
    {
      title: 'Trạng thái', dataIndex: 'Status', key: 'Status',
      render: (status, record) => {
        const color = status === "Đang chờ" ? "yellow" : status === "Xác nhận" ? "blue" : status === "Hoàn thành" ? "green" : "red"
        return <Tag className='w-[100%]' color={color} >
          <strong>{status}</strong>
        </Tag>
      }
    },
    {
      title: 'Hành động',
      render: (_, record) => {
        const status = record.Status
        let render = <></>
        if (status === "Đang chờ") {
          render = <>
            {render}
            <button onClick={() => handleChangeStatusOrder('Xác nhận', record)} className='w-full bg-white px-3 rounded-lg text-[15px] text-blueColor border border-blueColor hover:bg-blueColor hover:text-white'>
              Xác nhận
            </button>
            <button onClick={() => selectBookingCancel(record)} className='w-full flex items-center justify-center mt-3 bg-white px-3 rounded-lg text-[15px] text-[red] border border-[red] hover:bg-[red] hover:text-white'>
              <FaTimesCircle />Từ chối
            </button>
          </>
        }

        if (status === "Xác nhận") {
          render = <>
            {render}
            <button onClick={() => handleChangeStatusOrder('Hoàn thành', record)} className='mr-3 w-full mb-2'>
              <Tag className='w-full text-center p-1 rounded-lg px-3' color='green'>Hoàn thành</Tag>
            </button>
          </>
        }

        if ((status === "Xác nhận" || status === "Hoàn thành") && !checkDateOver24h(record.BookingAt)) {
          render = <>
            {render}
            <button onClick={() => { handleViewAccountInfor(record) }} className='w-full bg-white px-3 rounded-lg text-[15px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>
              Xem thông tin
            </button>
          </>
        }

        return (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '130px' }}>
            {render}
          </div>
        );
      }
    },
    {
      title: 'Trò chuyện', dataIndex: 'Status', key: 'Status',
      render: (status, record) => {
        return <BsChatDots onClick={() => checkGroupChat(record.AccountID)} className='cursor-pointer text-pinkColor text-[25px]' />
      }
    },
  ];
  return (
    <CaregiverLayout>
      <CustomModal isOpenModal={openPopupAccountInfor} handleCancel={() => { setOpenPopupAccountInfor(false) }}
        header="Thông tin người đặt"
        textCancel='Đóng'
        content={(<><div className='w-100'>
          <h2 className="text-[14px] text-black pb-3">Tên đầy đủ: {accountInfor?.FullName}</h2>
          <h2 className="text-[14px] text-black pb-3">Số điện thoại: {accountInfor?.PhoneNumber}</h2>
          <h2 className="text-[14px] text-black pb-3">Email: {accountInfor?.Email}</h2>
          <h2 className="text-[14px] text-black pb-3">Địa chỉ:<br /> {accountInfor?.Address}</h2>
        </div></>)}
      />
      <CustomModal isOpenModal={openPopupConfirm} handleConfirm={handleConfirmModal} handleCancel={() => { setOpenPopupConfirm(false) }}
        textConfirm={enoughtPoint ? "Chấp nhận" : "Nạp điểm"}
        header="Xác nhận dịch vụ"
        textCancel='Huỷ'
        content={(<><h2 className="text-[14px] text-black pb-3">Bạn có chắc chắn muốn xác nhận dịch vụ không?</h2>
          <h2 className="text-[14px] text-black pb-3"><strong>Lưu ý:</strong> Muốn xác nhận dịch vụ cần có phí xác nhận là <strong>10%</strong> phí dịch vụ</h2>
          <h2 className="text-[14px] text-black pb-3">tương đương với {price} điểm MCC</h2>
          {enoughtPoint ?
            <Tag className="text-[14px] text-black pb-3" color='blue'>Điểm của bạn: {myWallet?.Money} điểm</Tag> :
            <Tag className="text-[14px] text-black pb-3" color='red'>Điểm của bạn không đủ: {myWallet?.Money} điểm</Tag>}</>)}
      />
      <CustomModal isOpenModal={confirmCancel} handleConfirm={handleConfirmCancel} handleCancel={() => { setConfirmCancel(false) }}
        textConfirm="Từ chối"
        header="Từ chối nhận dịch vụ"
        textCancel='Đóng'
        content={(<>
          <h2 className="text-[14px] text-black pb-3">Bạn có chắc chắn muốn từ chối nhận dịch vụ không?</h2>
          <Input.TextArea onChange={(e) => setReasonCancel(e.target.value)} />
        </>)}
      />
      <LoadingPage isLoading={loading} />
      <div className='w-full flex justify-center'>
        <Radio.Group onChange={handleFilterSelection} className="grid grid-cols-4 gap-4 mb-10 w-full" defaultValue={valueFilter}>
          <Radio.Button className='shadow rounded p-0' value="" style={{ height: "auto" }}>
            <div className='p-4 bg-[#FFF4DE] rounded-lg h-32'>
              <span className="bg-[#FF947A] p-2 rounded-full flex items-center justify-center w-10 h-10">
                <FaClipboardList className='text-white text-[20px]' />
              </span>
              <p className='text-[18px] mt-2'>Số đơn đặt</p>
              <h2 className='text-[18px] font-medium'>{statistical.total}</h2>
            </div>
          </Radio.Button>

          <Radio.Button className='shadow rounded p-0' value="contains(Status, 'Đang chờ')" style={{ height: "auto" }}>
            <div className='p-4 bg-blue-200 rounded-lg h-32'>
              <span className="bg-blue-500 p-2 rounded-full flex items-center justify-center w-10 h-10">
                <FaSyncAlt className='text-white text-[25px]' />
              </span>
              <p className='text-[18px]'>Đang chờ</p>
              <h2 className='text-[18px] font-medium'>{statistical.waiting}</h2>
            </div>
          </Radio.Button>

          <Radio.Button className='shadow rounded p-0' value="contains(Status, 'Hoàn thành')" style={{ height: "auto" }}>
            <div className='p-4 bg-green-200 rounded-lg h-32'>
              <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-10 h-10">
                <FaCheckCircle className='text-white text-[25px]' />
              </span>
              <p className='text-[18px]'>Hoàn thành</p>
              <h2 className='text-[18px] font-medium'>{statistical.completed}</h2>
            </div>
          </Radio.Button>

          <Radio.Button className='shadow rounded p-0' value="contains(Status, 'Đã Huỷ')" style={{ height: "auto" }}>
            <div className='p-4 bg-[#FFE2E5] rounded-lg h-32'>
              <span className="bg-[#E53E3E] p-2 rounded-full flex items-center justify-center w-10 h-10">
                <FaTimesCircle className='text-white text-[20px]' />
              </span>
              <p className='text-[18px] mt-2'>Đã Huỷ</p>
              <h2 className='text-[18px] font-medium'>{statistical.cancel}</h2>
            </div>
          </Radio.Button>
        </Radio.Group>
      </div>

      <div className="flex justify-between mb-4">
        <h1 className='text-2xl font-semibold'>Đơn đặt dịch vụ</h1>
        <Select
          showSearch
          className="w-[30%] border border-gray-300 text-black bg-white text-large text-center flex items-center justify-between rounded-lg border"
          placeholder="Dịch vụ"
          onChange={handleFilterService}
        >
          <Option
            key={0}
            value={0}
          >
            Tất cả
          </Option>
          {service && service?.map((value, index) => (
            <Option
              key={index + 1}
              value={value.Id}
            >
              {value.Title}
            </Option>
          ))}
        </Select>
        {/* <Input
          className="w-1/4"
          placeholder="Tìm kiếm theo tên dịch vụ..."
          prefix={<SearchOutlined />}
          size="large"
          onChange={handleInputChange}
        /> */}
        <div className='w-1/4'></div>
      </div>

      <Table
        dataSource={services}
        columns={columns}
        pagination={false}
      />

      {/* Pagination */}
      <div className="mt-4 flex justify-end">
        <Pagination
          total={dataLength}
          showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} đơn đặt`}
          pageSize={pageSize}
          current={currentPage}
          onChange={handlePaginationChange}
        />
      </div>
    </CaregiverLayout>
  )
}
