import { Input, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaRedoAlt, FaSearch } from 'react-icons/fa'
import { toast } from 'react-toastify';
import { fetchEntities } from '../../api/odataClients/momClient';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export const SearchSidebar = ({ onResetFilter, setOrderBy, setSelectedCategory, setSearchText, onFilter }) => {
    const [optionType, setOptionType] = useState([]);
    const [defaultPrice, setDefaultPrice] = useState('Sắp xếp theo giá')
    const [defaultCategory, setDefaultCategory] = useState(null)
    const [defaultSearchText, setDefaultSearchText] = useState('')
    const [defaultRatingSort, setDefaultRatingSort] = useState('Sắp xếp theo đánh giá')
    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
        setDefaultCategory(value)
    };

    const handleReset = () => {
        setSearchText('')
        setSelectedCategory('');
        onResetFilter()
        setDefaultPrice('Sắp xếp theo giá')
        setDefaultCategory(null)
        setDefaultRatingSort('Sắp xếp theo đánh giá')
        setDefaultSearchText('')
        setOrderBy(null)
    };

    const loadTypeService = async () => {
        setOptionType([])
        try {
            const typeRsp = await fetchEntities("careservicetypes", {})
            const data = typeRsp.value
            if (data && data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    setOptionType((prev) => ([...prev, { label: element.Name, value: element.Id }]))
                }
            }
        } catch (error) {
            toast.error("Service Type Error")
        }
    }

    const handlePriceChange = (value) => {
        setOrderBy(value)
        setDefaultPrice(value)
    }

    const handleSearchChange = (e) => {
        const value = e.target.value
        setSearchText(value)
        setDefaultSearchText(value)
    }

    const handleRatingChange = (value) => {
        setOrderBy(value)
        setDefaultRatingSort(value)
    }

    useEffect(() => {
        loadTypeService();
    }, [])

    return (
        <div className='p-4 border rounded-lg space-y-6 flex flex-col justify-center'>
            {/* Category Section */}
            <div className='flex justify-center space-x-5'>
                <div>
                    <h2 className='text-lg font-bold'>Chọn danh mục</h2>
                    <Radio.Group block options={optionType} value={defaultCategory} direction="vertical" className='mt-5' onChange={(e) => handleCategoryChange(e.target.value)} />
                </div>
                <div>
                    <h2 className='text-lg font-bold mb-3'>Tìm theo tên</h2>
                    <Input value={defaultSearchText} onChange={handleSearchChange} placeholder='Nhập tên dịch vụ' />
                </div>
                <div>
                    <h2 className='text-lg font-bold mb-3'>Sắp xếp theo giá</h2>
                    <Select className='w-full' onChange={handlePriceChange} value={defaultPrice}>
                        <Select.Option value="Price ">
                            Giá tăng dần <ArrowUpOutlined />
                        </Select.Option>
                        <Select.Option value="Price desc">
                            Giá giảm dần <ArrowDownOutlined />
                        </Select.Option>
                    </Select>
                </div>
                <div>
                    <h2 className='text-lg font-bold mb-3'>Sắp xếp theo đánh giá</h2>
                    <Select className='w-full' onChange={handleRatingChange} value={defaultRatingSort}>
                        <Select.Option value="Rating asc">
                            Đánh giá tăng dần <ArrowUpOutlined />
                        </Select.Option>
                        <Select.Option value="Rating desc">
                            Đánh giá giảm dần <ArrowDownOutlined />
                        </Select.Option>
                    </Select>
                </div>
            </div>

            <div className="flex justify-center w-full space-x-2 w">
                <button
                    className='bg-pinkColor text-white p-2 w-[25%] rounded-full flex items-center justify-center'
                    type="button"
                    onClick={onFilter}
                >
                    <FaSearch className="mr-2" />
                    Tìm kiếm
                </button>

                <button
                    className='bg-slate-300 p-2 w-[25%] rounded-full flex items-center justify-center'
                    type="button"
                    onClick={handleReset}
                >
                    <FaRedoAlt className="mr-2" />
                    Đặt lại
                </button>
            </div>
        </div>
    )
}
