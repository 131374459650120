import axios from 'axios';

export const API_URL_PORT = process.env.REACT_APP_API_URL
export const KYC_URL_PORT = process.env.REACT_APP_KYC_URL
export const KYC_KEY = process.env.REACT_APP_KEY_KYC
export const TOKEN_API = localStorage.getItem('authMomToken') ||
  localStorage.getItem('authCaregiverToken') ||
  localStorage.getItem('authSupporterToken') ||
  localStorage.getItem('authAdminToken') || null;
export const ACCOUNT_ID = ()=>{
  return Number(localStorage.getItem('Id'))
}

const createAxiosInstance = (tokenKey, baseURL = `${API_URL_PORT}/odata`) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(tokenKey);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;
