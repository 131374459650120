import buildQuery from 'odata-query';
import createAxiosInstance, { API_URL_PORT } from '../axiosInstance';

const rootService = `${API_URL_PORT}/odata`;

export const axiosSupporterInstance = createAxiosInstance('authSupporterToken', rootService);

/**
 * Hàm thực hiện các truy vấn GET với các tùy chọn OData.
 * @param {string} entitySet - Tên tập hợp thực thể (entity set) trong OData.
 * @param {object} queryOptions - Các tùy chọn truy vấn OData như filter, select, orderBy, v.v.
 * @returns {Promise<object>} - Dữ liệu trả về từ API.
 */
const fetchEntities = async (entitySet, queryOptions = {}) => {
  try {
    const query = buildQuery(queryOptions);
    const response = await axiosSupporterInstance.get(`/${entitySet}${query}`);
    return response.data; // Trả về dữ liệu
  } catch (error) {
    console.error('Error fetching entities:', error);
    throw error; // Ném lỗi nếu có
  }
};

/**
 * Hàm tạo mới một thực thể.
 * @param {string} entitySet - Tên tập hợp thực thể trong OData.
 * @param {object} data - Dữ liệu của thực thể cần tạo.
 * @returns {Promise<object>} - Dữ liệu thực thể vừa tạo.
 */
const createEntity = async (entitySet, data) => {
  try {
    const response = await axiosSupporterInstance.post(`/${entitySet}`, data);
    return response.data; // Trả về dữ liệu vừa tạo
  } catch (error) {
    console.error('Error creating entity:', error);
    throw error; // Ném lỗi nếu có
  }
};

/**
 * Hàm cập nhật một thực thể.
 * @param {string} entitySet - Tên tập hợp thực thể trong OData.
 * @param {number|string} key - Khóa chính của thực thể cần cập nhật.
 * @param {object} data - Dữ liệu mới của thực thể.
 * @returns {Promise<object>} - Dữ liệu thực thể sau khi cập nhật.
 */
const updateEntity = async (entitySet, key, data) => {
  try {
    const response = await axiosSupporterInstance.put(`/${entitySet}(${key})`, data);
    return response.data; // Trả về dữ liệu đã cập nhật
  } catch (error) {
    console.error('Error updating entity:', error);
    throw error; // Ném lỗi nếu có
  }
};

/**
 * Hàm xóa một thực thể.
 * @param {string} entitySet - Tên tập hợp thực thể trong OData.
 * @param {number|string} key - Khóa chính của thực thể cần xóa.
 * @returns {Promise<void>}
 */
const deleteEntity = async (entitySet, key) => {
  try {
    await axiosSupporterInstance.delete(`/${entitySet}(${key})`); // Gửi yêu cầu xóa
  } catch (error) {
    console.error('Error deleting entity:', error);
    throw error; // Ném lỗi nếu có
  }
};

// Xuất các hàm để sử dụng trong các component khác
export { fetchEntities, createEntity, updateEntity, deleteEntity };
