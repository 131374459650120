import dayjs from "dayjs";

export const CaregiverContractEmailTemplate = (account, signatureData) => {
    return `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        line-height: 1.6;
                        background-color: #fdfdf5;
                    }
                    .container {
                        max-width: 600px;
                        margin: 20px auto;
                        padding: 40px;
                        border: 1px solid #ccc;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    }
                    .contract-section h2 {
                        color: #3498db;
                    }
                    .signature {
                        margin-top: 20px;
                    }
                    .signature img {
                        max-width: 100%;
                        height: auto;
                        border: 1px solid #ccc;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h1 style="text-align: center; text-decoration: underline;">HỢP ĐỒNG CHẤP THUẬN DỊCH VỤ</h1>
                    <p><strong>Tôi tên:</strong> ${account?.FullName}</p>
                    <p><strong>Ngày sinh:</strong> ${dayjs(account?.DateOfBirth).format("DD/MM/YYYY")}</p>
                    <p><strong>Giới tính:</strong> ${account?.Sex ? "Nam" : "Nữ"}</p>
                    <p><strong>Số điện thoại:</strong> ${account?.PhoneNumber}</p>
                    <p><strong>Số CCCD:</strong> ${account?.NumberCard}</p>

                    <p>Tôi đồng ý với các điều khoản sau để sử dụng hệ thống với vai trò là một người chăm sóc:</p>

                    <div class="contract-section">
                        <h2>1. Điều khoản Chất lượng Dịch vụ</h2>
                        <p>Caregiver cam kết cung cấp các dịch vụ đúng mô tả, đáp ứng đầy đủ tiêu chuẩn an toàn và vệ sinh. Đảm bảo kỹ năng chuyên môn của caregiver được duy trì và cập nhật thường xuyên.</p>
                    </div>

                    <div class="contract-section">
                        <h2>2. Trách nhiệm Đạo đức và An toàn</h2>
                        <p>Caregiver phải luôn giữ thái độ chuyên nghiệp, tôn trọng và hỗ trợ mẹ một cách tận tâm. Tất cả các hành vi không đạo đức hoặc gây nguy hại đều bị nghiêm cấm.</p>
                    </div>

                    <div class="contract-section">
                        <h2>3. Cam kết Bảo mật Thông tin</h2>
                        <p>Caregiver cam kết giữ bí mật mọi thông tin cá nhân và sức khoẻ của mẹ. Mọi thông tin chỉ được sử dụng với mục đích hỗ trợ và không chia sẻ cho bên thứ ba khi chưa được sự đồng ý.</p>
                    </div>

                    <div class="contract-section">
                        <h2>4. Điều khoản Bồi thường</h2>
                        <p>Trong trường hợp dịch vụ không đạt yêu cầu hoặc có sai sót, caregiver sẽ chịu trách nhiệm và bồi thường cho người sử dụng dịch vụ theo thỏa thuận.</p>
                    </div>

                    <div class="contract-section">
                        <h2>5. Điều khoản Dịch vụ</h2>
                        <p>Trong việc nhấp nhận các yêu cầu dịch vụ của các bà mẹ, phải bỏ ra <strong>10%</strong> giá của dịch vụ để có thể xác nhận yêu cầu từ phía các mẹ</p>
                    </div>

                    <div class="signature">
                        <p><strong>Chữ ký:</strong></p>
                        <img src="${signatureData}" alt="Signature">
                    </div>
                </div>
            </body>
        </html>
    `;
};
