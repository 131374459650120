import React, { useEffect, useState } from "react";
import {
  FaRegUser,
  FaBars,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import Notifications from "./Notifications";
import UserDropdown from "./UserDropdown";
import DropDownTools from "./DropDownTools";
import CustomModal from "../Common/CustomModalConfirm";
import logo from "../../assets/image/logo/LOGO_MC_CARE__.png";
import { MessageOutlined } from "@ant-design/icons";
import { TOKEN_API } from "../../api/axiosInstance";

export default function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hrefHome, setHrefHome] = useState("/home")
  const navigate = useNavigate();

  useEffect(() => {
    const momToken = localStorage.getItem("authMomToken");
    let token = null;
    if (momToken) {
      token = momToken;
      setHrefHome("/mom/home")
    }

    setIsLoggedIn(token !== null);
  }, []);

  const [isOpenModalLogout, setOpenModalLogout] = useState(false);
  const handleCancelLogout = () => {
    setOpenModalLogout(false)
  }

  const handleConfirmLogout = () => {
    setOpenModalLogout(false)
    setIsLoggedIn(false);
    navigate("/login")
    localStorage.clear();
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <CustomModal isOpenModal={isOpenModalLogout} handleConfirm={handleConfirmLogout} handleCancel={handleCancelLogout}
        textConfirm="Đăng xuất"
        header="Đăng xuất"
        content={(<><h2 className="text-[20px] text-black pb-3">Bạn có chắc chắn muốn đăng xuất không?</h2></>)}
      />
      <header className="bg-white border-b border-gray-400 h-[80px] md:px-10 shadow">
        <div className="container mx-auto flex justify-between sm:px-3">
          {/* Icon FaBars */}
          <div className="lg:hidden flex items-center">
            <FaBars onClick={toggleMenu} className="w-6 h-6 cursor-pointer" />
          </div>

          {/* <!-- Logo and Navigation --> */}
          <div className="flex items-center space-x-8">
            <Link to={hrefHome} className="">
              <img src={logo} alt="" className="w-24 mt-4" />
            </Link>
            <nav className="hidden lg:flex items-baseline font-bold text-gray-800 space-x-6 space-y-4 items-center flex">
              <DropDownTools />
              <Link to="/mom/service"> Dịch vụ cho sức khoẻ </Link>
              {TOKEN_API && <Link to="/mom/booked"> Các dịch vụ đã đăng ký </Link>}
              <Link to="/mom/list-examination"> Đặt lịch khám </Link>
              <Link to="/contact">Liên hệ</Link>
            </nav>
          </div>

          <div className="flex items-center space-x-2 space-y-4">
            {isLoggedIn ? (
              <>
                <div className="text-gray-600 mr-4 mt-8">
                </div>
                <div className="flex items-center">
                  <MessageOutlined
                    className="bg-pinkColor text-[18px] text-white p-3 rounded-full cursor-pointer shadow-lg transition-transform duration-200 transform hover:scale-110 hover:bg-pink-500"
                    onClick={() => navigate("/mom/chat")}
                  />
                </div>
                <Notifications />
                <UserDropdown handleOpenMedalLogout={() => setOpenModalLogout(true)} />
              </>
            ) : (
              <div className="flex items-center justifyContent-center bg-pinkColor text-white p-3 rounded-full mt-4">

                <Link to='/login' className='pr-2'>Đăng nhập</Link>
                <FaRegUser className='w-4 h-4' />
              </div>
            )}
          </div>

        </div>

        {isMenuOpen && (
          <div className="lg:hidden font-bold bg-white shadow-md mt-2 p-4 space-y-2 z-50 relative">
            <DropDownTools />
            {localStorage.getItem("authMomToken") && <>
              <Link className="block " to="/mom/service"> Dịch vụ cho sức khoẻ </Link>
              <Link className="block " to="/mom/booked"> Các dịch vụ đã đăng ký </Link>
            </>}
            <Link to="/contact" className="block ">
              Liên hệ
            </Link>
          </div>
        )}
      </header >
    </>
  );
}
