import React, { useState, useEffect } from "react";
import { Col, Form, Input } from "antd";
import axios from "axios";
import { API_URL_PORT } from "../../api/axiosInstance";
import { GrPowerReset } from "react-icons/gr";
import { SafeHtml } from "./SafeHTMLText";

const ChatBotMessage = ({
    token
}) => {
    const [form] = Form.useForm();
    const [messages, setMessages] = useState([]);
    const [isDisable, setIsDisable] = useState(false);

    let messagesEndRef = React.createRef();

    useEffect(() => {
        let saveMessages = sessionStorage.getItem("chatbotMessage");
        let parseMessages = JSON.parse(saveMessages);
        if (parseMessages && parseMessages.length > 0) {
            setMessages(parseMessages);
        }
        else
        {
            axios.get(
                `${API_URL_PORT}/api/chatbot/getgreetingmessage`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    }
                }
            ).then(
                (response) => {
                    if (response.data.status === "success") {
                        setMessages([...messages, response.data.data.messages]);
                    }
                },
                (error) => {
                    console.error(error);
                }
            )
            
        }
        hideThinkingMessage(true);
        hideErrorMessage(true);
    }, []);

    useEffect(() => {
        sessionStorage.setItem("chatbotMessage", JSON.stringify(messages));
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if(!isDisable){
            document.getElementById("messageInput").focus();
        }
    }, [isDisable]);

    const resetMessages = () => {
        hideErrorMessage(true);
        hideThinkingMessage(true);
        axios.get(
            `${API_URL_PORT}/api/chatbot/getgreetingmessage`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            }
        ).then(
            (response) => {
                if (response.data.status === "success") {
                    setMessages([response.data.data.messages]);
                }
            },
            (error) => {
                console.error(error);
            }
        )
    };

    const handleSubmit = async (value) => {
        let text = value.mess;
        if (text === "") {
        return;
        }
        let requestMessages = [...messages, { message: text, role: 1 }];
        setMessages(requestMessages);
        form.resetFields(["mess"]);
        hideThinkingMessage(false);
        setIsDisable(true);
        axios.post(
            `${API_URL_PORT}/api/chatbot/askchatbot`, 
            requestMessages, 
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            }
        ).then(
            response => {
                if (response.data.status === "success") {
                    setMessages([...requestMessages, response.data.data.responseMessage]);
                }
                hideThinkingMessage(true);
                setIsDisable(false);
            },
            error => {
                console.error(error);
                hideErrorMessage(false);
                hideThinkingMessage(true);
                setIsDisable(false);
            }
        );
    };

    const scrollToBottom = () => {
        setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const hideThinkingMessage = (isHide) => {
        document.getElementById("thinking").style.display = isHide ? "none" : "flex";
    };

    const hideErrorMessage = (isHide) => {
        document.getElementById("error").style.display = isHide ? "none" : "flex";
    };

    return (
        <Col flex={4} className="flex flex-col">
        <div className="w-full h-[10%] border shadow-md rounded-tr-lg flex items-center justify-center">
            <h2 className="text-center font-bold text-2xl">
                Trợ lý MCCare
            </h2>
        </div>
        <div className="flex-grow flex p-4 overflow-y-auto overflow-x-hidden h-[400px]">
            <div className="flex-col w-full">
            {messages.map((message, index) => (
                <div
                key={index}
                className={`flex ${message.role === 1
                    ? "justify-end"
                    : "justify-start"
                    }`}
                >
                <div
                    className={`${message.role === 1
                    ? "bg-pinkColor text-white"
                    : "bg-[#EAEAEA] text-black"
                    } max-w-lg p-3 rounded-xl mb-3 overflow-hidden whitespace-normal`}
                >
                    {message.role === 2 ? <SafeHtml content={message.message.replace(/(?:\r\n|\r|\n)/g, '<br />')}/> : message.message}
                </div>
                </div>
            ))}
            <div id="thinking" className="flex justify-start">
                <div className="bg-[#EAEAEA] text-black max-w-lg p-3 rounded-xl mb-3 flex gap-x-1.5">
                    <span className="relative flex h-2 w-2">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pinkColor opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-pinkColor"></span>
                    </span>
                    <span className="relative flex h-2 w-2">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pinkColor opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-pinkColor"></span>
                    </span>
                    <span className="relative flex h-2 w-2">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pinkColor opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-pinkColor"></span>
                    </span>
                </div>
            </div>
            <div id="error" className="flex justify-start">
                <div className="bg-[#EAEAEA] text-red-600 max-w-lg p-3 rounded-xl mb-3">
                    Gửi tin nhắn không thành công. Vui lòng thử lại sau.
                </div>
            </div>
            <div ref={messagesEndRef} />
            </div>
        </div>

        <div className="border">
            <Form form={form} onFinish={handleSubmit} className="w-full">
                <div className="flex items-center w-full p-2 mx-2">
                    <button 
                        type="button"
                        onClick={resetMessages}
                        disabled={isDisable}
                    >
                        <GrPowerReset className="text-[#7E7E7E] hover:text-black w-6 h-6" />
                    </button>
                    <Form.Item
                        name="mess"
                        className="w-full p-0 m-0 mr-1"
                    >
                        <Input
                            id="messageInput"
                            className="rounded-3xl ml-2 mr-2 text-[16px]"
                            placeholder="Nhập tin nhắn"
                            autoComplete="off"
                            autoFocus={true}
                            disabled={isDisable}
                        />
                    </Form.Item>
                    <Form.Item className="p-0" style={{ margin: 0, padding: "0 10px" }}>
                        <button
                            type="submit"
                            disabled={isDisable}
                            className="px-3 py-1 rounded-3xl bg-pinkColor text-white hover:bg-pinkColor hover:text-white hover:opacity-90 border-none text-[17px]"
                        >
                        Gửi
                        </button>
                    </Form.Item>
                </div>
            </Form>
        </div>
        </Col>
    );
};

export default ChatBotMessage;