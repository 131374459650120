import React, { useState, useEffect } from 'react';
import toolImg1 from '../../../assets/image/tool/Edd_IVF.png';
import toolImg2 from '../../../assets/image/tool/EDD_Last Menstrual Period.png';
import toolImg3 from '../../../assets/image/tool/EDD_Date of Conception.png';
import PopupEstimate from '../../../components/Tool/EstimateDate/PopupEstimate';
import dayjs from 'dayjs';
import './style.css';
import { DatePicker, Select, Radio, Space, Form } from 'antd';
import { DueDateFromLMPWithCycle, DueDateFromConception, DueDateFromIVF } from "../../../components/Tool/EstimateDate/EstimateCalculators";
import { toast } from 'react-toastify';

export const EstimateDate = () => {
    const [activeTool, setActiveTool] = useState('LMP');
    const [lmpDate, setLmpDate] = useState(dayjs());
    const [cycleLength, setCycleLength] = useState(0);
    const [docDate, setDocDate] = useState(dayjs());
    const [ivfDate, setIvfDate] = useState(dayjs());
    const [embryoOption, setEmbryoOption] = useState(3);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [resultCalcullator, setResultCalcullator] = useState({});

    // Update state with today's date when component mounts
    useEffect(() => {
        setLmpDate(dayjs().format('YYYY-MM-DD'));
        setDocDate(dayjs().format('YYYY-MM-DD'));
        setIvfDate(dayjs().format('YYYY-MM-DD'));
    }, []);

    const optionscycleLength = [];
    for (let i = 20; i <= 40; i++) {
        optionscycleLength.push({
            value: i,
            label: i + " Ngày",
        });
    }

    const handleCycleLength = (value) => {
        setCycleLength(value);
    };

    const handleToolSelect = (tool) => {
        setLmpDate(dayjs().format('YYYY-MM-DD'));
        setIvfDate(dayjs().format('YYYY-MM-DD'));
        setActiveTool(tool);
    };

    const handleLmpDateChange = (date) => {
        setLmpDate(date ? date.format('YYYY-MM-DD') : '');
    };

    const handleDocDateChange = (date) => {
        setDocDate(date ? date.format('YYYY-MM-DD') : '');
    };

    const handleIvfDateChange = (date) => {
        setIvfDate(date ? date.format('YYYY-MM-DD') : '');
    };

    const handleEmbryoOptionChange = (e) => {
        setEmbryoOption(e.target.value);
    };

    const handleSubmit = () => {
        switch (activeTool) {
            case "LMP":
                if (!lmpDate) {
                    toast.error("Hãy chọn ngày kinh nguyệt cuối cùng");
                    return;
                }
                setResultCalcullator(DueDateFromLMPWithCycle(lmpDate, cycleLength));
                break;
            case "DOC":
                setResultCalcullator(DueDateFromConception(docDate));
                break;
            case "IVF":
                if (!ivfDate) {
                    toast.error("Hãy chọn ngày chuyển phôi");
                    return;
                }
                setResultCalcullator(DueDateFromIVF(ivfDate, embryoOption));
                break;
        }
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div>
            <PopupEstimate isOpen={isPopupOpen} onClose={handleClosePopup} resultCalcullator={resultCalcullator} />
            <div>
                <div className='bg-gray-100 p-6 rounded-[50px]'>
                    <div className='space-y-5'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 sm:px-2 lg:px-4'>
                            <button
                                onClick={() => handleToolSelect('LMP')}
                                className={`${activeTool === 'LMP' ? '' : 'opacity-50'}`}
                            >
                                <div className={`bg-white rounded-3xl h-52 w-full sm:w-auto flex flex-col items-center justify-center ${activeTool === 'LMP' ? 'ring-2 ring-pinkColor' : ''}`}>
                                    <div className="flex justify-center">
                                        <img className='w-16 h-16' src={toolImg1} alt="IVF" />
                                    </div>
                                    <h5 className='text-base text-center mt-2'>Ngày kinh nguyệt cuối cùng</h5>
                                </div>
                            </button>

                            <button
                                onClick={() => handleToolSelect('IVF')}
                                className={`${activeTool === 'IVF' ? '' : 'opacity-50'}`}
                            >
                                <div className={`bg-white rounded-3xl h-52 w-full sm:w-auto flex flex-col items-center justify-center ${activeTool === 'IVF' ? 'ring-2 ring-pinkColor' : ''}`}>
                                    <div className="flex justify-center">
                                        <img className='w-16 h-16' src={toolImg3} alt="Date of Conception" />
                                    </div>
                                    <h5 className='text-base text-center mt-2'>Thụ tinh trong ống nghiệm - IVF</h5>
                                </div>
                            </button>
                        </div>

                        {activeTool === 'LMP' && (
                            <Form layout='vertical' onFinish={handleSubmit} initialValues={{lmpDate: dayjs()}}>
                                <div className='mt-2'>
                                    <Form.Item label="Ngày kinh nguyệt cuối cùng" name="lmpDate" rules={[{ required: true, message: "Chọn ngày kinh nguyệt cuối cùng" }]}>
                                        <DatePicker
                                            disabledDate={(current) => current > dayjs()}
                                            format="DD/MM/YYYY"
                                            className='w-full p-2 border border-gray-300 rounded-full'
                                            onChange={handleLmpDateChange} />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p>Độ dài chu kỳ kinh nguyệt</p>
                                    <Select
                                        size="large"
                                        defaultValue="28 Ngày"
                                        onChange={handleCycleLength}
                                        style={{ width: '100%' }}
                                        options={optionscycleLength}
                                    />
                                </div>
                                <button htmlType="submit" className='mt-4 w-full bg-pinkColor text-white py-2 rounded-full'>Tính toán</button>
                            </Form>
                        )}

                        {activeTool === 'IVF' && (
                            <Form layout='vertical' onFinish={handleSubmit} initialValues={{ivfDate: dayjs()}}>
                                <div className='mt-2'>
                                    <Form.Item label="Ngày chuyển phôi" name="ivfDate" rules={[{ required: true, message: "Chọn ngày chuyển phôi" }]}>
                                        <DatePicker className='w-full p-2 border border-gray-300 rounded-full'
                                            format="DD/MM/YYYY"
                                            onChange={handleIvfDateChange} />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Radio.Group className="custom-radio" onChange={handleEmbryoOptionChange} value={embryoOption}>
                                        <Space direction="vertical">
                                            <Radio value={3}>3 - Ngày chuyển phôi</Radio>
                                            <Radio value={5}>5 - Ngày chuyển phôi</Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <button htmlType="submit" className='mt-4 w-full bg-pinkColor text-white py-2 rounded-full'>Tính toán</button>
                            </Form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};