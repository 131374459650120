import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './style.css'; 

const localizer = momentLocalizer(moment);

export const MyCalendar = ({ events, onSelectEvent }) => {
  const eventStyle = (event) => {
    console.log(event)
    const backgroundColor = event.status ==="Completed" ? "#22c55e" : "#eab308";
    return {
      style: {
        backgroundColor,
        color: "white", 
        borderRadius: "5px",
        border: "none",
        padding: "5px",
      },
    };
  }
  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      eventPropGetter={eventStyle}
      style={{ height: 500, width: '80%', marginLeft: '5%' }}
      onSelectEvent={onSelectEvent}
    />
  );
};

