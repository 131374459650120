import React, { useEffect, useRef, useState } from 'react';
import { Table, Input, Select, Pagination, Button, Radio } from 'antd';
import { LockOutlined, SearchOutlined, UnlockOutlined } from '@ant-design/icons';
import AdminLayout from '../../../components/Admin/AdminLayout';
import { fetchEntities, updateEntity } from '../../../api/odataClients/adminClient';
import { toast } from 'react-toastify';
import "react-toastify/ReactToastify.min.css"
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { AddNewAccount } from '../../../components/Admin/Account/AddNewAccount';
import { FaCheckCircle, FaTimesCircle, FaUser, FaUsers } from 'react-icons/fa';
import debounce from 'lodash.debounce';

export const Account = () => {
  const [accounts, setAccounts] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statistical, setStatistical] = useState({
    totalAccounts: 0,
    mom: 0,
    active: 0,
    disable: 0,
  });
  const [loading, setLoading] = useState(true);
  const [showModalAddNew, setShowModalAddNew] = useState(false)
  const [valueFilter, setValueFilter] = useState("")

  const loadEntities = async (searchValue = null) => {
    setLoading(true)
    let filter = searchValue ? `contains(FullName,'${searchValue}')` : ""

    if (valueFilter && valueFilter.length > 0)
      filter += searchValue ? ` and ${valueFilter}` : valueFilter

    try {
      const data = await fetchEntities('accounts', {
        filter: filter,
        expand: ['Role'],
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ['Id desc']
      });
      setAccounts(data.value);
      setDataLength(data['@odata.count']);
    } catch (err) {
      toast(err);
    } finally {
      setLoading(false)
    }
  };

  const loadStatic = async () => {
    const dataStatistical = await fetchEntities('accounts', { count: true });

    const momAccounts = dataStatistical.value.filter((account) => account.RoleID === 1).length;
    const activeAccounts = dataStatistical.value.filter((account) => account.IsActive).length;

    setStatistical({
      totalAccounts: dataStatistical['@odata.count'],
      mom: momAccounts,
      active: activeAccounts,
      disable: dataStatistical.value.length - activeAccounts,
    });
  }

  useEffect(() => {
    loadStatic();
  }, [])

  useEffect(() => {
    loadEntities();
  }, [currentPage, pageSize, valueFilter]);

  const handleChangeStatusAccount = async (isActive, recordAccount) => {
    const accountId = recordAccount.Id
    try {
      await updateEntity('accounts', accountId, { Id: accountId, IsActive: isActive });
      toast.success('Cập nhập tài khoản thành công')
    } catch (error) {
      console.error('Lỗi cập nhập tài khoản:', error);
      toast.error('Lỗi cập nhập tài khoản')
    }
    loadEntities();
    loadStatic();
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleCreateNewAccount = () => {
    setShowModalAddNew(false);
    setLoading(true)
    loadEntities();
    loadStatic();
  }

  const handleFilterSelection = (e) => {
    const { value } = e.target;
    setValueFilter(value)
  }

  const debounceDropDown = useRef(
    debounce((searchValue) => {
      loadEntities(searchValue)
    }, 500)
  ).current;

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    debounceDropDown(searchValue);
  };

  React.useEffect(() => {
    return () => {
      debounceDropDown.cancel();
    };
  }, [debounceDropDown]);

  const columns = [
    { title: 'Tên', dataIndex: 'FullName', key: 'FullName' },
    { title: 'Quyền', dataIndex: 'Role', key: 'Role', render: (role) => (role ? role.Name : 'No Role') },
    { title: 'Số điện thoại', dataIndex: 'PhoneNumber', key: 'PhoneNumber' },
    { title: 'Email', dataIndex: 'Email', key: 'Email' },
    { title: 'Địa chỉ', dataIndex: 'CurrentAddress', key: 'CurrentAddress' },
    {
      title: 'Trạng thái', dataIndex: 'IsActive', key: 'IsActive',
      render: (status, record) => (
        <>
          {status ?
            <UnlockOutlined onClick={() => handleChangeStatusAccount(false, record)} style={{ fontSize: '24px', color: '#52c41a' }} /> :
            <LockOutlined onClick={() => handleChangeStatusAccount(true, record)} style={{ fontSize: '24px', color: '#ff4d4f' }} />}
        </>
      ),
    },
  ];

  return (
    <AdminLayout>
      <LoadingPage isLoading={loading} />
      <AddNewAccount show={showModalAddNew} onClose={() => setShowModalAddNew(false)} onSuccess={handleCreateNewAccount} />
      <Radio.Group onChange={handleFilterSelection} className="grid grid-cols-4 gap-4 mb-10" defaultValue={valueFilter}>
        <Radio.Button className='shadow rounded p-0' value="" style={{ height: "auto" }}>
          <div className="bg-green-200 rounded-lg p-4">
            <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-10 h-10 mb-2">
              <FaUser className="text-white text-2xl" />
            </span>
            <div className="text-sm">Tổng số tài khoản</div>
            <div className="text-3xl font-bold">{statistical.totalAccounts}</div>
          </div>
        </Radio.Button>

        <Radio.Button className='shadow rounded p-0' value="RoleID eq 1" style={{ height: "auto" }}>
          <div className="bg-blue-200 rounded-lg p-4">
            <span className="bg-blue-500 p-2 rounded-full flex items-center justify-center w-10 h-10 mb-2">
              <FaUsers className="text-white text-2xl" />
            </span>
            <div className="text-sm">Tài khoản của mẹ</div>
            <div className="text-3xl font-bold">{statistical.mom}</div>
          </div>
        </Radio.Button>

        <Radio.Button className='shadow rounded p-0' value="IsActive eq true" style={{ height: "auto" }}>
          <div className="bg-green-200 rounded-lg p-4">
            <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-10 h-10 mb-2">
              <FaCheckCircle className="text-white text-2xl" />
            </span>
            <div className="text-sm">Đang hoạt động</div>
            <div className="text-3xl font-bold">{statistical.active}</div>
          </div>
        </Radio.Button>

        <Radio.Button className='shadow rounded p-0' value="IsActive eq false" style={{ height: "auto" }}>
          <div className="bg-red-200 rounded-lg p-4">
            <span className="bg-red-500 p-2 rounded-full flex items-center justify-center w-10 h-10 mb-2">
              <FaTimesCircle className="text-white text-2xl" />
            </span>
            <div className="text-sm">Đang bị khoá</div>
            <div className="text-3xl font-bold">{statistical.disable}</div>
          </div>
        </Radio.Button>
      </Radio.Group>

      <div className="flex justify-between mb-4">
        <h1 className='text-2xl font-semibold'>Tài khoản</h1>

        <Input
          className="w-1/4"
          placeholder="Tìm theo tên..."
          prefix={<SearchOutlined />}
          size="large"
          onChange={handleInputChange}
        />

        <Button
          type="primary"
          className='bg-pinkColor text-lg font-semibold py-5  hover:bg-pinkColor'
          onClick={() => setShowModalAddNew(true)}
        >
          Tạo tài khoản mới
        </Button>
      </div>

      <Table
        dataSource={accounts}
        columns={columns}
        pagination={false}
      />

      {/* Pagination */}
      <div className="mt-4 flex justify-end">
        <Pagination
          total={dataLength}
          showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} tài khoản`}
          pageSize={pageSize}
          current={currentPage}
          onChange={handlePaginationChange}
        />
      </div>

    </AdminLayout>
  );
};

export default Account;