import React, { useEffect, useState } from 'react'
import { Input, Pagination, Table } from 'antd';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import { LockOutlined, SearchOutlined, UnlockOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { fetchEntities, updateEntity } from '../../../api/odataClients/supporterClient';
import { AddNewExaminationService } from '../../../components/Supporter/AddNewExaminationService/AddNewExaminationService';
import SupporterLayout from '../../../components/Supporter/SupporterLayout';
import { FaFileAlt, FaEdit } from 'react-icons/fa';
import { EditExaminationService } from '../../../components/Supporter/EditExaminationService/EditExaminationService';
import { ACCOUNT_ID } from '../../../api/axiosInstance';

export const ExaminationService = () => {
  const [services, setServices] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [showModalAddNew, setShowModalAddNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [valueFilter, setValueFilter] = useState("");
  const [id, setId] = useState(0);

  const loadEntities = async () => {
    let filter = searchText ? `and contains(Content,'${searchText}')` : ""

    if (valueFilter && valueFilter.length > 0)
      filter += searchText ? ` and ${valueFilter}` : valueFilter

    try {
      const data = await fetchEntities('schedules', {
        filter: `CreatedByID eq ${ACCOUNT_ID()}` + filter,
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ['Id desc']
      });
      setServices(data.value);
      setDataLength(data['@odata.count']);
    } catch (err) {
      toast(err);
    } finally {
      setLoading(false)
    }
  };

  const handleClickLockService = async (isActive, record) => {
    setLoading(true)
    try {
      await updateEntity("schedules", record.Id, { ...record, IsActive: isActive })
      toast.success("Cập nhập thành công")
      loadEntities();
    } catch (error) {
      toast.error("Lỗi cập nhập dịch vụ")
    }
  }

  useEffect(() => {
    loadEntities();
  }, [searchText, currentPage, pageSize, valueFilter]);

  const handleSearch = (value) => {
    setLoading(true)
    setSearchText(value);
  };


  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleCreateNewExaminationService = () => {
    setShowModalAddNew(false);
    setLoading(true)
    loadEntities();
    toast.success('Tạo dịch vụ thành công');
  }
  const handleEditExaminationService = () => {
    setShowModalEdit(false);
    setLoading(true)
    loadEntities();
    toast.success('Cập nhật dịch vụ thành công');
  }

  const columns = [
    { title: 'Dịch vụ khám', dataIndex: 'Content', key: 'Content' },
    { title: 'Bệnh viện', dataIndex: 'HospitalName', key: 'HospitalName' },
    {
      title: 'Địa chỉ', dataIndex: 'Address', key: 'Address',
      render: (text) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '280px' }}>
          {text || 'No Data'}
        </div>
      )
    },
    { title: 'Địa chỉ ảnh', dataIndex: 'ImageUrl', key: 'ImageUrl' },
    {
      title: 'Chỉnh sửa',
      render: (status, record) => (
        <>
          <div className='flex justify-center space-x-5'>
            <FaEdit onClick={() => {
              setId(record.Id);
              setShowModalEdit(true)
            }} className="text-[20px] cursor-pointer" style={{ color: '#f1c40f' }} />
            {record?.IsActive ?
              <UnlockOutlined onClick={() => handleClickLockService(false, record)} style={{ fontSize: '24px', color: '#52c41a' }} /> :
              <LockOutlined onClick={() => handleClickLockService(true, record)} style={{ fontSize: '24px', color: '#ff4d4f' }} />}
          </div>
        </>
      )
    }
  ];
  return (
    <div>
      <SupporterLayout>
        <LoadingPage isLoading={loading} />
        <AddNewExaminationService show={showModalAddNew} onClose={() => setShowModalAddNew(false)} onSuccess={handleCreateNewExaminationService} />
        <EditExaminationService id={id} show={showModalEdit} onClose={() => setShowModalEdit(false)} onSuccess={handleEditExaminationService} />
        <div className='flex space-x-10'>
          <div className='p-4 w-[100%] '>
            <div className='grid grid-cols-3 gap-5 mt-4'>
              <div className='p-4 bg-[#E6F4FF] rounded-lg h-32'>
                <span className="bg-[#1677FF] p-2 rounded-full inline-block">
                  <FaFileAlt className=' text-white ' />
                </span>
                <h2 className='text-xl font-medium'>{dataLength}</h2>
                <p>Tổng dịch vụ khám</p>
              </div>

            </div>
          </div>
        </div>
        <div className="flex justify-between mb-4">
          <h1 className='text-2xl font-semibold'>Danh sách Dịch vụ khám tại các bệnh viện</h1>

          <Input
            className="w-1/4"
            placeholder="Tìm kiếm..."
            prefix={<SearchOutlined />}
            size="large"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />

          <button
            className='bg-pinkColor px-14 py-2 rounded-lg text-[18px] text-white border border-pinkColor hover:bg-pinkColor hover:text-white'
            onClick={() => setShowModalAddNew(true)}
          >
            Thêm mới Dịch vụ khám
          </button>
        </div>

        <Table
          dataSource={services}
          columns={columns}
          pagination={false}
        />

        {/* Pagination */}
        <div className="mt-4 flex justify-end">
          <Pagination
            total={dataLength}
            showTotal={(total, range) => `${range[0]}-${range[1]}`}
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePaginationChange}
          />
        </div>
      </SupporterLayout>
    </div>
  )
}
