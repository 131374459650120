
import React from "react";
import { Form, Input } from "antd";
import { ProfileLayout } from "../../../components/Profile/Mom/ProfileLayout";
import { axiosMomInstance } from "../../../api/odataClients/momClient";
import { toast } from "react-toastify";
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from "../../../api/axiosInstance";
import axios from "axios";

export const ChangePassword = () => {
  const [form] = Form.useForm();

  const checkOldPassword = async (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Hãy nhập mật khẩu cũ của bạn'));
    } else {
      const response = await axios.post(`${API_URL_PORT}/api/auth/checkoldpassword`, { Id: ACCOUNT_ID(), Password: value.trim() }, {
        headers: {
          Authorization: `Bearer ${TOKEN_API}`
        }
      });
      if (response.data.status === "success") {
        return Promise.resolve();
      }
      return Promise.reject(new Error(response.data.message));
    }
  }

  const handleSubmit = async (values) => {
    const dataChangePass = {
      AccountId: ACCOUNT_ID(),
      OldPassword: values.oldPassword,
      NewPassword: values.newPassword,
      ConfirmPassword: values.confirmPassword
    };
    try {
      const response = await axiosMomInstance.post(`${API_URL_PORT}/api/auth/changepassword`, dataChangePass);
      if (response.data.status === "error") {
        toast.error(response.data.message);
        return;
      }
      toast.success("Thay đổi mật khẩu thành công!");
      form.resetFields();
    } catch (e) {
      console.error("Changed password failed", e);
      toast.error("Thay đổi mật khẩu thất bại!");
    }
  };

  return (
    <ProfileLayout>
      <div className="p-5 lg:w-auto md:w-full mx-auto">
        <h2 className="text-2xl font-bold text-center mb-8">Đổi mật khẩu</h2>
        <Form form={form} onFinish={handleSubmit} className="space-y-5 w-[80%] mx-[10%]">
          {/* Input for old password */}
          <div className="relative">
            <label
              htmlFor="oldPassword"
              className="absolute left-4 top-1 text-gray-500 transition-all duration-300 transform
              -translate-y-4 scale-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
              peer-focus:scale-75 peer-focus:-translate-y-4 bg-white px-1 text-[18px]"
              style={{ zIndex: 2 }}
            >
              Mật khẩu cũ
            </label>
            <Form.Item
              name="oldPassword"
              rules={[
                {
                  validator: checkOldPassword
                },
              ]}
              validateTrigger="onSubmit"
              className="mb-8"
            >
              <Input.Password
                className="w-full p-3 border border-gray-300 rounded-lg"
                placeholder="Nhập mật khẩu cũ: " />
            </Form.Item>
          </div>

          {/* Input for new password */}
          <div className="relative mt-6">
            <label
              htmlFor="newPassword"
              className="absolute left-4 top-1 text-gray-500 transition-all duration-300 transform
              -translate-y-4 scale-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
              peer-focus:scale-75 peer-focus:-translate-y-4 bg-white px-1 text-[18px]"
              style={{ zIndex: 2 }}
            >
              Mật khẩu mới
            </label>
            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: "Hãy nhập mật khẩu mới" },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message: "Mật khẩu phải có độ dài ít nhất 8 kí tự, gồm chữ viết hoa, viết thường, số và kí tự đặc biệt",
                },
              ]}
              className="mb-8"
            >
              <Input.Password className="w-full p-3 border border-gray-300 rounded-lg" placeholder="Nhập mật khẩu mới: " />
            </Form.Item>
          </div>

          {/* Input for confirm password */}
          <div className="relative">
            <label
              className="absolute left-4 top-1 text-gray-500 transition-all duration-300 transform
              -translate-y-4 scale-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
              peer-focus:scale-75 peer-focus:-translate-y-4 bg-white px-1 text-[18px]"
              style={{ zIndex: 2 }}
            >
              Nhập lại mật khẩu
            </label>
            <Form.Item
              name="confirmPassword"
              dependencies={['newPassword']}
              rules={[
                { required: true, message: "Hãy nhập lại mật mới" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Mật khẩu không trùng!"));
                  },
                }),
              ]}
              validateTrigger="onSubmit"
              className="mb-8"
            >
              <Input.Password className="w-full p-3 border border-gray-300 rounded-lg" placeholder="Nhập lại mật khẩu mới*: " />
            </Form.Item>
          </div>

          <button
            type="submit"
            className="lg:w-[20%] lg:ml-[40%] md:w-full bg-pinkColor text-white font-medium py-2 px-4 rounded-lg hover:bg-pinkColor focus:outline-none"
          >
            Lưu thay đổi
          </button>
        </Form>
      </div>
    </ProfileLayout>
  );
};
