import React from 'react';
import { DashboardSidebar } from './DashboardSidebar';
import LayoutMom from '../../LayoutCommon/LayoutMom';

export const ProfileLayout = ({ children }) => {
  return (
    <LayoutMom>
      <div className="container mx-auto px-3 py-12">
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <DashboardSidebar />
          <div className="flex-1">
            {children}
          </div>
        </div>
      </div>
    </LayoutMom>
  );
}


