import React, { useEffect, useState } from 'react'
import { Pagination, Rate } from 'antd'
import { SearchSidebar } from './SearchSidebar'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchEntities } from '../../api/odataClients/momClient'
import axios from 'axios'
import { API_URL_PORT } from '../../api/axiosInstance'
import LayoutMom from '../../components/LayoutCommon/LayoutMom'
import DOMPurify from 'dompurify'

export const Service = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [dataLength, setDataLength] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [services, setServices] = useState();
    const [serviceImages, setServiceImages] = useState();
    const [serviceRating, setServiceRating] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [orderBy, setOrderBy] = useState()

    const filterServices = async () => {
        setIsLoading(true)
        try {
            const data = await fetchEntities('careservices', {
                filter: `contains(Title,'${searchText}') and IsActive eq true ${selectedCategory ? `and CareServiceTypeID eq ${selectedCategory} ` : ""}`,
                expand: ['CareServiceType'],
                count: true,
                skip: (currentPage - 1) * pageSize,
                top: pageSize,
                orderBy: [`${orderBy ? orderBy : "Id desc"}`]
            });

            setServices(data.value);
            setDataLength(data["@odata.count"]);
        } catch (err) {
            toast.error("Lỗi hệ thống")
        } finally {
            setIsLoading(false)
        }
    };

    const fetchdata = async () => {
        setIsLoading(true)
        try {
            const data = await fetchEntities('careservices', {
                filter: "IsActive eq true",
                expand: ['CareServiceType'],
                count: true,
                skip: (currentPage - 1) * pageSize,
                top: pageSize,
                orderBy: ['Id desc']
            });
            setServices(data.value);
            setDataLength(data['@odata.count']);
        } catch (err) {
            toast.error("Lỗi hệ thống")
        } finally {
            setIsLoading(false)
        }
    }

    const hanldeLoadingImages = async () => {
        if (services && services.length > 0) {
            for (let i = 0; i < services.length; i++) {
                const element = services[i].ImageUrl.split(" - ")[0].trim();
                const url = process.env.NODE_ENV === 'development' ? `https://momchild.life/api/s3/getimage?url=${element}` : `${API_URL_PORT}/api/s3/getimage?url=${element}`
                const respGetImage = await axios.get(url);
                setServiceImages((prev) => ({ ...prev, [element]: respGetImage.data.data.imageUrl }))
            }

            const dataRating = await fetchEntities("comments?$apply=groupby((ServiceID), aggregate(Rating with average as AverageRating))", {})
            if (dataRating && dataRating.length > 0) {
                for (let i = 0; i < dataRating.length; i++) {
                    const element = dataRating[i];
                    setServiceRating((prev) => ({ ...prev, [element.ServiceID]: element.AverageRating }))
                }
            }
        }
    }

    useEffect(() => {
        setIsLoading(true)
        fetchdata()
    }, [])

    useEffect(() => {
        hanldeLoadingImages()
        console.log(serviceImages)
    }, [services])

    const truncateDescription = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text?.slice(0, maxLength) + '...';
        }
        return text;
    };

    const handleChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    return (
        <LayoutMom isLoading={isLoading}>
            <div className='container mx-auto mb-36'>
                <div className='bg-primaryColor text-center px-20 py-5 h-[230px] space-y-3'>
                    <h1 className='text-[30px] sm:text-[40px] font-bold text-pinkColor'>Dịch vụ chăm sóc sức khỏe tại nhà của MCCare</h1>
                    <p className='text-base text-[#454545] sm:text-lg hidden sm:block'>MCCare là dịch vụ chăm sóc Y tế tại nhà uy tín hàng đầu hiện nay dành cho Sản phụ và Bé sơ sinh tại Đà Nẵng. Với đội ngũ Bác sĩ uy tín ở cả 3 lĩnh vực <span className="font-bold">Sản Khoa – Nhi Khoa – Chăm sóc Tiền/Hậu Sản</span>, xây dựng giải pháp và liệu trình chăm sóc khoa học, trực tiếp đào tạo chuyên sâu cho đội ngũ Điều Dưỡng và giám sát quy trình chăm sóc nghiêm ngặt, MCCare cam kết mang đến dịch vụ chăm sóc sau sinh khoa học và chất lượng cao nhất cho cả Mẹ và Bé ngay khi xuất viện về nhà.</p>
                </div>

                <div className='m:mx-12 space-y-5'>
                    <div className='w-full'>
                        <SearchSidebar
                            onResetFilter={fetchdata}
                            onFilter={filterServices}
                            setSelectedCategory={setSelectedCategory}
                            setSearchText={setSearchText}
                            setOrderBy={setOrderBy}
                        />
                    </div>

                    <div className='space-y-8 container pl-[10vw] pr-[10vw]'>
                        {services && services.length > 0 ? <>
                            {services?.map(service => (
                               
                                    <div key={service.Id} className="flex w-full border border-gray-300 rounded-lg shadow-sm overflow-hidden mb-8">
                                        {/* Image Section */}
                                        <div className="w-1/3">
                                            <img
                                                src={serviceImages && serviceImages[service.ImageUrl.split(" - ")[0].trim()]}
                                                alt="Ảnh dịch vụ"
                                                className="w-full h-[220px] object-cover"
                                            />
                                        </div>

                                        {/* Content Section */}
                                        <div className="w-2/3 p-4">
                                            <Link to={`/mom/service/${service.Id}`} className='hover:text-pinkColor'>
                                                <h3 className="text-lg font-semibold">{service.Title}</h3>
                                            </Link>
                                            <div className="flex items-center my-2">
                                                {serviceRating && <Rate disabled defaultValue={serviceRating[service.Id]} style={{ fontSize: '1rem' }} />}
                                                <span className="ml-2 text-yellow-500 text-sm">{serviceRating && serviceRating[service.Id]}/5.0</span>
                                            </div>
                                            <p className="text-blue-600 font-semibold">{service.Price} VND / Giờ</p>
                                            <p className="text-gray-500 text-sm mt-2">
                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncateDescription(service.Description, 80)) }} />
                                            </p>

                                            {/* Buttons */}
                                            <div className="flex justify-end mt-8">
                                                <Link to={`/mom/service/${service.Id}`}>
                                                    <button
                                                        className="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white px-4 py-[5px] rounded-lg" // Thêm padding và border-radius
                                                    >
                                                        Xem thêm <ArrowRightOutlined />
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                               

                            ))}
                            <Pagination
                                total={dataLength}
                                pageSize={pageSize}
                                current={currentPage}
                                onChange={handleChangePage}
                                className="flex justify-center mt-6"
                            />
                        </> : <>
                            <h3 className='text-center'> no Data</h3>
                        </>}
                    </div>
                </div>
            </div>
        </LayoutMom>
    )
}
