import React from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { updateEntity, fetchEntities } from '../../api/odataClients/momClient';
import { Form, Input } from 'antd';
import { ACCOUNT_ID } from '../../api/axiosInstance';

const CalculateBMI = ({ isOpen, onClose, onBmiUpdated }) => {
  if (!isOpen) return null;

  const handleClickOutside = () => {
    onClose();
  };

  const calculateBMI = (height, weight) => {
    const heightInMeters = height / 100;
    return (weight / (heightInMeters * heightInMeters)).toFixed(2);
  };

  const handleSaveBMI = async (form) => {
    const Height = Number(form.Height)
    const Weight = Number(form.Weight)
    const calculatedBMI = calculateBMI(Height, Weight);

    try {
      const response = await fetchEntities(`momhealths`, {
        $filter: `Id eq ${ACCOUNT_ID()}`
      });

      if (response.value.length === 0) {
        console.error('Không tìm thấy dữ liệu');
        return;
      }

      const Id = response.value[0].Id;
      const responseUpdate = await updateEntity(`momhealths`, Id, {
        Id: Id,
        Height: parseFloat(Height),
        Weight: parseFloat(Weight),
        BMIIndex: parseFloat(calculatedBMI),
      });

      onBmiUpdated(responseUpdate);
      onClose();
    } catch (error) {
      console.error('Failed to update BMI:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 popup-overlay">
      <div className="w-[500px] h-[auto] text-center rounded-lg relative bg-primaryColor">
        <AiOutlineClose onClick={handleClickOutside} className='ml-auto text-[25px] mr-2 mt-2' style={{ cursor: 'pointer' }} />
        <h2 className="text-[25px] text-black font-bold">Tính chỉ số BMI</h2>
        <Form layout='vertical' onFinish={handleSaveBMI} className='p-5'>
          <div className='relative w-full'>
            <Form.Item label="Chiều cao (cm):" name='Height' className='mb-0' rules={[{ required: true, message: 'Hãy nhập chiều cao của mẹ' }]}>
              <Input type="text" placeholder='Chiều cao của mẹ (Cm):' className='p-2 border border-gray rounded-lg w-full' />
            </Form.Item>
          </div>
          <div className='relative w-full'>
            <Form.Item label="Cân nặng (Kg):" name='Weight' className='mb-0' rules={[{ required: true, message: 'Hãy nhập cân nặng của mẹ' }]}>
              <Input type="text" placeholder='Cân nặng của mẹ (Kg):' className='p-2 border border-gray rounded-lg w-full' />
            </Form.Item>
          </div>
          <button className='bg-pinkColor px-14 mt-4 py-2 rounded-lg text-[18px] text-white' type='submit'>Tính</button>
        </Form>
      </div>
    </div>
  )
}


export default CalculateBMI