import React, { useState } from "react";
import { BoyIcon, GirlIcon } from "../../../assets/image/gender/gender";
import { DatePicker, Form, Input, Radio } from 'antd';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

const CaculatorGrowth = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [bmi, setBmi] = useState(null);
  const [sex, setSex] = useState('Boy');
  const calculateBMI = (height, weight) => {
    if (height && weight) {
      const heightInMeters = height / 100;
      return Number((weight / (heightInMeters ** 2)).toFixed(2));
    }
    return null;
  };

  const handleFinishSubmit = (value) => {
    const data = {
      ...value,
      HeadCircumference: Number(value.HeadCircumference),
      Height: Number(value.Height),
      Weight: Number(value.Weight),
      Sex: sex,
      DateOfBirth: dayjs(value.DateOfBirth).format('YYYY-MM-DD'),
    };
    navigate("/tool/growth-child/result", { state: data });
  };

  const handleValuesChange = (changedValues, allValues) => {
    const { Height, Weight } = allValues;
    if (Height && Weight) {
      const calculatedBMI = calculateBMI(Height, Weight);
      setBmi(calculatedBMI);
      form.setFieldsValue({ BMIIndex: calculatedBMI });
    } else {
      setBmi(null);
      form.setFieldsValue({ BMIIndex: null });
    }
  };

  return (
    <div className="w-full">
      <div className="bg-gray-100 p-6 rounded-[50px]">
        <Form
          form={form}
          className="space-y-5"
          onFinish={handleFinishSubmit}
          onValuesChange={handleValuesChange}
        >
          {/* Baby Name */}
          <div className="relative">
            <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
              Tên của bé
            </label>
            <Form.Item
              name="Name"
              className="mb-8"
            >
              <Input
                placeholder="Nhập tên bé"
                className="p-4 w-full mr-2 rounded-lg"
              />
            </Form.Item>
          </div>

          {/* Gender Selection */}
          <p>Giới tính của bé là gì?</p>
          <Form.Item name="Sex" className="w-full">
            <Radio.Group className="w-full flex" defaultValue="Boy" onChange={(value) => setSex(value.target.value)}>
              <Radio.Button value="Boy" className='flex justify-center w-[50%] m-5' style={{ height: "auto" }}>
                <div className="bg-white h-20 rounded-full w-full flex justify-center items-center">
                  {BoyIcon}
                  <div className="text-2xl font-bold">Bé trai</div>
                </div>
              </Radio.Button>
              <Radio.Button value="Girl" className='flex justify-center w-[50%] m-5' style={{ height: "auto" }}>
                <div className="bg-white h-20 rounded-full w-full flex justify-center items-center">
                  {GirlIcon}
                  <div className="text-2xl font-bold">Bé gái</div>
                </div>
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          {/* Baby Information */}
          <div>
            {/* Date of Birth */}
            <div className="relative">
              <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                Ngày sinh
              </label>
              <Form.Item rules={[{ required: true, message: "Hãy chọn ngày sinh của bé!" }]} name="DateOfBirth" className="mb-8">
                <DatePicker
                  format="DD/MM/YYYY"
                  disabledDate={(current) => current > dayjs()}
                  className="w-full p-4 rounded-lg"
                />
              </Form.Item>
            </div>

            {/* Height, Weight, Head Circumference, BMI */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="relative">
                <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                  Chiều cao (cm)
                </label>
                <Form.Item
                  name="Height"
                  rules={[{ required: true, message: "Hãy nhập chiều cao của bé!" },
                  {
                    validator: (_, value) => {
                      if (300 < value < 30) {
                        return Promise.reject(new Error('Chiều cao không hợp lí'));
                      }
                      return Promise.resolve();
                    }
                  }
                  ]}
                  className="mb-8 relative"
                >
                  <Input
                    type="number"
                    min={0}
                    placeholder="Hãy nhập chiều cao của bé"
                    className="p-4 w-full rounded-lg"
                  />
                </Form.Item>
              </div>

              <div className="relative">
                <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                  Cân nặng (kg)
                </label>
                <Form.Item
                  name="Weight"
                  rules={[{ required: true, message: "Hãy nhập cân nặng của bé!" },
                  {
                    validator: (_, value) => {
                      if (200 < value < 2) {
                        return Promise.reject(new Error('Cân nặng không hợp lí không hợp lí'));
                      }
                      return Promise.resolve();
                    }
                  }
                  ]}
                  className="mb-8 relative"
                >
                  <Input
                    type="number"
                    min={0}
                    placeholder="Hãy nhập cân nặng của bé"
                    className="p-4 w-full rounded-lg"
                  />
                </Form.Item>
              </div>

              <div className="relative">
                <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                  Chu vi vòng đầu (cm)
                </label>
                <Form.Item
                  name="HeadCircumference"
                  rules={[{ required: true, message: "Hãy nhập chu vi vòng đầu của bé!" },
                  {
                    validator: (_, value) => {
                      if (100 < value < 25) {
                        return Promise.reject(new Error('Chu vi vòng đầu không hợp lí'));
                      }
                      return Promise.resolve();
                    }
                  }
                  ]}
                  className="mb-8 relative"
                >
                  <Input
                    type="number"
                    min={0}
                    placeholder="Nhập chu vi vòng đầu của bé"
                    className="p-4 w-full rounded-lg"
                  />
                </Form.Item>
              </div>

              <div className="relative">
                <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                  BMI (kg/m²)
                </label>
                <Form.Item name="BMIIndex" className="mb-8 relative">
                  <Input
                    type="text"
                    readOnly
                    placeholder="BMI (Kg/m²)"
                    value={bmi || ''}
                    className="p-4 w-full rounded-lg"
                  />
                </Form.Item>
              </div>
            </div>

            <button type="submit" className="mt-4 w-full bg-pinkColor text-white py-2 rounded-full">
              Tính toán
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CaculatorGrowth;