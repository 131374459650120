
import React from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineLogout,
  AiFillQuestionCircle,
  AiOutlineSchedule,
  AiOutlineProfile,
  AiOutlineFund,
} from "react-icons/ai";
import { Dropdown, Menu } from "antd";
import { FaRegUser } from "react-icons/fa";

const UserDropdown = ({ handleOpenMedalLogout }) => {
  const itemsUserDropdown = [
    {
      label: (
        <Link
          to="/mom/profile"
          className="text-[14px] flex text-center items-center hover:text-pinkColor"
        >
          <div className="flex text-center py-1 w-full">
            <AiOutlineProfile className="w-4 h-4 mr-2 mt-1" />
            Xem Hồ sơ
          </div>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link
          to="/mom/profile/manage-health"
          className="text-[14px] flex text-center items-center hover:text-pinkColor"
        >
          <div className="flex text-center py-1 w-full">
            <AiOutlineFund className="w-4 h-4 mr-2 mt-1" />
            Quản lý sức khỏe
          </div>
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link
          to="/mom/profile/schedule"
          className="text-[14px] flex text-center items-center hover:text-pinkColor"
        >
          <div className="flex text-center py-1 w-full">
            <AiOutlineSchedule className="w-4 h-4 mr-2 mt-1" />
            Lịch trình
          </div>
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <>
          <div className="border-t border-gray-300"></div>
          <Link
            to="/"
            className="text-[14px] flex text-center items-center hover:text-pinkColor"
          >
            <div className="flex text-center py-1 w-full">
              <AiFillQuestionCircle className="w-4 h-4 mr-2 mt-1" />
              Giúp đỡ
            </div>
          </Link>
        </>
      ),
      key: "4",
    },
    {
      label: (
        <span
          className="text-[14px] flex text-center items-center hover:text-pinkColor  w-full"
          onClick={handleOpenMedalLogout}
        >
          <div className="flex text-center w-full">
            <AiOutlineLogout className="w-4 h-4 mr-2 mt-1" />
            Đăng xuất
          </div>
        </span>
      ),
      key: "5",
    },
  ];

  const menuDrop = (
    <Menu
      items={itemsUserDropdown}
      className="w-[90vw] sm:w-[250px] rounded-lg bg-white shadow-lg pointer-events-auto mt-2 border border-gray-300 p-4"
    />
  );

  return (
    <>
      <Dropdown
        overlay={menuDrop}
        trigger={["click"]}
        arrow={{ pointAtLeft: true }}
        className="cursor-pointer "
      >
        <Link
          onClick={(e) => e.preventDefault()}
          className="flex items-center"
        >
          <div className="bg-pinkColor text-[18px] text-white p-3 rounded-full cursor-pointer shadow-lg transition-transform duration-200 transform hover:scale-110 hover:bg-pink-500">
            <FaRegUser className="w-4 h-4" />
          </div>
        </Link>
      </Dropdown>
    </>
  );
};

export default UserDropdown;
