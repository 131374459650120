import React, { useEffect, useState } from 'react';
import { MyCalendar } from './Calender/MyCalender';
import { Modal } from './Modal';
import { EventForm } from './EventForm';
import dayjs from 'dayjs';
import { ProfileLayout } from '../Profile/Mom/ProfileLayout';
import { toast } from 'react-toastify';
import { createEntity, fetchEntities, updateEntity } from '../../api/odataClients/momClient';
import LoadingPage from '../LoadingPage/LoadingPage';
import { ACCOUNT_ID } from '../../api/axiosInstance';

export const Schedule = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [events, setEvents] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    };

    useEffect(() => {
        loadingEvent()
    }, [])

    const checkHour = (date) => {
        if (!(date.hour() === 0 && date.minute() === 0)) {
            date = date.add(1, 'hour');
        }

        return date.toDate();
    }

    const loadingEvent = async () => {
        const response = await fetchEntities("myschedules", {
            filter: `AccountId eq ${ACCOUNT_ID()}`
        });
        const rspEvents = response.value
        if (rspEvents && rspEvents.length > 0) {
            let arrayEvents = []
            for (let index = 0; index < rspEvents.length; index++) {
                const rspEvent = {
                    id: rspEvents[index].Id,
                    title: rspEvents[index].Title,
                    date: dayjs(rspEvents[index].DoAt).format("YYYY-MM-DD HH:mm"),
                    start: dayjs(rspEvents[index].DoAt).toDate(),
                    end: checkHour(dayjs(rspEvents[index].DoAt)),
                    // startTime: dayjs(rspEvents[index].StartTime).format("HH:mm a"),
                    // endTime: dayjs(rspEvents[index].EndTime).format("HH:mm a"),
                    content: rspEvents[index].Content,
                    status: rspEvents[index].Status ? "Completed" : "Pending",
                };
                arrayEvents.push(rspEvent)
            }
            setEvents(arrayEvents)
        }
        
    }

    const handleOpenModal = (isOpen = true) => {
        setSelectedEvent(null);
        setShowModal(isOpen);
    };

    const handleFormSubmit = async (newEvent) => {
        setIsLoading(true)
        try {
            if (selectedEvent) {
                const eventUpdate = {
                    Id: newEvent.id,
                    AccountID: ACCOUNT_ID(),
                    Title: newEvent.title,
                    Content: newEvent.content,
                    DoAt: dayjs(newEvent.date).toISOString(),
                    // StartTime: dayjs(newEvent.start),
                    // EndTime: dayjs(newEvent.end),
                    Status: newEvent.status === "Completed"
                }
                await updateEntity("myschedules", eventUpdate.Id, eventUpdate);
                setEvents(events.map(event =>
                    event === selectedEvent ? { ...selectedEvent, ...newEvent } : event
                ));
                handleOpenModal(false)
                toast.success("Cập nhập lịch thành công")

            } else {
                const eventAdd = {
                    AccountID: ACCOUNT_ID(),
                    Title: newEvent.title,
                    Content: newEvent.content,
                    DoAt: dayjs(newEvent.date).toISOString(),
                    // StartTime: dayjs(newEvent.start),
                    // EndTime: dayjs(newEvent.end),
                    Status: false
                }
                await createEntity("myschedules", eventAdd);
                setEvents([...events, newEvent]);
                handleOpenModal(false)
                toast.success("Thêm lịch thành công")
            }
        } catch (error) {
            toast.error(error.message)
        }
        finally {
            setIsLoading(false)
            loadingEvent()
        }

    };

    return (
        <>
            <LoadingPage isLoading={isLoading} />
            <ProfileLayout>
                <div className="relative flex items-start w-full">
                    <MyCalendar events={events} onSelectEvent={handleSelectEvent} />
                    <button
                        className="bg-pinkColor text-white px-4 py-2 rounded-lg ml-4"
                        onClick={handleOpenModal}
                    >
                        Thêm hoạt động mới
                    </button>
                    <Modal show={showModal} onClose={() => handleOpenModal(false)}>
                        <EventForm onSubmit={handleFormSubmit} selectedEvent={selectedEvent} />
                    </Modal>
                </div>
            </ProfileLayout>
        </>
    );
};