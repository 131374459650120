import { Input, Form } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Header from '../../../components/Header/Header';

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        newPassword: "", 
        confirmPassword: ""
    });

    const goBack = () => {
        navigate(-1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (values) => {
        navigate("/login");
    };

    return (
        <>
            <Header />
            <Link className="btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
                <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
                Quay lại
            </Link>
            <div className="flex items-center justify-center mt-32">
                <div className="container mx-auto px-7 w-full max-w-md">
                    <div className="text-center space-y-4">
                        <h4 className="text-4xl font-semibold mb-4">Cài lại mật khẩu</h4>

                        <Form onFinish={handleSubmit} layout="vertical">
                            <div className="space-y-3">
                                <Form.Item
                                    name="newPassword"
                                    rules={[
                                        { required: true, message: 'Hãy nhập mật khẩu mới' },
                                        {
                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                            message: 'Mật khẩu phải dài ít nhất 8 ký tự và bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt'
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        placeholder="Mật khẩu mới của bạn"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="confirmPassword"
                                    dependencies={['newPassword']}
                                    rules={[
                                        { required: true, message: 'Vui lòng xác nhận mật khẩu của bạn' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Hai mật khẩu không khớp nhau!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        placeholder="Xác nhận mật khẩu mới của bạn"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <button
                                        className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                                        type="primary"
                                        htmlType="submit"
                                        block
                                    >
                                        Đặt lại mật khẩu
                                    </button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
