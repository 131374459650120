import React, { useEffect, useRef, useState } from "react";
import avaImg from "../../../assets/image/other/avatar.png";
import { ProfileLayout } from "../../../components/Profile/Mom/ProfileLayout";
import { Form, Input, Button, DatePicker } from "antd";
import {
  fetchEntities,
  updateEntity,
} from "../../../api/odataClients/momClient";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { AddressEdit } from "../../../components/Profile/Mom/AddressEdit/AddressEdit";
import { ACCOUNT_ID } from "../../../api/axiosInstance";

export const ProfileInformation = () => {
  const [isProfile, setIsProfile] = useState(false);
  const [form] = Form.useForm();
  const [profileMom, setProfileMom] = useState("");
  const [loading, setLoading] = useState(true)
  const [address, setAddress] = useState()
  const [afFullName, setAfFullName] = useState()

  const profileInputRef = useRef(null);

  useEffect(() => {
    if (isProfile && profileInputRef.current) {
      profileInputRef.current.focus();
    }
  }, [isProfile]);

  const onChangeAddress = (value) => {
    setAddress(value)
  }

  const onFinish = async (values) => {
    let formData = {
      ID: ACCOUNT_ID(),
      FullName: values.fullName,
      CurrentAddress: address,
      PhoneNumber: values.phone,
      DateOfBirth: values.date,
    };
    try {
      await updateEntity("accounts", ACCOUNT_ID(), formData);
      toast.success("Chỉnh sửa thông tin thành công!");
      if (values.fullName !== afFullName) {
        const groupchats = await fetchEntities("groupchats", { filter: `contains(Name, '${afFullName}')` })
        setAfFullName(values.fullName)
        for (let index = 0; index < groupchats.value.length; index++) {
          let element = groupchats.value[index];
          element.Name = element.Name.replace(afFullName, values.fullName);
          await updateEntity("groupchats", element.Id, element)
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Chỉnh sửa thông tin thất bại!");
    }
    loadEntities()
    setIsProfile(false);
  };

  const loadEntities = async () => {
    try {
      const data = await fetchEntities(`accounts(${ACCOUNT_ID()})`, {
        select: [
          "FullName",
          "Email",
          "PhoneNumber",
          "CurrentAddress",
          "DateOfBirth",
        ],
      });
      setAfFullName(data.FullName)
      const formattedDate = data.DateOfBirth ? dayjs(data.DateOfBirth) : null;
      form.setFieldsValue({
        fullName: data.FullName,
        email: data.Email,
        phoneNumber: data.PhoneNumber,
        address: data.CurrentAddress,
        date: formattedDate,
      });
      setProfileMom(data);
    } catch (error) {
      console.error("Error fetching profile:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadEntities();
  }, []);

  const handleChangeProfileMom = () => {
    if (isProfile) {
      form.submit();
    } else {
      setIsProfile(true);
    }
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <>
      <LoadingPage isLoading={loading} />
      <ProfileLayout>
        <div className="p-5 lg:w-3/4 md:w-full mx-auto">
          <h2 className="text-2xl font-bold mb-6">Cập nhật thông tin</h2>
          <div className="flex items-center space-x-6 mb-6">
            <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] rounded-full overflow-hidden bg-white flex justify-center items-center">
              <img className="w-full h-full object-cover" src={avaImg} alt="" />
            </div>
            <Button
              onClick={handleChangeProfileMom}
              className="border-solid border-pinkColor border-2 px-4 py-1 mt-8 rounded-full text-pinkColor font-medium hover:bg-pinkColor hover:text-white"
            >
              {isProfile ? "Lưu thông tin" : "Sửa thông tin"}
            </Button>
          </div>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="space-y-4"
          >
            <div className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium">
              <label htmlFor="currentAddress">ID:</label>
              <p id="currentAddress" className="mt-2">MCC{ACCOUNT_ID()}</p>
            </div>
            {isProfile ? <Form.Item
              name="fullName"
              rules={[
                { required: true, message: "Hãy nhập họ và tên" },
              ]}
              label="Họ tên"
              className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium"
            >
              <Input
                className="border-none focus:outline-none pl-0 w-[100%] focus:outline-none focus:border-black focus:border-b-2"
                placeholder="Họ tên"
              />
            </Form.Item> :
              <>
                <div className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium">
                  <label htmlFor="currentAddress">Họ tên:</label>
                  <p id="currentAddress" className="mt-2">{profileMom.FullName}</p>
                </div>
              </>
            }


            {isProfile ?
              <AddressEdit editMode={isProfile} onSave={onChangeAddress} momAddress={profileMom?.CurrentAddress} /> :
              <>
                <div className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium">
                  <label htmlFor="currentAddress">Địa chỉ</label>
                  <p id="currentAddress" className="mt-2">{profileMom.CurrentAddress}</p>
                </div>
              </>}

            {isProfile ? <Form.Item
              name="date"
              label="Ngày sinh"
              rules={[
                { required: true, message: "Hãy nhập ngày sinh" },
              ]}
              className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium"
            >
              <DatePicker
                format="DD/MM/YYYY"
                className="border-none focus:outline-none pl-0 w-[100%] focus:outline-none focus:border-black focus:border-b-2"
                disabledDate={disabledDate}
              />
            </Form.Item> :
              <>
                <div className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium">
                  <label htmlFor="currentAddress">Ngày sinh</label>
                  <p id="currentAddress" className="mt-2">{dayjs(profileMom.DateOfBirth).format("YYYY-MM-DD")}</p>
                </div>
              </>}

            <div className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium">
              <label htmlFor="currentAddress">Email</label>
              <p id="currentAddress" className="mt-2">{profileMom.Email}</p>
            </div>

            {isProfile ? <Form.Item
              name="phoneNumber"
              label="Số điện thoại"
              rules={[
                { required: true, message: "Hãy nhập số điện thoại" },
              ]}
              className="w-full m-0 border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium"
            >
              <Input
                type="text"
                placeholder="Số điện thoại"
                className="border-none focus:outline-none pl-0 focus:outline-none focus:border-black focus:border-b-2"
              />
            </Form.Item> :
              <div className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full font-medium">
                <label htmlFor="currentAddress">Số điện thoại</label>
                <p id="currentAddress" className="mt-2">{profileMom.PhoneNumber}</p>
              </div>}
          </Form>
        </div>
      </ProfileLayout>
    </>
  );
};
