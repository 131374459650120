import React, { useEffect, useRef, useState } from "react";
import { Table, Input, Pagination, Tag } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import AdminLayout from "../../../components/Admin/AdminLayout";
import { useNavigate } from "react-router-dom";
import { fetchEntities } from "../../../api/odataClients/adminClient";
import { toast } from "react-toastify";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { FaFileSignature, FaUsers } from "react-icons/fa";
import debounce from 'lodash.debounce';

export const Contract = () => {
  const [contracts, setContracts] = useState([]);
  const [dataLength, setDataLength] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [statistical, setStatistical] = useState({
    totalContract: 0,
    ContractSigned: 0,
  });

  const loadStatic = async () => {
    const dataStatistical = await fetchEntities('contracts', { count: true });
    const toContract = dataStatistical['@odata.count']
    const ConSigned = dataStatistical.value.filter((contract) => contract.IsSigned).length;

    setStatistical({
      totalContract: toContract,
      ContractSigned: ConSigned,
    });
  }

  const loadEntities = async (searchValue = null) => {
    setLoading(true)
    try {
      const data = await fetchEntities('contracts', {
        filter: `contains(Account/FullName,'${searchValue ? searchValue : ''}')`,
        expand: ['Account'],
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ['Id desc']
      });
      setContracts(data.value)
      setDataLength(data['@odata.count']);
    } catch (err) {
      toast(err);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    loadStatic()
  }, [])

  useEffect(() => {
    loadEntities();
  }, [currentPage, pageSize]);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  
  const columns = [
    {
      title: "Tên người ký", dataIndex: "Account", key: "Account",
      render: (account) => (account ? account.FullName : "No Data")
    },
    {
      title: "Ngày ký", dataIndex: "CreatedAt", key: "CreatedAt",
      render: (text) => {
        if(!text) return "Chưa ký"
        const date = new Date(text);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Trạng thái", dataIndex: "IsSigned", key: "IsSigned", width: '15%',
      render: (IsSigned, contract) => (
        <div>
          <Tag style={{ width: '70px' }} color={IsSigned ? 'green' : 'red'}>
            {IsSigned ? 'Đã ký' : 'Chưa ký'}
          </Tag>
          <EyeOutlined
            className="text-[20px] cursor-pointer"
            onClick={() => handleViewDetail(contract.Id)}
          />
        </div>
      ),
    },
  ];

  const handleViewDetail = (contractId) => {
    navigate(`/admin/contract/${contractId}`, { state: { defaultSelectedValue: 4 } });
  };

  const debounceDropDown = useRef(
    debounce((searchValue) => {
      loadEntities(searchValue)
    }, 500)
  ).current;

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    debounceDropDown(searchValue);
  };

  React.useEffect(() => {
    return () => {
      debounceDropDown.cancel();
    };
  }, [debounceDropDown]);

  return (
    <AdminLayout>
      <LoadingPage isLoading={loading} />
      <div className='w-full flex justify-center grid grid-cols-2 gap-4 mb-10 w-[50%]'>
        <div className="bg-green-200 rounded-lg p-4 flex flex-col items-center">
          <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-12 h-12">
            <FaUsers className="text-white text-3xl" />
          </span>
          <div className="text-sm mt-2">Tổng số hợp đồng</div>
          <div className="text-3xl font-bold">{statistical.totalContract}</div>
        </div>
        <div className="bg-blue-200 rounded-lg p-4 flex flex-col items-center">
          <span className="bg-blue-500 p-2 rounded-full flex items-center justify-center w-12 h-12">
            <FaFileSignature className="text-white text-3xl" />
          </span>
          <div className="text-sm mt-2">Hợp đồng đã ký</div>
          <div className="text-3xl font-bold">{statistical.ContractSigned}</div>
        </div>
      </div>
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-semibold">Hợp đồng</h1>

        <Input
          className="w-1/4 ml-96"
          placeholder="Tìm...."
          prefix={<SearchOutlined />}
          size="large"
          onChange={handleInputChange}
        />
      </div>

      <Table
        dataSource={contracts}
        columns={columns}
        pagination={false}
      />

      <div className="mt-4 flex justify-end">
        <Pagination
          total={dataLength}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} của ${total} hợp đồng`
          }
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePaginationChange}
        />
      </div>
    </AdminLayout>
  );
};

export default Contract;
