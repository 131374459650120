import React, { useEffect, useState } from "react";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { toast } from "react-toastify";
import { ProfileLayout } from "../../../components/Profile/Mom/ProfileLayout";
import { Col, Form, Input, Pagination, Row, Table, Tabs, Tag } from "antd";
import QRCode from "../../../assets/image/other/QRCode.jpg";
import { createEntity, fetchEntities, updateEntity } from "../../../api/odataClients/caregiverClient";
import dayjs from "dayjs";
import { ACCOUNT_ID } from "../../../api/axiosInstance";

export const Wallet = () => {
  const [dataLength, setDataLength] = useState(0);
  const [myWallet, setMyWallet] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [requests, setRequsets] = useState()
  const [activeKey, setActiveKey] = useState("0");
  const [loading, setLoading] = useState(true);

  const onFinishWithdraw = async (values) => {
    try {
      const money = Number(values.MoneyWithdraw);

      if (money > myWallet.Money) {
        toast.error('Số tiền điểm phải thấp hơn số điểm có');
        return;
      }

      const walletRequest = {
        WalletID: myWallet.Id,
        Money: money,
        Action: `Yêu cầu rút tiền cho tài khoản ${ACCOUNT_ID()}`,
        Date: dayjs().toISOString(), 
        Status: false,
      };

      await createEntity('requestwallets', walletRequest);
      toast.success('Tạo yêu cầu thành công');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Lỗi tạo yêu cầu');
    }
  };

  const onFinishTransferMoney = async (values) => {
    try {
      const { MoneyTransfer, ReceiverWalletId, TransferNote } = values;
      let walletToAccount = {}
      if (MoneyTransfer > myWallet.Money) {
        toast.error('Số tiền chuyển phải thấp hơn số dư hiện tại');
        return;
      }

      const transferRequest = {
        WalletID: myWallet.Id,
        ToAccountID: Number(ReceiverWalletId),
        Money: Number(MoneyTransfer),
        Action: `Chuyển tiền: ${TransferNote}`,
        Date: dayjs().toISOString(),
        Status: true,
      };

      const response = await fetchEntities('wallets', {
        filter: `AccountID eq ${transferRequest.ToAccountID}`,
      });

      if (response.value.length <= 0) {
        toast.error("Người dùng này chưa cài đặt ví trên hệ thống")
        return;
      }

      walletToAccount = response.value[0]
      walletToAccount.Money += transferRequest.Money
      await createEntity('requestwallets', transferRequest);
      await updateEntity('wallets', walletToAccount.Id ,walletToAccount);
      const walletUpdate = {...myWallet, Money: myWallet.Money - transferRequest.Money}
      const notification = {
        AccountID: transferRequest.ToAccountID,
        Content: transferRequest.Action,
        NotificationAt: dayjs().toDate().toISOString(),
        CreatedAt: dayjs().toDate().toISOString()
      }
      await updateEntity('wallets', walletUpdate.Id, walletUpdate);
      await createEntity('notifications', notification);
      toast.success('Chuyển tiền thành công');
      setMyWallet(walletUpdate)
    } catch (error) {
      console.error('Error:', error);
      toast.error('Lỗi khi tạo yêu cầu chuyển tiền');
    }
  };

  const onFinishSaveCard = async (value) => {
    try {
      if (myWallet) {
        const wallet = {
          ...myWallet,
          Bank: value.Bank,
          NumberCard: value.NumberCard,
          CardholderName: value.CardholderName
        }
        await updateEntity('wallets', wallet.Id, wallet);
        toast.success('Cập nhập ví thành công')
      } else {
        const wallet = {
          AccountID: ACCOUNT_ID(),
          Bank: value.Bank,
          NumberCard: value.NumberCard,
          Money: 0,
          CardholderName: value.CardholderName
        }
        const response = await createEntity('wallets', wallet);
        setMyWallet(response)
        toast.success('Thêm ví thanh công')
      }
    } catch (error) {
      console.error('Error', error);
      toast.error('Lỗi khi thêm ví')
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchDataWallet = async () => {
    const data = await fetchEntities('wallets', {
      filter: `AccountID eq ${ACCOUNT_ID()}`,
    });
    setMyWallet(data.value[0])
  }

  const loadDataRequest = async () => {
    try {
      const data = await fetchEntities('requestwallets', {
        expand: ['ToAccount($select=FullName)'],
        select: ['WalletID', 'ToAccountID', 'Money', 'Action', 'Date', 'Status', 'Id'],
        filter: `WalletID eq ${myWallet.Id}`,
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ['Id desc']
      });

      setRequsets(data.value);
      setDataLength(data['@odata.count']);
    } catch (err) {
      toast(err);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadDataRequest();
  }, [currentPage, pageSize, myWallet]);

  useEffect(() => {
    fetchDataWallet()
  }, [])

  const columns = [
    {
      title: "Người nhận",
      dataIndex: "ToAccount",
      key: "ToAccount",
      render: (toAccount) => {
        return (toAccount?.FullName ? toAccount?.FullName : "Tôi");
      },
    },
    { title: "Số MC Point", dataIndex: "Money", key: "Money" },
    { title: "Nội dung", dataIndex: "Action", key: "Action" },
    { title: "Đặt vào ngày", dataIndex: "Date", key: "Date", render: (_, { date }) => { return dayjs(date).format("YYYY-MM-DD") } },
    {
      title: "Trạng thái",
      dataIndex: "Status",
      key: "Status",
      render: (status) => {
        return (
          <div>
            <Tag style={{ width: "60%" }} color={status ? "green" : "yellow"}>
              {status ? "Thành công" : "Đang chờ"}
            </Tag>
          </div>
        );
      },
    },
  ];

  const items = ["Giao dịch của bạn", "Nạp & Rút MC Point"];
  return (
    <>
      <LoadingPage />
      <LoadingPage isLoading={loading} />
      <ProfileLayout>
        <div className="p-5 md:w-full">
          <h1 className="text-2xl font-bold mb-6">Ví của tôi - ID: {myWallet?.Id ? myWallet?.Id : "Not yet"} - MC point: {myWallet?.Money ? myWallet?.Money : "0"}</h1>
          <Form
            className="flex justify-center"
            name=""
            initialValues={myWallet}
            layout="vertical"
            onFinish={onFinishSaveCard}
          >
            <div className="flex">
              <Form.Item
                label="Số tài khoản"
                name="NumberCard"
                style={{ marginRight: "20px", width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tài khoản!",
                  },
                ]}
              >
                <Input placeholder={myWallet ? myWallet.NumberCard?.slice(0, -5) + "*****" : "Số tài khoản"} type="text" className="p-2" />
              </Form.Item>

              <Form.Item
                label="Ngân hàng"
                name="Bank"
                style={{ marginRight: "20px", width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên ngân hàng!",
                  },
                ]}
              >
                <Input
                  placeholder={myWallet ? myWallet.Bank : "Tên ngân hàng"}
                  type="text"
                  className="p-2"
                />
              </Form.Item>

              <Form.Item
                label="Chủ thẻ"
                name="CardholderName"
                style={{ marginRight: "20px", width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên chủ thẻ!",
                  },
                ]}
              >
                <Input placeholder={myWallet ? myWallet.CardholderName : "Tên chủ thẻ"} type="text" className="p-2" />
              </Form.Item>

              <button type="submit" className="w-[50%] mt-7 h-[40px] px-2 bg-pinkColor text-white text-bold rounded-lg text-[16px]">
                Lưu thẻ
              </button>
            </div>
          </Form>
          {myWallet ? <><div className="border shadow-lg rounded-lg">
            <div className=" mb-4 p-4">
              <Tabs
                activeKey={activeKey}
                onChange={(key) => setActiveKey(key)}
                type="card"
                items={items.map((item, i) => ({
                  label: (
                    <span
                      style={{
                        fontWeight: activeKey === `${i}` ? "bold" : "normal",
                        color: "black",
                      }}
                      onClick={i === 0 ? loadDataRequest : () => { }}
                    >
                      {item}
                    </span>
                  ),
                  key: `${i}`,
                  children:
                    i === 0 ? (
                      <div className="m-4">
                        <Table
                          dataSource={requests}
                          columns={columns}
                          pagination={false}
                        />
                      </div>
                    ) : (
                      <Row>
                        <Col
                          span={24}
                          lg={12}
                          className="mb-6 lg:mb-0 lg:border-r"
                        >
                          <div className="flex flex-col items-center justify-center mb-4">
                            <h4 className="text-center text-xl text-pinkColor font-bold pb-4">
                              Tạo mã nạp tiền
                            </h4>
                            <img
                              src={QRCode}
                              alt="QRCode"
                              className="w-[200px] h-[300px] sm:w-[250px] sm:h-[350px] lg:w-[300px] lg:h-[400px]"
                            />
                          </div>
                          <Form layout="vertical" className="flex justify-center">
                            <Form.Item className="text-center"
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập số điểm nạp!",
                                },
                              ]}>
                              <Input
                                className="p-2 w-[80%] sm:w-[60%] lg:w-[80%]"
                                placeholder="Số MC Point"
                                type="text"
                              />
                              <div className="flex justify-center">
                                <button type="submit" className="p-1 w-[50%] sm:w-[40%] mt-4 bg-pinkColor text-white rounded-lg text-[16px]">
                                  Gửi
                                </button>
                              </div>
                            </Form.Item>
                          </Form>
                        </Col>

                        <Col span={24} lg={12}>
                          <div className="flex flex-col items-center justify-center h-full w-full">
                            <div className="w-[90%] sm:w-[80%] mb-6">
                              <h4 className="text-center text-xl text-pinkColor font-bold pb-4">
                                Tạo yêu cầu rút tiền
                              </h4>
                              <Form layout="vertical" onFinish={onFinishWithdraw}>
                                <Form.Item
                                  name="MoneyWithdraw"
                                  className="text-center"
                                  rules={[{ required: true, message: 'Vui lòng nhập số MC Point!' }]}
                                >
                                  <Input
                                    className="p-2 w-[100%]"
                                    placeholder="Số MC Point"
                                    type="number"
                                  />
                                </Form.Item>
                                <div className="flex justify-center">
                                  <button type="submit" className="p-1 w-[50%] sm:w-[40%] mt-4 bg-pinkColor text-white rounded-lg text-[16px]">
                                    Gửi
                                  </button>
                                </div>
                              </Form>
                            </div>

                            <div className="w-[90%] sm:w-[80%]">
                              <h4 className="text-center text-xl text-pinkColor font-bold pb-4">
                                Chuyển tiền
                              </h4>
                              <Form layout="vertical" onFinish={onFinishTransferMoney}>
                                <div className="flex flex-col sm:flex-row justify-center">
                                  <Form.Item
                                    name="MoneyTransfer"
                                    className="text-center"
                                    rules={[{ required: true, message: 'Vui lòng nhập số MC Point!' }]}
                                  >
                                    <Input className="p-2 mb-4 sm:mb-0 sm:mr-4" placeholder="Số MC Point" type="number" />
                                  </Form.Item>
                                  <Form.Item
                                    name="ReceiverWalletId"
                                    className="text-center ml-3"
                                    rules={[{ required: true, message: 'Vui lòng nhập ID người nhận!' }]}
                                  >
                                    <Input className="p-2" placeholder="ID ví tiền người nhận" type="text" />
                                  </Form.Item>
                                </div>
                                <Form.Item
                                  name="TransferNote"
                                  className="text-center"
                                  rules={[{ required: true, message: 'Vui lòng nhập nội dung chuyển điểm!' }]}
                                >
                                  <Input className="p-2" placeholder="Nội dung chuyển điểm" type="text" />
                                </Form.Item>
                                <div className="flex justify-center">
                                  <button type="submit" className="p-1 w-[50%] sm:w-[40%] mt-4 bg-pinkColor text-white rounded-lg text-[16px]">
                                    Gửi
                                  </button>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ),
                }))}
              />
            </div>
          </div>
            <div className="mt-4 flex justify-end">
              <Pagination
                total={dataLength}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize}
                current={currentPage}
                onChange={handlePaginationChange}
              />
            </div></> : <>
            <Tag color="red" className="text-center p-3 w-full">
              <strong> Hãy thêm ngân hàng trước khi thực hiện</strong>
            </Tag>
          </>}


          {/* Pagination */}

        </div>
      </ProfileLayout>
    </>
  );
};
export default Wallet;
