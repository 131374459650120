import { Tag, Tooltip } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react"
import { FaClock } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { ACCOUNT_ID, API_URL_PORT } from "../../api/axiosInstance";
import { createEntity, fetchEntities, updateEntity } from "../../api/odataClients/caregiverClient";
import { toast } from "react-toastify";
import CustomModal from "../Common/CustomModalConfirm";
import LoadingPage from "../LoadingPage/LoadingPage";

export const AcceptFormBookingService = ({ careservicebookingId, token, role }) => {
    const [serviceBooking, setServiceBooking] = useState()
    const [serviceImage, setServiceImage] = useState();
    const [address, setAddress] = useState();
    const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
    const [price, setPrice] = useState()
    const [myWallet, setMyWallet] = useState()
    const [enoughtPoint, setEnoughtPoint] = useState(false)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const rspBooking = await axios.get(`${API_URL_PORT}/odata/careservicebookings(${careservicebookingId})?$expand=CareService`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        })

        const imagePath = rspBooking.data.CareService.ImageUrl.split(" - ")[0].trim()
        const url = process.env.NODE_ENV === 'development' ?
            `https://momchild.life/api/s3/getimage?url=${imagePath}` :
            `${API_URL_PORT}/api/s3/getimage?url=${imagePath}`
        const respGetImage = await axios.get(url);
        setServiceImage(respGetImage.data.data.imageUrl)
        setServiceBooking(rspBooking.data)

        if (role !== "1") {
            const rpsWallet = await fetchEntities("wallets", { filter: `AccountId eq ${ACCOUNT_ID()}`, select: ["Money", "Id"] })
            setMyWallet(rpsWallet.value[0])
            const split = rspBooking.data.Address.split("-")
            setAddress([split[1], split[2], split[3]].join("-"))
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleChangeStatusOrder = (value) => {
        if (value === "Xác nhận") {
            setOpenPopupConfirm(true)
            setPrice(Math.ceil((serviceBooking.CareService.Price / 1000) * 0.1))
            setEnoughtPoint(Math.ceil((serviceBooking.CareService.Price / 1000) * 0.1) < myWallet?.Money)
            return;
        }

        changeStatusBooking(value)
    };

    const changeStatusBooking = async (value) => {
        const OrderId = serviceBooking.Id
        try {
            let careservicebooking = { Id: OrderId, Status: value, RemainAmount: serviceBooking.RemainAmount }
            if (value === "Hoàn thành") {
                careservicebooking.RemainAmount = 0
            }
            await updateEntity('careservicebookings', careservicebooking.Id, careservicebooking);
            toast.success('Cập nhập trạng thái thành công')
        } catch (error) {
            console.error('Error updating user:', error);
            toast.error('Cập nhập thất bại')
        }
        fetchData()
    }

    const handleConfirmModal = async () => {
        if (!enoughtPoint) {
            navigate("/caregiver/my-wallet", { state: { RequestDeposit: true } })
        } else {
            try {
                changeStatusBooking("Xác nhận")
                await updateEntity("wallets", myWallet.Id, { Id: myWallet.Id, Money: myWallet.Money - price })
                await createEntity("notifications", {
                    AccountID: serviceBooking?.AccountID,
                    Content: `Dịch vụ: ${serviceBooking.CareService.Title} của bạn đã được chấp thuận`,
                    CreatedAt: dayjs(),
                    IsRead: false
                })
                await createEntity("myschedules", {
                    AccountID: ACCOUNT_ID(),
                    Title: `${serviceBooking.CareService.Title}`,
                    Content: `${serviceBooking.CareService.Title}`,
                    DoAt: dayjs(serviceBooking.BookingAt).toISOString(),
                    Status: false,
                    InHour: serviceBooking.InHour
                })
                setOpenPopupConfirm(false)
                fetchData()
            } catch (error) {
                toast.error("lỗi xác nhận dịch vụ")
            }
        }
    }

    return <>
        <div className={`flex justify-center`}>
            <LoadingPage isLoading={loading} />
            <CustomModal isOpenModal={openPopupConfirm} handleConfirm={handleConfirmModal} handleCancel={() => { setOpenPopupConfirm(false) }}
                textConfirm={enoughtPoint ? "Chấp nhận" : "Nạp điểm"}
                header="Xác nhận dịch vụ"
                textCancel='Huỷ'
                content={(<><h2 className="text-[14px] text-black pb-3">Bạn có chắc chắn muốn xác nhận dịch vụ không?</h2>
                    <h2 className="text-[14px] text-black pb-3"><strong>Lưu ý:</strong> Muốn xác nhận dịch vụ cần có phí xác nhận là <strong>10%</strong> phí dịch vụ</h2>
                    <h2 className="text-[14px] text-black pb-3">tương đương với {price} điểm MCC</h2>
                    {enoughtPoint ?
                        <Tag className="text-[14px] text-black pb-3" color='blue'>Điểm của bạn: {myWallet?.Money} điểm</Tag> :
                        <Tag className="text-[14px] text-black pb-3" color='red'>Điểm của bạn không đủ: {myWallet?.Money} điểm</Tag>}</>)}
            />
            <div className="flex flex-row w-full border border-slate-300 rounded-lg overflow-hidden">
                {/* Image Section */}
                <div className="w-full p-4">
                    <img
                        src={serviceImage}
                        alt="Ảnh dịch vụ"
                        className="w-full h-[130px] rounded-xl"
                    />

                    <div className="flex justify-center items-center space-x-2 mb-2 w-full">
                        <Tag className={`flex items-center px-2 h-[30%] font-medium 
                                            ${serviceBooking?.Status === 'Đã huỷ' ? "bg-red-500 border border-red-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Hoàn thành' ? "bg-green-500 border border-green-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Đang chờ' ? "bg-slate-500 border border-slate-500 text-white" : ''}
                                            ${serviceBooking?.Status === 'Xác nhận' ? "bg-yellow-500 border border-yellow-500 text-white" : ''}
                                        `}>
                            {serviceBooking?.Status}
                        </Tag>
                    </div>

                    <div className="flex justify-center space-y-2 w-full">
                        {role !== "1" &&
                            <>
                                {serviceBooking?.Status === "Đang chờ" &&
                                    <button
                                        type="button"
                                        className="bg-blueColor p-2 opacity-50  w-[150px] px-5 border-none text-white rounded-lg"
                                        onClick={() => handleChangeStatusOrder("Xác nhận")}
                                    >
                                        Xác nhận
                                    </button>}


                                {serviceBooking?.Status === "Xác nhận" && (
                                    <button
                                        type="button"
                                        className="bg-blueColor p-2  w-[150px] px-5 border-none text-white rounded-lg"
                                        onClick={() => handleChangeStatusOrder("Hoàn thành")}
                                    >
                                        Hoàn thành
                                    </button>
                                )}
                            </>
                        }
                    </div>
                </div>

                {/* Content Section */}
                <div className="flex space-x-2 w-full items-center">
                    <div className="w-full">
                        <h3 className="text-lg font-semibold">
                            <Link className="hover:text-slate-700 " to={`/caregiver/service/${serviceBooking?.CareServiceID}`}>{serviceBooking?.CareService?.Title}</Link>
                        </h3>
                        <p className={`${role === "1" ? "text-gray-200" : "text-blue-600"} font-semibold`}>Giá tổng dịch vụ: {serviceBooking?.Price} VND</p>
                        <div className="flex items-center space-x-1">
                            <FaClock />
                            <span>{dayjs(serviceBooking?.BookingAt).format("DD/MM/YYYY HH:mm")}</span>
                            <Tag color="red" className="text-gray-500 text-sm">
                                {serviceBooking?.InHour} tiếng
                            </Tag>
                        </div>
                        <p>Địa chỉ hẹn: {role === "1" ? serviceBooking?.Address : address}</p>
                    </div>

                    {/* Buttons */}

                </div>
            </div>
        </div>
    </>
}