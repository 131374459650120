import React from 'react';
import LayoutCommon from '../LayoutCommon/LayoutCommon';
import { AuditOutlined, CommentOutlined } from '@ant-design/icons';

const SupporterLayout = ({ children }) => {
  return (
    <LayoutCommon
      sidebarItems={[
        {
          label: 'Quản lí đặt lịch',
          icon: AuditOutlined,
          route: '/supporter/booking-management'
        },
        {
          label: 'Dịch vụ khám',
          icon: CommentOutlined,
          route: '/supporter/examination-service'
        },
        {
          label: 'Trò chuyện',
          icon: CommentOutlined,
          route: '/supporter/chatting'
        },
      ]}
      tokenKey="authSupporterToken"
    >
      {children}
    </LayoutCommon>
  );
};

export default SupporterLayout;

