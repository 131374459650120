import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../../assets/image/logo/LOGO_MC_CARE__.png"

const { Sider } = Layout;

const SidebarCommon = ({ sidebarItems, tokenKey }) => {

  const items = sidebarItems.map(
    (item, index) => ({
      key: String(index + 1),
      icon: React.createElement(item.icon),
      label: item.label,
    })
  )

  const navigate = useNavigate();
  const { state } = useLocation();
  const defaultSelectedValue = state?.defaultSelectedValue ? `${state.defaultSelectedValue}` : "1";
  const handleMenuClick = (e) => {
      const route = sidebarItems[parseInt(e.key) - 1].route;
      navigate(route, { state: { defaultSelectedValue: parseInt(e.key) } });
  };

  return (
    <>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        style={{ backgroundColor: '#fff' }}
      >
        <div className="logo" style={{ padding: '20px', textAlign: 'center' }}>
          <img src={logo} alt="" className="w-24 mt-4" />
        </div>
        <Menu theme="light" mode="inline" defaultSelectedKeys={[defaultSelectedValue]} items={items} onClick={handleMenuClick} />

      </Sider>
    </>
  );
};

export default SidebarCommon;