const BMIHealthMom = {
    Week: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
    underweight: [
        "0.55-2.17", "0.55-2.18", "0.55-2.19", "0.55-2.2", "0.55-2.21", "0.55-2.22",
        "0.55-2.23", "0.55-2.24", "0.55-2.25", "0.55-2.26", "0.55-2.27", "0.55-2.28",
        "0.55-2.29", "1-2.76", "1.45-3.32", "1.9-3.94", "2.35-4.53", "2.8-5.12",
        "3.25-5.71", "3.7-6.3", "4.15-6.89", "4.6-7.48", "5.05-8.07", "5.5-8.66",
        "5.95-9.25", "6.4-9.84", "6.85-10.43", "7.3-11.02", "7.75-11.61", "8.2-12.2",
        "8.65-12.79", "9.1-13.38", "9.55-13.97", "10-14.56", "10.45-15.15",
        "10.9-15.74", "11.35-16.33", "11.8-16.92", "12.25-17.51", "12.7-18.1"
    ],
    normal: [
        "1.58-3.75", "1.58-3.76", "1.58-3.77", "1.58-3.78", "1.58-3.79", "1.58-3.8",
        "1.58-3.81", "1.58-3.82", "1.58-3.83", "1.58-3.84", "1.58-3.85", "1.58-3.86",
        "1.58-3.87", "1.94-4.2", "2.3-4.65", "2.66-5.11", "3.02-5.55", "3.38-6.6",
        "3.74-6.45", "4.1-7.08", "4.46-7.35", "4.82-7.8", "5.18-8.25", "5.54-8.7",
        "5.9-9.15", "6.26-9.6", "6.62-10.05", "6.98-10.5", "7.34-10.95", "7.7-11.4",
        "8.06-11.85", "8.42-12.3", "8.78-12.75", "9.14-13.2", "9.5-13.65", "9.86-14.1",
        "10.22-14.55", "10.58-15", "10.94-15.45", "11.3-15.9"
    ],
    overweight: [
        "0.59-2.69", "0.59-2.7", "0.59-2.71", "0.59-2.72", "0.59-2.73", "0.59-2.74",
        "0.59-2.75", "0.59-2.76", "0.59-2.77", "0.59-2.78", "0.59-2.79", "0.59-2.8",
        "0.59-2.81", "0.82-3.01", "1.13-3.65", "1.38-4.3", "1.51-3.97", "1.74-4.29",
        "1.97-4.61", "2.2-4.93", "2.43-5.25", "2.66-5.57", "2.89-5.89", "3.12-6.21",
        "3.35-6.53", "3.58-6.85", "3.81-7.17", "4.04-7.49", "4.27-7.81", "4.5-8.13",
        "4.73-8.45", "4.96-8.77", "5.19-9.09", "5.42-9.41", "5.65-9.73", "5.88-10.05",
        "6.11-10.37", "6.34-10.69", "6.57-11.01", "6.8-11.33"
    ],
    obese: [
        "0.14-1.81", "0.14-1.82", "0.14-1.83", "0.14-1.84", "0.14-1.85", "0.14-1.86",
        "0.14-1.87", "0.14-1.88", "0.14-1.89", "0.14-1.9", "0.14-1.91", "0.14-1.92",
        "0.14-1.93", "0.32-2.08", "0.68-2.62", "0.91-3.05", "1.06-3.41", "1.22-3.73",
        "1.38-4.05", "1.53-4.37", "1.58-3.97", "1.76-4.24", "1.94-4.51", "2.12-4.78",
        "2.28-5.04", "2.46-5.32", "2.66-5.59", "2.84-5.86", "3.02-6.13", "3.2-6.4",
        "3.38-6.67", "3.56-6.94", "3.74-7.21", "3.92-7.48", "4.1-7.75", "4.28-8.02",
        "4.46-8.29", "4.64-8.56", "4.82-8.83", "5-9.1"
    ]
};

export default BMIHealthMom;