import { Form, Input} from 'antd';
import React from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { createEntity, updateEntity } from '../../../api/odataClients/caregiverClient';
import dayjs from 'dayjs';
import { ACCOUNT_ID } from '../../../api/axiosInstance';


export const FormRequestWallet = ({ show, onClose, dataWallet, onSuccess }) => {
    if (!show) return <></>;

    const hanldeSubmit = async (value) => {
        try {
            const money = Number(value.MoneyWithdraw);
      
            if (money > dataWallet.Money) {
              toast.error('Số tiền điểm phải thấp hơn số điểm có');
              return;
            }
      
            const walletRequest = {
              WalletID: dataWallet.Id,
              Money: money,
              Action: `Yêu cầu rút tiền cho tài khoản ${ACCOUNT_ID()}`,
              Date: dayjs().toISOString(), 
              Status: false,
            };
            
            await createEntity('requestwallets', walletRequest);
            const updateWallet = {...dataWallet, Money: dataWallet.Money - money}
            await updateEntity("wallets", updateWallet.Id, updateWallet);
            toast.success("Tạo yêu cầu thành công");
            onSuccess();
            onClose();
          } catch (error) {
            console.error('Error:', error);
            toast.error('Lỗi tạo yêu cầu');
          }
    }

    return (
        <>
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white h-[auto] w-[600px] p-10 rounded-lg shadow-lg relative">
                    <h3 className='text-3xl text-center font-bold pb-4 text-pinkColor'>Tạo yêu cầu rút tiền</h3>
                    <button className="absolute top-2 right-2" onClick={onClose}>
                        <FaRegTimesCircle className='w-6 h-6 m-2' />
                    </button>
                    <Form onFinish={hanldeSubmit} initialValues={dataWallet}>

                        <Form.Item
                            rules={[{ required: true, message: 'Hãy nhập số MC Point' }]}
                            className='mb-0 mt-3 w-full'
                            name="MoneyWithdraw"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Số MC Point"
                            />
                        </Form.Item>

                        <div className='flex'>
                            <Form.Item
                                rules={[{ required: true, message: 'Hãy nhập tên ngân hàng' }]}
                                className='mb-0 mt-3 mr-3 w-full'
                                name="Bank"
                            >
                                <Input
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                    placeholder="Tên ngân hàng"
                                />
                            </Form.Item>

                            <Form.Item
                                rules={[{ required: true, message: 'Hãy nhập số tài khoản' }]}
                                className='mb-0 mt-3 w-full'
                                name="NumberCard"
                            >
                                <Input
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                    placeholder="Số tài khoản"
                                />
                            </Form.Item>
                        </div>

                        <Form.Item
                            className='mb-0 mt-3 w-full'
                            rules={[{ required: true, message: 'Hãy nhập tên chủ thẻ' }]}
                            name="CardholderName"
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder='Tên chủ thẻ'
                               
                            />
                        </Form.Item>
                        
                        <div className='flex gap-4 items-center justify-center p-2 mx-4'>
                            <button onClick={onClose} className='bg-pinkColor px-14 mt-4 py-3 rounded-lg text-[18px] text-white hover:opacity-70'>Huỷ</button>
                            <button type="submit" className='bg-white px-14 mt-4 py-3 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>Gửi</button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};
