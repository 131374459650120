import React, { useState } from "react";
import Header from "../components/Header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import axios from "axios";
import { API_URL_PORT } from "../api/axiosInstance";
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { toast } from "react-toastify";
import { getRoleFromToken, getIdFromToken } from '../utils/authenToken';
import LoadingPage from '../components/LoadingPage/LoadingPage';
import Google from "../assets/image/social/google.svg";

const Register = () => {
  const navigate = useNavigate();
  const information = useLocation();
  const { state } = information;
  const [isLoading, setIsLoading] = useState(false);

  const handleFinishSubmit = (value) => {
    navigate("/register-form", { state: {
      Email: value.Email
    }});
  }

  const onLoginGoogleSuccess = async (response) => {
    setIsLoading(true)
    try {
      const apiResponse = await axios.post(`${API_URL_PORT}/api/auth/logingoogle`, {
        token: response.credential,
        accessToken: response.access_token
      });
      if(apiResponse.data.status === "success"){
        saveToken(apiResponse.data.data.token);
      } else if(apiResponse.data.data) {
        navigate("/register-form", { state: {
          Email: apiResponse.data.data.email,
          FullName: apiResponse.data.data.fullName
        }});
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Đăng nhập thất bại. Vui lòng thử lại!");
      }
    }finally{
      setIsLoading(false)
    }
  };

  useGoogleOneTapLogin({
    onSuccess: response => onLoginGoogleSuccess(response)
  });

  const loginGoogle = useGoogleLogin({
    onSuccess: response => onLoginGoogleSuccess(response)
  });

  function saveToken(token){
    const role = Number(getRoleFromToken(token));
    const id = Number(getIdFromToken(token));

    let storageKey = "";

    switch (role) {
      case 1:
        storageKey = "authMomToken";
        navigate("/mom/home");
        break;
      case 2:
        storageKey = "authCaregiverToken";
        navigate("/caregiver/dashboard");
        break;
      case 3:
        storageKey = "authSupporterToken";
        navigate("/supporter/booking-management");
        break;
      case 4:
        storageKey = "authAdminToken";
        navigate("/admin/dashboard");
        break;
      default:
        console.error("Invalid role");
        break;
    }

    if (storageKey) {
      localStorage.setItem(storageKey, token);
      localStorage.setItem("Id", id)
    }
  }

  const validateEmail = async (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value) {
      return Promise.reject(new Error('Hãy nhập địa chỉ email của bạn'));
    } else if (emailRegex.test(value)) {
      const email = value.trim();
      const response = await axios.get(`${API_URL_PORT}/api/auth/checkemailregister?email=${email}`);
      if (response.data.status === "success") {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Email đã được đăng ký'));
    } else {
      return Promise.reject(new Error('Hãy nhập Email hợp lệ'));
    }
  };

  return (
    <>
      <LoadingPage isLoading={isLoading}/>
      <Header />
      <div className="flex items-center justify-center mt-32">
        <div className="container mx-auto px-7 w-full max-w-md">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Tạo tài khoản</h4>
            <p className="text-sm">
              Nhập địa chỉ email của bạn để đăng ký tài khoản
            </p>

            <div className="relative mt-6 py-3">
              <div className="flex items-center justify-center">
                <hr className="w-full border-gray-300" />
                <p className="absolute bg-white px-2 text-sm">
                  <Link to="/login" className="font-bold">
                    Đăng nhập
                  </Link>
                  <span className="text-gray-500 ml-1">khi đã có tài khoản</span>
                </p>
              </div>
            </div>

            <Form onFinish={handleFinishSubmit} layout="vertical" initialValues={state}>
              <div className="space-y-3">
                <Form.Item
                  name="Email"
                  rules={[
                    {
                      validator: validateEmail
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <Input
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Email của bạn"
                    value={state?.Email}
                  />
                </Form.Item>

                <Form.Item>
                  <button
                    className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    Đăng ký
                  </button>
                </Form.Item>
              </div>
            </Form>

            <div className="relative mt-6 py-3">
              <div className="flex items-center justify-center">
                <hr className="w-full border-gray-300" />
                <span className="absolute bg-white px-2 text-gray-500 text-sm">
                  hoặc tiếp tục với
                </span>
              </div>
            </div>

            <button
              className="relative bg-white border py-2 w-full text-black rounded-lg flex items-center justify-center mt-4"
              type="button"
              onClick={() => loginGoogle()}
            >
              <div className="absolute mr-[90%]">
                <img src={Google} alt="Google" className="p-2" />
              </div>
              <span>Đăng nhập bằng Google</span>
            </button>

            <p className="text-sm mt-6">
              Bằng cách nhấp vào Đăng ký, bạn đồng ý với chúng tôi
              <Link className="text-black-500 font-bold ml-1" to="#">
                Điều khoản dịch vụ
              </Link>{" "}
              và
              <Link to="#" className="text-black-500 font-bold ml-1">
                Chính sách bảo mật
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
