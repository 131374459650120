import React, { useState } from 'react'
import Header from '../../../components/Header/Header'
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { DatePicker, Form, Input } from 'antd';
import dayjs from 'dayjs';

const PregnantMothersRegister = () => {
  const navigate = useNavigate();
  const information = useLocation();
  let { state } = information

  const calculateAge = (dateOfBirth) => {
    const today = dayjs();
    const birthDate = dayjs(dateOfBirth);
    const age = today.diff(birthDate, 'year');
    return age;
  };

  let account = { ...state }
  const [momData, setMomData] = useState({
    Height: 0,
    Weight: 0,
    Age: Number(calculateAge(state.DateOfBirth)),
  })

  const [pregnantData, setPregnantData] = useState({
    GestationalAge: 5,
    ExpectedDateOfBirth: null,
    EstimateFetalWeight: 0
  })

  const handleFinish = async (values) => {
    const heighMeter = momData.Height / 100;
    const BMIIndex = momData.Weight / (heighMeter * heighMeter)
    account = { ...account, DateOfBirth: dayjs(account.DateOfBirth).toISOString() }
    const mom = { ...momData, BMIIndex: BMIIndex.toFixed(2) }
    navigate('/confirm-otp-code', {state: {account: account, momData: mom, pregnantData}})
  };

  const handleInputChangeMom = (e) => {
    const { name, value } = e.target

    setMomData((prev) => ({ ...prev, [name]: Number(value) }))
  }

  const handleInputChangePregnant = (e) => {
    const { name, value } = e.target

    setPregnantData((prev) => ({ ...prev, [name]: Number(value) }))
  }

  const calculateGestationalAge = (dueDateStr) => {
    if (!dueDateStr) return;

    const dueDate = dayjs(dueDateStr);
    const today = dayjs();
    const totalPregnancyDays = 280;

    const pregnancyStartDate = dueDate.subtract(totalPregnancyDays, 'day');
    const totalDaysSincePregnancyStart = today.diff(pregnancyStartDate, 'day');
    const weeksSincePregnancyStart = Math.ceil(totalDaysSincePregnancyStart / 7);
    return weeksSincePregnancyStart > 0 ? weeksSincePregnancyStart : 0;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />
      <div className="cursor-pointer btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Quay lại
      </div>

      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Thông tin chi tiết cho bà mẹ mang thai</h4>
            <p className="text-sm">
              Nhập thông tin của bạn
            </p>
            <Form layout="vertical" onFinish={handleFinish} initialValues={account} className="space-y-3">
              {/* Full Name */}
              <Form.Item
                className='m-0'
                name="FullName"
                label="Họ và tên:"
              >
                <Input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  disabled
                />
              </Form.Item>

              <div className="flex space-x-3">
                <Form.Item
                  name="Email"
                  label="Email:"
                  className="w-full m-0"
                >
                  <Input
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  name="PhoneNumber"
                  label="Số điện thoại:"
                  className="w-full m-0"
                >
                  <Input
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    disabled
                  />
                </Form.Item>
              </div>

              <Form.Item
                name="DateOfBirth"
                label="Ngày sinh:"
                className="w-ful m-0"
              >
                <Input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  disabled
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                name="CurrentAddress"
                label="Địa chỉ hiện tại:"
              >
                <Input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  disabled
                />
              </Form.Item>

              <Form.Item
                name="ExpectedDateOfBirth"
                label="Ngày sinh dự tính:"
                rules={[
                  { required: true, message: 'Hãy nhập Ngày sinh dự tính' },
                  {
                    validator: (_, value) => {
                      if (!value || value.isBefore(dayjs(), 'day')) {
                        return Promise.reject(new Error('Ngày sinh dự tính không thể ở quá khứ'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <DatePicker
                  name="ExpectedDateOfBirth"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  placeholder="Ngày sinh dự tính"
                  format="DD/MM/YYYY"
                  onChange={(value) => {
                    if (value) {
                      const GestationalAge = calculateGestationalAge(value)
                      setPregnantData((prev) => ({ ...prev, ExpectedDateOfBirth: value.toISOString(), GestationalAge: GestationalAge }));
                    }
                  }}
                />
              </Form.Item>
              <div className="flex space-x-3">
                <div className='w-full'>
                  <p className='text-start'><span style={{ color: "red" }}>*</span> Tuần tuổi (Weeks):</p>
                  <Input
                    value={pregnantData && pregnantData?.GestationalAge}
                    readOnly
                    className="w-full mt-2 p-3 border border-gray-300 rounded-lg"
                    placeholder='Tuổi thai kỳ'
                    name="GestationalAge"
                  />
                </div>

                <Form.Item
                  name="EstimateFetalWeight"
                  label="Cân nặng thai nhi (kg):"
                  className="w-full"
                  rules={[{ required: true, message: 'Nhập cân nặng thai nhi' }]}
                >
                  <Input
                    name="EstimateFetalWeight"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Cân nặng thai nhi"
                    onChange={handleInputChangePregnant}
                  />
                </Form.Item>
              </div>

              <div className="flex space-x-3">
                <Form.Item
                  name="Weight"
                  label="Cân nặng của mẹ hiện tại (kg):"
                  className="w-full"
                  rules={[{ required: true, message: 'Nhập cân nặng của bạn' }]}
                >
                  <Input
                    name="Weight"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Cân nặng hiện tại"
                    onChange={handleInputChangeMom}
                  />
                </Form.Item>

                <Form.Item
                  name="Height"
                  label="Chiều cao của mẹ hiện tại (cm):"
                  className="w-full"
                  rules={[{ required: true, message: 'Hãy nhập Chiều cao của bạn' }]}
                >
                  <Input
                    name="Height"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    placeholder="Chiều cao hiện tại"
                    onChange={handleInputChangeMom}
                  />
                </Form.Item>
              </div>

              <Form.Item>
                <button
                  type="primary"
                  htmlType="submit"
                  className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                >
                  Đăng ký
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

    </>
  )
}

export default PregnantMothersRegister
