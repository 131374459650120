import { DatePicker, Form, Input, Select } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from '../../api/axiosInstance';
import { createEntity, updateEntity } from '../../api/odataClients/momClient';
import LoadingPage from '../LoadingPage/LoadingPage';

export const ChildForm = ({ isOpen, onClose, onSuccess, initialData, imageUpdate }) => {
  const [imageError, setImageError] = useState('');
  const [imageUpload, setImageUpload] = useState();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs())
  const handleClosePopup = () => {
    setLoading(false)
    onSuccess()
    onClose()
  }

  const handleDateChange = (value) => {
    setDate(value.toISOString())
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setImageError('Kích thước ảnh không được vượt quá 5MB');
      } else {
        setImageError('');
        const formData = new FormData();
        formData.append('image', file);
        setImageUpload(formData);
      }
    }
  };

  const calculateBMI = (weight, heightInCm) => {
    const heightInMeters = heightInCm / 100;
    const BMIIndex = (weight / (heightInMeters * heightInMeters)).toFixed(2)
    return Number(BMIIndex);
  };

  const handleSubmit = async (value) => {
    setLoading(true)
    if (imageError) {
      alert('Vui lòng tải ảnh hợp lệ dưới 5MB');
      return;
    }
    let child = {
      HeadCircumference: Number(value.HeadCircumference),
      Height: Number(value.Height),
      Name: value.Name,
      Sex: value.Sex,
      Weight: Number(value.Weight),
      DateOfBirth: date,
      AccountID: ACCOUNT_ID(),
      BMIIndex: calculateBMI(Number(value.Weight), Number(value.Height))
    }
    if (initialData) { // update
      let imageUrl = initialData.URLImage
      if (imageUpload) {
        const urlUploadImage = process.env.NODE_ENV === 'development' ?
          `https://momchild.life/api/s3/updateimage?url=${imageUrl.trim()}` :
          `${API_URL_PORT}/api/s3/updateimage?url=${imageUrl.trim()}`
        const respUploadImage = await axios.post(urlUploadImage, imageUpload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${TOKEN_API}`
          }
        });
        imageUrl = respUploadImage.data.data.imageUrl;
      }
      child = { ...child, URLImage: imageUrl }

      await updateEntity("childhealths", initialData.Id, { ...initialData, ...child })
    } else { // add
      let imageUrl = null;
      if (imageUpload) {
        const urlUploadImage = process.env.NODE_ENV === 'development' ? "https://momchild.life/api/s3/uploadimage" : `${API_URL_PORT}/api/s3/uploadimage`
        const respUploadImage = await axios.post(urlUploadImage, imageUpload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${TOKEN_API}`
          }
        });
        imageUrl = respUploadImage.data.data.imageUrl;
      }

      child = { ...child, URLImage: imageUrl }
      await createEntity("childhealths", child)
    }

    handleClosePopup()
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <LoadingPage isLoading={loading} />
      <div className="bg-white p-8 rounded-lg shadow-lg w-[700px] sm:w-[500px]">
        <h2 className="text-2xl font-bold mb-4 text-center text-pinkColor">{initialData ? 'Chỉnh sửa thông tin' : 'Thêm bé mới'}</h2>
        <Form initialValues={initialData} layout='vertical' onFinish={handleSubmit}>
          <Form.Item label="Tên của bé:" rules={[{ required: true, message: "Hãy nhập tên của bé" }]} name="Name">
            <Input />
          </Form.Item>
          <Form.Item
            className={`${initialData && "p-0 m-0"}`}
            label="Ảnh của bé:"
            rules={[
              { required: true, message: "Hãy tải ảnh của bé lên" },
              {
                validator: (_, value) => {
                  if (value && value.file.size > 5 * 1024 * 1024) {
                    return Promise.reject(new Error("Kích thước ảnh không được vượt quá 5MB"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type="file" accept="image/*" onChange={handleFileChange} />
          </Form.Item>
          <div className='flex justify-center w-full'>
            {initialData &&
              <img
                src={imageUpdate}
                alt="Child"
                className="w-[70%] mb-3 h-[200px] object-cover rounded-lg shadow-md"
              />}
          </div>
          {imageError && <p className="text-red-500 text-sm mt-2">{imageError}</p>}
          <div className='w-full space-x-3 flex'>
            <Form.Item className='w-full' label="Ngày sinh của bé:" rules={[{ required: true, message: "Hãy Chọn ngày sinh của bé" }]}>
              <DatePicker className='w-full' format="DD/MM/YYYY" defaultValue={dayjs(initialData?.DateOfBirth)} onChange={handleDateChange} />
            </Form.Item>
            <Form.Item className='w-full' defaultValue={true} label="Giới tính của bé:" rules={[{ required: true, message: "Hãy chọn giới tính của bé bé" }]} name="Sex">
              <Select>
                <Select.Option value={true}>
                  Bé Trai
                </Select.Option>
                <Select.Option value={false}>
                  Bé gái
                </Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className='w-full space-x-3 flex'>
            <Form.Item className='w-full' label="Cân nặng của bé (Kg):" rules={[{ required: true, message: "Hãy nhập cân nặng của bé" }]} name="Weight">
              <Input />
            </Form.Item>
            <Form.Item className='w-full' label="Chiều cao của bé (cm):" rules={[{ required: true, message: "Hãy nhập chiều cao của bé" }]} name="Height">
              <Input />
            </Form.Item>
          </div>
          <Form.Item label="Chu vi vòng đầu của bé (cm):" rules={[{ required: true, message: "Hãy nhập chu vi vòng đầu của bé" }]} name="HeadCircumference">
            <Input />
          </Form.Item>

          <div className="flex justify-center space-x-4">
            <button className="bg-pinkColor py-2 px-10 text-white rounded-lg" type='submit'>
              {initialData ? 'Cập nhật' : 'Thêm'}
            </button>

            <button
              className="bg-gray-300 py-2 px-10 rounded-lg"
              onClick={handleClosePopup}
            >
              Huỷ
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}
