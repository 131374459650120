import React, { useEffect, useState } from "react";
import { Table, Pagination, Radio, Button } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import AdminLayout from "../../../components/Admin/AdminLayout";
import {
  fetchEntities,
  updateEntity,
} from "../../../api/odataClients/adminClient";
import { toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import CustomModal from "../../../components/Common/CustomModalConfirm";

export const RequestWallet = () => {
  const [requestWallets, setRequestWallets] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statistical, setStatistical] = useState({
    totalRequest: 0,
    done: 0,
    notYet: 0,
  });
  const [loading, setLoading] = useState(true);
  const [valueFilter, setValueFilter] = useState("");
  const [showInforWallet, setShowInforWallet] = useState(false);
  const [walletSelect, setWalletSelect] = useState();

  const loadEntities = async () => {
    let filter = valueFilter ? ` and ${valueFilter}` : ""

    try {
      const data = await fetchEntities("requestWallets", {
        filter: `ToAccount eq null ${filter}`,
        expand: ["Wallet", "ToAccount($select=FullName)"],
        count: true,
        skip: (currentPage - 1) * pageSize,
        top: pageSize,
        orderBy: ["Id desc"],
      });
      setRequestWallets(data.value);

      setDataLength(data["@odata.count"]);

    } catch (err) {
      toast(err);
    } finally {
      setLoading(false);
    }
  };

  const loadStatic = async () => {
    const dataStatistical = await fetchEntities('requestWallets', { count: true });

    const doneRequest = dataStatistical.value.filter((request) => request.Status === true).length;
    const notYetRequest = dataStatistical.value.filter((request) => request.Status === false).length;

    setStatistical({
      totalRequest: dataStatistical['@odata.count'],
      done: doneRequest,
      notYet: notYetRequest,
    });
  }

  useEffect(() => {
    loadStatic();
  }, [])

  useEffect(() => {
    loadEntities();
  }, [currentPage, pageSize, valueFilter]);

  const handleChangeStatusRequest = async (isActive, recordRequest) => {
    const RequestId = recordRequest.Id;
    try {
      await updateEntity("requestWallets", RequestId, {
        Id: RequestId,
        Status: isActive,
      });

      toast.success("Cập nhập trạng thái thành công");
    } catch (error) {
      console.error("Error updating request:", error);
      toast.error("Lỗi cập nhập trạng thái");
    }
    loadEntities()
    loadStatic();
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleFilterSelection = (e) => {
    const { value } = e.target;
    setValueFilter(value);
  };

  const toggleShowInfor = (infor, money) => {
    setWalletSelect({...infor, requestMoney: money})
    setShowInforWallet(true)
  }

  const columns = [
    { title: "Nội dung", dataIndex: "Action", key: "Action" },
    {
      title: "Ngày", dataIndex: "Date", key: "Date",
      render: (text) => {
        const date = new Date(text);
        return date.toLocaleDateString();
      },
    },
    { title: "Số điểm", dataIndex: "Money", key: "Money" },
    {
      title: "Trạng thái",
      dataIndex: "Status",
      key: "Status",
      render: (status, record) => (
        <>
          {status ?
            <CheckCircleOutlined onClick={() => handleChangeStatusRequest(false, record)} className="text-green text-2xl" style={{ color: "green" }} /> :
            <CloseCircleOutlined onClick={() => handleChangeStatusRequest(true, record)} className="text-red text-2xl" style={{ color: "red" }} />}
        </>
      ),
    },
    {
      title: "Thông tin", dataIndex: "Date", key: "Date",
      render: (_, record) => {
        return <Button onClick={() => toggleShowInfor(record.Wallet, record.Money)} type="primary" className="bg-pinkColor py-5 hover:bg-pinkColor">Xem thông tin</Button>
      },
    },
  ];

  return (
    <AdminLayout>
      <CustomModal isOpenModal={showInforWallet} handleCancel={() => { setShowInforWallet(false) }}
        header="Thông tin người đặt"
        textCancel='Đóng'
        content={(<><div className='w-100'>
          <h2 className="text-[14px] text-black pb-3">Ngân hàng: {walletSelect?.Bank}</h2>
          <h2 className="text-[14px] text-black pb-3">Số Tài khoản: {walletSelect?.NumberCard}</h2>
          <h2 className="text-[14px] text-black pb-3">Tên chủ tài khoản: {walletSelect?.CardholderName}</h2>
          <h2 className="text-[14px] text-black pb-3">Số tiền cần rút:<br/> {walletSelect?.requestMoney * 1000} VND</h2>
        </div></>)}
      />
      <LoadingPage isLoading={loading} />
      <Radio.Group
        onChange={handleFilterSelection}
        className="grid grid-cols-3 gap-4 mb-10"
        defaultValue={valueFilter}
      >
        <Radio.Button className="shadow p-0 rounded" value="" style={{ height: "auto" }}>
          <div className="bg-blue-200 rounded-lg p-4">
            <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-12 h-12">
              <ProfileOutlined className="text-white text-3xl" />
            </span>
            <div className="text-sm mt-2">Tổng yêu cầu rút tiền</div>
            <div className="text-3xl font-bold">{statistical.totalRequest}</div>
          </div>
        </Radio.Button>
        <Radio.Button
          className="shadow p-0 rounded"
          value="Status eq true"
          style={{ height: "auto" }}
        >
          <div className="bg-green-200 rounded-lg p-4">
            <span className="bg-green-500 p-2 rounded-full flex items-center justify-center w-12 h-12">
              <CheckCircleOutlined className="text-white text-2xl" />
            </span>
            <div className="text-sm mt-2">Đã hoàn thành</div>
            <div className="text-3xl font-bold">{statistical.done}</div>
          </div>
        </Radio.Button>
        <Radio.Button
          className="shadow p-0 rounded"
          value="Status eq false"
          style={{ height: "auto" }}
        >
          <div className="bg-red-200 rounded-lg p-4">
            <span className="bg-red-500 p-2 rounded-full flex items-center justify-center w-12 h-12">
              <CloseCircleOutlined className="text-white text-2xl" />
            </span>
            <div className="text-sm mt-2">Đang chờ</div>
            <div className="text-3xl font-bold">{statistical.notYet}</div>
          </div>
        </Radio.Button>
      </Radio.Group>

      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-semibold">Yêu cầu rút tiền</h1>

        {/* <Input
          className="w-1/4"
          placeholder="Tìm theo tên..."
          prefix={<SearchOutlined />}
          size="large"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        /> */}
      </div>

      <Table dataSource={requestWallets} columns={columns} pagination={false} />

      {/* Pagination */}
      <div className="mt-4 flex justify-end">
        <Pagination
          total={dataLength}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} của ${total} yêu cầu`
          }
          pageSize={pageSize}
          current={currentPage}
          onChange={handlePaginationChange}
        />
      </div>
    </AdminLayout>
  );
};

export default RequestWallet;
