import React, { useEffect, useState } from "react";
import { ProfileLayout } from "../../../components/Profile/Mom/ProfileLayout";
import avaImg from "../../../assets/image/other/avatar.png";
import { Form, Input } from "antd";
import {
  fetchEntities,
  updateEntity,
} from "../../../api/odataClients/momClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_ID } from "../../../api/axiosInstance";

export const DisableAccount = () => {
  const [account, setAccount] = useState();
  const [reason, setReason] = useState("");
  const navigate = useNavigate();

  const loadEntities = async () => {
    try {
      const data = await fetchEntities(`accounts(${ACCOUNT_ID()})`, {});
      setAccount(data);
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    loadEntities();
  }, []);

  const handleSubmit = () => {
    const formData = { ID: ACCOUNT_ID(), IsActive: false };
    try {
      updateEntity("accounts", ACCOUNT_ID(), formData);
      toast.success("Khóa tài khoản thành công!");

      localStorage.clear();

      navigate("/login");
    } catch (error) {
        if (error.response && error.response.status === 401) {
        
          toast.error("Quyền xác thực kh thành công, hãy thử lại.");
          localStorage.clear();
          navigate("/login"); 
        } else {
          console.error("Error blocking account:", error);
          toast.error("Khóa tài khoản thất bại!");
        }
      }
  };

  return (
    <ProfileLayout>
      <div className="p-5 lg:w-3/4 md:w-full mx-auto">
        <h2 className="text-2xl font-bold mb-6">Khóa tài khoản</h2>
        <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] ml-[40%] mb-5 rounded-full overflow-hidden">
          <img className="w-full h-full object-cover" src={avaImg} alt="" />
        </div>
        <div className="space-y-5">
          <div>
            <p>
              Gửi {account?.FullName}, <br /> <br />
              Chúng tôi rất tiếc vì tài khoản của bạn đã bị vô hiệu hóa. Sứ mệnh của chúng tôi là
              để cung cấp cho bạn một hành trình chăm sóc sức khỏe thuận tiện. Chúng tôi luôn luôn
              cố gắng hết sức để hỗ trợ bạn và hy vọng rằng chúng tôi sẽ có
              cơ hội để giải quyết mọi vấn đề hoặc khó khăn mà bạn gặp phải
              có với tài khoản của bạn.
            </p>
          </div>
          <Form onFinish={handleSubmit}>
            <Form.Item
              name="reason"
              rules={[
                { required: true, message: "Hãy nhập lý do của bạn" },
              ]}
            >
              <Input.TextArea
                id="reason"
                name="reason"
                rows="4"
                cols="50"
                placeholder="Hãy cho chúng tôi biết lý do bạn muốn vô hiệu hóa tài khoản"
                className="border-gray-200 border border-solid h-[120px] w-full rounded-2xl px-7 py-3"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              ></Input.TextArea>
            </Form.Item>
            <button
              type="submit"
              className="w-full bg-pinkColor text-white py-2 px-4 rounded-lg text-lg focus:outline-none"
            >
              Khóa tài khoản
            </button>
          </Form>
        </div>
      </div>
    </ProfileLayout>
  );
};
