import React from 'react';
import { Spin } from 'antd';  // Ant Design's loading spinner

const LoadingPage = ({isLoading}) => {
  if(!isLoading) return;
  return (
    <div className="absolute inset-0 bg-black bg-opacity-10 flex justify-center items-center z-50">
      <Spin size="large" tip="Loading..." />
    </div>
  );
};

export default LoadingPage;
