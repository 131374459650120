import React from 'react';
import LayoutCommon from '../LayoutCommon/LayoutCommon';
import { DashboardOutlined, LogoutOutlined, MessageOutlined, FileProtectOutlined, UserOutlined, WalletFilled } from '@ant-design/icons';

const AdminLayout = ({ children }) => {
  return (
<LayoutCommon 
        sidebarItems={[
          {
            label: 'Thống kê',
            icon: DashboardOutlined,
            route: '/admin/dashboard'
          },
          {
            label: 'Tài khoản',
            icon: UserOutlined,
            route: '/admin/accounts'
          },
          {
            label: 'Hợp đồng',
            icon: FileProtectOutlined,
            route: '/admin/contract'
          },
          {
            label: 'Yêu cầu rút tiền',
            icon: WalletFilled,
            route: '/admin/requestwallets'
          },
          {
            label: 'Phản hồi',
            icon: MessageOutlined,
            route: '/admin/feedback'
          },
          
        ]}
        tokenKey="authAdminToken"
      >
        {children}
      </LayoutCommon>

  );
};

export default AdminLayout;

