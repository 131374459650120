import React, { useState } from 'react'
import Header from '../components/Header/Header'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Input } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import axios from 'axios'
import "react-toastify/ReactToastify.min.css"
import { toast } from 'react-toastify'
import { API_URL_PORT } from '../api/axiosInstance'
import LoadingPage from '../components/LoadingPage/LoadingPage'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google'
import { getRoleFromToken, getIdFromToken } from '../utils/authenToken';
import Google from "../assets/image/social/google.svg"

export const Login = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const onLoginGoogleSuccess = async (response) => {
    setStatus("");
    setIsLoading(true)
    try {
      const apiResponse = await axios.post(`${API_URL_PORT}/api/auth/logingoogle`, {
        token: response.credential,
        accessToken: response.access_token
      });
      if(apiResponse.data.status === "success"){
        saveToken(apiResponse.data.data.token);
      } else if(apiResponse.data.data) {
        navigate("/register-form", { state: {
          Email: apiResponse.data.data.email,
          FullName: apiResponse.data.data.fullName
        }});
      }
    } catch (err) {
      setStatus("error");
      if (err.response && err.response.data && err.response.data.message) {
        console.log(err.response.data.message);
      } else {
        toast.error("Đăng nhập thất bại. Vui lòng thử lại!");
      }
    }finally{
      setIsLoading(false)
    }
  }

  useGoogleOneTapLogin({
    onSuccess: response => onLoginGoogleSuccess(response)
  });

  const loginGoogle = useGoogleLogin({
    onSuccess: response => onLoginGoogleSuccess(response)
  });

  const onFinish = async (values) => {
    let formData = { email: values.emailOrPhone, password: values.password };
    setStatus("");
    setIsLoading(true)
    try {
      const response = await axios.post(`${API_URL_PORT}/api/auth/login`, formData);
      const token = response.data.data.token;
      saveToken(token);
    } catch (err) {
      setStatus("error");
      if (err.response && err.response.data && err.response.data.message) {
        console.log(err.response.data.message);
      } else {
        toast.error("Đăng nhập thất bại. Vui lòng thử lại!");
      }
    }finally{
      setIsLoading(false)
    }
  };

  function saveToken(token){
    const role = Number(getRoleFromToken(token));
    const id = Number(getIdFromToken(token));

    let storageKey = "";

    switch (role) {
      case 1:
        storageKey = "authMomToken";
        navigate("/mom/home");
        break;
      case 2:
        storageKey = "authCaregiverToken";
        navigate("/caregiver/dashboard");
        break;
      case 3:
        storageKey = "authSupporterToken";
        navigate("/supporter/booking-management");
        break;
      case 4:
        storageKey = "authAdminToken";
        navigate("/admin/dashboard");
        break;
      default:
        console.error("Invalid role");
        break;
    }

    if (storageKey) {
      localStorage.setItem(storageKey, token);
      localStorage.setItem("Id", id)
    }
  }


  return (
    <>
      <LoadingPage isLoading={isLoading}/>
      <Header />
      <div className="flex items-center justify-center mt-32">
        <div className="container mx-auto px-7 w-full max-w-md">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Đăng nhập</h4>
            <p className="text-sm">
              Nhập email của bạn để đăng nhập
            </p>

            <div className="space-y-3">
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className="space-y-3"
              >
                <Form.Item
                  name="emailOrPhone"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập email của bạn!",
                    },
                  ]}
                >
                  <Input
                    status={status}
                    placeholder="Email"
                    size="large"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu của bạn!",
                    },
                  ]}
                >
                  <Input.Password
                    status={status}
                    placeholder="Mật khẩu"
                    size="large"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>

                <Form.Item>
                  <button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                  >
                    Đăng nhập
                  </button>
                </Form.Item>
              </Form>
            </div>

            <div className="relative mt-6">
              <div className="flex items-center justify-center">
                <hr className="w-full border-gray-300" />
                <span className="absolute bg-white px-2 text-gray-500 text-sm">
                  hoặc tiếp tục với
                </span>
              </div>
            </div>

            <button
              className="relative bg-white border py-2 w-full text-black rounded-lg flex items-center justify-center mt-4"
              type="button"
              onClick={() => loginGoogle()}
            >
              <div className="absolute mr-[90%]">
                <img src={Google} alt="Google" className="p-2" />
              </div>
              <span>Đăng nhập bằng Google</span>
            </button>
            
            <p className="text-sm mt-6">
              Bạn không có tài khoản,{" "}
              <Link className="text-blue-500 font-bold" to="/register">
                Đăng ký!
              </Link>
            </p>
            <p className="text-sm">
              hoặc bạn{" "}
              <Link to="/reset-pass" className="text-black font-bold">
                quên mật khẩu của bạn?
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
