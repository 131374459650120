import { Form, Input } from 'antd';
import React, { useRef } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ACCOUNT_ID, API_URL_PORT, TOKEN_API } from '../../api/axiosInstance';
import { axiosAdminInstance } from '../../api/odataClients/adminClient';
import debounce from 'lodash.debounce';

export const ChangePasswordCommon = ({ show, onClose }) => {
    const checkOldPassword = async (_, value) => {
        if (!value) {
            return Promise.reject(new Error('Hãy nhập mật khẩu cũ của bạn'));
        } else {
            const response = await axios.post(`${API_URL_PORT}/api/auth/checkoldpassword`, { Id: ACCOUNT_ID(), Password: value.trim() }, {
                headers: {
                    Authorization: `Bearer ${TOKEN_API}`
                }
            });
            if (response.data.status === "success") {
                return Promise.resolve();
            }
            return Promise.reject(new Error(response.data.message));
        }
    }

    const hanldeSubmit = async (value) => {
        try {
            await axiosAdminInstance.post(`${API_URL_PORT}/api/auth/changepassword`, {
                AccountId: ACCOUNT_ID(),
                OldPassword: value.oldPassword,
                NewPassword: value.newPassword,
                ConfirmPassword: value.confirmPassword
            })
            setTimeout(() => onClose(), 2000)
        } catch (err) {
            console.log(err)
            if (err.response && err.response.data && err.response.data.message) {
                console.log(err.response.data.message);
            } else {
                toast.error("Đổi mật khẩu không thành công.");
            }
        }
    }

    if (!show) return <></>;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white h-[auto] w-[600px] p-10 rounded-lg shadow-lg relative">
                <h3 className='text-lg text-center'>Đổi mật khẩu</h3>
                <button className="absolute top-2 right-2" onClick={onClose}>
                    <FaRegTimesCircle className='w-6 h-6 m-2' />
                </button>
                <Form onFinish={hanldeSubmit} status="error" layout='vertical'>
                    <Form.Item
                        label="Mật khẩu cũ"
                        name="oldPassword"
                        rules={[
                            {
                                validator: checkOldPassword
                            },
                        ]}
                        validateTrigger="onSubmit"
                    >
                        <Input.Password
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Mật khẩu mới của bạn"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Mật khẩu mới"
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Hãy nhập mật khẩu mới' },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Mật khẩu phải dài ít nhất 8 ký tự và bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt'
                            }
                        ]}
                    >
                        <Input.Password
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Mật khẩu mới của bạn"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Nhập lại mật khẩu"
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true, message: 'Vui lòng xác nhận mật khẩu của bạn' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Hai mật khẩu không khớp nhau!'));
                                },
                            }),
                        ]}
                        validateTrigger="onSubmit"
                    >
                        <Input.Password
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Xác nhận mật khẩu mới của bạn"
                        />
                    </Form.Item>
                    <button
                        type="submit"
                        className="w-full mt-3 bg-pinkColor text-white py-2 px-4 rounded-lg"
                    >
                        <strong>Đổi</strong>
                    </button>
                </Form>
            </div>
        </div>
    );
};
