import React, { useState } from 'react'
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaRuler, FaWeight } from "react-icons/fa";
import CalculateBMI from './CalculateBMI';
import BMIHealthMom from '../../assets/data/BMIHealthMon';

const CalculateMomBMI = ({ momHealth, week = 1, onSubmitForm }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleCalculator = () => {
    setIsPopupOpen(true);
  }
  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  const handleBmiUpdated = (value) => {
    onSubmitForm(value);
  };

  return (
    <>
      <CalculateBMI isOpen={isPopupOpen}
        onClose={handleClosePopup}
        onBmiUpdated={handleBmiUpdated} />
      <div className="bg-[#fbe2f096] h-auto mt-7 rounded-[40px] text-center shadow-lg mx-6 p-6">
        <h3 className="text-2xl font-bold pt-4">Tính chỉ số BMI của mẹ</h3>

        <div className="flex flex-wrap justify-center text-center bg-[#87d5f4] mx-auto max-w-[90%] sm:max-w-[700px] p-4 mt-4 rounded-[30px] text-[16px] sm:text-[20px] gap-4">
          <div className="flex items-center justify-center px-2 sm:px-4 pt-2 w-full sm:w-auto">
            <FaRuler className="ml-2 sm:ml-3 text-[16px] sm:text-[30px] mr-2" />
            <span className="text-[16px] sm:text-[20px] mr-2">Chiều cao:</span>
            <span className="text-white">{momHealth ? `${momHealth.Height} cm` : '---'}</span>
          </div>

          <div className="flex items-center justify-center px-2 sm:px-4 pt-2 w-full sm:w-auto">
            <FaWeight className="ml-2 sm:ml-3 text-[16px] sm:text-[30px] mr-2" />
            <span className="text-[16px] sm:text-[20px] mr-2">Cân nặng :</span>
            <span className="text-white">{momHealth ? `${momHealth.Weight} kg` : '---'}</span>
          </div>

          <div className="flex items-center justify-center px-2 sm:px-4 pt-2 w-full sm:w-auto">
            <span className="text-[16px] sm:text-[20px] mr-2">BMI:</span>
            <span className="text-white">{momHealth ? momHealth.BMIIndex : '---'}</span>
          </div>

          <div className="w-full sm:w-auto flex justify-center sm:justify-start">
            <button className="bg-pinkColor p-2 rounded-full px-14 text-white" onClick={handleCalculator}>
              Tính toán
            </button>
          </div>
        </div>

        <div className="border border-black mx-auto mt-4 rounded-[30px] max-w-[1000px]">
          <div className="flex flex-wrap justify-center items-center text-center bg-[#87d5f4] p-2 text-[16px] sm:text-[20px] gap-x-2 sm:gap-x-4 rounded-tr-[30px] rounded-tl-[30px] border-b-2 border-black">
            <div className="flex-1 text-center px-2 sm:px-4 justify-center pt-2">
              <span className="text-[16px] sm:text-[20px] mr-2">
                BMI <br /> trước khi sinh
              </span>
            </div>

            <span className="border-l h-[40px] border-black hidden sm:inline-block"></span>

            <div className="flex-1 text-center px-2 sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px] mr-2">
                &gt; 18.5
              </span>
            </div>

            <span className="border-l h-[40px] border-black hidden sm:inline-block"></span>

            <div className="flex-1 text-center px-2 sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px] mr-2">
                18.5 - 25.9
              </span>
            </div>

            <span className="border-l h-[40px] border-black hidden sm:inline-block"></span>

            <div className="flex-1 text-center px-2 sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px] mr-2">26 - 29</span>
            </div>

            <span className="border-l h-[40px] border-black hidden sm:inline-block"></span>

            <div className="flex flex-1 items-center px-2 sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px] mx-auto mr-[20px]">
                &lt; 30
              </span>
              <AiOutlineInfoCircle
                className="sm:text-[20px] ml-2 bg-pinkColor rounded-full text-white"
                style={{ fontSize: "30px", cursor: "pointer" }}
              />
            </div>
          </div>

          <div className="flex justify-center items-center text-center bg-[#87d5f4] p-2 text-[16px] sm:text-[20px] gap-4">
            <div className="flex-1 text-center sm:px-4 justify-center pt-2">
              <span className="text-[16px] sm:text-[20px]">Tuần</span>
            </div>

            <span className="border-l h-[40px] border-black"></span>

            <div className="flex-1 text-center sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[19px]">Thiếu cân</span>
            </div>

            <span className="border-l h-[40px] border-black"></span>

            <div className="flex-1 text-center sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[19px]">Bình thường</span>
            </div>

            <span className="border-l h-[40px] border-black"></span>

            <div className="flex-1 text-center sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[19px]">Thừa cân</span>
            </div>

            <span className="border-l h-[40px] border-black"></span>

            <div className="flex-1 text-center sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[19px]">Béo phì</span>
            </div>
          </div>

          <div className="flex justify-center items-center text-center bg-[#87d5f4] p-4 text-[16px] sm:text-[20px] gap-4 rounded-br-[30px] rounded-bl-[30px]">
            <div className="flex-1 text-center px-2 sm:px-4 justify-center pt-2">
              <span className="text-[16px] sm:text-[20px] mr-6">{week + 1}</span>
            </div>

            <span className="border-l h-[40px] border-black"></span>

            <div className="flex-1 text-center px-2 sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px]">{BMIHealthMom.underweight[week]}</span>
            </div>

            <span className="border-l h-[40px] border-black"></span>

            <div className="flex-1 text-center sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px]">{BMIHealthMom.normal[week]}</span>
            </div>

            <span className="border-l h-[40px] border-black"></span>

            <div className="flex-1 text-center px-2 sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px]">{BMIHealthMom.overweight[week]}</span>
            </div>

            <span className="border-l h-[40px] border-black flex-shrink-0"></span>

            <div className="flex-1 text-center px-2 sm:px-4 pt-2">
              <span className="text-[16px] sm:text-[20px]">{BMIHealthMom.obese[week]}</span>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CalculateMomBMI