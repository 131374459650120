import React, { useEffect, useState } from 'react'
import { Form, Input, DatePicker, Radio, Select } from 'antd';
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { createEntity, fetchEntities } from '../../api/odataClients/momClient';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../LoadingPage/LoadingPage';
import { ACCOUNT_ID } from '../../api/axiosInstance';
const { Option } = Select;


export const AddNewBookingSchedule = ({ examination, show, onClose, onSuccess }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isServiceInfo, setIsServiceInfo] = useState("");
    const [serviceInfo, setServiceInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [account, setAccount] = useState()
    const [error, setError] = useState();

    const onFinish = async (values) => {
        setLoading(true)
        let formData = {
            AccountID: ACCOUNT_ID(),
            ScheduleID: examination.Id,
            CreatedAt: dayjs().toDate().toISOString(),
            BookingAt: values.ExpectedBooking,
            Status: 'Đang chờ'
        };
        try {
            const rspBooking = await createEntity("scheduleBookings", formData);
            toast.success("Tạo lịch hẹn khán thành công!");

            const checkGroup = await checkGroupChat()
            if (checkGroup) {
                const messSystem = `<p><a id='${examination.Id}' class='${rspBooking.Id}-examination' href=""><strong>${examination.Content}</strong></a></p>`
                await createNewGroupChat(checkGroup, messSystem)
            } else {
                toast.error("Hệ thống đang bận, không thể tạo đoạn trò chuyện")
            }
        } catch (error) {
            console.error("Error create schedule:", error);
            toast.error("Tạo lịch hẹn khám thất bại!");
        }
        onSuccess();
        setIsServiceInfo(false);
    };

    const createNewGroupChat = async (dataSend, mess) => {
        try {
            let newMessage = null
            if (dataSend.chatBoxExists === true) {
                newMessage = {
                    GroupID: dataSend.commonGroupID,
                    Content: mess,
                    CreatedByID: dataSend.momId,
                    ImageUrl: null,
                    IsMessageSys: true,
                    CreatedAt: dayjs().toISOString()
                };
            } else {
                const rspGroupChat = await createEntity("groupchats", { Name: dataSend.groupName });
                await createEntity("groupmembers", { GroupID: rspGroupChat.Id, AccountID: dataSend.momId });
                await createEntity("groupmembers", { GroupID: rspGroupChat.Id, AccountID: dataSend.suppoterId });

                newMessage = {
                    GroupID: rspGroupChat.Id,
                    Content: mess,
                    CreatedByID: dataSend.momId,
                    ImageUrl: null,
                    IsMessageSys: true,
                    CreatedAt: dayjs().toISOString()
                };
            }

            if (newMessage) {
                await createEntity("messages", newMessage);
                navigate('/mom/chat', { state: { commonGroupID: newMessage.GroupID } })
            } else {
                toast.error('Lỗi tạo tin nhắn')
            }
        } catch (e) {
            console.error("Error saving message:", e);
        }
    }

    const checkGroupChat = async () => {
        try {
            const supporterId = examination.CreatedByID;
            const dataAccount = await fetchEntities('groupmembers', {
                filter: `AccountId eq ${ACCOUNT_ID()}`,
                select: ["GroupID"]
            });
            const accountGroupIDs = dataAccount.value.map(message => message.GroupID);

            const dataCaregiver = await fetchEntities('groupmembers', {
                filter: `AccountId eq ${supporterId}`,
                select: ["GroupID"]
            });
            const caregiverGroupIDs = dataCaregiver.value.map(message => message.GroupID);

            const commonGroupID = accountGroupIDs.find(groupID => caregiverGroupIDs.includes(groupID));
            const chatBoxExists = Boolean(commonGroupID);

            const formData = {
                chatBoxExists: chatBoxExists,
                momId: ACCOUNT_ID(),
                suppoterId: supporterId,
                commonGroupID: commonGroupID,
                groupName: `${account.FullName}, ${examination.CreatedBy.FullName}`
            }

            return formData
        } catch (err) {
            toast.error("Kiểm ta hộp thoại");
        }
        return null
    }

    const handleLoadingDataAccount = async () => {
        try {
            const dataAccount = await fetchEntities(`accounts(${ACCOUNT_ID()})`, { select: ["FullName", "Sex", "DateOfBirth"] });
            form.setFieldsValue({
                Name: dataAccount.FullName,
                DateOfBirth: dayjs(dataAccount.DateOfBirth),
                Sex: dataAccount.Sex === false ? "Nữ" : "Nam"
            });
            setAccount(dataAccount)
        } catch (err) {
            setError(err)
            toast.error("Lỗi hệ thống")
        }
    }

    const handleLoadingDataSchedule = async () => {
        try {
            if (examination.Id) {
                const dataSchedule = await fetchEntities(`schedules(${examination.Id})`, { select: ["Content", "HospitalName", "Address"] });
                form.setFieldsValue({
                    Content: dataSchedule.Content,
                    HospitalName: dataSchedule.HospitalName,
                    Address: dataSchedule.Address
                });
                setServiceInfo(dataSchedule);
            }
        } catch (err) {
            setError(err)
        }

    }

    useEffect(() => {
        // loadEntities();
        handleLoadingDataAccount();
        handleLoadingDataSchedule();
    }, []);

    useEffect(() => {
        handleLoadingDataSchedule();
        form.setFieldsValue({
            ExpectedBooking: null
        })
    }, [examination]);

    const handleChangeInfoService = () => {
        if (isServiceInfo) {
            form.submit();
        } else {
            setIsServiceInfo(true);
        }
    };

    if (!show) return <></>;
    return (
        <>
            <LoadingPage isLoading={loading} />
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white h-[auto] w-[700px] p-10 rounded-lg shadow-lg relative">
                    <h3 className='text-3xl text-center font-bold pb-4 text-pinkColor'>Thông tin đặt khám</h3>
                    <button className="absolute top-2 right-2" onClick={onClose}>
                        <FaRegTimesCircle className='w-6 h-6 m-2' />
                    </button>

                    <Form
                        form={form}
                        onFinish={onFinish}
                    >
                        <p name='Content' className='text-center'>Dịch vụ khám: <strong>{serviceInfo.Content} - {serviceInfo.HospitalName}</strong></p>
                        <Form.Item
                            rules={[{ required: true, message: 'Tên bệnh nhân' }]}
                            className='mb-0 mt-3 w-full'
                            name="Name"
                            value
                        >
                            <Input
                                className="w-full p-3 border border-gray-300 rounded-lg"
                                placeholder="Tên bệnh nhân"

                            />
                        </Form.Item>
                        <div className='grid grid-cols-2 gap-2'>
                            <Form.Item
                                rules={[{ required: true, message: 'Giới tính' }]}
                                className='mb-0 mt-3 w-full'
                                name="Sex"
                            >
                                <Select className="w-full border border-gray-300 rounded-lg"
                                    placeholder="Giới tính"
                                    defaultValue={'Nữ'}>
                                    <Option value="Nữ">
                                        Nữ
                                    </Option>
                                    <Option value="Nam">
                                        Nam
                                    </Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                rules={[{ required: true, message: 'Vui lòng chọn năm sinh' }]}
                                className='mb-0 mt-3 w-full'
                                name="DateOfBirth"
                            >
                                <DatePicker
                                    className="w-full border border-gray-300 rounded-lg"
                                    placeholder="Năm sinh"
                                    format="YYYY"
                                    picker="year"
                                />
                            </Form.Item>
                        </div>

                        <div className=''>
                            <Form.Item
                                name="ExpectedBooking"
                                className='mb-0 mt-3 w-full'
                                rules={[{ required: true, message: 'Vui lòng chọn ngày hẹn!' }]}
                            >
                                <DatePicker
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                    disabledDate={(current) => {
                                        const today = dayjs().startOf('day');
                                        const twoWeeksLater = dayjs().add(2, 'week').endOf('day');
                                        return current && (current < today || current > twoWeeksLater);
                                    }}
                                    disabledTime={(date) => {
                                        const hours = Array.from({ length: 24 }, (_, i) => i);
                                        const disabledHours = hours.filter((hour) => hour < 8 || hour > 17);
                                        return {
                                            disabledHours: () => disabledHours,
                                        };
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                    showTime
                                />
                            </Form.Item>
                        </div>



                        <div className='flex gap-4 items-center justify-center p-2 mx-4'>
                            <button onClick={onClose} className='bg-pinkColor px-14 mt-4 py-3 rounded-lg text-[18px] text-white hover:opacity-70'>Huỷ</button>
                            <button onclick={handleChangeInfoService} type="submit" className='bg-white px-14 mt-4 py-3 rounded-lg text-[18px] text-pinkColor border border-pinkColor hover:bg-pinkColor hover:text-white'>
                                Đặt lịch</button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};
